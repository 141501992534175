import { useEffect, useState } from "react";
import PageHeading from "../components/PageHeading.component";
import Select from 'react-select'
import '../styles/OnSiteSelling.css';

import EventsService from "../services/events.service";
import AuthService from "../services/auth.service";

function InvitationsBooking() {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [total, setTotal] = useState(0);
    const [customerName, setCustomerName] = useState("Otayo Onsite Selling");
    const [customerEmail, setCustomerEmail] = useState("onsite@otayo.com");
    const [customerPhone, setCustomerPhone] = useState('4669999');
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [autoCheckin, setAutoCheckin] = useState('no');
    const [loading, setLoading] = useState(false);
    const [orderID, setOrderID] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            if(user!=null) {
                getAllEvents();
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                window.location.href='/login';
            }
        })
        .catch((err) => {
            setIsLoggedIn(false);
            console.log(err);
            window.location.href='/login';
        });
    }, [])

    useEffect(() => {
        if(isLoggedIn==false) {
            window.location.href='/login';
        }
    } , [isLoggedIn]);

    const getAllEvents = () => {
        EventsService.getAllEvents()
        .then((res) => {
            // console.log({res});
            setEvents(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }
    
    const eventChanged = (event) => {
        setSelectedEvent(event);
        EventsService.getEventTickets(event.value)
        .then((res) => {
            console.log("Tickets :", res);
            setTickets(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const calculateTotal = () => {
        let total = 0;
        tickets.forEach((ticket) => {
            total += ticket.price*ticket.quantity;
        } );
        setTotal(total);
    }

    const updateQuantity = (e, index) => {
        if(e.target.value>=0) {
            var newTickets = [...tickets];
            newTickets[index].quantity = e.target.value;
            setTickets(newTickets);
            calculateTotal();
        }
    } 

    const checkinTickets = (orderID) => {
        console.log("checkinTickets: ", orderID);
        EventsService.checkinTickets(orderID)
        .then(res => {
            console.log({res});
        })
        .catch(err => {
            console.log({err});
        });
    }

    const placeOrder = () => {
        setOrderID(0);
        setLoading(true);
        if(selectedEvent && tickets.length>0) {
            EventsService.placeOrder(selectedEvent, tickets, customerName, customerEmail, customerPhone, total, paymentMethod, autoCheckin)
            .then((res) => {
                console.log(res);
                setOrderID(res.data);
                checkinTickets(res.data);
                eventChanged(selectedEvent);
                clearAll();
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        }
    }

    const clearAll = () => {
        // setTotal(0);
        // setCustomerName("Otayo Onsite Selling");
        // setCustomerEmail("onsite@otayo.com");
        // setCustomerPhone("4669999");
        // setPaymentMethod("cash");
        var newTickets = [...tickets];
        newTickets.forEach((ticket, index) => {
            newTickets[index].quantity = 0;
        } );
        setTickets(newTickets);
        calculateTotal();
    }

    return (
        <div className="page-wrapper">
        <div className="page-wrapper shadow-lg p-4">
            <div className="row">
                <div className="col heading-h1-wrapper heading-h1-wrapper-onsiteselling">
                    <h1 className="text-lg text5e4642">Invitation Booking</h1>
                    <Select 
                    options={events} 
                    placeholder="Select an Event" 
                    onChange={(option) => eventChanged(option)}
                    />
                </div>
                <hr />
            </div>

            {selectedEvent && (
            <>
                <div className="row">
                    <div className="col">
                        <div className="event-details-wrapper" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}}>
                            <h2>{selectedEvent.label}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col tickets-heading">
                        Select Tickets Below
                    </div>
                </div>
                {tickets && (
                <div className="container">
                    <div className="row">
                        <div className="col event-details-tickets-wrapper">
                            <div className="event-details-tickets">
                                {tickets.map((ticket, index) => (
                                <div className="ticket-wrapper">
                                    <div className="ticket-name">{ticket.label}</div>
                                    <div className="ticket-price">Rs {ticket.price_formatted}</div>
                                    <div className={parseInt(ticket.stock)>0 ? "remaining-tickets":"remaining-tickets soldout"}>{ticket.stock} Left</div>
                                    <div className="quantity">
                                        {parseInt(ticket.stock)>0 && 
                                            <input type="number"  className="form-control" onChange={(e)=> updateQuantity(e, index)} value={ticket.quantity} />
                                        }
                                        {parseInt(ticket.stock)<1 && 
                                            <span class="soldout">Sold-Out</span>
                                        }
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col add-tickets-buttons">
                            <button className="btn btn-success">Add Tickets</button>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col tickets-heading">
                            Enter Customer Details 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col customer-details-form">
                                <div className="form-group">
                                    <label htmlFor="customer-name">Customer Name</label>
                                    <input type="text" className="form-control" id="customer-name" placeholder="Enter Customer Name" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customer-phone">Phone Number</label>
                                    <input type="number" className="form-control" id="customer-phone" placeholder="Enter Customer Phone" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)}  />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customer-email">Customer Email</label>
                                    <input type="email" className="form-control" id="customer-email" placeholder="Enter Customer Email" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)}  />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customer-email">Auto CheckIn</label>
                                    <select class="form-control form-control-select select-payment" value={autoCheckin} onChange={e => setAutoCheckin(e.target.value)}>
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col book-tickets-buttons">
                            <button className="btn btn-danger" onClick={e => clearAll()}>Clear</button>
                            <div className="total-wrapper">Total: Rs {total}</div>
                            <div className="booktickets-payment">
                                <select className="form-control form-control-select select-payment" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)}>
                                    <option value="cash">Cash</option>
                                    <option value="terminal">Terminal Payment</option>
                                </select>
                                <button className="btn btn-success" disabled={total==0 || loading} onClick={e => placeOrder()}>
                                    {loading ? "Creating Order..." : "Book Now"}
                                </button>
                            </div>
                        </div>
                    </div>
                    {orderID>0 && 
                    <div className="row">
                        <div className="col download-tickets">
                            <div class="alert alert-success" role="alert">
                                <span><b>Invitation Order# {orderID} Added Successfully!</b></span> <span><a href={"https://otayo.com/phantom/physicalticket-onsite.php?order="+orderID+"&admin=pos"} target="_blank">Download Tickets</a></span> 
                            </div>
                        </div>
                    </div>
                    }
                </div>
                )}
            </>
            )}
        </div>
        </div>
    );
}

export default InvitationsBooking;