import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { DataGrid  } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

import "../styles/CurrentEvents.css"
import EventsService from "../services/events.service";

import Spinner from '../components/LoadingState/Spinner';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import SearchIcon from '@mui/icons-material/Search';
import EventCard from "../components/Cards/EventCard";
import { set } from "react-hook-form";
// import Pagination from "../components/pagination.component";
import { Divider, Pagination } from "@mui/material";


// react functional component
const CurrentEvents = () => {

    const [data, setData] = useState([]);
    const [columnsheadings, setColumnsheadings] = useState([
        { field: 'id', headerName: 'Event ID', flex: 1, 
                 renderCell: (cellValues) => {
                return (
                    <div className="color-orange break-words font-bold">#{cellValues.row.id}</div>
                )
            },
        },
        { field: 'eventname', headerName: 'Event Name', flex: 1, cellClassName: 'flexcenter',
        renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px"}}>
                    {cellValues.row.eventname}
                </div>
                )
            }
        },
        { field: 'producer', headerName: 'Producer', flex: 2, cellClassName: 'flexcenter', 
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "start", padding:"10px", color: "#00aff2"}}>
                    <a href={"/edit-producer/"+cellValues.row.producer.id} className="color-orange font-bold underline break-words text-left" style={{color: "#00aff2"}}>{cellValues.row.producer.name}</a>
                </div>
                )
            }
        },
        { field: 'eventdate', headerName: 'Event Date', flex: 1, cellClassName: 'flexcenter', renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', width: '100%', alignItems: "center", padding:"10px", color: "#b7b7b7"}}>
                    {cellValues.row.eventdate}
                </div>
                )
            }
        },
        { field: 'eventype', headerName: 'Event Type', flex: 1, cellClassName: 'flexcenter', 
        renderCell: (cellValues) => {
                return (
                    <div className="flex flex-col justify-center items-center">
                        <Chip className="mb-2" color="success" label={cellValues.row.seated} />
                        <Chip className="" color="error" label={cellValues.row.collab} />
                    </div>
                ) 
            }
        },
        { field: 'ticketsallocated', headerName: 'Total No. Of Tickets Allocated', flex: 1, cellClassName: 'flexcenter' },
        { field: 'totalsold', headerName: 'Total Sold', flex: 1, cellClassName: 'flexcenter' },
        { field: 'totalinvites', headerName: 'Total Invites', flex: 1, cellClassName: 'flexcenter' },
        { field: 'totalreserved', headerName: 'No. Tickets Reserved', flex: 1, cellClassName: 'flexcenter' },
        { field: 'remaining', headerName: 'Remaining', flex: 1, cellClassName: 'flexcenter' },
        { field: 'totalsales', headerName: 'Total Sale (Without Discounts)', flex: 1, cellClassName: 'flexcenter', 
        renderCell: (cellValues) => {
                return (
                    <>
                        {"Rs. "+cellValues.row.totalsales}
                    </>
                ) 
            }
        },
        { field: 'status', headerName: 'Status', flex: 1, cellClassName: 'flexcenter', 
            renderCell: (cellValues) => {
                return (
                <div className="flex flex-col justify-center items-center" data-last5days={cellValues.row.last5days}>
                    {cellValues.row.remaining < 50 && cellValues.row.remaining > 0 && <Chip className="mb-2" color="error" label="Low Stock" />}
                    {cellValues.row.last5days > 100 && <Chip color="success" label="Selling Fast" />}
                </div>
                ) 
            }
        },
        { field: 'report', headerName: 'Report', flex: 1, cellClassName: 'flexcenter',  
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center", padding:"10px"}}>
                    <a href={"/event/"+cellValues.row.id} className="color-orange break-words underline">View Report &gt;</a>
                </div>
                ) 
            }
        }
    ]);
    const [limit, setLimit] = useState(20);
    const [rowCount, setRowCount] = useState(0);
    const [dataloader, setDataLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setsearch] = useState('');

    const [totalItems, settotalItems] = useState()
    
    const onFilterChange = () => {}
    const handleSortModelChange = () => {

    }

    const PaginationTotal = Math.ceil(totalItems / 5);

    const searchClick = () => {
        setDataLoader(true);
        setData(0)
        console.log("type")
        EventsService.getAllEventsCurrent(search, 0, limit, null)
        .then(res => {
            console.log(res.data);
            settotalItems(res.data)
            // setData(res.data);
            // setDataLoader(false);
            getEventDetails(1);
            setRowCount(res.data);
            setTotalPages(res.data);
        })
        .catch(err => {
            console.log(err);
            setDataLoader(false);
        })
    }

    console.log ('totItem' , totalItems)
    useEffect(() => {
        if(!dataloader) {
            searchClick();
        }
    }, []);

    const getEventDetails= async (totalPages) => {
        var allData = [];
        for(var i=1; i<=totalPages; i++) {
            await EventsService.getAllEventsCurrent(search, i, limit, null)
            .then(res => {
                console.log(res.data);
                allData.push(...res.data);
                console.log("allData:", allData)
                // if(i==totalPages) {
                    
                // }
            })
            .catch(err => {
                console.log(err);
                setDataLoader(false);
            });
        }

        setData(allData);
        setDataLoader(false);
    }

    const getEventDetailsPage = async (page) => {
        var allData = [];
        setDataLoader(true);
        // for(var i=1; i<=totalPages; i++) {
            EventsService.getAllEventsCurrent(search, page, limit, null)
            .then(res => {
                console.log(res.data);
                allData.push(...res.data);
                console.log("allData:", allData)
                setData(allData);
                setDataLoader(false);
            })
            .catch(err => {
                console.log(err);
                setDataLoader(false);
            });
        // }
    }

    const currentPage = (page) => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        //   });
        setPage(page)
        setData(0);
        getEventDetailsPage(page)
    }

    return (
        <>
        <div className='d-none d-lg-block row'>
            <div className='col-md-12'>
                <div className='drop-shadow-lg EventsArchive bg-white pr-4 pl-4 pt-4 pb-4'>
                    <div className="flex justify-between">
                        <div class="title font-bold mb-3 h1 text-lg text5e4642">Otayo Current Events <b className="text-red-700">{dataloader && 'Loading events 5 at a time...'}</b></div>
                        <div className="flex flex-row">
                            <div className="flex flex-start mr-4">
                                <input type="text" value={search} className="form-control rounded-none" onChange={e => setsearch(e.target.value)} />
                                <button type="button" className="btn btn-sm btn-primary bg-green-700 hover:bg-green-800 rounded-none text-white font-bold mr-2" onClick={e => searchClick()}>Search</button>
                                <button type="button" className="btn btn-sm btn-primary bg-amber-500 hover:bg-amber-800 rounded-none text-black font-bold" onClick={e => window.location.reload()}>Reset</button>
                            </div>
                            <a href="/events-archive" className=" btn btn-warning btn-sm bg-orange-700 hover:bg-orange-800 text-white p-2 rounded-none font-bold">View Archived Events</a>
                        </div>
                    </div>
                    <p className="text-black mb-5">On the dashboard you will details on all events currently being sold via Otayo including the event details and ticket sales.</p>
                    <div class="table-responsive">
                        <DataGrid
                            rows={data}
                            columns={columnsheadings}
                            pageSize={5}
                            rowsPerPageOptions={[10, 20, 50]} 
                            autoHeight={true}
                            // rowHeight={100}
                            getEstimatedRowHeight={() => 200}
                            getRowHeight={() => 'auto'}
                            pagination 
                            rowCount={totalPages}
                            paginationMode="server"
                            filterMode="server"
                            onFilterModelChange={() => onFilterChange()}
                            loading={dataloader}
                            onPageChange={async (data) => {
                                console.log({data});
                                await setPage(data+1);
                                getEventDetailsPage(data+1);
                            }}
                            page={page - 1}
                            onPageSizeChange={(newPage) => setPage(newPage)} 
                            sortingMode="server" 
                            onSortModelChange={() => handleSortModelChange()}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class='d-lg-none' style={{padding : "0px 10px 0px 10px"}}>
            <div className="d-flex justify-content-between align-items-center">
             <p className='' style={{fontWeight : "700", marginLeft : "5px" ,color : "grey"}}>
              OTAYO Events
             </p>
            {data.length > 0  ? 

             <div className="d-flex gap-2">
              <button type="button" className="text-orange-600" onClick={e => window.location.reload()}><SyncOutlinedIcon/></button>
              <a href="/events-archive" className="text-white font-semibold bg-orange-600 px-2 py-1 rounded-sm text-xs">Archived Events</a>
             </div>

             :
              <></>
            }
            </div>

            <div className="d-flex gap-2 mb-3 mt-2">
            
             <input type="text" placeholder="Search Event" value={search} className="form-control rounded-sm text-sm py-2" onChange={e => setsearch(e.target.value)} />
             <button type="button" className="bg-orange-600 rounded-sm text-white px-2"
             onClick={e => searchClick()}><SearchIcon/></button>
             
            </div>
            
            <div className="d-flex flex-col gap-3">

            {data.length > 0 ? data.map( ( eventData , index) => (
             <EventCard eventData={eventData} />
            )) : <div className="d-flex justify-content-center align-items-center"><Spinner/></div>
             }
            </div>

            <div className="d-flex flex-col align-items-center gap-2 justify-content-center pt-3">
            <Pagination
            //  hidePrevButton hideNextButton
             shape="rounded" 
             count={PaginationTotal}
             page={totalPages} 
             onChange={(event, value) => currentPage(value)}/>

             <p className="text-xs text-secondary">{page} of {PaginationTotal}</p>
            </div>

            {/* <div>
                <Pagination total={totalItems} current={page} limit={5} onPageChange={currentPage} /> 
            </div> */}
            
        </div>
       </>
    );
};

export default CurrentEvents;