import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";


import "../styles/Posmain.css"
import POSMainCard from "../components/Cards/POS/POSMainCard";
import Spinner from '../components/LoadingState/Spinner'
import { Divider, Drawer } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

export const optionsBArChart = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right"
    },
  }
};

const POSMain = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [posName, setPosName] = useState('');
    const [posEmail, setPosEmail] = useState('');
    const [posEmail1, setPosEmail1] = useState('');
    const [posEmail2, setPosEmail2] = useState('');
    const [posLocation, setPosLocation] = useState('');
    const [posOperatingHoursDays, setPosOperatingHoursDays] = useState('');
    const [posPhones, setPosPhones] = useState('');
    const [posStatus, setPosStatus] = useState('');
    const [posComments, setPosComments] = useState('');
    const [allPOS, setAllPOS] = useState([]);
    const [loading, setloading] = useState('Loading...');
    const [dataCashPerPos, setDataCashPerPos] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });
    const [dataPaymentMethodPOS, setDataPaymentMethodPOS] = useState({
    labels: [],
    datasets: [{
        data: [],
        backgroundColor: [],
    }]
    });

    const [CNPDrawerOpen, setCNPDrawerOpen] = useState(false)

    const handleCNPOS = () => {
        setCNPDrawerOpen(!CNPDrawerOpen);
      };

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                PosService.getAllPOS()
                .then(response => {
                    console.log('POS response', {response});
                    setAllPOS(response.data);
                    preparePOSPaymentMethodsChart(response.data);
                    setloading('');
                    var templabel = [];
                    var tempData = [];

                    response.data.map((item, index) => {
                        templabel.push(item.pos_name);
                        console.log(item.pos_name);
                        tempData.push(item.poscashbalance.totalCash);
                        console.log(item.poscashbalance.totalCash);
                    });

                    setDataCashPerPos({
                        labels: templabel,
                        datasets: [{
                            data: tempData,
                            backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                        }]
                    });
                })
                .catch(err => console.log({err}));
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const createPOS = (e) => {
        e.preventDefault();
        PosService.createPOS(posName, posEmail, posEmail1, posEmail2, posLocation, posOperatingHoursDays, posPhones, posStatus, posComments)
        .then(response => {
            console.log('POS response', {response});
            setIsOpen(false);
            swal('POS Created', "The POS has been created", "success");
            PosService.getAllPOS()
            .then(response => {
                console.log('POS response1', {response});
                setAllPOS(response.data);
                preparePOSPaymentMethodsChart(response.data);
                var templabel = [];
                var tempData = [];

                response.data.map((item, index) => {
                    templabel.push(item.pos_name);
                    console.log(item.pos_name);
                    tempData.push(item.poscashbalance.totalCash);
                    console.log(item.poscashbalance.totalCash);
                });

                setDataCashPerPos({
                    labels: templabel,
                    datasets: [{
                        data: tempData,
                        backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                    }]
                });
            })
            .catch(err => console.log({err}));
        })
        .catch(err => console.log({err}));
    }

    const preparePOSPaymentMethodsChart = (data) => {
        var templabel = [];
        var tempData = [
          {
            label: "Credit Card / Terminal",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: []
          },

          {
            label: "Cash",
            backgroundColor: "rgba(155,231,91,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: []
          }
        ]

        data.map((item, index) => {
            templabel.push(item.pos_name);
            // console.log(item.pos_name);
            tempData[0].data.push(item.perpaymentmethods.cash);
            tempData[1].data.push(item.perpaymentmethods.terminal);
            // console.log(item.poscashbalance.totalCash);
        });

        setDataPaymentMethodPOS({
            labels: templabel,
            datasets: tempData
        });
    }


    return (
        <>
        <div className="salesreport-wrapper p2 d-none d-lg-block">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-xl mb-2 text5e4642">POS Overview <small className="text-red-600">{loading}</small></h1>
                        <div>
                        <button className="btn btn-primary bg-blue-500 hover:bg-blue-600 rounded-none font-semibold text-sm" onClick={(e) => setIsOpen(e)}>Create New POS >></button>
                        <a href="/pos-notifications" className="btn btn-warning btn-orange ml-4 font-semibold text-sm">Email Notifications Section >></a>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="uppercase">POS (overview)</th>
                                    <th scope="col" className="uppercase">Sales Report</th>
                                    <th scope="col" className="text-center uppercase">Last Money Collection Date</th>
                                    <th scope="col" className="text-center uppercase">POS Cash Balance</th>
                                    <th scope="col" className="text-center uppercase">This Month Sales</th>
                                    <th scope="col" className="text-center uppercase">Last Month Sales</th>
                                    <th scope="col" className="text-center uppercase">Landline</th>
                                    <th scope="col" className="text-center uppercase">Status</th>
                                    <th scope="col" className="text-center uppercase">Issue</th>
                                    <th scope="col" className="text-center uppercase">Ticket Stock</th>
                                    <th scope="col" className="text-center uppercase">Pochette Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPOS.length>0 && allPOS.map((pos, index) => (
                                    <tr key={index}>
                                        <td className="text-sm"><a href={`/pos/`+pos.id} className="font-bold" style={{color: "#03a9f4"}}>{pos.pos_name}</a></td>
                                        <td className="flex flex-col">
                                            <a className="mb-1 text-blue-500 font-semibold text-sm" href={`/pos-sales/`+pos.id}>Sales Report</a><a className="mb-1 text-red-500 font-semibold text-sm" href={`/pos-collection/`+pos.id}>Money Collection Report</a><a className="mb-1 text-green-500 font-semibold text-sm" href={`/pos-performance/`+pos.id}>Staff Performance</a>
                                        </td>
                                        <td className="text-center text-sm">{pos.lastcollection}</td>
                                        <td className="text-center text-sm">Rs {pos.poscashbalance.totalCash.toLocaleString('en-US')}</td>
                                        <td className="text-center text-sm">Rs {Math.round(pos.getPOSEarnings.thismonth).toLocaleString('en-US')}</td>
                                        <td className="text-center text-sm">Rs {Math.round(pos.getPOSEarnings.lastmonth).toLocaleString('en-US')}</td>
                                        <td className="text-center text-sm">{pos.phones}</td>
                                        <td className="text-center text-sm">
                                            {/* {pos.status} */}
                                        {(pos.status=='Fully Operational' || pos.status=='') &&
                                            <b className="text-green-600">{pos.status}</b>
                                        }
                                        {pos.status=='Partially Operational' &&
                                            <b className="text-orange-600">{pos.status}</b>
                                        }
                                        {pos.status=='Not Operational' &&
                                            <b className="text-blue-600">{pos.status}</b>
                                        }
                                        {pos.status=='Closed' &&
                                            <b className="text-red-600">{pos.status}</b>
                                        }
                                        </td>
                                        <td className="text-center text-sm">
                                            <div dangerouslySetInnerHTML={{__html: pos.getPOSIssues}}></div>
                                                </td>
                                        <td className="text-center text-sm">
                                            <b>{Math.round(pos.getLastTicketStock.balance).toLocaleString('en-US')}</b>
                                            <br />
                                            <small>{pos.getLastTicketStock.date}</small>
                                        </td>
                                        <td className="text-center text-sm">
                                            <b>{Math.round(pos.getLastPochetteStock.balance).toLocaleString('en-US')}</b>
                                            <br />
                                            <small>{pos.getLastPochetteStock.date}</small>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="chart row pt-10 pb-10 w-100 mt-10 mb-10">
                    <div className="chart-item col-5 flex flex-col justify-center items-center">
                        <div className="sub-heading text-base font-bold mb-4 text5e4642">Cash Balance per POS</div>
                        <Bar data={dataCashPerPos} options={optionsBArChart} />
                    </div>
                    <div className="chart-item col-1"></div>

                    <div className="chart-item col-1"></div>
                    <div className="chart-item col-5 flex flex-col justify-center items-center">
                        <div className="sub-heading text-base font-bold mb-4 text5e4642">Sales in POS by Payment method</div>
                        <Bar data={dataPaymentMethodPOS} options={optionsBArChart} />
                    </div>
                </div>

            </div>

            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => createPOS(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Create a POS
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>POS Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter POS name" required="required" value={posName} onChange={e => setPosName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Email Address</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" required="required" value={posEmail} onChange={e => setPosEmail(e.target.value)} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Alternate Email 1</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={posEmail1} onChange={e => setPosEmail1(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Alternate Email 2</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={posEmail2} onChange={e => setPosEmail2(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Location</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter POS Location" required="required" value={posLocation} onChange={e => setPosLocation(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Operating Days & Hours</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Enter POS Location" required="required" value={posOperatingHoursDays} onChange={e => setPosOperatingHoursDays(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Phones</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter phone number" required="required" value={posPhones} onChange={e => setPosPhones(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>General Comment</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Enter a comment if any" value={posComments} onChange={e => setPosComments(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>

        <div className="d-lg-none" style={{padding : '0px 10px'}}>
         <div className="d-flex justify-content-between align-items-baseline pb-3">
          <p className="font-semibold">POS Overview</p>
          <button className="bg-orange-500 px-2 py-1 text-sm font-semibold text-white rounded-sm" onClick={() => handleCNPOS()}>Create New POS</button>
         </div>

          <Drawer anchor="right" open={CNPDrawerOpen} onClose={handleCNPOS} >
          <div className="d-flex flex-col" style={{ width : '100vw'}}>
            <div className="d-flex justify-content-between align-items-center pt-3 p-3">
              <button onClick={() => handleCNPOS()}><ArrowBackIcon style={{fontSize : '25px'}}/></button>
              <p className='text-sm text-secondary'>Create New POS</p>
            </div> 

            <Divider/>

            <form onSubmit={(e) => createPOS(e)} style={{textAlign: "left"}} className="px-3">
                
                    <div className="form-group ">
                        <label for="" className="col-form-label text-sm"><b>POS Name</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter POS name" required="required" value={posName} onChange={e => setPosName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group" >
                        <label for="" className="col-form-label text-sm"><b>Email Address</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" required="required" value={posEmail} onChange={e => setPosEmail(e.target.value)} />
                        </div>
                    </div>

                     <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>Alternate Email 1</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={posEmail1} onChange={e => setPosEmail1(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>Alternate Email 2</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={posEmail2} onChange={e => setPosEmail2(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>Location</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter POS Location" required="required" value={posLocation} onChange={e => setPosLocation(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>Operating Days & Hours</b></label>
                        <div className=""> 
                            <textarea rows={2} className="form-control" placeholder="Enter POS Location" required="required" value={posOperatingHoursDays} onChange={e => setPosOperatingHoursDays(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>Phones</b></label>
                        <div className=""> 
                            <input type="text" className="form-control" placeholder="Enter phone number" required="required" value={posPhones} onChange={e => setPosPhones(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="" className="col-form-label text-sm"><b>General Comment</b></label>
                        <div className=""> 
                            <textarea rows={2} className="form-control" placeholder="Enter a comment if any" value={posComments} onChange={e => setPosComments(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="d-flex justify-content-around pt-2 pb-4">
                        <button type="submit" className="btn btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Create</button>
                    </div>
            </form>

            <Divider/>


          </div>
          </Drawer>

         <div>
         {allPOS.length == 0 && 
         <div className="d-flex justify-content-center align-items-center">
             <Spinner/>
         </div>

         }

         {allPOS && allPOS.map((pos, index) => (
            <POSMainCard pos={pos}/>
         ))}
         </div>
        </div>
        </>
    );
}

export default POSMain;