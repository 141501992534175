import axios from "axios";

const APIURL = "https://otayo.com/erpapi/notifications.php";

class NotificationsService {
    getTicketCategories() {
        var params = {
            action: "getTicketCategories"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getEmails(status, gateway, category, type, channel, from, to) {
        var params = {
            action: "getEmails",
            status: status,
            gateway: gateway,
            category: category,
            type: type,
            channel: channel,
            from: from,
            to: to
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    SendEmails(emailsComma, emailSubject, emailBody, emailsExcludeComma, preView='no', lang, attachment) {
        var params = {
            action: "SendEmails",
            preView: preView,
            emailsComma: emailsComma,
            emailSubject: emailSubject,
            emailBody: emailBody,
            emailsExcludeComma: emailsExcludeComma,
            lang: lang,
            attachment: attachment
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
}

export default new NotificationsService();