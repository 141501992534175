import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

import "../styles/CurrentEvents.css"
import EventsService from "../services/events.service";

import { Card, CardContent, CardMedia, Typography, CardActions, Button, TextField } from '@mui/material';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Spinner from "../components/LoadingState/Spinner";


// react functional component
const HomeMobileSlider = () => {

    const [data, setData] = useState([]);
    const [dataloader, setDataLoader] = useState(false);
    const loadData = () => {
        setDataLoader(true);
        EventsService.mobileSlider()
        .then(res => {
            console.log(res.data);
            setData(res.data);
            setDataLoader(false);
        })
        .catch(err => {
            console.log(err);
        });
    };
    useEffect(() => {
        loadData();
    }, []);

    const updateSort = (event, val) => {
        EventsService.updateSort(event, val)
        .then(res => {
            console.log(res.data);
            loadData();
            setDataLoader(false);
        })
        .catch(err => {
            console.log(err);
        });
    };

    const sortInputChange = (e, value, id) => {
        e.preventDefault();
        let tempData = data;
        tempData.map((item, index) => {
            if(item.id == id) {
                item.sort = value;
            }
        });
        setData([...tempData]);
    };

    const saveData = () => {
        setDataLoader(true);
        EventsService.updateMobileSliderSortingAll(data)
        .then(res => {
            console.log(res.data);
            loadData();
            setDataLoader(false);
        })
        .catch(err => {
            console.log(err);
        });
    };

    return (
        <>
        <div className='d-none d-lg-block drop-shadow-lg EventsArchive bg-white pr-4 pl-4 pt-4 pb-4'>
        <div className='row'>
            <div className='col-md-12'>
                <div>
                    <div className="flex justify-between">
                        <div class="title font-bold mb-3 h1 text-lg text5e4642">Home Mobile Slider <b className="text-red-700">{dataloader && 'Loading slider...'}</b></div>
                        <div>
                            <button className="btn btn-sm bg-purple-600 btn-success text-white text-sm rounded-none hover:bg-purple-800 mr-4" onClick={e => loadData()}>Refresh</button>
                            <button className="btn btn-sm bg-green-600 btn-success text-white text-sm rounded-none" onClick={e => saveData()}>Save</button>
                        </div>
                    </div>
                    <p className="text-black mb-5">Manage Mobile Slider For HomePage</p>
                    <div class="table-responsive">
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-md-12'>
                <div className="table-responsive">
                    <table className="table" style={{borderTop: "1px solid #e1e1e1"}}>
                        <thead>
                            <tr>
                                <th className='texte2e2e2 uppercase font-semibold' scope="col" style={{backgroundColor: "#e6e6e6", color: "#939393"}}>Event ID</th>
                                <th className='texte2e2e2 uppercase font-semibold' scope="col" style={{backgroundColor: "#e6e6e6", color: "#939393"}}>Event</th>
                                <th className='texte2e2e2 uppercase font-semibold' scope="col" style={{backgroundColor: "#e6e6e6", color: "#939393"}}>Image</th>
                                <th className='texte2e2e2 uppercase font-semibold' scope="col" style={{backgroundColor: "#e6e6e6", color: "#939393"}}>Url</th>
                                <th className='texte2e2e2 uppercase font-semibold' scope="col" style={{backgroundColor: "#e6e6e6", color: "#939393"}}>Sort</th>
                                {/* <th className='texte2e2e2 uppercase font-semibold' scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.length>0 && data.map((item, index) => (
                            <tr key={index}>
                                <td className='text5b4842'>{item.id}</td>
                                <td className='text5b4842' style={{color: "#f36523"}}>{item.title}</td>
                                <td className='text5b4842'><img style={{width: "100px"}} src={item.image} /></td>
                                <td className='text5b4842'>{item.url}</td>
                                <td className='text5b4842'>
                                    <input type="number" className="form-control" value={item.sort} onChange={e => sortInputChange(e, e.target.value, item.id)} />
                                    {/* <button className="btn btn-sm btn-primary" onClick={e=>updateSort()}>Update</button> */}
                                </td>
                                {/* <td className='text5b4842'>Hide</td> */}
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>

        <div className="d-lg-none" style={{ padding: "0px 20px 0px 20px" , overflow:"hidden"}}>
 
        <div className="d-flex justify-content-between align-items-center" style={{marginBottom : "15px"}}>
        <p className='' style={{fontWeight : "700" ,color : "#aaaaaa"}}>
            Mobile Carousel Slider
        </p>
      
         {!dataloader ?          
         <div className="d-flex flex-nowrap gap-3">
            <button className="text-orange-600" onClick={e => loadData()}><SyncOutlinedIcon/></button>
            <button className="text-orange-600" onClick={e => saveData()}><SaveOutlinedIcon/></button>
        </div>
        : 
        <Spinner/>
        }

        </div>

        <div className="d-flex align-items-center flex-column gap-3" >
        {data.length>0 && data.map((item, index) => (
             <Card key={index} sx={{ maxWidth: 345, borderRadius: 0  }}>
             <CardMedia
               component="img"
               alt="#"
               height="100"
               image={item.image}
             />
             <CardContent>
               <Typography  sx={{ color: '#f36523', fontWeight: 500, padding: '0px'}} component="p">
                 {item.title}
               </Typography>
             </CardContent>
             <CardActions sx={{ justifyContent: 'space-between' }}>
               <Button style={{ color: 'black' }}>#{item.id}</Button>
               <TextField
                label="Sort"
                variant="outlined"
                size="small"
                type="number"
                value={item.sort}
                onChange={e => sortInputChange(e, e.target.value, item.id)} 
                style={{ width: '70px' }} 
                />
             </CardActions>
             </Card>
         ))}
        </div>
        </div>

       </>
    );
};

export default HomeMobileSlider;