import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import AuthService from "../services/auth.service";
import EventService from "../services/events.service";
import SalesService from "../services/sales.service";
import UtilityService from "../services/utility.service";
import "../styles/EventSingle.css"

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from "chart.js";
import { Pie, Bar, Line } from "react-chartjs-2";

const eventExtrasURL = "https://otayo.com/erpapi/eventsextras.php";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  LineElement
  );

  export const optionsBArChart = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right"
    },
    // title: {
    //   display: true,
    //   text: "Total Sales in Rs"
    // }
  }
};

export const optionsLineChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Sales Analytics',
    },
  },
};

const ReportPerEvent = () => {

    const [events, setEvents ] = useState(null);
    const [eventID, setEventID] = useState(null);
    const [getStatsBtn, setGetStatsBtn] = useState('Get Stats');
    const [event, setEvent] = useState(null)
    const [tickets, setTickets] = useState(null);
    const [salesLoading, SetSalesLoading] = useState("Loading Sales Report...");
    
    const [user, setuser] = useState(null);

    const [dataSalesPerCategory, setDataSalesPerCategory] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataTicketSales, setDataTicketSales] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataTicketSales7Days, setdataTicketSales7Days] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataPos, setdataPos] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataSalesPerGateway, setDataSalesPerGateway] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataSalesPerRegion, setDataSalesPerRegion] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

    const [minDate, setMinDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateAnalytics, setStartDateAnalytics] = useState('');
    const [endDateAnalytics, setEndDateAnalytics] = useState('');
    const [advanceSalesReportAnalytics, setAdvanceSalesReportAnalytics] = useState(null);
    const [graphsReport, setGraphsReport] = useState(null);
    const [loadingAdvanceAnalytics, setLoadingAdvanceAnalytics] = useState(false);
    const [advancedRange, setAdvancedRange] = useState('alltime');
    const [advancedRangeAnalytics, setAdvancedRangeAnalytics] = useState('alltime');
    const [dataSalesAnalytics, setDataSalesAnalytics] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

    const getAdvanceReportAnalytics = (e) => {
        
        setLoadingAdvanceAnalytics('Fetching Report...');
        SalesService.getAdvanceSalesReportAnalytics( UtilityService.format_date_time(startDateAnalytics), UtilityService.format_date_time(endDateAnalytics), eventID )
        .then((res) => {
            setAdvanceSalesReportAnalytics(res.data);
            // console.log("analytics", res.data);
            setLoadingAdvanceAnalytics(false);

            setDataSalesAnalytics({
                labels: res.data.labels,
                datasets: res.data.graphData
            });

        })
        .catch((err) => {
            console.log(err);
        });
    }

    const setStartDateCalAnalytics = (date) => {
        setStartDateAnalytics(date);
        setMinDate(date);
        if (endDate != '' && endDate < date) {
            setEndDateAnalytics(date);
        }
    }

    const setEndDateCalAnalytics = (date) => {
        setEndDateAnalytics(date);
    }

     const allTimeStartEndAnalytics = () => {
        setStartDateAnalytics('');
        setEndDateAnalytics('');
        setAdvancedRangeAnalytics('alltime');
    }

    const oneDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 1);
        setStartDateAnalytics(start);
        
        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('oneday');
    }

    const sevenDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 7);
        setStartDateAnalytics(start);

        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('sevenday');
    }

    const thirtyDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 30);
        setStartDateAnalytics(start);

        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('thirtyday');
    }
    

    useEffect(() => {
        // if(eventID>0) {
            AuthService.checklogin()
            .then(user => {
                // console.log("user", user)
                if(user!=false) {
                    setuser(user);
                    // getData();
                    // getEventMarketingServices();
                    // getEventProducerTickets();
                    // eventRelatedFiles();
                    getAllEvents();
            }
        }).catch((err) => {
            console.log(err);
            // setIsLoggedIn(false);
            window.location.href='/login';
        });
            
        // }
    }, []);

    // useEffect(() => {
    //     if(eventID!=null) {
    //         getData();
    //     }
    // }, [eventID]);


    const getData = () => {
        setGetStatsBtn("Loading Stats...");
        SetSalesLoading("Loading Sales Report...");
        setLoadingAdvanceAnalytics("Loading Sales Analytics...");

        getAdvanceReportAnalytics(null);

        EventService.getEvent(eventID)
        .then(res => {
            // console.log(res.data);
            setEvent(res.data);
            // setSeated(res.data.seated);

            EventService.getEventTicketsSales(eventID, startDate, endDate)
            .then(res => {
                // console.log("tickets", res.data);
                setTickets(res.data);
                SetSalesLoading("");
                setGetStatsBtn("Get Stats");

                var templabel = [];
                var tempData = [];
                if(res.data.tickets.length>0) {
                    res.data.tickets.map((item, index) => {
                        templabel.push(item.ticketname);
                        tempData.push(item.totalsold_raw);
                    });
                }

                setDataSalesPerCategory({
                    labels: templabel,
                    datasets: [{
                        data: tempData,
                        backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                    }]
                });

                var templabel2 = ['Sold', 'Reserved', 'Remaining'];
                var tempData2 = [parseInt(res.data.totalsales.totalSold_raw), parseInt(res.data.totalsales.totalreserved_raw), parseInt(res.data.totalsales.totalremaining_raw)];

                setDataTicketSales({
                    labels: templabel2,
                    datasets: [{
                        data: tempData2,
                        backgroundColor: ["#999933", "#666699", "#CC9933", "#006666", "#3399FF", "#993300", "#CCCC99", "#666666", "#FFCC66", "#6699CC", "#663366", "#9999CC", "#CCCCCC", "#669999", "#CCCC66", "#CC6600", "#9999FF", "#0066CC", "#99CCCC", "#999999", "#FFCC00", "#009999", "#99CC33", "#FF9900", "#999966", "#66CCCC", "#339966", "#CCCC33", "#003f5c", "#665191", "#a05195", "#d45087", "#2f4b7c", "#f95d6a", "#ff7c43", "#ffa600", "#EF6F6C", "#465775", "#56E39F", "#59C9A5", "#5B6C5D", "#0A2342", "#2CA58D", "#84BC9C", "#CBA328", "#F46197", "#DBCFB0", "#545775", "#336699", "#99CCFF"],
                    }]
                });

                var templabel3 = [];
                var tempData3 = [];

                res.data.tickets.map((item, index) => {
                    templabel3.push(item.ticketname);
                    tempData3.push(item.totalsold_raw7days);
                });

                setdataTicketSales7Days({
                    labels: templabel3,
                    datasets: [{
                        data: tempData3,
                        backgroundColor: ["#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                    }]
                });

                var templabel4 = ['POS', 'Online'];
                var tempData4 = [parseInt(res.data.totalsales.soldbypos), parseInt(res.data.totalsales.totalSold_raw)-parseInt(res.data.totalsales.soldbypos)];
                // console.log("totalsales", parseInt(res.data.totalsales.totalSold_raw));
                setdataPos({
                    labels: templabel4,
                    datasets: [{
                        data: tempData4,
                        backgroundColor: ["#CBA328", "#F46197", "#DBCFB0", "#545775", "#336699", "#99CCFF", "#999933", "#666699", "#9999CC", "#CCCCCC", "#669999", "#CCCC66", "#CC6600", "#9999FF", "#0066CC", "#99CCCC", "#999999", "#FFCC00", "#009999", "#99CC33", "#FF9900", "#999966", "#66CCCC", "#339966", "#CCCC33", "#003f5c", "#665191", "#a05195", "#d45087", "#2f4b7c", "#f95d6a", "#ff7c43", "#ffa600", "#EF6F6C", "#465775", "#56E39F", "#59C9A5", "#5B6C5D", "#0A2342", "#2CA58D", "#84BC9C", "#CC9933", "#006666", "#3399FF", "#993300", "#CCCC99", "#666666", "#FFCC66", "#6699CC", "#663366"],
                    }]
                });

                var templabel5 = ['Juice', 'Terminal', 'Migs', 'Bank', 'Cash'];
                var tempData5 = [parseInt(res.data.totalsales.soldbyjuice), parseInt(res.data.totalsales.soldbyterminal), parseInt(res.data.totalsales.soldbymigs), parseInt(res.data.totalsales.soldbybank), parseInt(res.data.totalsales.soldbycash)];

                setDataSalesPerGateway({
                    labels: templabel5,
                    datasets: [{
                        data: tempData5,
                        backgroundColor: ["#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999"],
                    }]
                });

                var templabel6 = [];
                var tempData6 = [];
                res.data.totalsales.regions.map((item, index) => {
                    // console.log(item);
                    templabel6.push(item.name.charAt(0).toUpperCase() + item.name.slice(1));
                    tempData6.push(item.sold);
                });

                setDataSalesPerRegion({
                    labels: templabel6,
                    datasets: [{
                        data: tempData6,
                        backgroundColor: ["#EF6F6C","#465775","#56E39F","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600"],
                    }]
                });

            })
            .catch(err => {
                console.log(err);
            });


            EventService.getAllEventMeta(eventID)
            .then(res => {
                // console.log("event meta", res.data);
                let data = res.data;
            })
            .catch(err => {
                console.log(err);
            });

        })
        .catch(err => {
            console.log(err);
        });
    }

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const Refresh = () => {
        window.location.reload(false);
    }

    const eventChanged = (option) => {
        setEvent(option);
        setEventID(option.value);
    }

    const getAllEvents = () => {
        EventService.getAllEvents()
        .then((res) => {
            // console.log({res});
            setEvents(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className="event-single-wrapper p-2">
        <div className="singleevent shadow-lg p-4">
            <div className="row">
                <div className="col heading-h1-wrapper heading-h1-wrapper-onsiteselling">
                    <h1 className="text-lg text5e4642">Event Report</h1>
                    <div className="flex justify-end items-center w-1/2">
                    <Select 
                    options={events} 
                    placeholder="Select an Event" 
                    onChange={(option) => eventChanged(option)}
                    />
                    <button type="button" className="btn btn-success bg-green-700 hover:bg-green-800 font-bold text-white ml-2 rounded-none" disabled={eventID==null} onClick={e=>getData()}>{getStatsBtn}</button>
                    <button type="button" className="btn btn-warning bg-yellow-700 hover:bg-yellow-800 font-bold text-white ml-2 rounded-none" disabled={eventID==null} onClick={e=>window.location.reload()}>Reset</button>
                    </div>
                </div>
            </div>

            {eventID!=null && 
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading font-bold text-sm uppercase p-2 bg-gray-300 text-gray-500 mb-2">Event Summary</div>
                    <div className="summary mb-5">
                        <div className="section grid grid-cols-3 gap-3">
                            <div className="summary-item flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Producer:</div>
                                <div className="content text-xs w-50 bac text1a1a1a">{event!=null && event.producer}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event Location:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.location}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event Start Date:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.eventstartdate}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event End Date:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.eventenddate}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Seated Event:</div>
                                <div className="content w-50 text-xs text1a1a1a">{event!=null && event.seated}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            }

            {eventID!=null && 
            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>
            }

            {eventID!=null && 
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-base mb-4 text5c4742">Ticket Sales</div>
                    </div>
                    <div className="section-content text-base text-gray-500 mb-4">
                        <p>The Ticket Summary Report shows you the revenue performance of your ticket sales. Here you can see how well event sales have been, down to specific categories. Along with the total revenue of each ticket category, you will even get a breakdown of the number of tickets sold and the number of tickets remaining from the allocated group.</p>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="flex justify-end mb-2">
                                <div>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        onChange={date => setStartDateCal(date)}
                                        placeholderText="Start Date"
                                        className='form-control rounded-none text-sm'
                                    />
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate}
                                        onChange={date => setEndDateCal(date)}
                                        placeholderText="End Date"
                                        className='form-control rounded-none text-sm'
                                        minDate = {minDate}
                                    />
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <a href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getData()} >{getStatsBtn}</a>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <a href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 text-white rounded-none text-sm" onClick={(e) => Refresh()} >Reset</a>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="ticketsales">
                        <div class="ticketsales-table mb-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-xs uppercase">Ticket Category</th>
                                        <th scope="col" className="text-xs uppercase">Price per Ticket</th>
                                        <th scope="col" className="text-xs uppercase text-center">Tickets Sold</th>
                                        <th scope="col" className="text-xs uppercase text-center">Total Sale</th>
                                        <th scope="col" className="text-xs uppercase text-center">Tickets Reserved</th>
                                        <th scope="col" className="text-xs uppercase text-center">Tickets Allocated</th>
                                        {/* <th scope="col" className="text-xs uppercase text-center">Tickets Quota</th> */}
                                        <th scope="col" className="text-xs uppercase text-center">Remaining Tickets</th>
                                        <th scope="col" className="text-xs uppercase text-center">% Ticket Sold</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tickets!=null && tickets.tickets.map((ticket, index) => (
                                        <tr key={index} data-ticket-id={ticket.id}>
                                            <td className="text-xs font-bold">{ticket.ticketname}</td>
                                            <td className="text-xs font-bold">Rs {ticket.price}</td>
                                            <td className="text-xs text-center font-bold">{ticket.totalsold}</td>
                                            <td className="text-xs text-center font-bold">Rs {ticket.totalsales}</td>
                                            <td className="text-xs text-center">{ticket.totalreserved}</td>
                                            <td className="text-xs text-center font-bold" style={{color: "#8c8c8c"}}>{ticket.ticketsallocated}</td>
                                            {/* <td className="text-xs text-center" style={{color: "#8c8c8c"}}>{ticket.ticketsquota}</td> */}
                                            <td className="text-xs text-center">{ticket.remaining}</td>
                                            <td className="text-xs text-center font-bold">{Math.round((parseInt(ticket.totalsold.replace(",",""))/ticket.ticketsallocated)*100)}%</td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                                <tfoot>
                                    {tickets!=null &&
                                    <tr>
                                        <td className="text-xs font-bold">Grand Total</td>
                                        <td></td>
                                        <td className="text-xs text-center font-bold">{tickets.totalsales.totalSold}</td>
                                        <td className="text-xs text-center font-bold">Rs {tickets.totalsales.totalsales}</td>
                                        <td className="text-xs text-center font-bold">{tickets.totalsales.totalreserved}</td>
                                        <td className="text-xs text-center font-bold">{tickets.totalsales.totalallocated}</td>
                                        <td className="text-xs text-center font-bold">{tickets.totalsales.totalquota}</td>
                                        <td className="text-xs text-center font-bold">{tickets.totalsales.totalremaining}</td>
                                        <td></td>
                                    </tr>
                                    }
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="font-bold text-base p-2 subsection-heading mb-2 text5e4642">Event Sales Analytics: <span className="text-red-600 font-bold ml-4">{loadingAdvanceAnalytics!=false && loadingAdvanceAnalytics}</span></h2>
                        <div className="flex justify-start">
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => allTimeStartEndAnalytics(e)} >All Time</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => oneDayStartEndAnalytics(e)} >Past 24 Hours</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => sevenDayStartEndAnalytics(e)} >Past 7 Days</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => thirtyDayStartEndAnalytics(e)} >Past 30 Days</button>
                            </div>
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDateAnalytics}
                                    onChange={date => setStartDateCalAnalytics(date)}
                                    placeholderText="Start Date"
                                    className='form-control text-sm'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDateAnalytics}
                                    onChange={date => setEndDateCalAnalytics(date)}
                                    placeholderText="End Date"
                                    className='form-control text-sm'
                                    minDate = {minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getAdvanceReportAnalytics(e)} >Get Report</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 rounded-none text-white text-sm" onClick={(e) => window.location.reload(false)} >Reset</button>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="row mb-10">
                    <div className="col-2">
                        <div className="flex flex-col items-center justify-center analytics-item-wrapper">
                            <div className="analytics-item">
                                <div className="header">Total Sales</div>
                                <div className="value">Rs
                                    {advanceSalesReportAnalytics!==null &&
                                        advanceSalesReportAnalytics.totalSalesUi
                                    }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Average Daily</div>
                                <div className="value">Rs
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.avgDailySalesUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Orders Placed</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.ordersPlacedUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Tickets Sold</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.ticketSoldUi
                                }
                                </div>
                            </div>
                            {/* <div className="analytics-item">
                                <div className="header">Add to Cart (Orders)</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.addtoCartOrdersUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Add to Cart (Tickets)</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.addtoCartTicketsUi
                                }
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-10">
                        {advanceSalesReportAnalytics!==null &&
                            <div className="flex justify-center items-center">
                                <Line data={dataSalesAnalytics} options={optionsLineChart} />
                            </div>
                        }
                    </div>
                </div>

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="font-bold text-lg mb-4">Sales Report <span className="ml-2 text-red-600 text-sm">{salesLoading}</span></div>
                    <div className="sales-table mb-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-xs uppercase">Event Name</th>
                                    <th scope="col" className="text-xs uppercase text-center">Total Tickets Sold</th>
                                    <th scope="col" className="text-xs uppercase text-center">Total Sales amount</th>
                                    <th scope="col" className="text-xs uppercase text-center">No. of Orders</th>
                                    <th scope="col" className="text-xs uppercase text-center">Average Order Amount</th>
                                    <th scope="col" className="text-xs uppercase text-center">Average No. Tickets per Order</th>
                                    <th scope="col" className="text-xs uppercase text-center">Past 24 hours (Sales / Tickets)</th>
                                    <th scope="col" className="text-xs uppercase text-center">Past 7 Days (Sales / Tickets)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets!=null && typeof tickets.totalsales!="undefined" &&
                                <tr>
                                    <td className="text-orange-600 font-bold text-xs text01bff1 underline">{event!=null && event.name}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.totalSold}</td>
                                    <td className="text-xs uppercase text-center">Rs {tickets.totalsales.totalsales}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.totalOrders}</td>
                                    <td className="text-xs uppercase text-center">Rs {tickets.totalsales.avgorderamount}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.avgticketquantity}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.onetotalsales} / {tickets.totalsales.onetotalSold}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.seventotalsales} / {tickets.totalsales.seventotalSold}</td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="sales-charts row mt-10 mb-10">
                        <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Sales Per Category</div>
                            <Pie data={dataSalesPerCategory} />
                        </div>
                       
                        <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales</div>
                            <Pie data={dataTicketSales} />
                        </div>

                         <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales Amout (Last 7 Days)</div>
                            <Pie data={dataTicketSales7Days} />
                        </div>

                          <div className="sales-charts-item col-3">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales POS vs Online</div>
                            <Pie data={dataPos} />
                          </div>
                    </div>
                     <div className="sales-charts row mt-10 mb-10">
                        <div className="sales-charts-item col-6 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Tickets Sold per Payment Gateway</div>
                            <Bar data={dataSalesPerGateway} options={optionsBArChart} />
                        </div>

                        <div className="sales-charts-item col-6 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Tickets Sold per District <span className="text-gray-500 text-sm italic font-semibold">(Based on available user details)</span></div>
                            <Bar data={dataSalesPerRegion} options={optionsBArChart} />
                        </div>
                     </div>
                </div>
            </div>



        </div>

        </div>
    );
}

export default ReportPerEvent;