import axios from "axios";
// const API_URL = "https://otayo.com/erpapi/orders.php";
const API_URL = "https://otayo.com/erpapi/orders.php?rand="+Math.random();
class OrdersReportService {
    getcompanies() {
        var params = {
            action: 'getcompanies',
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getproducts(company, supplier) {
        var params = {
            action: 'fetchproducts',
            company: company,
            supplier: supplier
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchFollowupList(orderid = '0') {
        var params = {
            action: "fetchfollowuplist",
            orderid: orderid
        };

        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });

    }

    getOrderExport(page, limit, startdate, enddate, event, status, specialcases, ordertags, paymentmethods, voucherstatus, search, userid, filterValue, sortValue) {
        var params = {
            action: 'getorders',
            page: page,
            limit: limit,
            startdate: startdate,
            enddate: enddate,
            product: event,
            status: status,
            specialcases: specialcases,
            ordertags: ordertags,
            paymentmethods: paymentmethods,
            voucherstatus: voucherstatus,
            search: search,
            userid: userid,
            filterValue: filterValue,
            sortValue: sortValue
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log("first response", response);
                var result = {
                    data : []
                };
                let promises = [];
                var size = response.data.count;
                // console.log({size});
                var loopSize = Math.ceil(parseFloat(size/30));
                // console.log({loopSize});

                for (var i=1; i<=loopSize; i++) {
                    // console.log({i});
                    promises.push(
                        this.getOrders(i, limit, startdate, enddate, event, status, specialcases, ordertags, paymentmethods, voucherstatus, search, userid, filterValue, sortValue)
                        .then(response1 => {
                            // console.log('response1: ', response1);
                            result.data = result.data.concat(response1.data.orders);
                        })
                        .catch(error1 => {
                            console.log({error1});
                        })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    return result;
                });

                // console.log(response);
                // return response;
                // return result;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getOrders(page, limit, startdate, enddate, event, status, specialcases, ordertags, paymentmethods, voucherstatus, search, userid, filterValue, sortValue) {
         var params = {
            action: 'getorders',
            page: page,
            limit: limit,
            startdate: startdate,
            enddate: enddate,
            product: event,
            status: status,
            specialcases: specialcases,
            ordertags: ordertags,
            paymentmethods: paymentmethods,
            voucherstatus: voucherstatus,
            search: search,
            userid: userid,
            filterValue: filterValue,
            sortValue: sortValue
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getOrderPaymentDetails(orderid) {
        var params = {
            action: 'getorderpaymentdetails',
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderRefundDetails(orderid) {
        var params = {
            action: 'getorderrefunddetails',
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderDetails(orderid) {
        var params = {
            action: 'getorderdetails',
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderDetailsProducts(orderid) {
        var params = {
            action: 'getorderdetailsproducts',
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderNotes(orderid) {
         var params = {
            action: 'getordernotes',
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    addOrderNote(orderid, note, username) {
         var params = {
            action: 'addordernote',
            orderid: orderid,
            note: note,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateItemCheckinTimes(orderid ,itemid, checkin, checkout, username) {
        var params = {
            action: 'updateitemcheckintimes',
            itemid: itemid,
            checkin: checkin,
            checkout: checkout,
            username:username,
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    sendVoucherToCustomer(orderID, itemID, username) {
        var url = "https://deals.mu/phantom/gen.php?order_id="+orderID+"&item="+itemID+"&email=1&type=cus&ajax=1";
        return axios
            .get(url)
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    sendVoucherToSupplier(orderID, itemID, username) {
        var url = "https://deals.mu/phantom/gen.php?order_id="+orderID+"&item="+itemID+"&email=1&type=sup&ajax=1";
        return axios
            .get(url)
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    addLog(orderid, itemid, username, actiontype, actiontaken, data) {
         var params = {
            action: 'postlog',
            itemid: itemid,
            orderid: orderid,
            username: username,
            actiontype: actiontype,
            actiontaken: actiontaken,
            data: data
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateSupplierConfirmed(type, orderid, itemid, username, date="", time="", person="", dealsperson="", company="", supplier="") {
        var params = {
            action: 'updatesupplierconfirmed',
            itemid: itemid,
            type: type,
            orderid: orderid,
            date: date,
            time: time,
            person: person,
            dealsperson: dealsperson,
            username: username,
            company: company,
            supplier: supplier
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateVoucherDetails(orderid, itemid, time, location, contacts, username) {
        var params = {
            action: 'updatevoucherdetails',
            itemid: itemid,
            orderid: orderid,
            time: time,
            location: location,
            contacts: contacts,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    applyCoupon(orderid, couponCode, username) {
        var params = {
            action: 'applycouponcode',
            orderid: orderid,
            couponCode: couponCode,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    removeCoupon(orderid, couponCode, username) {
        var params = {
            action: 'removecouponcode',
            orderid: orderid,
            couponCode: couponCode,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    createRefund(orderid, products, type, amount, agent, reason, explaination, bankdetails, bankname, accountname, accountnumber, username) {
        var params = {
            action: 'createrefund',
            orderid: orderid,
            products: products,
            type: type,
            amount: amount,
            agent: agent,
            reason: reason,
            explaination: explaination,
            bankdetails: bankdetails,
            bankname: bankname,
            accountname: accountname,
            accountnumber: accountnumber,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getAllRefunds(orderid) {
        var params = {
            action: 'getallrefunds',
            orderid: orderid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    deleteRefund(orderid, refundid, username, amount) {
        var params = {
            action: 'deleterefund',
            refundid: refundid,
            username: username,
            amount: amount,
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    submitComplaint(orderid, chosenitems, handlingAgentName, receivedby, linktocomplaint, customermobilenumber, complaintSummary, username) {
        var params = {
            action: 'addissue',
            order_id: orderid,
            issueproducts: chosenitems,
            issuehandlingagent: handlingAgentName,
            issue_channel_type: receivedby,
            issue_link: linktocomplaint,
            issue_customer_mobile_number: customermobilenumber,
            issue_complaint_summary: complaintSummary,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getAllComplaints(orderid) {
        var params = {
            action: 'allcomplaints',
            orderid: orderid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    addFollowup(orderid, datetime, reminder, username) {
        var params = {
            action: 'addfollowup',
            orderid: orderid,
            datetime: datetime,
            reminder: reminder,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateStatus(orderid, status, username) {
        var params = {
            action: 'updateorderstatus',
            orderid: orderid,
            status: status,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateDetails(orderid, transactionref, cash_reference, paymentstatusnote, voucher_note, username) {
        var params = {
            action: 'updateorderdetails',
            orderid: orderid,
            transactionref: transactionref,
            cash_reference: cash_reference,
            paymentstatusnote: paymentstatusnote,
            voucher_note: voucher_note,
            username: username
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderLogs(orderid) {
        var params = {
            action: 'fetchorderlog',
            orderid: orderid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    resolveFollowup(id, username, orderid) {
        var params = {
            action: 'resolvefollowup',
            id: id,
            username: username,
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateFollowupTime(id, timedate, username, orderid) {
        var params = {
            action: 'updatefollowuptime',
            id: id,
            timedate: timedate,
            username: username,
            orderid: orderid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    updateMultiSupplier(orderid, itemid, type, value, username) {
        var params = {
            action: 'updatemultisupplier',
            itemid: itemid,
            orderid: orderid,
            username: username,
            type: type,
            value: value
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getOrderUserRoles(userid) {
        var params = {
            action: 'getuserroles',
            userid: userid
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    transferOrder(orderid, newemail, username, orderOwner, ownerName) {
        var params = {
            action: 'transferorder',
            orderid: orderid,
            newemail: newemail,
            username: username,
            orderOwner: orderOwner,
            ownerName: ownerName
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    resendorderemail(orderid, orderstatus, username) {
        var params = {
            action: 'resendorderemail',
            orderid: orderid,
            username: username,
            orderstatus: orderstatus
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    sendVoucherToSupplierTravel(orderid, itemid, company, supplier, items, randomName='', type='sup') {
        var params = {
            action: 'sendvouchertosuppliertravel',
            orderid: orderid,
            itemid: itemid,
            company: company,
            supplier: supplier,
            items: items,
            preview: 'no',
            randomName: randomName,
            type: type
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    previewVoucherToSupplierTravel(orderid, itemid, company, supplier, items, randomName='', type='sup') {
        var params = {
            action: 'sendvouchertosuppliertravel',
            orderid: orderid,
            itemid: itemid,
            company: company,
            supplier: supplier,
            items: items,
            preview: 'yes',
            randomName: randomName,
            type: type
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getAlreadySentTravel(orderid, itemid) {
        var params = {
            action: 'getalreadysenttravel',
            orderid: orderid,
            itemid: itemid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getAlreadyConfirmedTravel(orderid, itemid) {
        var params = {
            action: 'getalreadyconfirmedtravel',
            orderid: orderid,
            itemid: itemid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    resendEmail(orderid) {
        var params = {
            action: 'resendEmail',
            orderid: orderid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getJuiceSMS(orderid) {
        var params = {
            action: 'getJuiceSMS',
            orderid: orderid,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    changePaymentMethod(orderid, method) {
        var params = {
            action: 'changePaymentMethod',
            id: orderid,
            method: method
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

}

export default new OrdersReportService();