import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid';

import "../styles/EventsArchive.css"
import EventsService from "../services/events.service";


// react functional component
const EventsArchive = () => {

    const [data, setData] = useState([]);
    const [columnsheadings, setColumnsheadings] = useState([
        { field: 'id', headerName: 'Event ID', flex: 1, 
            renderCell: (cellValues) => {
                return (
                    <div className="color-orange break-words font-bold">#{cellValues.row.id}</div>
                )
            },
        },
        { field: 'title', headerName: 'Title', flex: 3, 
            renderCell: (cellValues) => {
                return (
                    <div className="break-words font-normal text5f4745">{cellValues.row.title}</div>
                )
            }
        },
        { field: 'producer', headerName: 'Producer', flex: 2, cellClassName: 'flexcenter', 
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px"}}>
                    <a href={"/edit-producer/"+cellValues.row.producer.id} className="color-orange break-words underline text-left">{cellValues.row.producer.name}</a>
                </div>
                )
            }
        },
        { field: 'eventdate', headerName: 'Event Date', flex: 1, cellClassName: 'flex justify-start' },
        // { field: 'totalsold', headerName: 'Total Sold', flex: 1 },
        { field: 'link', headerName: 'Full Reports', flex: 1, cellClassName: 'flex justify-start', 
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left"}}>
                    <a href={"/event/"+cellValues.row.id} className="color-orange break-words underline text-left">View Report ></a>
                </div>
                )
            }
        },
    ]);
    const [limit, setLimit] = useState(20);
    const [rowCount, setRowCount] = useState(0);
    const [dataloader, setDataLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setsearch] = useState('');

    
    const onFilterChange = () => {}
    const handleSortModelChange = () => {

    }

    const searchClick = () => {
        setDataLoader(true);
        console.log("type")
        EventsService.getAllEventsArchive(search, 0, limit, null)
        .then(res => {
            // console.log(res.data.events);
            // setData(res.data.events);
            // setDataLoader(false);
            setRowCount(res.data.count);

            EventsService.getAllEventsArchive(search, page, limit, null)
            .then(res => {
                // console.log(res.data.events);
                setData(res.data.events);
                setDataLoader(false);
                // setRowCount(res.data.count);
            })
            .catch(err => {
                console.log(err);
                setDataLoader(false);
            })
            })
            .catch(err => {
                console.log(err);
                setDataLoader(false);
            })
    }

    useEffect(() => {
        if(!dataloader) {
            searchClick();
        }
    }, []);

    const setsearchAndReload = () => {
        searchClick();
    }

    // useEffect(() => {
    //     if(search.length>3) {
    //         searchClick();
    //     }
    // }, [search]);

    const handleSearchKeyDown = (e) => {
        if(e.key=="Enter") {
            searchClick();
        }
    }

    useEffect(() => {
        searchClick();
    }, [page]);

    return (
        <div className="p-2">
        <div className="shadow-lg p-4">
        <div className='row'>
            <div className='col-md-12'>
                <div className=''>
                    <div className="flex justify-between items-center">
                        <div class="title font-bold mb-3 h1 text-lg text5e4944">Otayo Event Archive</div>
                        <a href="/current-events" className=" btn btn-warning btn-sm bg-orange-700 hover:bg-orange-800 text-white p-2 rounded-md font-bold text-sm">View Current Events</a>
                    </div>
                    <p className="text-black text-sm mb-3">Keep track of all the events that you’ve hosted with Otayo here. This is your main page where you will find all your past, current & upcoming events and get access to a comprehensive report for each event. To view a complete report, simply select View Report on the corresponding event and you will be redirected.</p>
                    <div className="flex w-100 justify-end items-center mb-2">
                        <div className='flex justify-start items-center'>
                            <input type="text" className="form-control rounded-none text-sm" value={search} placeholder="Search atleast 4 alphabets" onChange={(e) => setsearch(e.target.value)} onKeyDown={(e) => handleSearchKeyDown(e)} />
                            <button className='btn btn-primary bg-blue-700 rounded-none text-white text-sm'  onClick={(e) => {setsearchAndReload()}}>Search</button>
                            <button className='btn btn-warning bg-orange-700 hover:bg-orange-800 rounded-none text-white ml-2 text-sm'  onClick={(e) => {window.location.reload(false)}}>Reset</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataGrid
                            rows={data}
                            columns={columnsheadings}
                            pageSize={limit}
                            rowsPerPageOptions={[10, 20, 50]} 
                            autoHeight={true}
                            // rowHeight={50}
                            getEstimatedRowHeight={() => 200}
                            getRowHeight={() => 'auto'}
                            pagination
                            rowCount={rowCount}
                            paginationMode="server"
                            filterMode="server"
                            onFilterModelChange={onFilterChange}
                            loading={dataloader}
                            onPageChange={async (data) => {
                                console.log({data});
                                await setPage(data+1);
                                // searchClick();
                            }}
                            page={page - 1}
                            onPageSizeChange={(newPage) => setPage(newPage)} 
                            sortingMode="server" 
                            onSortModelChange={handleSortModelChange}
                        />
                    </div>
                </div>
            </div>
        </div>
       </div>
       </div>
    );
};

export default EventsArchive;