import React from 'react'
import { Divider } from '@mui/material'

const EventTicketSales = ({ticket ,tickets}) => {

  const widthStyle = tickets ? {} : { width: "270px" };

  return (
    <div className="border rounded-sm shadow-sm flex-shrink-0" style={widthStyle}>
                <p className='px-3 py-2 text-orange-500 font-semibold'> 
                 {!tickets ? <span>{ticket?.ticketname}</span> : <span>Grand Total</span>}
                 </p>
                <Divider />

                {!tickets ?  
                <>
                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                  <p className='text-secondary'>Price Per Ticket :</p>
                  <p className="font-medium">Rs {ticket?.price}</p>
                </div>
                <Divider />
                </>

                  : <></>}


                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between ' style={{backgroundColor : '#F7F7F7'}}>
                  <p className='text-secondary'>Tickets Quota :</p>
                  <p className="font-medium">{ticket?.ticketsquota} {tickets?.totalsales.totalquota}</p>
                </div>

                <Divider/>

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                <p className='text-secondary'>Tickets Allocated :</p>
                <p className="font-medium">{ticket?.ticketsallocated} {tickets?.totalsales.totalallocated}</p>
                </div>

                <Divider/>

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between ' style={{backgroundColor : '#F7F7F7'}}>
                <p className='text-secondary'>Tickets Sold :</p>
                <p className="font-medium">{ticket?.totalsold} {tickets?.totalsales.totalSold}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                <p className='text-secondary'>Tickets Reserved :</p>
                <p className="font-medium">{ticket?.totalreserved}{tickets?.totalsales.totalreserved}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between ' style={{backgroundColor : '#F7F7F7'}}>
                <p className='text-secondary'>Remanining :</p>
                <p className="font-medium">{ticket?.remaining}{tickets?.totalsales.totalremaining}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                <p className='text-secondary'>Total Sales :</p>
                <p className="font-medium">Rs {ticket?.totalsales} {tickets?.totalsales.totalsales}</p>
                </div>
    </div>
  )
}

export default EventTicketSales;