import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";


import "../styles/Posmain.css"

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const APIURL = "https://otayo.com/erpapi/posnotifications.php";

const PosNotifications = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCreate, setIsOpenCreate] = useState(false);

    const [endDate, setEndDate] = useState("");
    const [emailBody, setEmailBody] = useState(null);
    const [emailBodyCreate, setEmailBodyCreate] = useState(null);

    const [getStatsBtn, setGetStatsBtn] = useState('Get Notifications');
    const [allNotifications, setallNotifications] = useState([]);
    const [editEmailID, seteditEmailID] = useState(0);
    const [editEmailTo, seteditEmailTo] = useState('');
    const [editEmailSubject, seteditEmailSubject] = useState('');
    const [editEmailBody, seteditEmailBody] = useState('');
    const [editEmailAttachment, seteditEmailAttachment] = useState('');
    const [editEmailType, seteditEmailType] = useState('');

    const [createEmailTo, setcreateEmailTo] = useState('');
    const [createEmailSubject, setcreateEmailSubject] = useState('');
    const [createEmailBody, setcreateEmailBody] = useState('');
    const [createEmailAttachment, setcreateEmailAttachment] = useState('');


    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                getAllNotifications();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const getAllNotifications = () => {
        var params = {
            action: 'getLastFourDaysNotifications',
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({response});
            setallNotifications(response.data);
        })
        .catch(err => console.log({err}));
    }

    const Refresh = () => {
        window.location.reload(false);
    }

    const editResendEmails = (e) => {
        e.preventDefault();

        var params = {
            action: 'sendEmailNotification',
            emails: createEmailTo,
            subject: createEmailSubject,
            emailBody: createEmailBody, 
            attach: createEmailAttachment
        }
        if(editEmailID>0) {
            var params = {
                action: 'ResendEmailNotification',
                emails: editEmailTo,
                subject: editEmailSubject,
                emailBody: editEmailBody, 
                attach: editEmailAttachment,
                id: editEmailID,
                email_type: editEmailType
            }
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({response});
            setcreateEmailTo('');
            setcreateEmailSubject('');
            setcreateEmailBody('');
            setcreateEmailAttachment('');

            setIsOpen(false);

            seteditEmailTo('');
            seteditEmailSubject('');
            seteditEmailBody('');
            seteditEmailAttachment('');

            setIsOpenCreate(false);

            swal('Email Sent', "The Email has been sent", "success");
        })
        .catch(err => console.log({err}));
    }

    const getData = () => {

    }

    const resendEmail = (id) => {
        
        seteditEmailID(id);

        var params = {
            action: 'getEmailNotification',
            id: id,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({response});
            seteditEmailTo(response.data.sent_to);
            seteditEmailSubject(response.data.subject);
            seteditEmailBody(response.data.body);
            seteditEmailAttachment(response.data.attachment);
            seteditEmailType(response.data.email_type);

            setIsOpen(true);
        })
        .catch(err => console.log({err}));
    }

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-xl mb-2">Courts Mammouth - Curepipe Sales Report</h1>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="flex justify-start mb-2">
                            <div style={{ marginLeft: '10px' }}>
                                <select className="form-control" value="">
                                    <option value="Any">Select POS</option>
                                    <option value="Any">Any</option>
                                    <option value="Collected">Collected</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Pending">Pending</option>
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <select className="form-control" value="">
                                    <option value="Daily">Daily Sales Report</option>
                                    <option value="Weekly">Weekly Sales Report</option>
                                    <option value="General">General Notification</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none" onClick={(e) => getData()} >{getStatsBtn}</a>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 text-white rounded-none" onClick={(e) => Refresh()} >Reset</a>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="#" className="btn btn-warning btn-yellow" onClick={e => setIsOpenCreate(true)}>Create Email Notification</a>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead>
                                <tr>
                                    <th scope="col">Sent On</th>
                                    <th scope="col">To Who</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allNotifications.length>0 && allNotifications.map((row, index) => (
                                <tr key={index}>
                                    <td scope="col">{row.sent_on}</td>
                                    <td scope="col">{row.sent_to}</td>
                                    <td scope="col">{row.email_type}</td>
                                    <td scope="col"><button className="btn btn-sm btn-warning btn-orange" disabled={row.resent>0} onClick={e => resendEmail(row.id)}>Edit or Resend</button></td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            {/* resend edit email notification */}
            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 1000, textAlign: 'center' }}>
                <form onSubmit={(e) => editResendEmails(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Resend/Edit Notification
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>TO</b></label>
                        <div className="col-10"> 
                            <input type="hidden" className="form-control" value={editEmailID} />
                            <input type="text" className="form-control" required="required" value={editEmailTo} onChange={e => seteditEmailTo(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Subject</b></label>
                        <div className="col-10"> 
                            <input type="text" className="form-control" required="required" value={editEmailSubject} onChange={e => seteditEmailSubject(e.target.value)} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Email Text</b></label>
                        <div className="col-10"> 
                            <CKEditor
                                editor={ ClassicEditor }
                                data={editEmailBody}
                                hei={10}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    seteditEmailBody(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Upload Attachment</b></label>
                        <div className="col-10"> 
                            <input type="file" className="form-control" required="required" value={editEmailAttachment} onChange={e => seteditEmailAttachment(e.target.value)} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* create email notification */}
            <Modal
                open={isOpenCreate}
                onClose={e => setIsOpenCreate(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 1000, textAlign: 'center' }}>
                <form onSubmit={(e) => editResendEmails(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Create Notification
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>TO</b></label>
                        <div className="col-10"> 
                            <input type="text" className="form-control" required="required" value={createEmailTo} onChange={e => setcreateEmailTo(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Subject</b></label>
                        <div className="col-10"> 
                            <input type="text" className="form-control" required="required" value={createEmailSubject} onChange={e => setcreateEmailSubject(e.target.value)} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Email Text</b></label>
                        <div className="col-10"> 
                            <CKEditor
                                editor={ ClassicEditor }
                                data={createEmailBody}
                                hei={10}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setcreateEmailBody(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-2 col-form-label"><b>Upload Attachment</b></label>
                        <div className="col-10"> 
                            <input type="file" className="form-control" disabled={true} required="required" value={createEmailAttachment} onChange={e => setcreateEmailAttachment(e.target.value)} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenCreate(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>
    );
}

export default PosNotifications;