import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import DatePicker from "react-datepicker";
import axios from "axios";


import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import FileUploader from "../components/fileuploader.component";

import "../styles/Posmain.css"

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    marginTop: "150px"
};

const styleNew1 = {
    position: 'absolute',
    top: '550px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    marginTop: "150px"
};


const APIURL = "https://otayo.com/erpapi/vsp.php";

const VSPStaffList = () => {

    const [allStaffs, setAllStaffs] = useState([]);
    const [loading, setloading] = useState('Loading...');

    const [isOpen, setIsOpen] = useState(false);
    const [addStaffName, setAddStaffName] = useState('');
    const [addStaffID, setAddStaffID] = useState(0);
    const [addStaffHomeAddress, setAddStaffHomeAddress] = useState('');
    const [addStaffRegion, setAddStaffRegion] = useState('');
    const [addStaffMobile, setAddStaffMobile] = useState('');
    const [addStaffWhatsapp, setAddStaffWhatsapp] = useState('');
    const [addStaffEmail, setAddStaffEmail] = useState('');
    const [addStaffDrivingLic, setAddStaffDrivingLic] = useState('No');
    const [addStaffOfficeTraining, setAddStaffOfficeTraining] = useState('No');
    const [addStaffOnsiteTrial, setAddStaffOnsiteTrial] = useState('No');
    const [addStafAvailabilityStatus, setAddStafAvailabilityStatus] = useState('Not Available');
    const [addStaffQualification, setAddStaffQualification] = useState([]);
    const [addStaffRating, setAddStaffRating] = useState(1);
    const [addStaffAvailabilityNotes, setAddStaffAvailabilityNotes] = useState('');
    const [addStaffFormCV, setAddStaffFormCV] = useState('');
    const [addStaffOwnTransport, setAddStaffOwnTransport] = useState('No');
    const [addStaffComment, setAddStaffComment] = useState('');

    const [isOpenAllocate, setIsOpenAllocate] = useState(false);
    const [allocateStaffEventID, setAllocateStaffEventID] = useState(0);

    const [isOpenPast, setIsOpenPast] = useState(false);
    const [pastEvents, setPastEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [eventVSPData, setEventVSPData] = useState([]);
    const [allocatedStaff, setAllocatedStaff] = useState({});

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            // console.log("user", user)
            if(user!=false) {
                getAllStaff();
                getAllEvents();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const getAllEvents = () => {
        var action = 'getAllEventsCurrent';
        var params = {
            action: action,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("getAllEventsCurrent", response)
            setAllEvents(response.data);
        })
        .catch(err => console.log(err));
    }

    const getAllStaff = () => {
        var action = 'getAllVSPStaff';
        var params = {
            action: action,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("getAllVSPStaff", response)
            setAllStaffs(response.data);
            setloading(false);
            var tempAllocated = {};
            for(var i =0; i<response.data.length; i++) {
                tempAllocated[response.data[i].id] = {
                    selected: false,
                    role: ""
                }
            }
            setAllocatedStaff(tempAllocated);
            // console.log("🚀 ~ file: vspStaffList.js:118 ~ getAllStaff ~ tempAllocated", tempAllocated)
        })
        .catch(err => console.log(err));
    }

    const AddNewStaff = (e) => {
        e.preventDefault();
        var action = 'addVSPStaff';
        if(addStaffID>0) {
            action = 'editVSPStaff';
        }
        var params = {
            action: action,
            id: addStaffID,
            addStaffDrivingLic: addStaffDrivingLic,
            addStaffName: addStaffName,
            addStaffHomeAddress: addStaffHomeAddress,
            addStaffRegion: addStaffRegion,
            addStaffMobile: addStaffMobile,
            addStaffWhatsapp: addStaffWhatsapp,
            addStaffOwnTransport: addStaffOwnTransport,
            addStaffOfficeTraining: addStaffOfficeTraining,
            addStaffOnsiteTrial: addStaffOnsiteTrial,
            addStafAvailabilityStatus: addStafAvailabilityStatus,
            addStaffQualification: addStaffQualification,
            addStaffRating: addStaffRating,
            addStaffAvailabilityNotes: addStaffAvailabilityNotes,
            addStaffFormCV: addStaffFormCV,
            addStaffComment: addStaffComment,
            addStaffEmail: addStaffEmail
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("equipment", response)
            // if(response.data!='undefined') {
                setAddStaffID(0);
                setAddStaffDrivingLic(0);
                setAddStaffName('');
                setAddStaffHomeAddress('');
                setAddStaffRegion('');
                setAddStaffMobile('');
                setAddStaffWhatsapp('');
                setAddStaffOwnTransport('');
                setAddStaffOfficeTraining('No');
                setAddStaffOnsiteTrial('No');
                setAddStafAvailabilityStatus('Not Available');
                setAddStaffQualification([]);
                setAddStaffRating(1);
                setAddStaffAvailabilityNotes('');
                setAddStaffFormCV('');
                setAddStaffOwnTransport('No');
                // setAddStaffFormCV('');
                setAddStaffComment('');
                setAddStaffEmail('');
                getAllStaff();
                setIsOpen(false);
                swal('Staff saved', "Staff hasbeen added", 'success');
            // }
        })
        .catch(err => console.log(err));
    }

    const OpenNewStaff = (e) => {
        setAddStaffID(0);
        setAddStaffDrivingLic(0);
        setAddStaffName('');
        setAddStaffHomeAddress('');
        setAddStaffRegion('');
        setAddStaffMobile('');
        setAddStaffWhatsapp('');
        setAddStaffOfficeTraining('No');
        setAddStaffOnsiteTrial('No');
        setAddStafAvailabilityStatus('Not Available');
        setAddStaffQualification([]);
        setAddStaffRating(1);
        setAddStaffAvailabilityNotes('');
        setAddStaffFormCV('');
        setAddStaffOwnTransport('No');
        // setAddStaffFormCV('');
        setAddStaffComment('');
        setAddStaffEmail('');
        setIsOpen(true);
    }

    const OpenNewStaffEdit = (e, staff_id) => {
        var action = 'getVSPStaff';
        var params = {
            action: action,
            id: staff_id,
        }
        // console.log("🚀 ~ file: vspStaffList.js:181 ~ OpenNewStaffEdit ~ params", params)

        setAddStaffID(staff_id);

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("equipment", response)
            if(response.data.staffname!='undefined') {
                
                setAddStaffDrivingLic(response.data.driving_license);
                setAddStaffName(response.data.staffname);
                setAddStaffHomeAddress(response.data.home_address);
                setAddStaffRegion(response.data.region);
                setAddStaffMobile(response.data.mobile);
                setAddStaffWhatsapp(response.data.whatsapp);
                setAddStaffOwnTransport(response.data.owntransport);
                setAddStaffOfficeTraining(response.data.office_training);
                setAddStaffOnsiteTrial(response.data.onsite_trial);
                setAddStafAvailabilityStatus(response.data.availability_status);
                setAddStaffQualification(response.data.qualification);
                setAddStaffRating(response.data.rating);
                setAddStaffAvailabilityNotes(response.data.availability_notes);
                setAddStaffComment(response.data.comment);
                setAddStaffEmail(response.data.email);
                setIsOpen(true);
            }
        })
        .catch(err => console.log(err));
    }

    const openAllocateStaff = (e) => {
        setAllocateStaffEventID();
        setIsOpenAllocate(true);
    }

    const allocatStaff = (e) => {
        e.preventDefault();
         var params = {
            action: 'allocatStaff',
            data: allocatedStaff,
            event: allocateStaffEventID
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setAllocateStaffEventID(0);
            setIsOpenAllocate(false);
            // setAllocatedStaff({});
            // setEventVSPData([]);
            swal("Staff Allocated", "Staff has been allocated!", "success");
        })
        .catch(err => console.log({err}));
    }

    const openPastEvent = (staff_id) => {
        setPastEvents([]);
        var params = {
            action: 'getPastEventsLog',
            id: staff_id,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setPastEvents(response.data);
            setIsOpenPast(true);
        })
        .catch(err => console.log({err}));
    }

    const deleteVSPStaff = (staff_id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The Staff will be deleted from the list!",
        icon: "warning",
        buttons: ["No, Keep the Staff!", "Yes, Remove Staff!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            var params = {
                action: 'deleteVSPStaff',
                id: staff_id,
            }

            axios.post(APIURL+"?rand="+Math.random(), params)
            .then(response => {
                getAllStaff();
                swal("Staff Removed", "Staff has been removed!", "success");
            })
            .catch(err => console.log({err}));
        } else {
            swal("Staff Not Removed", "Staff is safe!", "success");
        }
        });
    }

    const updateQualifications = (e) => {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }

        setAddStaffQualification(value);
    }

    const updateAllocateEvent = (e) => {
        setAllocateStaffEventID(e.target.value);

        var params = {
            action: 'getEventVSPData',
            event_id: e.target.value,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setEventVSPData(response.data);
            // console.log("🚀 ~ file: vspStaffList.js:292 ~ updateAllocateEvent ~ response.data", response.data);
            checkifAllocated(response.data);
        })
        .catch(err => console.log({err}));
    }

    const checkifAllocated = async (data) => {
        var tempallocated = allocatedStaff;
        const keys = Object.keys(tempallocated);

        // console.log({data});

        await keys.forEach((key, index) => {
            // console.log(key, index);
            if(data.allocated_staffs_ids.includes(key)) {
                tempallocated[key].selected = true;
                tempallocated[key].role = data.allocated_staffs_roles[key];
            }
        });

        setAllocatedStaff(tempallocated);
    } 

    const allocateCheckChange = (e, staff_id) => {
        var isCheck = e.target.checked;
        var tempallocated = allocatedStaff;
        tempallocated[staff_id].selected = isCheck;
        console.log("tempallocated", tempallocated);
        setAllocatedStaff({...tempallocated});
    }

    const allocateRoleChange = (e, staff_id) => {
        var role = e.target.value;
        var tempallocated = allocatedStaff;
        tempallocated[staff_id].role = role;
        console.log("tempallocated", tempallocated)
        setAllocatedStaff({...tempallocated});
    }

    const updateAddStaffFormCV = (url) => {
        console.log("url", url);
        setAddStaffFormCV(url);
    } 

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">On-Site Staff List <small className="text-red-600">{loading}</small></h1>
                        <div>
                        <button className="btn btn-success btn-green ml-4 font-semibold  text-sm" onClick={(e) => {OpenNewStaff(e)}}>Add New Staff</button>
                        <button className="btn btn-warning btn-orange ml-4 font-semibold text-sm" onClick={e => openAllocateStaff(e)}>Allocate Staff</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <table className="table">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Names</th>
                                    <th scope="col">Home Address</th>
                                    <th scope="col">Region</th>
                                    <th scope="col" className="text-center">Mobile</th>
                                    <th scope="col" className="text-center">Whatsapp</th>
                                    <th scope="col" className="text-center">Email</th>
                                    <th scope="col" className="text-center">Transportation</th>
                                    <th scope="col" className="text-center">Training</th>
                                    <th scope="col" className="text-center">Staff Form / CV</th>
                                    <th scope="col" className="text-center">Comment / Overview</th>
                                    <th scope="col" className="text-center">Rating</th>
                                    <th scope="col" className="text-center">Qualification</th>
                                    <th scope="col" className="text-center">Past Experience</th>
                                    <th scope="col" className="text-center">Availability Status</th>
                                    <th scope="col" className="text-center">Availability Notes</th>
                                    <th scope="col" className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStaffs.length>0 && allStaffs.map((staff, index)=>(
                                <tr key={index} className=" text-sm">
                                    <td><b>{staff.staffname}</b></td>
                                    <td>{staff.home_address}</td>
                                    <td>{staff.region}</td>
                                    <td className="text-center">{staff.mobile}</td>
                                    <td className="text-center">{staff.whatsapp}</td>
                                    <td className="text-center">{staff.email}</td>
                                    <td className="text-center">
                                        {staff.driving_license=='Yes' && 
                                            <span>Driving License: <span className="text-green-500 font-semibold">{staff.driving_license}</span></span>
                                        }
                                        {staff.driving_license=='No' && 
                                            <span>Driving License: <span className="text-red-500 font-semibold">{staff.driving_license}</span></span>
                                        }
                                        <br />
                                        {staff.owntransport=='Yes' && 
                                            <span>Own Transport: <span className="text-green-500 font-semibold">{staff.owntransport}</span></span>
                                        }
                                        {staff.owntransport=='No' && 
                                            <span>Own Transport: <span className="text-red-500 font-semibold">{staff.owntransport}</span></span>
                                        }
                                    </td>
                                    <td className="text-center">
                                        {staff.office_training=='Yes' && 
                                            <span>Office Training: <span className="text-green-500 font-semibold">{staff.office_training}</span></span>
                                        }
                                        {staff.office_training=='No' && 
                                            <span>Office Training: <span className="text-red-500 font-semibold">{staff.office_training}</span></span>
                                        }
                                        <br />
                                        {staff.onsite_trial=='Yes' && 
                                            <span>On-site Trial: <span className="text-green-500 font-semibold">{staff.onsite_trial}</span></span>
                                        }
                                        {staff.onsite_trial=='No' && 
                                            <span>On-site Trial: <span className="text-red-500 font-semibold">{staff.onsite_trial}</span></span>
                                        }
                                    </td>
                                    <td className="text-center">
                                        {staff.form_cv!='' && 
                                            <a target="_blank" className="text-blue-600 hover:text-blue-900" href={staff.staffname}>View Form</a>
                                        }
                                        {staff.form_cv=='' && 
                                            <button className="btn btn-sm btn-link" onClick={e => OpenNewStaffEdit(e, staff.id)}>Upload</button>
                                        }
                                        </td>
                                    <td className="text-center">{staff.comment}</td>
                                    <td className="text-center">{staff.rating}</td>
                                    <td className="text-center">{staff.qualification.join(', ')}</td>
                                    <td className="text-center"><button className="btn btn-sm btn-link" onClick={e => openPastEvent(staff.id)}>View Log</button></td>
                                    <td className="text-center">
                                        {staff.availability_status=='Available' && 
                                            <span className="text-green-500 font-semibold">{staff.availability_status}</span>
                                        }
                                        {staff.availability_status=='Not Available' && 
                                            <span className="text-red-500 font-semibold">{staff.availability_status}</span>
                                        }
                                        {staff.availability_status=='Temporary not available' && 
                                            <span className="text-yellow-500 font-semibold">{staff.availability_status}</span>
                                        }
                                        {staff.availability_status=='Suspended' && 
                                            <span className="text-purple-500 font-semibold">{staff.availability_status}</span>
                                        }
                                    </td>
                                    <td className="text-center">{staff.availability_notes}</td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-warning btn-orange mb-2" onClick={e => OpenNewStaffEdit(e, staff.id)}>Edit</button>
                                        <br />
                                        <button className="btn btn-sm btn-danger btn-red" onClick={e => deleteVSPStaff(staff.id)}>Delete</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box sx={{...styleNew1, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => AddNewStaff(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add / Edit Staff
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Full Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Full Name" required="required" value={addStaffName} onChange={e => setAddStaffName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Home Address</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Home Address" required="required" value={addStaffHomeAddress} onChange={e => setAddStaffHomeAddress(e.target.value)} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Region</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Region" value={addStaffRegion} onChange={e => setAddStaffRegion(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Mobile Number</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" placeholder="Enter mobile number" value={addStaffMobile} onChange={e => setAddStaffMobile(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Whatsapp Number</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" placeholder="Enter Whatsapp number" value={addStaffWhatsapp} onChange={e => setAddStaffWhatsapp(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Email</b></label>
                        <div className="col-8"> 
                            <input type="email" className="form-control" placeholder="Enter Email" required="required" value={addStaffEmail} onChange={e => setAddStaffEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Driving License</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffDrivingLic} onChange={e => setAddStaffDrivingLic(e.target.value)} required="required">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Own Transport</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffOwnTransport} onChange={e => setAddStaffOwnTransport(e.target.value)} required="required">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Office Training</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffOfficeTraining} onChange={e => setAddStaffOfficeTraining(e.target.value)} required="required">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>On-Site Trial</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffOnsiteTrial} onChange={e => setAddStaffOnsiteTrial(e.target.value)} required="required">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Availability Status</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStafAvailabilityStatus} onChange={e => setAddStafAvailabilityStatus(e.target.value)} required="required">
                            <option value="Available">Available</option>
                            <option value="Not Available">Not Available</option>
                            <option value="Temporary not available">Temporary not available</option>
                            <option value="Suspended">Suspended</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Availability Note</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Availability Note" required="required" value={addStaffAvailabilityNotes} onChange={e => setAddStaffAvailabilityNotes(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Rating</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffRating} onChange={e => setAddStaffRating(e.target.value)} required="required">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Qualification</b></label>
                        <div className="col-8"> 
                           <select className="form-control" value={addStaffQualification} onChange={e => updateQualifications(e)} multiple={true}>
                            <option value="Ticket Control">Ticket Control</option>
                            <option value="VSP">VSP</option>
                            <option value="Hostess">Hostess</option>
                            <option value="Team Leader">Team Leader</option>
                            <option value="On-site Manager">On-site Manager</option>
                           </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Overview / Comment</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Overview / Comment" required="required" value={addStaffComment} onChange={e => setAddStaffComment(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Form / CV</b></label>
                        <div className="col-8"> 
                            <FileUploader event={0} metakey="" onChange={(url) => updateAddStaffFormCV(url)} />
                        </div>
                    </div>
                    
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            <Modal
                open={isOpenAllocate}
                onClose={e => setIsOpenAllocate(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => allocatStaff(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Allocate Staff
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Event Name</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={allocateStaffEventID} onChange={e=>updateAllocateEvent(e)}>
                                <option value="0">Select An Event</option>
                                {allEvents.length>0 && allEvents.map((event, index) => (
                                    <option value={event.id} key={index}>{event.eventname}</option>
                                )) }
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>Role</th>
                                        <th>No. Staff required</th>
                                        <th>No. Staff allocated</th>
                                        <th>Pending Allocation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ticket Control</td>
                                        <td>
                                            {(typeof eventVSPData.service_ticket_control_pax!='undefined') ? eventVSPData.service_ticket_control_pax:0}
                                        </td>
                                        <td>
                                            {(typeof eventVSPData.ticket_control_allocated!='undefined') ? eventVSPData.ticket_control_allocated:0}
                                        </td>
                                        <td className="text-red-500">
                                             {(typeof eventVSPData.ticket_control_allocated!='undefined') ? parseInt(eventVSPData.service_ticket_control_pax)-parseInt(eventVSPData.ticket_control_allocated):0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>VSP</td>
                                        <td>
                                            {(typeof eventVSPData.service_vsp_pax!='undefined') ? eventVSPData.service_vsp_pax:0}
                                        </td>
                                        <td>
                                            {(typeof eventVSPData.vsp_allocated!='undefined') ? eventVSPData.vsp_allocated:0}
                                        </td>
                                        <td className="text-red-500">
                                            {(typeof eventVSPData.service_vsp_pax!='undefined') ? parseInt(eventVSPData.service_vsp_pax)-parseInt(eventVSPData.vsp_allocated):0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Hostesses</td>
                                        <td>
                                            {(typeof eventVSPData.service_hostessess_pax!='undefined') ? eventVSPData.service_hostessess_pax:0}
                                        </td>
                                        <td>
                                            {(typeof eventVSPData.hostessess_allocated!='undefined') ? eventVSPData.hostessess_allocated:0}
                                        </td>
                                        <td className="text-red-500">
                                            {(typeof eventVSPData.service_hostessess_pax!='undefined') ? parseInt(eventVSPData.service_hostessess_pax)-parseInt(eventVSPData.hostessess_allocated):0}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>Staff Name</th>
                                        <th>Home Address</th>
                                        <th>Qualification</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allStaffs.length>0 && allocateStaffEventID>0 && allStaffs.map((staff, index) => {
                                        if(staff.availability_status=='Available') {
                                            return (
                                            <tr key={index}>
                                                <td><input type="checkbox" checked={allocatedStaff[staff.id].selected} onClick={e => allocateCheckChange(e, staff.id)} />  {staff.staffname}</td>
                                                <td>{staff.home_address}</td>
                                                <td>{staff.qualification.join(', ')}</td>
                                                <td>
                                                    <select className="form-control" value={allocatedStaff[staff.id].role} onChange={e => allocateRoleChange(e, staff.id)}>
                                                        <option vlaue="">Select Role</option>
                                                        <option vlaue="Hostess">Hostess</option>
                                                        <option vlaue="Ticket Control">Ticket Control</option>
                                                        <option vlaue="VSP">VSP</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenAllocate(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            <Modal
                open={isOpenPast}
                onClose={e => setIsOpenPast(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Past Events for STAFFNAMEHERE
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>Name of Event</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pastEvents.length>0 && pastEvents.map((log, index) => (
                                        <tr key={index}>
                                            <td>{log.post_title}</td>
                                            <td>{log.type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenPast(false)}>Close</button>
                    </div>
                </Box>
            </Modal>

        </div>
    );
}

export default VSPStaffList;