import axios from "axios";

const APIURL = "https://otayo.com/erpapi/dashboard.php";

class DashboardService {

    getDashBoardOrders() {
        var params = {
            action: "getDashBoardOrders"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getStats() {
        var params = {
            action: "getStats"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
}

export default new DashboardService();