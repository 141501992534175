import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Chip, Divider } from '@mui/material';

import "../styles/Dashboard.css";
import DashboardService from '../services/dashboard.service';
import AuthService from '../services/auth.service';

import Spinner from '../components/LoadingState/Spinner';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LastOrderCard from '../components/Cards/LastOrderCard';



// react functional component
const Dashboard = () => {

    const [data, setData] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [stats, setStats] = useState({
        orders: 0,
        sales: 0,
        tickets: 0,
    });
    const fetchOrders = async () => {
        await DashboardService.getDashBoardOrders()
        .then(res => {
            console.log(res.data);
            setData(res.data);
            
        })
        .catch(err => {
            console.log(err);
        });
    }
    useEffect(() => {
        // if(data.length<1) {
        AuthService.checklogin()
        .then(user => {
            console.log("user dash", user)
            setCurrentUser(user);
            if(user!=null && user!=false) {
                fetchOrders(); 
                DashboardService.getStats()
                .then(res => {
                    console.log(res.data);
                    setStats({
                        orders: res.data.orders,
                        sales: res.data.amount,
                        tickets: res.data.tickets,
                    });
                })
            } else {
                window.location.href='/login';
            }
        })
        .catch(err => {
            console.log(err);
             window.location.href='/login';
        });
            
        // }
    }, []);

    useEffect(() => {
        // if(currentUser!=null) {
        //     fetchOrders(); 
        //     DashboardService.getStats()
        //     .then(res => {
        //         console.log(res.data);
        //         setStats({
        //             orders: res.data.orders,
        //             sales: res.data.amount,
        //             tickets: res.data.tickets,
        //         });
        //     })
        // } else {
        //     window.location.href='/login';
        // }
    }, [currentUser]);

    return (
        <div>
        <div className='row'> 
            <p className='d-lg-none' style={{fontWeight : "700",marginBottom : "10px" ,marginLeft : "10px" ,color : "#aaaaaa"}}>
               Quick Links
            </p>
            <div className='col-md-12 d-flex dashboard-cards justify-between items-center'>
                <a href="/current-events"><div className='card custom-events'>Current Events</div></a>
                <a href="/sales"><div className='card sales-events'>Sales Report</div></a>
                <a href="/events-archive"><div className='card events-archive'>Events Archive</div></a>
                <a href="/producer-list"><div className='card prod-list'>Producer List</div></a>
                <a href="/customer-notifications"><div className='card customer-nitif'>Customer Notification</div></a>
            </div>
        </div>
        <div className='row'>
            <div className='d-none d-lg-flex col-md-12 flex stats-cards justify-between items-center'>
                
                <div className='card orders'>
                    <div className='title'>{stats.orders}</div>
                    <div className="title-sub">ORDERS</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                <div className='card tickets'>
                    <div className='title'>{stats.tickets}</div>
                    <div className="title-sub">TICKETS SOLD</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                <div className='card amount'>
                    <div className='title'>Rs {stats.sales}</div>
                    <div className="title-sub">SALES AMOUNT</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>
            </div>

            <p className='d-lg-none' style={{fontWeight : "700",marginBottom : "10px" ,marginLeft : "10px" ,color : "#aaaaaa"}}>
               Analytics
            </p>
            <div className='d-lg-none col-md-12 flex flex-column stats-cards justify-between'>
                <div className='stats-sep'>
                <div className='card orders'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Orders</p>
                        <CreditCardIcon sx={{ fontSize: 22 }} />                        
                    </div>
                    <div className='title'>{stats.orders}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                <div className='card tickets'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Ticket Sold</p>
                        <ConfirmationNumberOutlinedIcon sx={{ fontSize: 22 }}/>
                    </div>
                    <div className='title'>{stats.tickets}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                </div>

                <div className='card amount'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Sales Amount</p>
                        <AttachMoneyOutlinedIcon sx={{ fontSize: 22 }}/>
                    </div>
                    <div className='title'>Rs {stats.sales}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>
            </div>        

        </div>

        <div className='row '>
            <div className='d-none d-lg-block col-md-12'>
               <div className='drop-shadow-lg last-orders bg-white'>
                    <div className="title mb-6 text5b4842">Last Orders</div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col">Order ID</th>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col">Date</th>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col">Customer</th>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col">Status</th>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col">Price</th>
                                    <th className='texte2e2e2 uppercase font-semibold' scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length>0 && data.map((item, index) => {
                                    return (
                                        <tr key={index} className=' text-sm'>
                                            <th scope="row" className='orderid textf36523 font-semibold text-sm'>#{item.id} - {item.events}</th>
                                            <td className='date text-sm' style={{color: "#8c8c8c"}}>{item.datenew}</td>
                                            <td className='customer'>{item.customer.trim()=="" ? item.email:item.customer}</td>
                                            <td className='status flex justify-start items-center'>
                                                {item.status === 'Pending' && <Chip label={item.status} color="warning" style={{backgroundColor: "#ed1b24", width:"90px"}} />}
                                                {item.status === 'Processing' && <Chip label={"Paid"} color="success" style={{backgroundColor: "#3ab54a", width:"90px"}} />}
                                                {item.status === 'Cancelled' && <Chip label={item.status} color="error" style={{backgroundColor: "#1a1a1a", width:"90px"}} />}
                                            </td>
                                            <td className='price'>Rs {item.total}</td>
                                            <td className='link'><a className='textf36523 underline text-sm' href={"/edit-order/"+item.id}>View Order &gt;</a></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
               </div>
            </div>

            <div className='d-lg-none' style={{padding : "0px 6%"}}>
             <div className='d-flex justify-content-between'>
              <p className='mb-2' style={{fontWeight : "700",marginBottom : "10px", marginLeft : "5px" ,color : "#aaaaaa"}}>
               Last Orders
              </p>
              {data.length == 0 && 
              <Spinner/>
                }
             </div>

             <div className='d-flex flex-column gap-3'>
             {data.length > 0 && data.map((item, index) => {
              return (
              <LastOrderCard data={item} index={index}/>
              )})}
             </div>

            </div>

        </div>
        </div>
    );
};

export default Dashboard;
