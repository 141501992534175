// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: 'AIzaSyC58LqyNNQdwQ_w7C4dtwWB4R0a7bOlyXE',
  authDomain: 'otayo-bcb99.firebaseapp.com',
  databaseURL: 'https://otayo-bcb99.firebaseio.com',
  projectId: 'otayo-bcb99',
  storageBucket: 'otayo-bcb99.appspot.com',
  messagingSenderId: '732609473510',
  appId: "1:732609473510:web:674d3f32598dc83938c427"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebaseApp.database();

export { database };
