import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import axios from "axios";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";


import "../styles/Posmain.css"
import POSCollectionReport from "../components/Cards/POS/POSCollectionReport";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Divider } from "@mui/material";

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const APIURL = "https://otayo.com/erpapi/pos.php";

const PosCollectionReport = () => {

    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [posTotalBalance, setposTotalBalance] = useState(0);
    const [posCashBalance, setposCashBalance] = useState(0);

    const [posName, setPosName] = useState('');
    const [posEmail, setPosEmail] = useState('');
    const [getStatsBtn, setGetStatsBtn] = useState('Get Report');
    const [allReport, setAllReport] = useState([]);
    const [submitCollectionID, setsubmitCollectionID] = useState(0);
    const [submitCollectionOrders, setsubmitCollectionOrders] = useState('');
    const [submitCollectionTotalOrderAmount, setsubmitCollectionTotalOrderAmount] = useState(0);
    const [submitCollectedCash, setsubmitCollectedCash] = useState(0);
    const [submitCollectedCard, setsubmitCollectedCard] = useState(0);
    const [submitIncentiveGiven, setsubmitIncentiveGiven] = useState(0);
    const [submitComments, setsubmitComments] = useState('');
    const [submitDeprec, setsubmitDeprec] = useState(true);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                PosService.getSinglePOS(id)
                .then(response => {
                    console.log('POS response', {response});
                    if(response.data.pos_name!='undefined') {
                        getAllCollection();
                        setPosName(response.data.pos_name);
                        setPosEmail(response.data.email);
                        getPOSBalaance(response.data.email, response.data.id);
                    }
                })
                .catch(err => console.log({err}));
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const getAllCollection = () => {
        var action = 'getAllCollections';
        
        var params = {
            action: action,
            pos: id,
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({allReport})
            setAllReport(response.data);
        })
        .catch(err => console.log(err));
    }

    const getPOSBalaance = (email, posid) => {
        var action = 'getPOSBalance';
        
        var params = {
            action: action,
            pos: posid,
            posEmail: email
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setposTotalBalance(response.data.totalBalance);
            setposCashBalance(response.data.totalCash);
        })
        .catch(err => console.log(err));
    }

    const submitCollection = (collection_id, orders, orderamount, deprec) => {
        setsubmitCollectionID(collection_id);
        setsubmitCollectionOrders(orders);
        setsubmitCollectionTotalOrderAmount(orderamount);
        setIsOpen(true);
        setsubmitDeprec(true);
    }

    const collectionConfirm = (e) => {
        e.preventDefault();
        var action = 'submitCollection';

        var params = {
            action: action,
            id: submitCollectionID,
            date_collected: startDate,
            cash_collected: submitCollectedCash,
            credit_card_collected: submitCollectedCard,
            incentive_given: submitIncentiveGiven,
            comments: submitComments,
            orders: submitCollectionOrders,
            ordersamount: submitCollectionTotalOrderAmount
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            getAllCollection();
            setIsOpen(false);
            setsubmitCollectionID(0);
            setStartDate('');
            setsubmitCollectedCash(0);
            setsubmitCollectedCard(0);
            setsubmitComments('');
            setsubmitDeprec(true);
        })
        .catch(err => console.log(err));
    }

    const getData = () => {
    }

    const deleteEntry = (collection_id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the collection!",
        icon: "warning",
        buttons: ["No, Keep the Entry!", "Yes, Remove Entry!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            var params = {
                action: 'deleteCollection',
                id: collection_id,
            }
            axios.post(APIURL+"?rand="+Math.random(), params)
            .then(response => {
                getAllCollection();
                swal("Entry Removed", "Your entry has been removed!", "success");
            })
            .catch(err => console.log(err));
        } else {
            swal("Entry Not Removed", "Your entry is safe!", "success");
        }
        });
    }

    const checkDiscrepencies = () => {
        if(parseFloat(submitCollectedCard)+parseFloat(submitCollectedCash) != parseFloat(submitCollectionTotalOrderAmount)) {
            // console.log("🚀 ~ file: posCollectionReport.js:180 ~ checkDiscrepencies ~ submitCollectedCard+submitCollectedCash", parseFloat(submitCollectedCard)+parseFloat(submitCollectedCash))
            // console.log("🚀 ~ file: posCollectionReport.js:180 ~ checkDiscrepencies ~ submitCollectionTotalOrderAmount", parseFloat(submitCollectionTotalOrderAmount))
            setsubmitDeprec(false);
        } else {
            setsubmitDeprec(true);
            // setsubmitComments('');
        }
    }

    useEffect(() => {
        checkDiscrepencies();
    }, [submitCollectedCard, submitCollectedCash]);

    return (
        <>
        <div className="d-lg-none" style={{padding : '0px 10px'}}>
        <div className="d-flex justify-content-between align-items-center border-b fixed top-0 left-0 z-20 w-100 bg-white px-3 py-3">
            <div onClick={() => window.history.back()}>
            <ArrowBackOutlinedIcon />
            </div>

            <p className="font-semibold line-clamp-1 text-sm">
            {posName} 
            </p>

            <div></div>
        </div>
         <p style={{color : '#aaaaaa'}} className="pt-1 font-semibold">Money Collection Report</p>

         <div className="pb-3 pt-2">
            <p className="text-xs py-1 font-medium">Total POS Balance : <span className="text-orange-500 text-sm font-semibold">Rs {posTotalBalance.toLocaleString('en-US')}</span></p>
            <Divider/>
            <p className="text-xs py-1 font-medium">POS Cash Balance : <span className="text-orange-500 text-sm font-semibold">Rs {posCashBalance.toLocaleString('en-US')}</span></p>
         </div>
        {allReport.length>0 && allReport.map((row, index) => (
         <POSCollectionReport row={row}/>
        ))}
        </div>
     
        <div className="salesreport-wrapper p2 d-none d-lg-block">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">{posName} Money Collection Report</h1>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="flex justify-start mb-2">
                            <div className="flex items-center mr-10">
                                <div className="mr-1 font-normal text-sm text5e4642">Total POS Balance:</div>
                                <div className="ml-2 font-normal text-sm text5e4642 bgf0f0f0 pl-8 pr-8 pt-2 pb-2">Rs {posTotalBalance.toLocaleString('en-US')}</div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-1 font-normal text-sm text5e4642">POS Cash Balance:</div>
                                <div className="ml-2 font-normal text-sm text5e4642 bgf0f0f0 pl-8 pr-8 pt-2 pb-2">Rs {posCashBalance.toLocaleString('en-US')}</div>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-xs text-center">
                                <tr>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Collection Entry ID</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Date Created</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Order Period</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Date Collected</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Total No. of Tickets</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Total Order Amount</th>
                                    <th scope="col" style={{backgroundColor: "#6dcff6", color: "#fff"}}>Total Cash</th>
                                    <th scope="col" style={{backgroundColor: "#f78e55", color: "#fff"}}>Cash Collected</th>
                                    <th scope="col" style={{backgroundColor: "#a2d39c", color: "#fff"}}>Total Credit Card</th>
                                    <th scope="col" style={{backgroundColor: "#7da7d9", color: "#fff"}}>Credit Card collected</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Discrepencies</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Incentive Given</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Net amount Collected</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Comments</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Status</th>
                                    <th scope="col" style={{backgroundColor: "#f4989d", color: "#fff"}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allReport.length>0 && allReport.map((row, index) => (
                                <tr key={index} className=" text-sm text-center">
                                    <td scope="col">{row.id}</td>
                                    <td scope="col">{row.created_on}</td>
                                    <td scope="col">{UtilityService.parse_pos_collection_date(row.order_period)}</td>
                                    <td scope="col">{row.date_collected}</td>
                                    <td scope="col">{row.total_tickets==null ? row.total_tickets : row.total_tickets.toLocaleString('en-US')}</td>
                                    <td scope="col">{row.order_amount==null ? row.order_amount : row.order_amount.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#e9f8ff"}}>{row.order_cash==null ? row.order_cash : row.order_cash.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#e9f8ff"}}>{row.cash_collected==null ? row.cash_collected : row.cash_collected.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#ffeee6"}}>{row.order_card==null ? row.order_card : row.order_card.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#ffeee6"}}>{row.credit_card_collected==null ? row.credit_card_collected : row.credit_card_collected.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#f0f0f0"}}>{row.discrepencies==null ? row.discrepencies : row.discrepencies.toLocaleString('en-US')}</td>
                                    <td scope="col">{row.incentive_given==null ? row.incentive_given : row.incentive_given.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#f0f0f0"}}>{row.net_amount_collected==null ? row.net_amount_collected : row.net_amount_collected.toLocaleString('en-US')}</td>
                                    <td scope="col" style={{backgroundColor: "#f0f0f0"}}>{row.comments}</td>
                                    <td scope="col" style={{backgroundColor: "#f0f0f0"}}>
                                        {row.status!='Submitted' && 
                                         <b className="text-orange-600">In-progress</b>
                                        }
                                        {row.status=='Submitted' && 
                                         <b className="text-green-600">Submitted</b>
                                        }
                                    </td>
                                    <td scope="col">
                                        {row.status!='Submitted' && 
                                            <button className="btn btn-sm btn-warning btn-yellow mb-2" onClick={e => submitCollection(row.id, row.orders, row.order_amount, row.discrepencies)}>Submit</button>
                                        }
                                        <button className="btn btn-sm btn-danger btn-red" onClick={e => deleteEntry(row.id)}>Delete</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            {/* Add confirmation form */}
            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => collectionConfirm(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Confirmation
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Collection Date</b></label>
                        <div className="col-8"> 
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                placeholderText="Collection Date"
                                className='form-control rounded-none'
                                maxDate={new Date()}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Cash Collected</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" required="required" value={submitCollectedCash} onChange={e => {setsubmitCollectedCash(e.target.value);}} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Credit Card Collected</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" required="required" value={submitCollectedCard} onChange={e => {setsubmitCollectedCard(e.target.value);}} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Incentive Given</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" required="required" value={submitIncentiveGiven} onChange={e => setsubmitIncentiveGiven(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Comments</b></label>
                        <div className="col-8"> 
                            <textarea type="text" className="form-control" value={submitComments} disabled={submitDeprec} onChange={e => setsubmitComments(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <p>By Submitting this form you hereby confirm that:</p>
                        <p className="mt-3">
                            <ul>
                                <li>- The money has been collected and reached our office</li>
                                <li>- The cash amount was counted and it is confirmed to be accurate</li>
                                <li>- All credit card slips have been vetted and are processed and approved by the bank</li>
                                <li>- Incentive was given as per the agreement with the Point of Sale and the Otayo policies</li>
                            </ul>
                        </p>
                        <p className="mt-3">Note: after submission all linked orders will be marked as "Collected"</p>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>
        </>
    );
}

export default PosCollectionReport;