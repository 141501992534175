import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/ordersreport.service";

export default class AllRefunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: props.render,
            allRefunds: [],
            user: props.user
        }
    }

    componentDidMount() {
        this.getRefunds();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.render!=this.props.render) {
            this.getRefunds();
        }
    }

    getRefunds = () => {
        OrdersReportService.getAllRefunds(this.state.orderid)
        .then(response => {
            console.log("all refunds", response);
            this.setState({
                allRefunds: response.data,
                rerender: false
            })
        })
        .catch(error => console.log({error}));
    }

    removeRefund = (e, id, amount) => {
        e.preventDefault();
        e.target.disabled = true;
        e.target.innerText = "Deleting...";
        OrdersReportService.deleteRefund(this.state.orderid, id, this.state.user.user_nicename, amount)
        .then(response => {
            swal('Refund Deleted!', 'The refund has been deleted', 'success');
            this.getRefunds();
            this.props.onChange();
        })
        .catch(error=>{
            console.log({error});
        })
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {this.state.allRefunds.length>0 && 
            <div style={{marginTop: "35px"}}>
            <div className="row">
                <div className="col">
                    <h6>All Refunds:</h6>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>By</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Products</th>
                                <th>Refund Type</th>
                                <th>Handling Agent</th>
                                <th>Reason</th>
                                <th>Explanation & Comments</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allRefunds!=null && this.state.allRefunds.map((refund, index) => (
                            <tr key={index}>
                                <td scope="row">{refund.id}</td>
                                <td>{refund.by}</td>
                                <td>{refund.date}</td>
                                <td>Rs {refund._refund_amount}</td>
                                <td>{refund.refund_status}</td>
                                <td>{refund.refund_products}</td>
                                <td>{refund.refund_type}</td>
                                <td>{refund.handling_agent}</td>
                                <td>{refund.refund_reason_drop}</td>
                                <td>{refund.refund_reason}</td>
                                <td>
                                    {refund.refund_status=='Pending' && 
                                    <button type="button" className="btn btn-sm btn-danger" onClick={e => this.removeRefund(e, refund.id, refund._refund_amount)}>Remove</button>
                                    }
                                    {refund.refund_status!='Pending' && 
                                    <p>The refund has been processed already.</p>
                                    }
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            }
            </>
        );
    }
}