import axios from "axios";

const APIURL = "https://otayo.com/erpapi/tickettemplates.php";

class TicketTemplatesService {
    getEventTemplate(eventID) {
        var params = {
            action: "getEventTemplate",
            eventID: eventID
        };
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getEventTickets(eventID) {
        var params = {
            action: "getEventTickets",
            eventId: eventID
        };
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getTicketConfig(ticketID) {
        var params = {
            action: 'getTicketConfig',
            ticketID: ticketID
        };
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    updateTicketConfig(ticketID, config) {
        var params = {
            action: "updateTicketConfig",
            config: config,
            ticketID: ticketID
        }

        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
}

export default new TicketTemplatesService();