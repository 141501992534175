import { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Chip from '@mui/material/Chip';
import swal from "sweetalert";
import { useParams } from 'react-router-dom';
// import Loader from 'react-loader-spinner';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Orders.css";
import FollowUpList from "../components/orders/followuplist.component";
import OrderPaymentDetails from "../components/orders/orderpaymentdetails.component";
import OrderRefundsDetails from "../components/orders/orderrefundsdetails.component";
import OrderComments from "../components/orders/orderComments.component";
import OrdersReportService from "../services/ordersreport.service";
import UtilityService from "../services/utility.service";
import EventsService from "../services/events.service";


import Spinner from '../components/LoadingState/Spinner'
import OrderCard from "../components/Cards/OrderCard";
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Pagination} from "@mui/material";

const Orders = () => {

    const [orders, setOrders] = useState([]);
    const [events, setEvents] = useState([{ value: 'all', label: 'All' }]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [selectedEvent, setSelectedEvent] = useState({ value: 'all', label: 'All' });
    const [loadingValues, setLoadingValues] = useState(true);
    const [exportCsvBtnDisabled, setExportCsvBtnDisabled] = useState(false);
    const [exportExcelBtnDisabled, setExportExcelBtnDisabled] = useState(false);
    const [exportExcelBtnText, setExportExcelBtnText] = useState("Export To Excel");
    const [exportCsvBtnText, setExportCsvBtnText] = useState("Export To CSV");

    const [FilterDrawerOpen, setFilterDrawerOpen] = useState(false);

    const [TotalOrders, setTotalOrders] = useState()

    const handleDrawer = () => {
        setFilterDrawerOpen(!FilterDrawerOpen)
    }
    
    const [orderStatus, setOrderStatus] = useState([
        { value: 'all', label: 'All Status' },
        { value: 'pending', label: 'Pending' },
        { value: 'paid', label: 'Paid' },
        { value: 'cancelled', label: 'Cancelled' },
    ]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState({ value: 'all', label: 'All' });
    const [searchBtnText, setSearchBtnText] = useState('Search');
    const [searchBtnDisabled, setSearchBtnDisabled] = useState(false);
    const [statusFilter, setStatusFilter] = useState({
              all: true,
              processing : false, 
              pending: false, 
              cancelled: false
          });
    const [paymentMethodFilter, setPaymentMethodFilter] = useState({
                all: true,
                juice : false, 
                internetbanking: false, 
                creditcard: false,
                cash: false,
                terminal: false
            });
    const [voucherStatusFilter, setVoucherStatusFilter] = useState({
              all: true,
              notSentSupplier : false, 
              notSentCustomer: false, 
              notConfirmedSupplier: false,
          });
    const [specialCasesFilter, setSpecialCasesFilter] = useState({
            all: true,
            complaint : false, 
            refunded: false, 
            credit: false,
            partpayment: false,
          });
    const [orderTagsFilter, setOrderTagsFilter] = useState({
            all: true,
            superurgent : false, 
            urgent: false, 
            corporate: false,
            bigbooking: false,
            followup: false,
            openvoucher: false,
            instantproduct: false,
          });
    const [showFollowup, setShowFollowup] = useState(false);
    const [opensearch, setOpensearch] = useState("");
    const [filtersapplied, setFiltersapplied] = useState("No filters applied");
    // const { id } = useParams();
    const [userID, setUserID] = useState(0);
    const [columnsheadings, setColumnsheadings] = useState([
            { field: 'id', headerName: 'Order ID', flex: 1, 
                renderCell: (cellValues) => {
                    return (
                        <div className="color-orange break-words font-bold">#{cellValues.row.id}</div>
                    )
                }
            },
            { field: 'customerName', headerName: 'Customer Name', flex: 1, cellClassName: 'flex justify-start', renderCell: (cellValues) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left"}}>
                        {cellValues.row.customerName}
                    </div>
                    )
                }
            },
            { field: 'events', headerName: 'Event(s)', flex: 1, cellClassName: 'flex justify-start', renderCell: (cellValues) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left"}}>
                        {cellValues.row.events}
                    </div>
                    )
                }
            },
            // { field: 'customerContacts', headerName: 'Customer Contacts', flex: 2, cellClassName: 'flexcenter', 
            //     renderCell: (cellValues) => {
            //         return (
            //         <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center"}}>
            //             <span>{cellValues.row.customerContactsEmail}</span>
            //             <span>{cellValues.row.customerContactsPhone}</span>
            //         </div>
            //         )
            //     }
            // },
            { field: 'orderDate', headerName: 'Order Date', flex: 1, cellClassName: 'flexcenter' },
            { field: 'orderStatus', headerName: 'Order Status', flex: 1, cellClassName: 'flexcenter',
                renderCell: (cellValues) => {
                    var color = "black";
                    var bg = "#cdcbcb";
                    if(cellValues.row.orderStatus=='Processing') {
                        color = "white";
                        bg = "#0fab0f";
                    } else if(cellValues.row.orderStatus=='Cancelled') {
                        color = "white";
                        bg = "#ff6767";
                    }
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <span style={{background: bg, color: color, padding: "10px", fontWeight: "bold"}}>{cellValues.row.orderStatus}</span>
                        </div>
                    )
                } 
            },
            { field: 'paymentMethod', headerName: 'Payment Method', flex: 1, cellClassName: 'flexcenter' },
            { field: 'followUp', headerName: 'Follow Up', flex: 1.1, sortable: false, disableColumnFilter: true, cellClassName: 'flexcenter', 
                renderCell : (cellValues) => {
                    if(cellValues.row.followup==true) {
                        return <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between', width: '100%', alignItems: "center"}}>
                                    <Chip label="Followup Required" style={{fontWeight: "bold"}} />
                                </div>
                    } else {
                        return "";
                    }
                }
            },
            { field: 'notes', headerName: 'Order Comments', flex: 1, cellClassName: 'flexcenter', 
                renderCell: (cellValues) => {
                    return (
                        <div className="flex flex-column justify-center items-center">
                            <p className="mb-1 text-gray-600">{cellValues.row.cmb_voucher_note}</p>
                            <p className="mb-1 text-gray-600">{cellValues.row.cmb_payment_note}</p>
                            <OrderComments orderid={cellValues.row.id} />
                        </div>
                    )
                }    
            },
            { field: 'paymentDetails', headerName: 'Payment Details', flex: 1, sortable: false, disableColumnFilter: true, cellClassName: 'flexcenter', 
                renderCell: (cellValues) => {
                    return(
                        <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between', width: '100%', alignItems: "center"}}>
                            <b>Total: </b>Rs {cellValues.row.total}<br />
                            <OrderPaymentDetails orderid={cellValues.row.id} />
                            {cellValues.row.haverefunds &&
                                <OrderRefundsDetails orderid={cellValues.row.id} />
                            }
                        </div>
                    );
                }
            },
            { field: 'orderDetails', headerName: 'Order Details', flex: 1, sortable: false, disableColumnFilter: true, cellClassName: 'flexcenter', 
                renderCell: (cellValues) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <a style={{color: "red", textDecoration: "underline", fontWeight: "normal"}} href={"/edit-order/"+cellValues.row.id}>View All Details</a>
                        </div>
                    );
                }
            },
        ]);
    const [limit, setLimit] = useState(20);
    const [rowCount, setRowCount] = useState(0);
    const [dataloader, setDataloader] = useState(false);
    const [page, setPage] = useState(1);
    const [filterValue, setFilterValue] = useState({
              key: "",
              value: ""
          });
    const [sortValue, setSortValue] = useState({
              field: "",
              sort: ""
          });
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState({
            status: false,
            message: "",
          });
    const [show, setShow] = useState(false);
    
    
    useEffect(() => {
        setDataloader(true);
        setLoadingValues(true);
        EventsService.getAllEvents()
        .then((response) => {
            setEvents(response.data);
            setLoadingValues(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if(dataloader) {
            searchClick();
        }
        
    }, [dataloader]);

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const handleChangeEvent = (selectedOption) => {
        setSelectedEvent(selectedOption);
    }

    const handleStatusChange = (e, value) => {
        if(value=='all' && e.target.checked) {
            setStatusFilter({
                all: true,
                processing : false, 
                pending: false, 
                cancelled: false
            });
        } else {
            var statusFilterTemp = statusFilter;
            statusFilterTemp['all'] = false;
            statusFilterTemp[value] = e.target.checked;
            setStatusFilter({...statusFilterTemp});
        }
    }

    const handlePaymentMethodChange = (e, value) => {
        // console.log({value});
        if(value=='all' && e.target.checked) {
            setPaymentMethodFilter({
                all: true,
                juice : false, 
                internetbanking: false, 
                creditcard: false,
                cash: false,
                terminal: false
            });
        } else {
            var paymentMethodFilterTemp = paymentMethodFilter;
            paymentMethodFilterTemp['all'] = false;
            paymentMethodFilterTemp[value] = e.target.checked;
            setPaymentMethodFilter({...paymentMethodFilterTemp});
        }
    }

    const fetchAndShowFollowupList = (e) => {
        setShowFollowup(!showFollowup);
    }

    const handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    const onFilterChange = (filterModel) => {
        console.log("filter value", filterModel.items[0].value);
        console.log("filter Item", filterModel.items[0].columnField);

        setFilterValue({
            key: filterModel.items[0].columnField,
            value: filterModel.items[0].value
        });

        if(typeof filterModel.items[0].value!='undefined') {
            searchClick();
        } else {
            setFilterValue({
                key: "",
                value: ""
            });
            
            searchClick();
        }
    }

    const handleSortModelChange = (newModel) => {
        console.log({newModel});
        setSortValue(newModel[0]);

        searchClick();
    }

    const filtersappliedCheck = () => {
        if(statusFilter.processing==true || statusFilter.pending==true || statusFilter.cancelled==true || paymentMethodFilter.juice==true || paymentMethodFilter.internetbanking==true || statusFilter.creditcard==true || paymentMethodFilter.cash==true || voucherStatusFilter.notSentSupplier==true || voucherStatusFilter.notSentCustomer==true || voucherStatusFilter.notConfirmedSupplier==true || specialCasesFilter.complaint==true || specialCasesFilter.refunded==true || specialCasesFilter.credit==true || specialCasesFilter.partpayment==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || orderTagsFilter.processing==true || opensearch!="") {
            
            setFiltersapplied("Filters Applied");
        }
    }


    const searchClick = (resetPage=false) => {
        var startday = '';
        var endday = '';
        setOrders([]);
        setDataloader(true);
        setSearchBtnDisabled(true);
        setSearchBtnText("Fetching....");

        if(resetPage) {
            setPage(1);
        }

        filtersappliedCheck();

        if (startDate != '' && endDate!='') {
            startday = UtilityService.format_date(startDate);
            endday = UtilityService.format_date(endDate);
        }

        console.log({startday});

        OrdersReportService.getOrders(page, limit, startday, endday, selectedEvent.value, statusFilter, specialCasesFilter, orderTagsFilter, paymentMethodFilter, voucherStatusFilter, opensearch, userID, filterValue, sortValue)
        .then(response => {
            console.log("get orders", response);
            setTotalOrders(response.data.count)
            setOrders(response.data.orders);
            setShow(true);
            setLoader(false);
            setRowCount(response.data.count);
            setDataloader(false);
            setSearchBtnText("Apply");
            setSearchBtnDisabled(false);    
        })
        .catch(error => console.log({error}));
        
        console.log("search clicked");
    }

    const exportResults = (e, type) => {
        e.preventDefault();

        if(type=='csv') {
            setExportCsvBtnText("Exporting...");
            setExportCsvBtnDisabled(true);
            
        } else {
            setExportExcelBtnText("Exporting...");
            setExportExcelBtnDisabled(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        searchClick();
    }

    console.log('orders: ' , orders)


    // abhi form for filter  
    const handleCheckboxChange = (checkbox) => {
        setStatusFilter((prevStatusFilter) => ({
        ...prevStatusFilter,
        all: false,
        [checkbox]: !prevStatusFilter[checkbox],
        }));
    };

    const handleCheckboxChangeMethod = (checkbox) => {
        setPaymentMethodFilter((prevPaymentMethodFilter) => ({
          ...prevPaymentMethodFilter,
          all: false,
          [checkbox]: !prevPaymentMethodFilter[checkbox],
        }));
      };


      const clearFilters = () => {
        setStatusFilter({
            all: true,
            processing : false, 
            pending: false, 
            cancelled: false
        })
        setPaymentMethodFilter({
            all: true,
            juice : false, 
            internetbanking: false, 
            creditcard: false,
            cash: false,
            terminal: false
        });
      };

      const currentPage = (value) => {
        searchClick(setPage(value));
      }

    return (
        <>
        <div className="d-none d-lg-block p-2">
        <div className="p-4 shadow-lg">
        <div className="row">
            <div className='col mb-3'>
                <div class="title font-bold h1 text-lg text5e4944">Otayo Orders</div>
                <hr />
            </div>
        </div>
        <div className="row mb-3">
            <div className='col'>
                {loadingValues == true &&
                    <p style={{ color: "#df153b" }}><b><i>Loading Orders. Please Wait....</i></b></p>
                }
                <form onSubmit={e => submitForm(e)} className="order-reports-search-form" style={{ display: 'flex' }}>
                    <input type="hidden" name="page" value="deals-payment-report" />
                    <div>
                        
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={startDate}
                            onChange={date => setStartDateCal(date)}
                            placeholderText="Start Date"
                            className='form-control'
                        />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={endDate}
                            onChange={date => setEndDateCal(date)}
                            placeholderText="End Date"
                            className='form-control'
                            minDate = {minDate}
                        />
                    </div>
                    <div style={{ marginLeft: '10px', width: '300px' }}>
                        
                        <Select
                            value={selectedEvent}
                            onChange={(option) => handleChangeEvent(option)}
                            options={events}
                        />    
                    </div>
                   
                    {/* <div style={{ marginLeft: '10px', width: '300px' }}>
                        <Select
                            value={selectedOrderStatus}
                            onChange={(option) => setSelectedOrderStatus(option)}
                            options={orderStatus}
                        />
                    </div> */}
                    
                    <div style={{ marginLeft: '10px' }}>
                        <button type="button" className="btn btn-success btn-large booking-search-btn bg-green-600" disabled={searchBtnDisabled} onClick={(e) => searchClick(true)}>{searchBtnText}</button>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <a href="/orders" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                    </div>

                    <div style={{ marginLeft: '10px' }}>
                        <button className="btn btn-large btn-danger bg-red-700 hover:bg-red-800 rounded-none" onClick={(e) => searchClick(false)}>Refresh</button>
                    </div>

                    <div style={{ marginLeft: '10px' }}>
                        <a href="/juice-automation" className="btn btn-large btn-orange bg-orange-700 hover:bg-orange-800 rounded-none">Juice Automation</a>
                    </div>

                </form>

                <hr className="mt-3" />
            </div>
        </div>
        <Accordion style={{marginBottom: "20px"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{backgroundColor: "#ebebeb"}}>
             <Typography style={{fontWeight: "bold", color:"#5e4642"}}>Filters & Export 
            {/*{userID!=0 && 
                <span style={{color: "red", marginLeft: "10px"}}>
                    (Filtered Result for User ID: {userID})
                </span>
            } */}
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <div className="row">
                <div className='col'>
                    <form onSubmit={e => submitForm(e)} className="order-reports-filter-search-form filter-form-container">
                        <FormGroup>
                            <label className="filterform-label">Order Status</label>
                            <FormControlLabel className="filter-form-checkbox" checked={statusFilter.all} onChange={(e) => handleStatusChange(e, 'all')} control={<Checkbox />} label="All" />
                            <FormControlLabel className="filter-form-checkbox" checked={statusFilter.processing} onChange={(e) => handleStatusChange(e, 'processing')} control={<Checkbox />} label="Processing" />
                            <FormControlLabel className="filter-form-checkbox" checked={statusFilter.pending} onChange={(e) => handleStatusChange(e, 'pending')} control={<Checkbox />} label="Pending Payment" />
                            <FormControlLabel className="filter-form-checkbox" checked={statusFilter.cancelled} onChange={(e) => handleStatusChange(e, 'cancelled')} control={<Checkbox />} label="Cancelled" />
                        </FormGroup>
                        <FormGroup>
                            <label className="filterform-label">Payment Method</label>
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.all} onChange={(e) => handlePaymentMethodChange(e, 'all')} control={<Checkbox checked={paymentMethodFilter.all} />} label="All" />
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.juice} onChange={(e) => handlePaymentMethodChange(e, 'juice')} control={<Checkbox checked={paymentMethodFilter.juice} />} label="MCB Juice" />
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.internetbanking} onChange={(e) => handlePaymentMethodChange(e, 'internetbanking')} control={<Checkbox checked={paymentMethodFilter.internetbanking} />} label="Internet Banking" />
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.creditcard} onChange={(e) => handlePaymentMethodChange(e, 'creditcard')} control={<Checkbox checked={paymentMethodFilter.creditcard} />} label="Credit Card" />
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.terminal} onChange={(e) => handlePaymentMethodChange(e, 'terminal')} control={<Checkbox checked={paymentMethodFilter.terminal} />} label="Card Terminal" />
                            <FormControlLabel className="filter-form-checkbox" checked={paymentMethodFilter.cash} onChange={(e) => handlePaymentMethodChange(e, 'cash')} control={<Checkbox checked={paymentMethodFilter.cash} />} label="Cash" />
                        </FormGroup>
                        <FormGroup>
                            <label className="filterform-label">Actions</label>
                                <Box
                            // component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <TextField id="outlined-basic" name="opensearch" value={opensearch} label="Search" variant="outlined" onChange={(e)=>setOpensearch(e.target.value)} />
                            </Box>
                            <button type="button" style={{marginTop:"10px"}} disabled={(startDate=="" && endDate=="") || exportCsvBtnDisabled} className="btn btn-success btn-sm" onClick={e => exportResults(e, 'csv')}>{exportCsvBtnText}</button>
                            <button type="button" style={{marginTop:"10px"}} disabled={(startDate=="" && endDate=="") || exportExcelBtnDisabled} className="btn btn-success btn-sm" onClick={e => exportResults(e, 'excel')}>{exportExcelBtnText}</button>
                            <button type="button" style={{marginTop:"10px"}} className="btn btn-primary btn-sm bg-blue-800" onClick={e => fetchAndShowFollowupList(e)}>View Followup List</button>
                            
                        </FormGroup>
                    </form>

                    <hr />
                </div>
            </div>
            </AccordionDetails>
            </Accordion>
            {showFollowup==true && 
                <FollowUpList loadnow={showFollowup} orderid="0" />
            }
            <div className="row">
                    <div className='col'>
                        <h2 className="font-bold text-base p-2 subsection-heading mb-2">Orders</h2>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {/* <div className="container-fluid"> */}
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={orders}
                                    columns={columnsheadings}
                                    pageSize={limit}
                                    rowsPerPageOptions={[10, 20, 50]} 
                                    autoHeight={true}
                                    // rowHeight={200}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => 'auto'}
                                    pagination
                                    rowCount={rowCount}
                                    paginationMode="server"
                                    filterMode="server"
                                    onFilterModelChange={()=>onFilterChange()}
                                    loading={dataloader}
                                    onPageChange={async (data) => {
                                        console.log({data});
                                        await setPage(data+1);
                                        searchClick(false);
                                    }}
                                    // page={setPage(page - 1)}
                                    onPageSizeChange={(newPage) => setPage(newPage)} 
                                    sortingMode="server" 
                                    onSortModelChange={() => handleSortModelChange()}
                                />
                                </div>
                            }
                            {/* {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            } */}
                        {/* </div> */}
                    </div>
                </div>
        </div>
        </div>

        <div className="d-lg-none">
         <div className="d-flex justify-content-between pb-2" style={{ padding : '0px 10px 0px 10px'}}>
           <p style={{fontWeight : "700",marginBottom : "0px",marginLeft : '5px',color : 'grey'}}>OTAYO Orders</p>
           
           {!loadingValues ? <button onClick={() =>  handleDrawer()} className="px-2 rounded-sm text-sm hover:bg-orange-500 hover:text-white"> <TuneOutlinedIcon/> </button>
           : <></>}
         </div>


         <Drawer anchor='bottom' open={FilterDrawerOpen} onClose={handleDrawer}>
            <div style={{ padding: 20 , height : '70vh' }} className='d-flex flex-col gap-3'>
               
                <div className="d-flex gap-2">

                <div>
                <p className="text-sm font-semibold pb-2">Start Date : </p>
                <DatePicker
                 dateFormat="dd-MM-yyyy"
                 selected={startDate}
                 onChange={date => setStartDateCal(date)}
                 placeholderText="Start Date"
                 className='form-control'
                 onInputClick={() =>console.log('input Start Date clicked line 658')}
                />
                </div>

                <div>
                <p className="text-sm font-semibold pb-2">End Date : </p>
                <DatePicker
                 dateFormat="dd-MM-yyyy"
                 selected={endDate}
                 onChange={date => setEndDateCal(date)}
                 placeholderText="End Date"
                 className='form-control'
                 minDate = {minDate}
                />
                </div>

                </div>

                <div>
                <p className="text-sm font-semibold pb-2">Event : </p>
                <Select
                 value={selectedEvent}
                 onChange={(option) => handleChangeEvent(option)}
                 options={events}
                />    
                </div>
                <div>
                    <p className="text-sm font-semibold pb-2">Filter Order Status : </p>

                    <div className="d-flex flex-col">
                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="allCheckbox"
                            checked={statusFilter.all}
                            onChange={() => setStatusFilter({ all: !statusFilter.all, processing: false, pending: false, cancelled: false })}
                            />
                            <label className="form-check-label" htmlFor="allCheckbox">
                            All
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="processingCheckbox"
                            checked={statusFilter.processing}
                            onChange={() => handleCheckboxChange('processing')}
                            />
                            <label className="form-check-label" htmlFor="processingCheckbox">
                            Processing
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="pendingPaymentCheckbox"
                            checked={statusFilter.pending}
                            onChange={() => handleCheckboxChange('pending')}
                            />
                            <label className="form-check-label" htmlFor="pendingPaymentCheckbox">
                            Pending Payment
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="cancelledCheckbox"
                            checked={statusFilter.cancelled}
                            onChange={() => handleCheckboxChange('cancelled')}
                            />
                            <label className="form-check-label" htmlFor="cancelledCheckbox">
                            Cancelled
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <p className="text-sm font-semibold pb-2">Filter Payment Method : </p>

                    <div className="d-flex flex-col">
                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="allCheckbox"
                            checked={paymentMethodFilter.all}
                            onChange={() =>
                                setPaymentMethodFilter({
                                all: !paymentMethodFilter.all,
                                juice: false,
                                internetbanking: false,
                                creditcard: false,
                                cash: false,
                                terminal: false,
                                })
                            }
                            />
                            <label className="form-check-label" htmlFor="allCheckbox">
                            All
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="juiceCheckbox"
                            checked={paymentMethodFilter.juice}
                            onChange={() => handleCheckboxChangeMethod('juice')}
                            />
                            <label className="form-check-label" htmlFor="juiceCheckbox">
                            Juice
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="internetbankingCheckbox"
                            checked={paymentMethodFilter.internetbanking}
                            onChange={() => handleCheckboxChangeMethod('internetbanking')}
                            />
                            <label className="form-check-label" htmlFor="internetbankingCheckbox">
                            Internet Banking
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="creditcardCheckbox"
                            checked={paymentMethodFilter.creditcard}
                            onChange={() => handleCheckboxChangeMethod('creditcard')}
                            />
                            <label className="form-check-label" htmlFor="creditcardCheckbox">
                            Credit Card
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="cashCheckbox"
                            checked={paymentMethodFilter.cash}
                            onChange={() => handleCheckboxChangeMethod('cash')}
                            />
                            <label className="form-check-label" htmlFor="cashCheckbox">
                            Cash
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            id="terminalCheckbox"
                            checked={paymentMethodFilter.terminal}
                            onChange={() => handleCheckboxChangeMethod('terminal')}
                            />
                            <label className="form-check-label" htmlFor="terminalCheckbox">
                            Terminal
                            </label>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-evenly pb-4">
                    <a href="/orders" className="bg-black font-semibold text-white rounded-sm px-2 py-1">Clear Filters</a>
                    <button onClick={() => { searchClick(); handleDrawer();}} className="bg-orange-500 font-semibold text-white rounded-sm px-4 py-1">Apply</button>
                </div>


                {/* <div>
                    
                    <input value={opensearch} onChange={(e)=>setOpensearch(e.target.value)} placeholder="Search" />
                    <button onClick={() => { handleDrawer(); searchClick(); }}>Search</button>
                </div> */}
            </div>
         </Drawer>

         <div className="pb-1" style={{ padding : '0px 10px 0px 10px'}}>
            <div className="d-flex justify-content-between gap-2">
             <input type="number" value={opensearch} onChange={(e)=> setOpensearch(e.target.value)} placeholder="Search Orders" className="border py-2 px-2 rounded-sm text-sm w-100" />
             <button onClick={() => searchClick() } className="bg-orange-500 text-white rounded-sm px-2"><SearchIcon/></button>
            </div>
         </div>


         <div className="d-flex justify-content-between pt-1 pb-2
          flex-wrap" style={{ padding : '0px 10px 0px 10px'}}>
           <div className="d-flex px-1 gap-1 flex-nowrap overflow-scroll">
            {/* {statusFilter.all && (
            <div >
                <p className="text-orange-600 text-nowrap px-2 py-1 rounded-pill" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                All
                </p>
            </div>
            )} */}

            {statusFilter.processing && (
            <div onClick={() => {setStatusFilter({ ...statusFilter, processing: false }); searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Processing <CloseIcon style={{ fontSize: 12 }} />
                </p>
            </div>
            )}

            {statusFilter.pending && (
            <div onClick={() => {setStatusFilter({ ...statusFilter, pending: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Pending <CloseIcon style={{ fontSize: 12 }} />
                </p>
            </div>
            )}
            {statusFilter.cancelled && (
            <div onClick={() => {setStatusFilter({ ...statusFilter, cancelled: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Cancelled <CloseIcon style={{ fontSize: 12 }} />
                </p>
            </div>
            )}
         

                {/* {paymentMethodFilter.all && (
                <div>
                <p className="text-orange-600 py-1 px-2 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                All
                </p>
                </div>
                )} */}
                {paymentMethodFilter.juice && (
                <div onClick={() => {setPaymentMethodFilter({ ...paymentMethodFilter, juice: false }) ;searchClick();}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Juice <CloseIcon style={{ fontSize: 12 }} />
                </p>
                </div>
                )}
                {paymentMethodFilter.internetbanking && (
                <div onClick={() => {setPaymentMethodFilter({ ...paymentMethodFilter, internetbanking: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Internet Banking <CloseIcon style={{ fontSize: 12 }} />
                </p>
                </div>
                )}
                {paymentMethodFilter.creditcard && (
                <div onClick={() => {setPaymentMethodFilter({ ...paymentMethodFilter, creditcard: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Credit Card <CloseIcon style={{ fontSize: 12 }} />
                </p>
                </div>
                )}
                {paymentMethodFilter.cash && (
                <div onClick={() => {setPaymentMethodFilter({ ...paymentMethodFilter, cash: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Cash <CloseIcon style={{ fontSize: 12 }} />
                </p>
                </div>
                )}
                {paymentMethodFilter.terminal && (
                <div onClick={() => {setPaymentMethodFilter({ ...paymentMethodFilter, terminal: false });searchClick()}}>
                <p className="text-orange-600 pl-2 pr-1 py-1 rounded-pill text-nowrap" style={{ border: '1px solid #f97316', fontSize: 10 }}>
                Terminal <CloseIcon style={{ fontSize: 12 }} />
                </p>
                </div>
                )}
           </div>

        </div>

         <div className="d-flex flex-col gap-3" style={{ padding : '0px 10px 0px 10px'}}>

          {orders == 0 && <div className="m-auto"><Spinner/></div>}
          {orders.map((order , index) => (
           <OrderCard order={order}/>
          ))}
         </div>

{/* 
         <Pagination
         hidePrevButton hideNextButton
         variant="outlined" shape="rounded" 
         count={TotalOrders} defaultPage={1} page={page} onChange={(event , value) => currentPage(value)} /> */}

           <div className="d-flex flex-col align-items-center gap-2 justify-content-center pt-3">
            <Pagination
             hidePrevButton hideNextButton
             shape="rounded" 
             siblingCount={1}
             count={TotalOrders}
             defaultPage={1}
             page={page} 
             onChange={(event , value) => currentPage(value)} />

             <p className="text-xs text-secondary">{page} of {TotalOrders}</p>
            </div>


        </div>
        </>
    );
}

export default Orders;
