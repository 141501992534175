import React, { Component } from "react";
// import Loader from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import swal from "sweetalert";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";

import OrdersReportService from "../../services/ordersreport.service";

export default class FollowUpList extends Component {
    constructor(props) {
        super(props);

        this.state = {
          data: [],
          columnsheadings: [
            { field: 'reminderTime', headerName: 'Reminder Time', flex: 1 },
            { field: 'reminderNote', headerName: 'Reminder Note', flex: 1 },
            { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
            { field: 'customerContacts', headerName: 'Customer Contacts', flex: 1 },
            { field: 'agentName', headerName: 'Agent Name', flex: 1 },
            { field: 'status', headerName: 'Status', flex: 1, 
                renderCell: (cellValues) => {
                    if(cellValues.row.status=="Pending") {
                        return (<Chip label="Pending" />);
                    } else {
                        return (<Chip label="Resolved" color="success" />);
                    }
                }
            },
            { field: 'orderDetails', headerName: 'Order Details', flex: 1, 
                renderCell: (cellValues) => {
                    return(
                        <div style={{display: "flex", flexDirection: "row", justifyContent: 'flex-start', width: '100%', alignItems: "center"}}>
                            <a href={"/edit-order/"+cellValues.row.orderNumber}><button type="button" className="btn btn-sm btn-primary bg-blue-800">View Details</button></a>
                        </div>
                    );
                }
            },
            { field: 'actions', headerName: 'Actions', flex: 1, 
                renderCell: (cellValues) => {
                    if(cellValues.row.status=="Pending") {
                        return(
                            <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center"}}>
                                <button type="button" className="btn btn-sm btn-link" style={{color: "red", marginTop: "20px"}} onClick={e => this.resolveFollowup(e, cellValues.row.id)}>Resolve</button>
                                <button type="button" className="btn btn-sm btn-link" style={{marginBottom: "20px"}} onClick={e => this.updateFollowupTimePopup(e, cellValues.row.id)}>Update Reminder Date</button>
                            </div>
                        );
                    } else {
                        return (
                        <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center"}}>
                            <Chip label="Resolved" color="success" />
                        </div>
                        );
                    }
                }    
            },
          ],
          show: false,
          loadingvalues: true,
          error: {
            status: false,
            message: "",
          },
          loader: false,
          loadnow:props.loadnow,
          orderid: props.orderid,
          rerender: props.rerender,
          isopen: false,
          remindertimenew: '',
          updateremindertimebtndisabled: false,
          updateremindertimebtntext: "Update",
          updateId: "",
          username: props.username
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    componentDidMount() {
        console.log("componentDidMount", this.state);
        this.fetchList();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.rerender!=this.props.rerender) {
            this.fetchList();
        }
    }

    resolveFollowup = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once marked resolved the reminder cannot be changed",
            icon: "warning",
            buttons: ["No, Keep it", "Yes, Mark as resolved"],
            dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {

            OrdersReportService.resolveFollowup(id, this.state.username, this.state.orderid)
            .then(response => {
                swal("Reminders has been marked as resolved!", {
                icon: "success",
                });
                this.fetchList();
            })
            .catch(error => console.log({error}));
        } else {
            swal("Your reminder is safe!");
        }
        });
    }

    updateFollowupTimePopup = (e, id) => {
        e.preventDefault();
        this.setState({
            isopen: true,
            updateId: id
        });
    }

    updateReminderTimeData = (e) => {
        e.preventDefault();
        this.setState({
            updateremindertimebtntext: "Updating...",
            updateremindertimebtndisabled: true
        });

        OrdersReportService.updateFollowupTime(this.state.updateId, this.state.remindertimenew, this.state.username, this.state.orderid)
        .then(res => {
            this.setState({
                updateremindertimebtntext: "Update",
                updateremindertimebtndisabled: false,
                isopen: false,
                updateId: "",
                remindertimenew: ""
            });
            swal("Updated!", "Reminder date and time has been updated", "success");
            this.fetchList();
        })
        .catch(error => {console.log({error})})
    }


    fetchList = () => {
        if(this.state.loadnow) {
            OrdersReportService.fetchFollowupList(this.state.orderid)
            .then(response => {
                console.log({response});
                this.setState({
                    data: response.data,
                    show: true,
                    loadingvalues: false
                });
            })
            .catch(error => console.log({error}));
        }
    }

    render() {
        var { data, columnsheadings, show, loadingvalues, error, loader } = this.state;
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <div style={{marginBottom: "20px"}}>
                <div className="row">
                    <div className='col'>
                        <h1 className="orders-h1 font-bold text-base p-2 subsection-heading mb-2">Followup List</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Followups. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {/* <div className="container-fluid"> */}
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <div style={{width: '100%' }}>
                                    <DataGrid
                                        rows={data}
                                        columns={columnsheadings}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        autoHeight={true}
                                        rowHeight={80}
                                    />
                                </div>
                            }
                            {/* {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            } */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.updateReminderTimeData(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Update Reminder Time
                        </div>
                    </div>
                     
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="handlingAgentName" className="col-4 col-form-label"><b>Reminder Date & Time:</b></label>
                        <div className="col-8"> 
                            <input type="datetime-local" className="form-control" id="remindertimenew" placeholder="Select reminder date and time" required="required" onChange={e => {this.setState({remindertimenew: e.target.value})}} value={this.state.remindertimenew} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.updateremindertimebtndisabled || this.state.remindertimenew==""}>{this.state.updateremindertimebtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}