import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams, useRoutes } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { CSVLink, CSVDownload } from "react-csv";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import FinancialLogs from "../components/financiallogs.component";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from "@fortawesome/free-solid-svg-icons";


import AuthService from "../services/auth.service";
import EventService from "../services/events.service";

import FileUploader from "../components/fileuploader.component";

import "../styles/EventSingle.css"
import { FileUploadRounded, Refresh } from "@mui/icons-material";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { set } from "react-hook-form";


import { Divider ,Drawer} from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EventSingleSummery from "../components/Cards/EventSingleSummery";
import EventTicketSales from "../components/Cards/EventTicketSales";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import Spinner from '../components/LoadingState/Spinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../styles/Event/EventTables.css'



const eventExtrasURL = "https://otayo.com/erpapi/eventsextras.php";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

  export const optionsBArChart = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right"
    },
    // title: {
    //   display: true,
    //   text: "Total Sales in Rs"
    // }
  }
};

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EventSingle = (props) => {

    const { id } = useParams();
    const [eventID, setEventID] = useState(id);
    const [getStatsBtn, setGetStatsBtn] = useState('Get Stats');
    const [seated, setSeated] = useState(null);
    const [event, setEvent] = useState(null)
    const [tickets, setTickets] = useState(null);
    const [inviteTickets, setInviteTickets] = useState(null);
    const [salesReport, setSalesReport] = useState(true);
    const [ticketControl, setticketControl] = useState('no');
    const [ticketControlDetails, setticketControlDetails] = useState("");
    const [vsp, setvsp] = useState("no");
    const [vspDetails, setvspDetails] = useState("no");
    const [hostessess, sethostessess] = useState("");
    const [hostessessDetails, sethostessessDetails] = useState("");
    const [ticketCommision, setticketCommision] = useState("");
    const [invitationFee, setinvitationFee] = useState("");
    const [bankFeePayableBy, setbankFeePayableBy] = useState("");
    const [specialComments, setspecialComments] = useState("");
    const [marketingSpecialComments, setmarketingSpecialComments] = useState("");
    const [marketingPackages, setmarketingPackages] = useState("no");
    const [marketingPackagesDetails, setmarketingPackagesDetails] = useState("");
    const [envelopes, setenvelopes] = useState("no");
    const [envelopesDetails, setenvelopesDetails] = useState("");
    const [brandedTickets, setbrandedTickets] = useState("no");
    const [brandedTicketsDetails, setbrandedTicketsDetails] = useState("");
    const [seatLabeling, setseatLabeling] = useState("no");
    const [seatLabelingDetails, setseatLabelingDetails] = useState("");
    const [producerNotification, setproducerNotification] = useState("yes");
    const [additionalNotifEmail, setadditionalNotifEmail] = useState("");
    const [notifEmails, setnotifEmails] = useState([]);
    const [allEventDeposits, setallEventDeposits] = useState([]);
    const [allPaymentToProducer, setallPaymentToProducer] = useState([]);
    const [allAdvanceProdPayments, setallAdvanceProdPayments] = useState([]);
    const [showContractUpload, setShowContractUpload] = useState(false);
    const [showInvoiceUpload, setShowInvoiceUpload] = useState(false);
    const [contract, setContract] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [additionalEmail, setAdditionalEmail] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [salesLoading, SetSalesLoading] = useState("Loading Sales Report...");
    const [collabLevel, setcollabLevel] = useState("normal");
    const [collabLevelDetails, setcollabLevelDetails] = useState("");
    const [coreProducerFee, setCoreProducerFee] = useState("");

    const [ticketControlPax, setticketControlPax] = useState("");
    const [ticketControlHours, setticketControlHours] = useState([]);
    const [ticketControlcost, setticketControlcost] = useState("");
    const [ticketControlproducerpay, setticketControlproducerpay] = useState("");
    const [vspPax, setvspPax] = useState("");
    const [vspHours, setvspHours] = useState([]);
    const [vspcost, setvspcost] = useState("");
    const [vspproducerpay, setvspproducerpay] = useState("");
    const [hostessessPax, sethostessessPax] = useState("");
    const [hostessessHours, sethostessessHours] = useState([]);
    const [hostessesscost, sethostessesscost] = useState("");
    const [hostessessproducerpay, sethostessessproducerpay] = useState("");
    const [transportation, settransportation] = useState("");
    const [transportationvehicle, settransportationvehicle] = useState("");
    const [transportationNumber, settransportationNumber] = useState("");
    const [transportationcost, settransportationcost] = useState("");
    const [transportationproducerpay, settransportationproducerpay] = useState("");
    const [transportationDetails, settransportationDetails] = useState("");
    const [totalPersonsServices, setTotalPersonsServices] = useState(0);
    const [totalCostServices, setTotalCostServices] = useState(0);
    const [totalFeePayableServices, setTotalFeePayableServices] = useState(0);
    const [relatedEventFiles, setrelatedEventFiles] = useState([]);
    const [isOpenRelatedFileModal, setisOpenRelatedFileModal] = useState(false);

    const [MoreTicketDetails, setMoreTicketDetails] = useState(false)
    const [MoreSalesReport, setMoreSalesReport] = useState(false)

    const [eventDeposit, seteventDeposit] = useState({
        amount: "",
        date: "",
        additional_info: "",
        payment_method: "",
        reference: "",

    });

    const [advanceProdPayment, setadvanceProdPayment] = useState({
        amount: "",
        date: "",
        additional_info: "",
        payment_method: "",
        reference: "",

    });

    const [paymentToProducer, setpaymentToProducer] = useState({
        amount: "",
        date: "",
        additional_info: "",
        payment_method: "",
        reference: "",
        status: ""

    });
    const [user, setuser] = useState(null);

    const [dataSalesPerCategory, setDataSalesPerCategory] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataTicketSales, setDataTicketSales] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataTicketSales7Days, setdataTicketSales7Days] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataPos, setdataPos] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataSalesPerGateway, setDataSalesPerGateway] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataSalesPerRegion, setDataSalesPerRegion] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });
    const [labelsDownloaded, setlabelsDownloaded] = useState([]);
    const [labelsDownloadedAll, setlabelsDownloadedAll] = useState([]);
    const [minDate, setMinDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [marketingServicesMul, setmarketingServicesMul] = useState([]);
    const [marketingServicesSingleCategory, setmarketingServicesSingleCategory] = useState("");
    const [marketingServiceSingleDescription, setmarketingServiceSingleDescription] = useState("");
    const [marketingServiceSinglePayable, setmarketingServiceSinglePayable] = useState("");
    const [marketingServiceSinglePaid, setmarketingServiceSinglePaid] = useState("");
    const [marketingServiceSinglePending, setmarketingServiceSinglePending] = useState("");

    const [producerTickets, setproducerTickets] = useState([]);
    const [producerTicketsSingleDateProcessed, setproducerTicketsSingleDateProcessed] = useState("");
    const [producerTicketsSingleNoOfTickets, setproducerTicketsSingleNoOfTickets] = useState("");
    const [producerTicketsSinglePriceEx, setproducerTicketsSinglePriceEx] = useState("");
    const [producerTicketsSingleTotalPriceIn, setproducerTicketsSingleTotalPriceIn] = useState("");
    const [alltotaltickets, setalltotaltickets] = useState(0);
    const [alltotalPrice, setalltotalPrice] = useState(0);
    
    const [isOpenPayment, setisOpenPayment] = useState(false);
    const [paymnetID, setpaymnetID] = useState(0);
    const [paymentType, setpaymentType] = useState('');
    const [paymentAmount, setpaymentAmount] = useState('');
    const [paymentDate, setpaymentDate] = useState('');
    const [paymentMethod, setpaymentMethod] = useState('');
    const [paymentReference, setpaymentReference] = useState('');

    const [isOpenPaymentDetails, setisOpenPaymentDetails] = useState(false);
    const [servicespaymentdetails, setservicespaymentdetails] = useState([]);
    const [seatlabelingservice, setseatlabelingservice] = useState('no');
    const [seatlabelingservicecost, setseatlabelingservicecost] = useState('');
    const [seatlabelingservicepayable, setseatlabelingservicepayable] = useState('');
    const [seatlabelingservicecomments, setseatlabelingservicecomments] = useState('');
    const [realtedFileUploadedUrl, setrealtedFileUploadedURL] = useState(false);
    const [relatedFileDesc, setRelatedFileDesc] = useState("");

    const [classifiedCouponsFees, setclassifiedCouponsFees] = useState(null);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    useEffect(() => {
        if(eventID>0) {
            AuthService.checklogin()
            .then(user => {
                // console.log("user", user)
                if(user!=false) {
                    setuser(user);
                    getData();
                    getEventMarketingServices();
                    getEventProducerTickets();
                    eventRelatedFiles();
            }
        }).catch((err) => {
            console.log(err);
            // setIsLoggedIn(false);
            window.location.href='/login';
        });
            
        }
    }, [eventID]);

    const eventRelatedFiles = () => {
        EventService.eventRelatedFiles(eventID)
        .then(res => {
            console.log("related files", res.data);
            setrelatedEventFiles(res.data);
        })
        .catch(err => {
            console.log(err);
        });
    };

    const deleteRelatedFile = (id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The file will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the File!", "Yes, Remove File!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            EventService.deleteRelatedFile(id)
            .then(res => {
                setrelatedEventFiles(relatedEventFiles.filter(item => item.id !== id));
                swal("Success", "File deleted successfully", "success");
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            swal("File Not Removed", "Your file is safe!", "success");
        }
        });
    };

   useEffect(() => {
    if(realtedFileUploadedUrl!=false) {
        submitRelatedFile(realtedFileUploadedUrl, relatedFileDesc);
        setrealtedFileUploadedURL(false);
         setisOpenRelatedFileModal(false);
    }
   }, [realtedFileUploadedUrl]);

    const submitRelatedFile = (url) => {
        EventService.submitRelatedFile(eventID, url, relatedFileDesc)
        .then(res => {
            eventRelatedFiles();
        })
        .catch(err => {
            console.log(err);
        });
    };

    const getData = () => {
        setGetStatsBtn("Loading Stats...");
        EventService.getEventCoupons(eventID)
        .then(res => {
            console.log("coupons", res.data);
            setCoupons(res.data);
        })
        .catch(err => {
            console.log(err);
        });

        EventService.getEventCouponsClassifications(eventID)
        .then(res => {
            console.log("coupons classifications", res.data);
            setclassifiedCouponsFees(res.data);
        }).catch(err => {
            console.log({err});
        })

        EventService.getEvent(eventID)
        .then(res => {
            // console.log(res.data);
            setEvent(res.data);

            downloadLabels(eventID);
            downloadLabelsAll(eventID);
            setSeated(res.data.seated);

            EventService.getEventTicketsSales(eventID, startDate, endDate)
            .then(res => {
                console.log("tickets", res.data);
                setTickets(res.data);
                SetSalesLoading("");
                setGetStatsBtn("Get Stats");

                var templabel = [];
                var tempData = [];
                if(res.data.tickets.length>0) {
                    res.data.tickets.map((item, index) => {
                        templabel.push(item.ticketname);
                        tempData.push(item.totalsold_raw);
                    });
                }

                setDataSalesPerCategory({
                    labels: templabel,
                    datasets: [{
                        data: tempData,
                        backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                    }]
                });

                var templabel2 = ['Sold', 'Reserved', 'Remaining'];
                var tempData2 = [parseInt(res.data.totalsales.totalSold_raw), parseInt(res.data.totalsales.totalreserved_raw), parseInt(res.data.totalsales.totalremaining_raw)];

                setDataTicketSales({
                    labels: templabel2,
                    datasets: [{
                        data: tempData2,
                        backgroundColor: ["#999933", "#666699", "#CC9933", "#006666", "#3399FF", "#993300", "#CCCC99", "#666666", "#FFCC66", "#6699CC", "#663366", "#9999CC", "#CCCCCC", "#669999", "#CCCC66", "#CC6600", "#9999FF", "#0066CC", "#99CCCC", "#999999", "#FFCC00", "#009999", "#99CC33", "#FF9900", "#999966", "#66CCCC", "#339966", "#CCCC33", "#003f5c", "#665191", "#a05195", "#d45087", "#2f4b7c", "#f95d6a", "#ff7c43", "#ffa600", "#EF6F6C", "#465775", "#56E39F", "#59C9A5", "#5B6C5D", "#0A2342", "#2CA58D", "#84BC9C", "#CBA328", "#F46197", "#DBCFB0", "#545775", "#336699", "#99CCFF"],
                    }]
                });

                var templabel3 = [];
                var tempData3 = [];

                res.data.tickets.map((item, index) => {
                    templabel3.push(item.ticketname);
                    tempData3.push(item.totalsold_raw7days);
                });

                setdataTicketSales7Days({
                    labels: templabel3,
                    datasets: [{
                        data: tempData3,
                        backgroundColor: ["#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                    }]
                });

                var templabel4 = ['POS', 'Online'];
                var tempData4 = [parseInt(res.data.totalsales.soldbypos), parseInt(res.data.totalsales.totalSold_raw)-parseInt(res.data.totalsales.soldbypos)];
                console.log("totalsales", parseInt(res.data.totalsales.totalSold_raw));
                setdataPos({
                    labels: templabel4,
                    datasets: [{
                        data: tempData4,
                        backgroundColor: ["#CBA328", "#F46197", "#DBCFB0", "#545775", "#336699", "#99CCFF", "#999933", "#666699", "#9999CC", "#CCCCCC", "#669999", "#CCCC66", "#CC6600", "#9999FF", "#0066CC", "#99CCCC", "#999999", "#FFCC00", "#009999", "#99CC33", "#FF9900", "#999966", "#66CCCC", "#339966", "#CCCC33", "#003f5c", "#665191", "#a05195", "#d45087", "#2f4b7c", "#f95d6a", "#ff7c43", "#ffa600", "#EF6F6C", "#465775", "#56E39F", "#59C9A5", "#5B6C5D", "#0A2342", "#2CA58D", "#84BC9C", "#CC9933", "#006666", "#3399FF", "#993300", "#CCCC99", "#666666", "#FFCC66", "#6699CC", "#663366"],
                    }]
                });

                var templabel5 = ['Juice', 'Terminal', 'Migs', 'Bank', 'Cash'];
                var tempData5 = [parseInt(res.data.totalsales.soldbyjuice), parseInt(res.data.totalsales.soldbyterminal), parseInt(res.data.totalsales.soldbymigs), parseInt(res.data.totalsales.soldbybank), parseInt(res.data.totalsales.soldbycash)];

                setDataSalesPerGateway({
                    labels: templabel5,
                    datasets: [{
                        data: tempData5,
                        backgroundColor: ["#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999"],
                    }]
                });

                var templabel6 = [];
                var tempData6 = [];
                res.data.totalsales.regions.map((item, index) => {
                    console.log(item);
                    templabel6.push(item.name.charAt(0).toUpperCase() + item.name.slice(1));
                    tempData6.push(item.sold);
                });

                setDataSalesPerRegion({
                    labels: templabel6,
                    datasets: [{
                        data: tempData6,
                        backgroundColor: ["#EF6F6C","#465775","#56E39F","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775", "#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600"],
                    }]
                });

            })
            .catch(err => {
                console.log(err);
            });

            
            EventService.getEventInviteTicketsSale(eventID)
            .then(res => {
                console.log("invite tickets", res.data);
                setInviteTickets(res.data);
            })
            .catch(err => {
                console.log(err);
            });

            // EventService.getEventReport(eventID)
            // .then(res => {
            //     console.log("sales report", res.data);
            //     setSalesReport(res.data);
            //     SetSalesLoading("");
            // })
            // .catch(err => {
            //     console.log(err);
            // });

            EventService.getAllEventMeta(eventID)
            .then(res => {
                console.log("event meta", res.data);
                let data = res.data;
                setticketControl(data.service_ticket_control);
                setticketControlDetails(data.service_ticket_control_details);
                setvsp(data.service_vsp);
                setvspDetails(data.service_vsp_details);
                sethostessess(data.service_hostessess);
                sethostessessDetails(data.service_hostessess_details);
                setticketCommision(data.service_ticket_commision);
                setinvitationFee(data.service_invitation_fee);
                setbankFeePayableBy(data.service_bank_fee_payable_by);
                setspecialComments(data.service_special_comments);
                setmarketingPackages(data.branding_marketing_packages);
                setmarketingPackagesDetails(data.branding_marketing_packages_details);
                setenvelopes(data.branding_envelopes);
                setenvelopesDetails(data.branding_envelopes_details);
                setbrandedTickets(data.branding_branded_tickets);
                setbrandedTicketsDetails(data.branding_branded_tickets_details);
                setseatLabeling(data.branding_seat_labeling);
                setseatLabelingDetails(data.branding_seat_labeling_details);
                setmarketingSpecialComments(data.branding_marketing_special_comments);
                setproducerNotification(data.producer_notification);
                setContract(data.contract_file);
                setInvoice(data.invoice_file);
                setAdditionalEmail(data.additional_prod_email);
                setcollabLevel(data.collab_level);
                setcollabLevelDetails(data.collab_level_details);
                setCoreProducerFee(data.service_core_fee);

                setticketControlPax(data.service_ticket_control_pax);
                setticketControlHours(data.service_ticket_control_hours);
                setticketControlcost(data.service_ticket_control_cost);
                setticketControlproducerpay(data.service_ticket_control_producerpay);

                setvspPax(data.service_vsp_pax);
                setvspHours(data.service_vsp_hours);
                setvspcost(data.service_vsp_cost);
                setvspproducerpay(data.service_vsp_producerpay);

                sethostessessPax(data.service_hostessess_pax);
                sethostessessHours(data.service_hostessess_hours);
                sethostessesscost(data.service_hostessess_cost);
                sethostessessproducerpay(data.service_hostessess_producerpay);

                setseatlabelingservice(data.service_seat_labeling);
                setseatlabelingservicecost(data.service_seat_labeling_cost);
                setseatlabelingservicepayable(data.service_seat_labeling_producerpay);
                setseatlabelingservicecomments(data.service_seat_labeling_details);

                settransportation(data.service_transportation);
                settransportationvehicle(data.service_transportation_vehicle);
                settransportationNumber(data.service_transportation_number);
                settransportationcost(data.service_transportation_cost);
                settransportationproducerpay(data.service_transportation_producerpay);
                settransportationDetails(data.service_transportation_details);
                // setadditionalNotifEmail(data.additional_notif_email);
                // setnotifEmails(data.notif_emails);
            })
            .catch(err => {
                console.log(err);
            });

            EventService.getEventDeposits(eventID)
            .then(res => {
                // console.log("event deposits", res.data);
                setallEventDeposits(res.data);
            })
            .catch(err => {
                console.log(err);
            });

            EventService.getPaymentToProducer(eventID)
            .then(res => {
                // console.log("payment to producer", res.data);
                setallPaymentToProducer(res.data);
            })
            .catch(err => {
                console.log(err);
            });

            EventService.getAdvancePaymentToProducer(eventID)
            .then(res => {
                // console.log("advance prod payments", res.data);
                setallAdvanceProdPayments(res.data);
            })
            .catch(err => {
                console.log(err);
            });

        })
        .catch(err => {
            console.log(err);
        });
    }

    const addtableEntry = (type) => {
        var dataToUpdate = null; 
        if(type=="eventDeposit") {
            dataToUpdate = eventDeposit;
        }
        if(type=="advanceProdPayment") {
            dataToUpdate = advanceProdPayment;
        }
        if(type=="paymentToProducer") {
            dataToUpdate = paymentToProducer;
        }

        console.log("data to update", dataToUpdate);

        if(dataToUpdate!=null) {
            EventService.addSingleTableEntry(eventID, dataToUpdate, type, user.user_nicename)
            .then(res => {
                console.log("add table entry", res.data);
                resetTableAndReload(type);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    const resetTableAndReload = (type) => {

        if(type=="eventDeposit") {
            EventService.getEventDeposits(eventID)
            .then(res => {
                console.log("event deposits", res.data);
                setallEventDeposits(res.data);
                seteventDeposit({
                    amount: "",
                    date: "",
                    additional_info: "",
                    payment_method: "",
                    reference: "",
                });
            })
            .catch(err => {
                console.log(err);
            });
        }
        if(type=="advanceProdPayment") {
            EventService.getAdvancePaymentToProducer(eventID)
            .then(res => {
                console.log("advance prod payments", res.data);
                setallAdvanceProdPayments(res.data);
            })
            .catch(err => {
                console.log(err);
            });
            setadvanceProdPayment({
                amount: "",
                date: "",
                additional_info: "",
                payment_method: "",
                reference: "",
            });
        }
        if(type=="paymentToProducer") {
             EventService.getPaymentToProducer(eventID)
            .then(res => {
                console.log("payment to producer", res.data);
                setallPaymentToProducer(res.data);
            })
            .catch(err => {
                console.log(err);
            });
            setpaymentToProducer({
                amount: "",
                date: "",
                additional_info: "",
                payment_method: "",
                reference: "",
                status: "",
            });
        }
        
    }

    const deleteTableEntry = (type, id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the Entry!", "Yes, Remove Entry!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            EventService.deleteTableEntry(type, id, user.user_nicename, eventID)
            .then(res => {
                swal("Success", "Entry deleted successfully", "success");
                resetTableAndReload(type);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            swal("Entry Not Removed", "Your entry is safe!", "success");
        }
        });
        
    }

    const setSingleTableInput = (type, key, value) => {
        if(type=="eventDeposit") {
            seteventDeposit({...eventDeposit, [key]: value});
        }
        if(type=="advanceProdPayment") {
            setadvanceProdPayment({...advanceProdPayment, [key]: value});
        }
        if(type=="paymentToProducer") {
            setpaymentToProducer({...paymentToProducer, [key]: value});
        }
    }

     const setArrayTableInput = (type, index, key, value) => {
        if(type=="eventDeposit") {
            let temp = allEventDeposits;
            temp[index][key] = value;
            setallEventDeposits(temp);
        }
        if(type=="advanceProdPayment") {
            let temp = allAdvanceProdPayments;
            temp[index][key] = value;
            setallAdvanceProdPayments(temp);
        }
        if(type=="paymentToProducer") {
            let temp = allPaymentToProducer;
            temp[index][key] = value;
            setallPaymentToProducer(temp);
        }
     }

    const deleteFile = (type) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The file will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the File!", "Yes, Remove File!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            var key = '_contract_file';
            if(type=="invoice") {
                key = '_invoice_file';
                setInvoice(null);
            } else if(type=="contract") {
                key = '_contract_file';
                setContract(null);
            }
            EventService.updateEventMeta(eventID, key, "")
            .then(res => {
                swal("Success", "File deleted successfully", "success");
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            swal("File Not Removed", "Your file is safe!", "success");
        }
        });
     }

     const addAdditionalEmail = () => {
        EventService.updateEventMeta(eventID, '_additional_prod_email', additionalEmail)
            .then(res => {
                swal("Success", "Email added successfully", "success");
            })
            .catch(err => {
                console.log(err);
            });
     }

     const downloadLabels = () => {
        // e.preventDefault();
        EventService.downloadLabels(eventID, 'paid')
            .then(res => {
                // console.log("Labels", res.data);
                setlabelsDownloaded(res.data);
                // swal("Success", "Labels downloaded successfully", "success");
            })
            .catch(err => {
                console.log(err);
            });
     }

     const downloadLabelsAll = () => {
        // e.preventDefault();
        EventService.downloadLabels(eventID, 'all')
            .then(res => {
                // console.log("Labels", res.data);
                setlabelsDownloadedAll(res.data);
                // swal("Success", "Labels downloaded successfully", "success");
            })
            .catch(err => {
                console.log(err);
            });
     }

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const Refresh = () => {
        window.location.reload(false);
    }

    // useEffect(() => {
    //     EventService.updateEventMeta(eventID, '_service_ticket_control_hours', ticketControlHours);
    // }, [ticketControlHours]);

    // useEffect(() => {
    //     EventService.updateEventMeta(eventID, '_service_vsp_hours', vspHours);
    // }, [vspHours]);

    // useEffect(() => {
    //     EventService.updateEventMeta(eventID, '_service_hostessess_hours', hostessessHours);
    // }, [hostessessHours]);

    // useEffect(() => {
    //     EventService.updateEventMeta(eventID, '_service_ticket_control_producerpay', ticketControlproducerpay);
    // }, [ticketControlproducerpay]);

    const saveEventServices = () => {
        EventService.updateEventMeta(eventID, 'collabType', collabLevel);
        EventService.updateEventMeta(eventID, '_service_ticket_control', ticketControl);
        EventService.updateEventMeta(eventID, '_service_ticket_control_hours', ticketControlHours);
        EventService.updateEventMeta(eventID, '_service_ticket_control_cost', ticketControlcost);
        EventService.updateEventMeta(eventID, '_service_ticket_control_pax', ticketControlPax);
        EventService.updateEventMeta(eventID, '_service_ticket_control_details', ticketControlDetails);
        EventService.updateEventMeta(eventID, '_service_ticket_control_producerpay', ticketControlproducerpay);

        EventService.updateEventMeta(eventID, '_service_vsp', vsp);
        EventService.updateEventMeta(eventID, '_service_vsp_hours', vspHours);
        EventService.updateEventMeta(eventID, '_service_vsp_cost', vspcost);
        EventService.updateEventMeta(eventID, '_service_vsp_pax', vspPax);
        EventService.updateEventMeta(eventID, '_service_vsp_details', vspDetails);
        EventService.updateEventMeta(eventID, '_service_vsp_producerpay', vspproducerpay);

        EventService.updateEventMeta(eventID, '_service_hostessess', hostessess);
        EventService.updateEventMeta(eventID, '_service_hostessess_hours', hostessessHours);
        EventService.updateEventMeta(eventID, '_service_hostessess_cost', hostessesscost);
        EventService.updateEventMeta(eventID, '_service_hostessess_pax', hostessessPax);
        EventService.updateEventMeta(eventID, '_service_hostessess_details', hostessessDetails);
        EventService.updateEventMeta(eventID, '_service_hostessess_producerpay', hostessessproducerpay);

        EventService.updateEventMeta(eventID, '_service_seat_labeling', seatlabelingservice);
        EventService.updateEventMeta(eventID, '_service_seat_labeling_cost', seatlabelingservicecost);
        EventService.updateEventMeta(eventID, '_service_seat_labeling_producerpay', seatlabelingservicepayable);
        EventService.updateEventMeta(eventID, '_service_seat_labeling_details', seatlabelingservicecomments);

        EventService.updateEventMeta(eventID, '_service_core_fee', coreProducerFee);
        EventService.updateEventMeta(eventID, '_service_ticket_commision', ticketCommision);
        EventService.updateEventMeta(eventID, '_service_invitation_fee', invitationFee);
        EventService.updateEventMeta(eventID, '_service_bank_fee_payable_by', bankFeePayableBy);
        EventService.updateEventMeta(eventID, '_service_special_comments', specialComments);

        EventService.updateEventMeta(eventID, '_service_transportation', transportation);
        EventService.updateEventMeta(eventID, '_service_transportation_cost', transportationcost);
        EventService.updateEventMeta(eventID, '_service_transportation_producerpay', transportationproducerpay);
        EventService.updateEventMeta(eventID, '_service_transportation_number', transportationNumber);
        EventService.updateEventMeta(eventID, '_service_transportation_vehicle', transportationvehicle);

        EventService.updateEventMeta(eventID, '_branding_marketing_packages', marketingPackages);
        EventService.updateEventMeta(eventID, '_branding_marketing_packages_details', marketingPackagesDetails);
        EventService.updateEventMeta(eventID, '_branding_envelopes_details', envelopesDetails);
        EventService.updateEventMeta(eventID, '_branding_envelopes', envelopes);
        EventService.updateEventMeta(eventID, '_branding_branded_tickets', brandedTickets);
        EventService.updateEventMeta(eventID, '_branding_branded_tickets_details', brandedTicketsDetails);
        EventService.updateEventMeta(eventID, '_branding_seat_labeling', seatLabeling);
        EventService.updateEventMeta(eventID, '_branding_seat_labeling_details', seatLabelingDetails);
        EventService.updateEventMeta(eventID, '_branding_marketing_special_comments', marketingSpecialComments);
        
        swal("Success", "Event Services updated successfully", "success");

    }


    const getEventMarketingServices = () => {
        var params = {
            action: "getAllMarketingServices",
            eventID: eventID
        }

        axios.post(eventExtrasURL, params)
        .then(res => {
            setmarketingServicesMul(res.data);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getEventProducerTickets = () => {
        var params = {
            action: "getAllProducerTickets",
            eventID: eventID
        }

        axios.post(eventExtrasURL, params)
        .then(res => {
            setproducerTickets(res.data);
            var totalTickets= 0;
            var totalPrice = 0;

            for(var i = 0; i < res.data.length; i++) {
                totalTickets += parseInt(res.data[i].numberoftickets);
                totalPrice += parseFloat(res.data[i].totalprice);
            }

            setalltotaltickets(totalTickets);
            setalltotalPrice(totalPrice);
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    const addmarketingService = () => {

        if(marketingServicesSingleCategory == '' || marketingServiceSingleDescription == '' || marketingServiceSinglePayable == '') {
            swal("Error", "Please fill Category, Description and Amount Payable !", "error");
            return;
        }
        let params = {
            category: marketingServicesSingleCategory,
            description: marketingServiceSingleDescription,
            payable: marketingServiceSinglePayable,
            eventID: eventID,
            action: "addMarketingService",
            addedby: user.user_nicename
        }

        axios.post(eventExtrasURL, params)
        .then(res => {
            setmarketingServicesSingleCategory('');
            setmarketingServiceSingleDescription('');
            setmarketingServiceSinglePayable('');
            getEventMarketingServices();
        })
        .catch(err => {
            console.log(err);
        });
    }

    const editMarketingService = (id) => {
        setpaymnetID(id);
        setpaymentType('marketing');
        setisOpenPayment(true);
    }

    const editProducerTickets = (id) => {
        setpaymnetID(id);
        setpaymentType('tickets');
        setisOpenPayment(true);
    }

    const deleteMarketingService = (index, recid) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the Entry!", "Yes, Remove Entry!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            let tempServices = marketingServicesMul;
            tempServices.splice(index, 1);
            setmarketingServicesMul([...tempServices]);

            axios.post(eventExtrasURL, {action: "deleteMarketingService", id: recid})
            .then(res => {
                getEventMarketingServices();
            })
            .catch(err => {
                console.log(err);
            });
        } else {
           
        }
        });
        
    }

    const addProducerTickets = () => {
        if(producerTicketsSingleDateProcessed == '' || producerTicketsSingleNoOfTickets == '' || producerTicketsSinglePriceEx == '') {
            swal("Error", "Please fill Date Processed, Number of Tickets and Price per Ticket (excl. VAT) !", "error");
            return;
        }
        
        let params = {
            dateprocessed: producerTicketsSingleDateProcessed,
            numberoftickets: producerTicketsSingleNoOfTickets,
            priceperticket: producerTicketsSinglePriceEx,
            eventID: eventID,
            action: "addProducerTicket",
            addedby: user.user_nicename
        }

        axios.post(eventExtrasURL, params)
        .then(res => {
            setproducerTicketsSingleDateProcessed('');
            setproducerTicketsSingleNoOfTickets('');
            setproducerTicketsSinglePriceEx('');
            getEventProducerTickets();
        })
        .catch(err => {
            console.log(err);
        });
    }

    const deleteProducerTickets = (index, recid) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the Entry!", "Yes, Remove Entry!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
           let tempProducersTickets = producerTickets;
            tempProducersTickets.splice(index, 1);
            setproducerTickets([...tempProducersTickets]);

            axios.post(eventExtrasURL, {action: "deleteProducerTickets", id: recid})
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            // swal("Entry Not Removed", "Your entry is safe!", "success");
        }
        });
        
    }

    const addpaymentMarkTicks = (e) => {
        e.preventDefault();

        let params = {
            eventID: eventID,
            action: "addPayment",
            paymentType: paymentType,
            paymentID: paymnetID,
            paymentAmount: paymentAmount,
            paymentDate: paymentDate,
            paymentMethod: paymentMethod,
            paymentReference: paymentReference,
            addedby: user.user_nicename
        }

        if(paymentAmount == '' || paymentDate == '' || paymentMethod == '' || paymentReference == '') {
            swal("Error", "Please fill all the fields !", "error");
            return;
        }

        axios.post(eventExtrasURL, params)
        .then(res => {
            console.log(res);
            setisOpenPayment(false);
            setpaymnetID('');
            setpaymentAmount('');
            setpaymentDate('');
            setpaymentMethod('');
            setpaymentReference('');
            if(paymentType == 'marketing') {
                getEventMarketingServices();
            } else {
                getEventProducerTickets();
            }
            swal("Success", "Payment added successfully", "success");
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getPymentDetails = (recid, type) => {
        setservicespaymentdetails([]);
        setpaymentType(type);
        let params = {
            action: "getPaymentDetails",
            id: recid,
            type: type
        }

        
        axios.post(eventExtrasURL, params)
        .then(res => {
            console.log(res);
            setservicespaymentdetails(res.data);
            setisOpenPaymentDetails(true);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const deletePaymentDetails = (item_id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the event!",
        icon: "warning",
        buttons: ["No, Keep the Entry!", "Yes, Remove Entry!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            axios.post(eventExtrasURL, {action: "deletePaymentDetails", id: item_id, type: paymentType})
            .then(res => {
                console.log(res);
                // getPymentDetails(paymnetID, 'tickets');
                
                if(paymentType == 'marketing') {
                    getEventMarketingServices();
                } else {
                    getEventProducerTickets();
                }
                setisOpenPaymentDetails(false);
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            // swal("Entry Not Removed", "Your entry is safe!", "success");
        }
        });
        
    }

    const isImage = (path) => {
        return(path.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    const fileName = (path) => {
        return path.split('/').pop();
    }


    return (
        <>
        <div className="d-none d-lg-block event-single-wrapper p-2">
        <div className="singleevent shadow-lg p-4">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="eventname font-bold text-xl mb-2">{event!=null && event.name}</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="overview">
                        <div className="section-heading font-bold text-sm uppercase p-2 text-gray-500 mb-2">Overview</div>
                        <div className="section-content text-gray-500 text-base mb-5">
                            <p className="mb-1">The Event page gives you access to a fully detailed report providing essential insights into the event.</p>
                            <p className="mb-1">The information Otayo has collected and collated is presented in a simple, user-friendly format to help you focus and control your event. You can easily monitor the sales performance and set goals and targets to boost the events success.</p>
                            <p>The report will provide information related to:</p>
                            <ul className="pl-6">
                                <li className="list-disc">Event Details and Summary</li>
                                <li className="list-disc">Ticketing and Sales</li>
                                <li className="list-disc">Attendees and Guests</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading font-bold text-sm uppercase p-2 bg-gray-300 text-gray-500 mb-2">Event Summary</div>
                    <div className="summary mb-5">
                        <div className="section grid grid-cols-3 gap-3">
                            <div className="summary-item flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Producer:</div>
                                <div className="content text-xs w-50 bac text1a1a1a">{event!=null && event.producer}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event Location:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.location}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event Start Date:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.eventstartdate}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event End Date:</div>
                                <div className="content text-xs w-50 text1a1a1a">{event!=null && event.eventenddate}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Seated Event:</div>
                                <div className="content w-50 text-xs text1a1a1a">{event!=null && event.seated}</div>
                            </div>
                            <div className="summary-item  flex flex-row items-center">
                                <div className="label text-xs min-w-25 w-25 font-bold text1a1a1a">Event Contract</div>
                                <div className="contractbtns text-00b0f0 flex flex-col w-50 justify-center items-center">
                                    <a href={contract} target="_blank" className="p-2 mb-1 bg-amber-600 text-white w-100 text-center capitalize cursor-pointer text-sm">view contract</a>
                                    <div className="flex justify-around items-center w-100">
                                        <a onClick={(e) => setShowContractUpload(!showContractUpload)} className="upload font-bold cursor-pointer text-sm">Upload</a>
                                        <span className="text-gray-400">|</span>
                                        <a className="delete font-bold cursor-pointer text-sm" onClick={(e) => deleteFile('contract')}>Delete</a>
                                    </div>
                                </div>
                            </div>
                            {showContractUpload && 
                                <FileUploader event={eventID} metakey="_contract_file" onChange={(url) => setContract(url)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-base mb-4 text5c4742">Ticket Sales</div>
                        {/* <button className="btn btn-sm btn-primary bg-blue-700 hover:bg-blue-800 rounded-none" onClick={(e) => downloadLabels(e)}>Download Paid Seats Labels / Lists</button> */}
                        <div>
                        {seated=='Yes' && labelsDownloaded.length>0 && 
                            <CSVLink data={labelsDownloaded} filename={event.name+"-seats-labels.csv"} className="btn btn-sm btn-primary rounded-none mr-2 bg-blue-700 hover:bg-blue-800 text-sm">Download Paid Seats Labels / Lists</CSVLink>
                        }

                        {seated=='Yes' && labelsDownloadedAll.length>0 && 
                            <CSVLink data={labelsDownloadedAll} filename={event.name+"-seats-labels.csv"} className="btn btn-sm btn-warning rounded-none text-white bg-orange-700 hover:bg-orange-800 text-sm">Download All Seats Labels / Lists</CSVLink>
                        }
                        </div>
                    </div>
                    <div className="section-content text-base text-gray-500 mb-4">
                        <p>The Ticket Summary Report shows you the revenue performance of your ticket sales. Here you can see how well event sales have been, down to specific categories. Along with the total revenue of each ticket category, you will even get a breakdown of the number of tickets sold and the number of tickets remaining from the allocated group.</p>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="flex justify-end mb-2">
                                <div>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        onChange={date => setStartDateCal(date)}
                                        placeholderText="Start Date"
                                        className='form-control rounded-none text-sm'
                                    />
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate}
                                        onChange={date => setEndDateCal(date)}
                                        placeholderText="End Date"
                                        className='form-control rounded-none text-sm'
                                        minDate = {minDate}
                                    />
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <a href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getData()} >{getStatsBtn}</a>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    <a href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 text-white rounded-none text-sm" onClick={(e) => Refresh()} >Reset</a>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="ticketsales">
                        <div class="ticketsales-table mb-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-xs uppercase">Ticket Category</th>
                                        <th scope="col" className="text-xs uppercase">Price per Ticket</th>
                                        <th scope="col" className="text-xs uppercase text-center">Tickets Quota</th>
                                        <th scope="col" className="text-xs uppercase text-center">Total No. Tickets Allocated</th>
                                        <th scope="col" className="text-xs uppercase text-center">No. Tickets Sold</th>
                                        <th scope="col" className="text-xs uppercase text-center">No. Tickets Reserved</th>
                                        <th scope="col" className="text-xs uppercase text-center">Remaining Tickets</th>
                                        <th scope="col" className="text-xs uppercase text-center">Total Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tickets!=null && tickets.tickets.map((ticket, index) => (
                                        <tr key={index} data-ticket-id={ticket.id}>
                                            <td className="text-xs font-bold">{ticket.ticketname}</td>
                                            <td className="text-xs">Rs {ticket.price}</td>
                                            <td className="text-xs text-center" style={{color: "#8c8c8c"}}>{ticket.ticketsquota}</td>
                                            <td className="text-xs text-center" style={{color: "#8c8c8c"}}>{ticket.ticketsallocated}</td>
                                            <td className="text-xs text-center">{ticket.totalsold}</td>
                                            <td className="text-xs text-center">{ticket.totalreserved}</td>
                                            <td className="text-xs text-center">{ticket.remaining}</td>
                                            <td className="text-xs text-center">Rs {ticket.totalsales}</td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                                <tfoot>
                                    {tickets!=null &&
                                    <tr>
                                        <td className="text-xs font-normal">Grand Total</td>
                                        <td></td>
                                        <td className="text-xs text-center font-normal">{tickets.totalsales.totalquota}</td>
                                        <td className="text-xs text-center font-normal">{tickets.totalsales.totalallocated}</td>
                                        <td className="text-xs text-center font-normal">{tickets.totalsales.totalSold}</td>
                                        <td className="text-xs text-center font-normal">{tickets.totalsales.totalreserved}</td>
                                        <td className="text-xs text-center font-normal">{tickets.totalsales.totalremaining}</td>
                                        <td className="text-xs text-center font-normal">Rs {tickets.totalsales.totalsales}</td>
                                    </tr>
                                    }
                                </tfoot>
                            </table>
                        </div>
                        <div class="ticketsales-table-invites-coupons flex justify-between items-start">
                            <div class="ticketsales-table-invites">
                                <div className="sub-heading text-base font-bold mb-4">Invitations:</div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-xs uppercase text-center" scope="col">Ticket Category</th>
                                            <th className="text-xs uppercase text-center" scope="col">No. of Invitations</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inviteTickets!=null && inviteTickets.tickets.map((ticket, index) => (
                                            <tr key={index} data-ticket-id={ticket.id}>
                                                <td className="text-xs text-center font-normal"><b>{ticket.ticketname}</b></td>
                                                <td className="text-xs text-center">{ticket.totalsold}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-col">
                                <div className="fee">
                                    <table className="table w-80">
                                        <tbody>
                                            <tr style={{borderTop: "3px solid #e6e6e6"}}>
                                                <td colspan="2" className="text-base font-bold text-center">Extra Fees:</td>
                                            </tr>
                                            {classifiedCouponsFees!=null &&   
                                                <tr>
                                                    <td className="text-sm"><b>Total Ticket Fees</b></td>
                                                    <td className="text-sm text-center">Rs {parseFloat(classifiedCouponsFees.ticketfee.amount).toFixed(2).toLocaleString()}</td>
                                                </tr>
                                            }
                                            {classifiedCouponsFees!=null &&   
                                                <tr>
                                                    <td className="text-sm"><b>Total Payment Gateway Fees</b></td>
                                                    <td className="text-sm text-center">Rs {parseFloat(classifiedCouponsFees.gatewayfee.amount).toFixed(2).toLocaleString()}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>     
                                </div>
                                
                                <div className="fee mt-4">
                                    <table className="table w-80">
                                        <tbody>
                                            <tr style={{borderTop: "3px solid #e6e6e6"}}>
                                                <td colspan="2" className="text-base font-bold text-center">Discounts:</td>
                                            </tr>
                                            {classifiedCouponsFees!=null &&   
                                                <tr>
                                                    <td className="text-sm"><b>Coupon Discounts</b></td>
                                                    <td className="text-sm text-center">Rs {parseFloat(classifiedCouponsFees.cart.amount).toFixed(2).toLocaleString()}</td>
                                                </tr>
                                            }
                                            {classifiedCouponsFees!=null &&   
                                                <tr>
                                                    <td className="text-sm"><b>Points Discount</b></td>
                                                    <td className="text-sm text-center">Rs {parseFloat(classifiedCouponsFees.points.amount).toFixed(2).toLocaleString()}</td>
                                                </tr>
                                            }

                                            {classifiedCouponsFees!=null &&   
                                                <tr>
                                                    <td className="text-sm"><b>Other Discount</b></td>
                                                    <td className="text-sm text-center">Rs {parseFloat(classifiedCouponsFees.other.amount).toFixed(2).toLocaleString()}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>     
                                </div>

                            </div>
                            <div class="ticketsales-table-coupons">
                                <div className="sub-heading text-base font-bold mb-4">Coupons Used:</div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-xs uppercase text-center" scope="col">Coupon Category</th>
                                            <th className="text-xs uppercase text-center" scope="col">Coupon Code</th>
                                            <th className="text-xs uppercase text-center" scope="col">Coupons Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {coupons.length!=null && coupons.map((item, index) => ( 
                                            <tr key={index}>
                                                <td className="text-xs text-center"><b>{item.type}</b></td>
                                                <td className="text-xs text-center">{item.name}</td>
                                                <td className="text-center text-xs">{item.count}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="font-bold text-lg mb-4">Sales Report <span className="ml-2 text-red-600 text-sm">{salesLoading}</span></div>
                    <div className="sales-table mb-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-xs uppercase">Event Name</th>
                                    <th scope="col" className="text-xs uppercase text-center">Total Tickets Sold</th>
                                    <th scope="col" className="text-xs uppercase text-center">Total Sales amount</th>
                                    <th scope="col" className="text-xs uppercase text-center">No. of Orders</th>
                                    <th scope="col" className="text-xs uppercase text-center">Average Order Amount</th>
                                    <th scope="col" className="text-xs uppercase text-center">Average No. Tickets per Order</th>
                                    <th scope="col" className="text-xs uppercase text-center">Past 24 hours (Sales / Tickets)</th>
                                    <th scope="col" className="text-xs uppercase text-center">Past 7 Days (Sales / Tickets)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets!=null && typeof tickets.totalsales!="undefined" &&
                                <tr>
                                    <td className="text-orange-600 font-bold text-xs text01bff1 underline">{event!=null && event.name}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.totalSold}</td>
                                    <td className="text-xs uppercase text-center">Rs {tickets.totalsales.totalsales}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.totalOrders}</td>
                                    <td className="text-xs uppercase text-center">Rs {tickets.totalsales.avgorderamount}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.avgticketquantity}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.onetotalsales} / {tickets.totalsales.onetotalSold}</td>
                                    <td className="text-xs uppercase text-center">{tickets.totalsales.seventotalsales} / {tickets.totalsales.seventotalSold}</td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="sales-charts row mt-10 mb-10">
                        <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Sales Per Category</div>
                            <Pie data={dataSalesPerCategory} />
                        </div>
                       
                        <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales</div>
                            <Pie data={dataTicketSales} />
                        </div>

                         <div className="sales-charts-item col-3 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales Amout (Last 7 Days)</div>
                            <Pie data={dataTicketSales7Days} />
                        </div>

                          <div className="sales-charts-item col-3">
                            <div className="sub-heading text-base font-bold mb-4">Ticket Sales POS vs Online</div>
                            <Pie data={dataPos} />
                          </div>
                    </div>
                     <div className="sales-charts row mt-10 mb-10">
                        <div className="sales-charts-item col-6 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Tickets Sold per Payment Gateway</div>
                            <Bar data={dataSalesPerGateway} options={optionsBArChart} />
                        </div>

                        <div className="sales-charts-item col-6 flex flex-col justify-center items-center">
                            <div className="sub-heading text-base font-bold mb-4">Tickets Sold per District <span className="text-gray-500 text-sm italic font-semibold">(Based on available user details)</span></div>
                            <Bar data={dataSalesPerRegion} options={optionsBArChart} />
                        </div>
                     </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="font-bold text-base mb-4 text5c4742">Otayo Services</div>
                    <div className="otayoservices flex flex-column justify-between items-start mb-4">
                        <div className="services-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-xs uppercase" scope="col">List of Services</th>
                                        <th className="text-xs uppercase text-center" scope="col">Service Required</th>
                                        <th className="text-xs uppercase text-center" scope="col">Number of Pax</th>
                                        <th className="text-xs uppercase text-center" scope="col">Working Hours</th>
                                        <th className="text-xs uppercase text-center" scope="col">Total Cost</th>
                                        <th className="text-xs uppercase text-center" scope="col">Fee Payable by Producer</th>
                                        <th className="text-xs uppercase text-center" scope="col">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Collaboration Level</td>
                                        <td>
                                            <select value={collabLevel} className="form-control form-control-select text-sm" onChange={e => {setcollabLevel(e.target.value);EventService.updateEventMeta(eventID, 'collabType', e.target.value);}}>
                                                <option value="exclusive">Exclusive</option>
                                                <option value="normal">Non Exclusive</option>
                                            </select>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><textarea className="form-control" value={collabLevelDetails} onChange={e => {setcollabLevelDetails(e.target.value);EventService.updateEventMeta(eventID, '_collaboration_level_details', e.target.value);}} /></td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Ticket Control</td>
                                        <td>
                                            <select value={ticketControl} className="form-control form-control-select text-sm" onChange={e => {setticketControl(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" value={ticketControlPax} className="form-control text-sm" onChange={e => {setticketControlPax(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control_pax', e.target.value);}} />
                                        </td>
                                        <td>
                                            {/* <input type="text" value={ticketControlHours} className="form-control" onChange={e => {setticketControlHours(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control_hours', e.target.value);}} /> */}

                                            <DateTimeRangePicker onChange={e => {setticketControlHours(e);EventService.updateEventMeta(eventID, '_service_ticket_control_hours', e);}} value={ticketControlHours} format={"dd-MM-y h:mm"} />
                                        </td>
                                        <td>
                                            <input type="number" value={ticketControlcost} className="form-control text-sm" onChange={e => {setticketControlcost(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={ticketControlproducerpay} className="form-control text-sm" onChange={e => {setticketControlproducerpay(e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={ticketControlDetails} onChange={e => {setticketControlDetails(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control_details', e.target.value);}} /></td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">VSP (on-site ticket selling)</td>
                                        <td>
                                            <select className="form-control form-control-select text-sm" value={vsp} onChange={e => {setvsp(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" value={vspPax} className="form-control text-sm" onChange={e => {setvspPax(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_pax', e.target.value);}} />
                                        </td>
                                        <td>
                                            {/* <input type="text" value={vspHours} className="form-control" onChange={e => {setvspHours(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_hours', e.target.value);}} /> */}

                                            <DateTimeRangePicker onChange={e => {setvspHours(e);EventService.updateEventMeta(eventID, '_service_vsp_hours', e);}} value={vspHours} format={"dd-MM-y h:mm"} />
                                        </td>
                                        <td>
                                            <input type="number" value={vspcost} className="form-control text-sm" onChange={e => {setvspcost(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={vspproducerpay} className="form-control text-sm" onChange={e => {setvspproducerpay(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_producerpay', e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={vspDetails} onChange={e => {setvspDetails(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_details', e.target.value);}} /></td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Hostesses</td>
                                        <td>
                                            <select className="form-control form-control-select text-sm" value={hostessess} onChange={e => {sethostessess(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" value={hostessessPax} className="form-control text-sm" onChange={e => {sethostessessPax(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_pax', e.target.value);}} />
                                        </td>
                                        <td>
                                            {/* <input type="text" value={hostessessHours} className="form-control" onChange={e => {sethostessessHours(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_hours', e.target.value);}} /> */}

                                            <DateTimeRangePicker onChange={e => {sethostessessHours(e);EventService.updateEventMeta(eventID, '_service_hostessess_hours', e);}} value={hostessessHours} format={"dd-MM-y h:mm"} />
                                        </td>
                                        <td>
                                            <input type="number" value={hostessesscost} className="form-control text-sm" onChange={e => {sethostessesscost(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={hostessessproducerpay} className="form-control text-sm" onChange={e => {sethostessessproducerpay(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_producerpay', e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={hostessessDetails} onChange={e => {sethostessessDetails(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_details', e.target.value);}} /></td>
                                    </tr>

                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Seat Labeling</td>
                                        <td>
                                            <select className="form-control form-control-select text-sm" value={seatlabelingservice} onChange={e => {setseatlabelingservice(e.target.value);EventService.updateEventMeta(eventID, '_service_seat_labeling', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td>
                                           -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <input type="number" value={seatlabelingservicecost} className="form-control text-sm" onChange={e => {setseatlabelingservicecost(e.target.value);EventService.updateEventMeta(eventID, '_service_seat_labeling_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={seatlabelingservicepayable} className="form-control text-sm" onChange={e => {setseatlabelingservicepayable(e.target.value);EventService.updateEventMeta(eventID, '_service_seat_labeling_producerpay', e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={seatlabelingservicecomments} onChange={e => {setseatlabelingservicecomments(e.target.value);EventService.updateEventMeta(eventID, '_service_seat_labeling_details', e.target.value);}} /></td>
                                    </tr>

                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Transportation</td>
                                        <td>
                                            <select className="form-control form-control-select text-sm" value={transportation} onChange={e => {settransportation(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td  className="text-center">
                                            <label className="text-sm textaeaeae">Vehicle Details</label>
                                            <input type="text" placeholder="Vehicle Details" value={transportationvehicle} className="form-control text-sm" onChange={e => {settransportationvehicle(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_vehicle', e.target.value);}} />
                                        </td>
                                        <td className="text-center">
                                            <label className="text-sm textaeaeae">Number of Vehicles</label>
                                            <input type="text" placeholder="Number of Vehicles" value={transportationNumber} className="form-control text-sm" onChange={e => {settransportationNumber(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_number', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={transportationcost} className="form-control text-sm" onChange={e => {settransportationcost(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={transportationproducerpay} className="form-control text-sm" onChange={e => {settransportationproducerpay(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_producerpay', e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={transportationDetails} onChange={e => {settransportationDetails(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_details', e.target.value);}} /></td>
                                    </tr>
                                    
                                     <tr>
                                        <td className="font-semibold text-sm text5e4642">Total</td>
                                        <td>
                                        </td>
                                        <td className="text-center">
                                           {(vspPax==''?0:parseInt(vspPax))+(hostessessPax==''?0:parseInt(hostessessPax))+(ticketControlPax==''?0:parseInt(ticketControlPax))}
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td className="text-center">
                                            Rs {(ticketControlcost==''?0:parseFloat(ticketControlcost))+(vspcost==''?0:parseFloat(vspcost))+(hostessesscost==''?0:parseFloat(hostessesscost))+(transportationcost==''?0:parseFloat(transportationcost))+(seatlabelingservicecost==''?0:parseFloat(seatlabelingservicecost))}
                                        </td>
                                        <td className="text-center">
                                            Rs {(ticketControlproducerpay==''?0:parseFloat(ticketControlproducerpay))+(vspproducerpay==''?0:parseFloat(vspproducerpay))+(hostessessproducerpay==''?0:parseFloat(hostessessproducerpay))+(transportationproducerpay==''?0:parseFloat(transportationproducerpay))+(seatlabelingservicepayable==''?0:parseFloat(seatlabelingservicepayable))}
                                        </td>
                                        <td>
                                            <button className="btn btn-warning btn-sm text-right float-right rounded-none" onClick={e => {e.preventDefault();saveEventServices();}}>Click Here to Save Otayo Services</button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="services-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th className="text-xs uppercase text-center" scope="col">Service Required</th>
                                        <th className="text-xs uppercase text-center" scope="col">Vehicle Details</th>
                                        <th className="text-xs uppercase text-center" scope="col">Number of Vehicles</th>
                                        <th className="text-xs uppercase text-center" scope="col">Total Cost</th>
                                        <th className="text-xs uppercase text-center" scope="col">Fee Payable by Producer</th>
                                        <th className="text-xs uppercase text-center" scope="col">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Transportation</td>
                                        <td>
                                            <select className="form-control form-control-select text-sm" value={transportation} onChange={e => {settransportation(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation', e.target.value);}}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" value={transportationvehicle} className="form-control text-sm" onChange={e => {settransportationvehicle(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_vehicle', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="text" value={transportationNumber} className="form-control text-sm" onChange={e => {settransportationNumber(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_number', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={transportationcost} className="form-control text-sm" onChange={e => {settransportationcost(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_cost', e.target.value);}} />
                                        </td>
                                        <td>
                                            <input type="number" value={transportationproducerpay} className="form-control text-sm" onChange={e => {settransportationproducerpay(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_producerpay', e.target.value);}} />
                                        </td>
                                        <td><textarea className="form-control text-sm" value={transportationDetails} onChange={e => {settransportationDetails(e.target.value);EventService.updateEventMeta(eventID, '_service_transportation_details', e.target.value);}} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                        <div className="fees-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-xs uppercase text-center" scope="col">Ticketing Fees</th>
                                        <th className="text-xs uppercase text-center" scope="col">Fees (VAT Excl.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Core Producer Fee:</td>
                                        <td>
                                            {/* <textarea className="form-control" placeholder="" value={coreProducerFee} onChange={e => {setCoreProducerFee(e.target.value);EventService.updateEventMeta(eventID, '_service_core_fee', e.target.value);}} /> */}
                                            <input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} className="form-control text-sm" placeholder="" value={coreProducerFee.replace("Rs", "")+"Rs"} onChange={e => {setCoreProducerFee(e.target.value);EventService.updateEventMeta(eventID, '_service_core_fee', e.target.value);}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Ticket Commission:</td>
                                        <td>
                                            {/* <textarea className="form-control" placeholder="" value={ticketCommision} onChange={e => {setticketCommision(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_commision', e.target.value);}} /> */}
                                            <input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key) && (event.key != ".")) {
                                                event.preventDefault();
                                                }
                                            }} className="form-control text-sm" placeholder="" value={ticketCommision.replace("%", "")+"%"} onChange={e => {setticketCommision(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_commision', e.target.value);}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">Invitation Fee:</td>
                                        <td>
                                            {/* <textarea className="form-control" placeholder="" value={invitationFee} onChange={e => {setinvitationFee(e.target.value);EventService.updateEventMeta(eventID, '_service_invitation_fee', e.target.value);}} /> */}
                                            <input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            className="form-control text-sm" placeholder="" value={invitationFee.replace("Rs", "")+"Rs"} onChange={e => {setinvitationFee(e.target.value);EventService.updateEventMeta(eventID, '_service_invitation_fee', e.target.value);}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-normal text-sm text5e4642">payabBank Fees le by:</td>
                                        <td>
                                            {/* <textarea className="form-control" placeholder="Producer / Customer" value={bankFeePayableBy} onChange={e => {setbankFeePayableBy(e.target.value);EventService.updateEventMeta(eventID, '_service_bank_fee_payable_by', e.target.value);}} /> */}
                                            <select className="form-control text-sm" value={bankFeePayableBy} onChange={e => {setbankFeePayableBy(e.target.value);EventService.updateEventMeta(eventID, '_service_bank_fee_payable_by', e.target.value);}}>
                                                <option value="">Select Payer</option>
                                                <option value="Producer">Producer</option>
                                                <option value="Customer">Customer</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="special-conditions w-70">
                        <div className="font-bold text-sm mb-3">Special Conditions, Comments and Information:</div>
                        <textarea className="form-control text-sm" rows="5" id="comment" placeholder="Special Conditions" value={specialComments} onChange={e => {setspecialComments(e.target.value);EventService.updateEventMeta(eventID, '_service_special_comments', e.target.value);}}></textarea>
                        <button className="btn btn-warning mt-3 btn-sm text-right float-right rounded-none" onClick={e => {e.preventDefault();saveEventServices();}}>Click Here to Save Otayo Services</button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="font-bold text-base text5c4742 mb-4">Branding Services</div>
                    <div className="marketingbranding flex justify-between items-center">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-xs uppercase" scope="col">List of Services</th>
                                    <th className="text-xs uppercase text-center" scope="col">Service Required</th>
                                    <th className="text-xs uppercase text-center" scope="col">Service Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="font-normal text-sm text5e4642">Marketing Packages</td>
                                    <td>
                                        <select className="form-control form-control-select text-sm" value={marketingPackages} onChange={e => {setmarketingPackages(e.target.value);EventService.updateEventMeta(eventID, '_branding_marketing_packages', e.target.value);}}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </td>
                                    <td><textarea className="form-control text-sm" value={marketingPackagesDetails} onChange={e => {setmarketingPackagesDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_marketing_packages_details', e.target.value);}} /></td>
                                </tr> */}
                                <tr>
                                    <td className="font-normal text-sm text5e4642">Branded Pochettes (Envelopes)</td>
                                    <td>
                                        <select className="form-control form-control-select text-sm" value={envelopes} onChange={e => {setenvelopes(e.target.value);EventService.updateEventMeta(eventID, '_branding_envelopes', e.target.value);}}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </td>
                                    <td><textarea className="form-control text-sm"  value={envelopesDetails} onChange={e => {setenvelopesDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_envelopes_details', e.target.value);}}/></td>
                                </tr>
                                <tr>
                                    <td className="font-normal text-sm text5e4642">Branded Event Tickets</td>
                                    <td>
                                        <select className="form-control form-control-select text-sm" value={brandedTickets} onChange={e => {setbrandedTickets(e.target.value);EventService.updateEventMeta(eventID, '_branding_branded_tickets', e.target.value);}}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </td>
                                    <td><textarea className="form-control text-sm" value={brandedTicketsDetails} onChange={e => {setbrandedTicketsDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_branded_tickets_details', e.target.value);}} /></td>
                                </tr>
                                {/* <tr>
                                    <td className="font-normal text-sm text5e4642">Seat Labeling</td>
                                    <td>
                                        <select className="form-control form-control-select text-sm" value={seatLabeling} onChange={e => {setseatLabeling(e.target.value);EventService.updateEventMeta(eventID, '_branding_seat_labeling', e.target.value);}}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </td>
                                    <td><textarea className="form-control text-sm" value={seatLabelingDetails} onChange={e => {setseatLabelingDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_seat_labeling_details', e.target.value);}} /></td>
                                </tr> */}
                            </tbody>
                        </table>

                        <div className="special-conditions-branding">
                            <div className="font-bold text-sm mb-3">Special Conditions, Comments and Information:</div>
                            <textarea className="form-control text-sm" rows="5" id="comment" placeholder="Special Conditions" value={marketingSpecialComments} onChange={e => {setmarketingSpecialComments(e.target.value);EventService.updateEventMeta(eventID, '_branding_marketing_special_comments', e.target.value);}}></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="flex justify-between items-cente">
                        <div className="font-bold text-base text5c4742 mb-4">Marketing Services</div>
                    </div>
                    <div className="event-deposit">
                        <div className="marketingbranding event-deposit-table mb-5">
                            <table className="table" style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm" scope="col">Service Category</th>
                                        <th className="font-bold text-sm" scope="col">Description</th>
                                        <th className="font-bold text-sm text-center" scope="col">Amount Payable (VAT Incl.)</th>
                                        <th className="font-bold text-sm text-center" scope="col">Amount Paid</th>
                                        <th className="font-bold text-sm text-center" scope="col">Pending Payment</th>
                                        <th className="font-bold text-sm text-center" scope="col">Payment Records</th>
                                        <th className="font-bold text-sm" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketingServicesMul!=null && marketingServicesMul.map((service, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm">{service.category}</td>
                                                <td className="text-sm">{service.description}</td>
                                                <td className="text-sm text-center">Rs {service.payable}</td>
                                                <td className="text-sm text-center text-blue-700">Rs {service.paid}</td>
                                                <td className="text-sm text-center text-red-600">Rs {service.pending}</td>
                                                <td className="text-sm text-center">
                                                    <button type="button" className="btn btn-sm btn-link" onClick={e => getPymentDetails(service.id, 'marketing')}>Payment Details</button>
                                                </td>
                                                <td className="text-sm">
                                                    <button className="btn btn-sm btn-warning bg-orange-600 text-white mr-3" onClick={(e) => editMarketingService(service.id)}>Edit</button>
                                                    {service.paid==0 && 
                                                        <button className="btn btn-sm btn-warning bg-red-600 text-white" onClick={(e) => deleteMarketingService(index, service.id)}>Delete</button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    } )}
                                    
                                    <tr>
                                        <td className="text-sm">
                                            <td className="text-sm"><input className="form-control text-sm bgf7f7f7" value={marketingServicesSingleCategory} onChange={e=> setmarketingServicesSingleCategory(e.target.value)} /></td>
                                        </td>
                                        <td className="text-sm bgf7f7f7"><input type="text" className="form-control text-sm" value={marketingServiceSingleDescription} onChange={e=> setmarketingServiceSingleDescription(e.target.value)} /></td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm" value={marketingServiceSinglePayable} onChange={e=> setmarketingServiceSinglePayable(e.target.value)} /></td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm" value={marketingServiceSinglePaid} onChange={e=> setmarketingServiceSinglePaid(e.target.value)} disabled={true} /></td>
                                        <td className="text-sm"><input className="form-control text-sm bgf7f7f7" value={marketingServiceSinglePending} disabled={true} /></td>
                                        <td className="text-sm"></td>
                                        <td className="text-sm"><button className="btn btn-sm bg-green-600 btn-success text-white text-sm" onClick={(e) => addmarketingService()}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="flex justify-between items-cente">
                        <div className="font-bold text-base text5c4742 mb-4">Producer Tickets (Invitations / Sponsor Tickets)</div>
                    </div>
                    <div className="event-deposit">
                        <div className="marketingbranding event-deposit-table mb-5">
                            <table className="table" style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm" scope="col">Date Processed</th>
                                        <th className="font-bold text-sm text-center" scope="col">Number of Tickets</th>
                                        <th className="font-bold text-sm text-center" scope="col">Price per Ticket (excl. VAT)</th>
                                        <th className="font-bold text-sm text-center" scope="col">Total Price (incl. VAT)</th>
                                        <th className="font-bold text-sm text-center" scope="col">Amount Paid</th>
                                        <th className="font-bold text-sm text-center" scope="col">Pending Payment</th>
                                        <th className="font-bold text-sm text-center" scope="col">Payment Records</th>
                                        <th className="font-bold text-sm" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {producerTickets!=null && producerTickets.map((tickets, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm">{tickets.dateprocessed}</td>
                                                <td className="text-sm text-center">{tickets.numberoftickets}</td>
                                                <td className="text-sm text-center">Rs {tickets.priceperticket}</td>
                                                <td className="text-sm text-center">Rs {tickets.totalprice}</td>
                                                <td className="text-sm text-blue-700 text-center">Rs {tickets.paid}</td>
                                                <td className="text-sm text-red-600 text-center">Rs {tickets.pending}</td>
                                                <td className="text-sm text-center"><button type="button" className="btn btn-sm btn-link" onClick={e => getPymentDetails(tickets.id, 'tickets')}>Payment Details</button></td>
                                                <td className="text-sm">
                                                    <button className="btn btn-sm btn-warning bg-orange-600 text-white mr-3" onClick={(e) => editProducerTickets(tickets.id)}>Edit</button>
                                                    {tickets.paid==0 && 
                                                        <button className="btn btn-sm btn-warning bg-red-600 text-white" onClick={(e) => deleteProducerTickets(index, tickets.id)}>Delete</button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    } )}
                                    
                                    <tr>
                                        <td className="text-sm">
                                            <td className="text-sm"> <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={producerTicketsSingleDateProcessed}
                                                onChange={date => setproducerTicketsSingleDateProcessed(date)}
                                                placeholderText="Date"
                                                className='form-control text-sm bgf7f7f7'
                                            /></td>
                                        </td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm" value={producerTicketsSingleNoOfTickets} onChange={e=> setproducerTicketsSingleNoOfTickets(e.target.value)} /></td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm" value={producerTicketsSinglePriceEx} onChange={e=> setproducerTicketsSinglePriceEx(e.target.value)} /></td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm"  disabled={true} /></td>
                                        <td className="text-sm"><input type="number" className="form-control text-sm"  disabled={true} /></td>
                                        <td className="text-sm"><input type="number" className="form-control text-sm"  disabled={true} /></td>
                                        <td className="text-sm"></td>
                                        <td className="text-sm"><button className="btn btn-sm bg-green-600 btn-success text-white text-sm" onClick={(e) => addProducerTickets()}>Add</button></td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td className=" text-sm text-center"><b>{alltotaltickets}</b></td>
                                        <td></td>
                                        <td className=" text-sm text-center"><b>Rs {alltotalPrice}</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row mb-10">
                <div className="col-md-12">
                    <div className="font-bold text-base text5c4742 mb-4">Event Producer Notifications</div>
                    <div className="notifications flex justify-start items-center">
                        <div className="notifications-item flex items-center">
                            <div className="notifications-item-heading font-normal text-sm text5e4642">Send Producer Daily Sales Report</div>
                            <div className="notifications-item-content ml-10">
                                <select className="form-control form-control-select w-20 text-sm"  value={producerNotification} onChange={e => {setproducerNotification(e.target.value);EventService.updateEventMeta(eventID, '_producer_notification', e.target.value);}}>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="notifications-item flex items-center ml-20">
                            <div className="notifications-item-heading font-normal text-sm text5e4642">Daily Sales Report Sent to :</div>
                            <div className="notifications-item-content flex items-center ml-10">
                                <input type="text" disabled={true} value={event!=null && event.producer_email} className="form-control notif-input text-sm" placeholder="Email Address" />
                                <div class="add-more flex items-center ml-4">
                                    <input type={"email"} value={additionalEmail} onChange={e => setAdditionalEmail(e.target.value)} className="form-control text-sm"></input><button className="addmore-btn ml-3 text-sm" disabled={additionalEmail==null} onClick={e => addAdditionalEmail}>[+] Add Additional Email</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="row">
                <div className="col-md-12">
                    <hr className="text-gray-500 mb-10" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="flex justify-between items-cente">
                        <div className="font-bold text-base text5c4742 mb-4">Financial Summary</div>
                        <FinancialLogs eventid={eventID} />
                    </div>
                    <div className="event-deposit">
                       <div className="event-deposit-heading p-2 font-bold text-center text-sm bg-green-300 w-100" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}}>Event Deposit</div>
                        <div className="event-deposit-table mb-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm" scope="col">Date Processed</th>
                                        <th className="font-bold text-sm" scope="col">Deposit Amount</th>
                                        <th className="font-bold text-sm" scope="col">Method of Payment</th>
                                        <th className="font-bold text-sm" scope="col">Payment Reference</th>
                                        <th className="font-bold text-sm" scope="col">Additional info</th>
                                        <th className="font-bold text-sm" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allEventDeposits!=null && allEventDeposits.map((deposit, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm">{deposit.date}</td>
                                                <td className="text-sm">{deposit.amount}</td>
                                                <td className="text-sm">{deposit.payment_method}</td>
                                                <td className="text-sm">{deposit.reference}</td>
                                                <td className="text-sm">{deposit.additional_info}</td>
                                                <td className="text-sm"><button className="btn btn-sm btn-warning bg-red-600 text-white" onClick={(e) => deleteTableEntry("eventDeposit", deposit.id)}>Delete</button></td>
                                            </tr>
                                        )
                                    } )}
                                    
                                    <tr>
                                        <td className="text-sm">
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={eventDeposit.date}
                                                onChange={date => setSingleTableInput('eventDeposit', 'date', date)}
                                                placeholderText="Date"
                                                className='form-control text-sm bgf7f7f7'
                                            />
                                        </td>
                                        <td className="text-sm bgf7f7f7"><input type="number" className="form-control text-sm" value={eventDeposit.amount} onChange={e=> setSingleTableInput('eventDeposit', 'amount', e.target.value)} /></td>
                                        <td className="text-sm">
                                            <select className="form-control form-control-select text-sm bgf7f7f7" value={eventDeposit.payment_method} onChange={e=> setSingleTableInput('eventDeposit', 'payment_method', e.target.value)}>
                                                <option value="None">None</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Bank Transfer">Bank Transfer</option>
                                                <option value="Cheque">Cheque</option>
                                            </select>
                                        </td>
                                        <td className="text-sm"><input className="form-control text-sm bgf7f7f7" value={eventDeposit.reference} onChange={e=> setSingleTableInput('eventDeposit', 'reference', e.target.value)} /></td>
                                        <td className="text-sm"><input className="form-control text-sm bgf7f7f7" value={eventDeposit.additional_info} onChange={e=> setSingleTableInput('eventDeposit', 'additional_info', e.target.value)} /></td>
                                        <td className="text-sm"><button className="btn btn-sm bg-green-600 btn-success text-white text-sm" onClick={(e) => addtableEntry('eventDeposit')}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="prod-payment">
                       <div className="prod-payment-heading p-2 font-bold text-center text-sm bg-blue-300 w-100" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}}>Advance Producer Payment</div>
                        <div className="prod-payment-table mb-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm" scope="col">Date Processed</th>
                                        <th className="font-bold text-sm" scope="col">Payment Amount</th>
                                        <th className="font-bold text-sm" scope="col">Method of Payment</th>
                                        <th className="font-bold text-sm" scope="col">Payment Reference</th>
                                        <th className="font-bold text-sm" scope="col">Additional info</th>
                                        <th className="font-bold text-sm" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allAdvanceProdPayments!=null && allAdvanceProdPayments.map((deposit, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm">{deposit.date}</td>
                                                <td className="text-sm">{deposit.amount}</td>
                                                <td className="text-sm">{deposit.payment_method}</td>
                                                <td className="text-sm">{deposit.reference}</td>
                                                <td className="text-sm">{deposit.additional_info}</td>
                                                <td className="text-sm"><button className="btn btn-sm btn-warning bg-orange-600 text-white text-sm"  onClick={(e) => deleteTableEntry("advanceProdPayment", deposit.id)}>Delete</button></td>
                                            </tr>
                                        )
                                    } )}
                                    
                                    <tr>
                                        <td>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={advanceProdPayment.date}
                                                onChange={date => setSingleTableInput('advanceProdPayment', 'date', date)}
                                                placeholderText="Date"
                                                className='form-control text-sm bgf7f7f7'
                                            />
                                        </td>
                                        <td><input type="number" className="form-control text-sm bgf7f7f7" value={advanceProdPayment.amount} onChange={e=> setSingleTableInput('advanceProdPayment', 'amount', e.target.value)} /></td>
                                        <td>
                                            <select className="form-control form-control-select text-sm bgf7f7f7" value={advanceProdPayment.payment_method} onChange={e=> setSingleTableInput('advanceProdPayment', 'payment_method', e.target.value)}>
                                                <option value="None">None</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Bank Transfer">Bank Transfer</option>
                                                <option value="Cheque">Cheque</option>
                                            </select>
                                        </td>
                                        <td><input className="form-control text-sm bgf7f7f7" value={advanceProdPayment.reference} onChange={e=> setSingleTableInput('advanceProdPayment', 'reference', e.target.value)} /></td>
                                        <td><input className="form-control text-sm bgf7f7f7" value={advanceProdPayment.additional_info} onChange={e=> setSingleTableInput('advanceProdPayment', 'additional_info', e.target.value)} /></td>
                                        <td><button className="btn btn-sm bg-green-600 btn-success text-white text-sm" onClick={(e) => addtableEntry('advanceProdPayment')}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="payment-to-prod">
                       <div className="payment-to-prod-heading p-2 font-bold text-sm text-center bg-pink-300 w-100" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}}>Payment to Producer</div>
                        <div className="payment-to-prod-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm" scope="col">Date Processed</th>
                                        <th className="font-bold text-sm" scope="col">Payment Amount</th>
                                        <th className="font-bold text-sm" scope="col">Method of Payment</th>
                                        <th className="font-bold text-sm" scope="col">Payment Reference</th>
                                        <th className="font-bold text-sm" scope="col">Status</th>
                                        <th className="font-bold text-sm" scope="col">Additional info</th>
                                        <th className="font-bold text-sm" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPaymentToProducer!=null && allPaymentToProducer.map((deposit, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm">{deposit.date}</td>
                                                <td className="text-sm">{deposit.amount}</td>
                                                <td className="text-sm">{deposit.payment_method}</td>
                                                <td className="text-sm">{deposit.reference}</td>
                                                <td className="text-sm">{deposit.status}</td>
                                                <td className="text-sm">{deposit.additional_info}</td>
                                                <td className="text-sm"><button className="btn btn-sm btn-warning bg-red-600 text-white text-sm" onClick={(e) => deleteTableEntry("paymentToProducer", deposit.id)}>Delete</button></td>
                                            </tr>
                                        )
                                    } )}
                                    
                                    <tr>
                                        <td>
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={paymentToProducer.date}
                                                onChange={date => setSingleTableInput('paymentToProducer', 'date', date)}
                                                placeholderText="Date"
                                                className='form-control text-sm bgf7f7f7'
                                            />
                                        </td>
                                        <td><input type="number" className="form-control bgf7f7f7" value={paymentToProducer.amount} onChange={e=> setSingleTableInput('paymentToProducer', 'amount', e.target.value)} /></td>
                                        <td>
                                            <select className="form-control form-control-select text-sm bgf7f7f7" value={paymentToProducer.payment_method} onChange={e=> setSingleTableInput('paymentToProducer', 'payment_method', e.target.value)}>
                                                <option value="None">None</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Bank Transfer">Bank Transfer</option>
                                                <option value="Cheque">Cheque</option>
                                            </select>
                                        </td>
                                        <td><input className="form-control text-sm bgf7f7f7" value={paymentToProducer.reference} onChange={e=> setSingleTableInput('paymentToProducer', 'reference', e.target.value)} /></td>
                                        <td>
                                            <select className="form-control form-control-select text-sm bgf7f7f7" value={paymentToProducer.status} onChange={e=> setSingleTableInput('paymentToProducer', 'status', e.target.value)}>
                                                <option value="unpaid">Unpaid</option>
                                                <option value="Paid">Paid</option>
                                            </select>
                                        </td>
                                        <td><input className="form-control text-sm bgf7f7f7" value={paymentToProducer.additional_info} onChange={e=> setSingleTableInput('paymentToProducer', 'additional_info', e.target.value)} /></td>
                                        <td><button className="btn btn-sm bg-green-600 btn-success text-white text-sm" onClick={(e) => addtableEntry('paymentToProducer')}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="payment-to-prod">
                        <div className="payment-to-prod-heading flex justify-between items-center p-2 mt-10 font-bold text-sm text-center bg-pink-300 w-100" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}}><span className="w-4/5">Event Related Files</span><button type="button" class="btn btn-success btn-sm bg-green-600 btn-success text-white text-sm" onClick={e => setisOpenRelatedFileModal(true)}>Upload File</button></div>
                        <div className="payment-to-prod-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="font-bold text-sm text-center" scope="col">ID</th>
                                        <th className="font-bold text-sm text-center" scope="col">Date Processed</th>
                                        <th className="font-bold text-sm text-center" scope="col">Description</th>
                                        <th className="font-bold text-sm text-center" scope="col">Preview</th>
                                        <th className="font-bold text-sm text-center" scope="col">File Download</th>
                                        <th className="font-bold text-sm text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {relatedEventFiles!=null && relatedEventFiles.length>0 && relatedEventFiles.map((file, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-sm text-center" style={{textAlign: "center", verticalAlign: "middle"}}>{file.id}</td>
                                                <td className="text-sm" style={{textAlign: "center", verticalAlign: "middle"}}>{file.dateadded}</td>
                                                <td className="text-sm" style={{textAlign: "center", verticalAlign: "middle"}}>{file.description}</td>
                                                <td className="text-sm" style={{textAlign: "center", verticalAlign: "middle"}}>
                                                    {isImage(file.filepath) && 
                                                       <a href={file.filepath} target="_blank"><img style={{width: "100px", margin: "0 auto"}} src={file.filepath} /></a>
                                                    }
                                                    {!isImage(file.filepath) && 
                                                        "File: "+fileName(file.filepath)
                                                    }
                                                </td>
                                                <td className="text-sm" style={{textAlign: "center", verticalAlign: "middle"}}><a target="_blank" className="text-blue-500 underline" href={file.filepath}>Download File <FontAwesomeIcon icon={faDownload} /> </a></td>
                                                <td className="text-sm" style={{textAlign: "center", verticalAlign: "middle"}}><button className="btn btn-sm btn-warning bg-red-600 text-white text-sm" onClick={(e) => deleteRelatedFile(file.id)}>Delete</button></td>
                                            </tr>
                                        )
                                    } )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="row mt-5 mb-5">
                <div className="col-md-12 flex justify-end items-start">
                    <div className="mr-4 font-bold">Otayo Bilan</div>
                    <div className="flex flex-col items-center justify-center">
                        <a href={invoice} target="_blank" className="view-invoice cursor-pointer text-white">View Bilan</a>
                        <div className="flex justify-around mt-2 w-100">
                            <button className="upload-invoice font-bold cursor-pointer" onClick={(e) => setShowInvoiceUpload(!showInvoiceUpload)}>Upload</button>
                            <span className="text-gray-400">|</span>
                            <button className="delete-invoice font-bold cursor-pointer" onClick={(e) => deleteFile('invoice')}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {showInvoiceUpload && 
                <FileUploader event={eventID} metakey="_invoice_file" onChange={(url) => setInvoice(url)} />
            }

        </div>


        {/* Add confirmation form */}
            <Modal
                open={isOpenPayment}
                onClose={e => setisOpenPayment(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addpaymentMarkTicks(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add Payment Details
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Amount Paid</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" required="required" value={paymentAmount} onChange={e => {setpaymentAmount(e.target.value);}} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Payment Method</b></label>
                        <div className="col-8"> 
                            <select className="form-control form-control-select" required="required" vlaue={paymentMethod} onChange={e => setpaymentMethod(e.target.value)}>
                                <option vlaue="">Select a Method</option>
                                <option vlaue="Cash">Cash</option>
                                <option vlaue="Bank Transfer">Bank Transfer</option>
                                <option vlaue="Cheque">Cheque</option>
                                <option vlaue="MCB Juice">MCB Juice</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Payment Reference</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" required="required" value={paymentReference} onChange={e => {setpaymentReference(e.target.value);}} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Payment Date</b></label>
                        <div className="col-8"> 
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={paymentDate}
                                onChange={date => setpaymentDate(date)}
                                placeholderText="Payment Date"
                                className='form-control rounded-none'
                                maxDate={new Date()}
                                required={true}
                            />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Submit</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setisOpenPayment(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

            <Modal
                open={isOpenPaymentDetails}
                onClose={e => setisOpenPaymentDetails(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Payment Details
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Amount Paid</th>
                                        <th>Payment Method</th>
                                        <th>Payment Reference</th>
                                        <th>Payment Date</th>
                                        {user!=null && (user.user_nicename=='hassnain' || user.user_nicename=='uri') &&
                                            <th>Actions</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {servicespaymentdetails.length>0 && servicespaymentdetails.map((item, index) => (
                                        <tr key={index}>
                                            <td>Rs {item.amountpaid}</td>
                                            <td>{item.methodpayment}</td>
                                            <td>{item.reference}</td>
                                            <td>{item.paymentdate}</td>
                                            {user!=null && (user.user_nicename=='hassnain' || user.user_nicename=='uri') &&
                                                <td>
                                                    <button className="btn btn-sm btn-warning bg-red-600 text-white" onClick={(e) => deletePaymentDetails(item.id)}>Delete</button>
                                                </td>
                                            }   
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setisOpenPaymentDetails(false)}>Cancel</button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={isOpenRelatedFileModal}
                onClose={e => setisOpenRelatedFileModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Upload a File
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <textarea className="form-control mb-2" rows="4" placeholder="Enter file description if any..." value={relatedFileDesc} onChange={e => setRelatedFileDesc(e.target.value)}></textarea>  
                            <FileUploader event={eventID} metakey="_related_file_temp" onChange={(url) => {setrealtedFileUploadedURL(url);}} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setisOpenRelatedFileModal(false)}>Cancel</button>
                    </div>
                </Box>
            </Modal>
        </div>

        <div className="d-lg-none" style={{ padding: "0px 10px" }}>
            <div className="d-flex justify-content-between align-items-center border-b fixed top-0 left-0 z-20 w-100 bg-white px-3 py-3">
              <div onClick={() => window.history.back()}>
                <ArrowBackOutlinedIcon />
              </div>

              <p className="font-semibold line-clamp-1 text-sm mt-1">
                {event != null && event.name}
              </p>

              <div></div>
            </div>

            <EventSingleSummery event={event} contract={contract} />

            <div className="d-flex justify-content-between align-items-center py-3" style={{padding : '0px 10px'}}>
              <p
                className=""
                style={{
                  fontWeight: "700",
                  color: "#aaaaaa",
                }}
              >
                Ticket Sales
              </p>

              {tickets ? <></> : <Spinner />}
            </div>

            {tickets && <EventTicketSales tickets={tickets} />}

            <div className="d-flex justify-content-between align-items-center py-3" style={{padding : '0px 10px'}}>
              <p
                className=""
                style={{
                  fontWeight: "700",
                  color: "#aaaaaa",
                }}
              >
                Ticket Details
              </p>
              {tickets ? <p onClick={()=> setMoreTicketDetails(!MoreTicketDetails)} className="text-sm text-orange-500">{!MoreTicketDetails ? <span>View More<KeyboardArrowDownIcon style={{width : 20 , height : 20}}/></span> : ''}</p> : <Spinner />}
            </div>

            <div className="" style={{ display: MoreTicketDetails ? 'block' : 'none' }}>
            
            {tickets && (
              <div className=" flex overflow-scroll gap-3 ">
                {tickets != null &&
                  tickets.tickets.map((ticket, index) => (
                    <EventTicketSales ticket={ticket} />
                  ))}
              </div>
            )}

            {inviteTickets != null && (
              <div className="mt-3 border rounded-sm shadow-sm">
                <p className="font-semibold px-3 py-2 text-orange-500">Invitations</p>
                <Divider />

                {inviteTickets != null &&
                  inviteTickets.tickets.map((ticket, index) => (
                    <>
                      <div
                        className="px-3 py-1 text-sm d-flex gap-1 justify-content-between"
                        style={{ backgroundColor: index === 1 ? "#F7F7F7" : "" }}
                      >
                        <p className="text-secondary">{ticket.ticketname}</p>
                        <p className="font-medium">{ticket.totalsold}</p>
                      </div>
                      <Divider />
                    </>
                  ))}
              </div>
            )}

            <div className="mt-3 border rounded-sm shadow-sm">
              <p className="font-semibold px-3 py-2 text-orange-500 ">Extra fees</p>
              <Divider />

              <div className="px-3 py-1 text-sm d-flex gap-1 justify-content-between ">
                <p className="text-secondary">Total Ticket Fees</p>
                <p className="font-medium">
                  Rs{" "}
                  {parseFloat(classifiedCouponsFees?.ticketfee.amount)
                    .toFixed(2)
                    .toLocaleString()}
                </p>
              </div>
              <Divider />

              <div
                className="px-3 py-1 text-sm d-flex gap-1 justify-content-between "
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <p className="text-secondary">Total Payment Gateway Fees</p>
                <p className="font-medium">
                  Rs{" "}
                  {parseFloat(classifiedCouponsFees?.gatewayfee.amount)
                    .toFixed(2)
                    .toLocaleString()}
                </p>
              </div>
            </div>

            <div className="mt-3 border rounded-sm shadow-sm">
              <p className="font-semibold px-3 py-2 text-orange-500">Discount</p>
              <Divider />

              <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                <p className="text-secondary">Coupon Discounts</p>
                <p className="font-medium">
                  Rs{" "}
                  {parseFloat(classifiedCouponsFees?.cart.amount)
                    .toFixed(2)
                    .toLocaleString()}
                </p>
              </div>
              <Divider />

              <div
                className="text-sm d-flex gap-1 justify-content-between px-3 py-1"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <p className="text-secondary">Points Discount</p>
                <p className="font-medium">
                  Rs{" "}
                  {parseFloat(classifiedCouponsFees?.points.amount)
                    .toFixed(2)
                    .toLocaleString()}
                </p>
              </div>
              <Divider />

              <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                <p className="text-secondary">Other Discount</p>
                <p className="font-medium">
                  Rs{" "}
                  {parseFloat(classifiedCouponsFees?.other.amount)
                    .toFixed(2)
                    .toLocaleString()}
                </p>
              </div>
              <Divider />

              <div
                className="text-sm d-flex gap-1 justify-content-between px-3 py-1"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <p className="text-secondary">Coupon used</p>
                <button onClick={handleDrawer}>
                  <MoreHorizIcon style={{ color: "#ea580c" }} />
                </button>
              </div>
            </div>

            <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
              <div style={{ padding: "12px", maxHeight: "70vh" }}>
                <p className="px-3 py-2 text-orange-500 font-medium">Coupon Used :</p>

                <Divider />

                {coupons.length != null &&
                  coupons.map((item, index) => (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-between px-3 py-1"
                        style={{ backgroundColor: index % 2 !== 0 ? "#F7F7F7" : "" }}
                      >
                        <p className="text-xs font-semibold">{item.type}</p>
                        <p className="text-xs">{item.name}</p>
                        <p className="text-xs">{item.count}</p>
                      </div>
                      <Divider />
                    </>
                  ))}
              </div>
            </Drawer>

            </div>

            <Divider/>

            <div className="d-flex justify-content-between align-items-center py-3" style={{padding : '0px 10px'}}>
              <p
                className=""
                style={{
                  fontWeight: "700",

                  color: "#aaaaaa",
                }}
              >
                Sales Report
              </p>

              <p onClick={()=> setMoreSalesReport(!MoreSalesReport)} className="text-sm text-orange-500">{!MoreSalesReport ? <span>View More<KeyboardArrowDownIcon style={{width : 20 , height : 20}}/></span> : ''}</p> 

            </div>

            <div className="" style={{ display: MoreSalesReport ? 'block' : 'none' }}>

            {tickets != null && typeof tickets.totalsales != "undefined" && (
              <div className="mt-1 border rounded-sm shadow-sm">
                <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                  <p className="text-secondary">Total Ticket Sold</p>
                  <p className="font-medium">{tickets.totalsales.totalSold}</p>
                </div>
                <Divider />

                <div
                  className="text-sm d-flex gap-1 justify-content-between px-3 py-1"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <p className="text-secondary">Total Sales Amount</p>
                  <p className="font-medium">Rs {tickets.totalsales.totalsales}</p>
                </div>
                <Divider />

                <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                  <p className="text-secondary">Total Order</p>
                  <p className="font-medium">{tickets.totalsales.totalOrders}</p>
                </div>
                <Divider />

                <div
                  className="text-sm d-flex gap-1 justify-content-between px-3 py-1"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <p className="text-secondary">AVERAGE ORDER AMOUNT</p>
                  <p className="font-medium">RS {tickets.totalsales.avgorderamount}</p>
                </div>
                <Divider />

                <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                  <p className="text-secondary">AVERAGE NO. TICKETS PER ORDER</p>
                  <p className="font-medium">{tickets.totalsales.avgticketquantity}</p>
                </div>
                <Divider />

                <div
                  className="text-sm d-flex gap-1 justify-content-between px-3 py-1"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <p className="text-secondary">PAST 24 HOURS (SALES / TICKETS)</p>
                  <p className="font-medium text-right text-nowrap">
                    {tickets.totalsales.onetotalsales} / {tickets.totalsales.onetotalSold}
                  </p>
                </div>
                <Divider />

                <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
                  <p className="text-secondary">PAST 7 DAYS (SALES / TICKETS)</p>
                  <p className="font-medium text-right text-nowrap">
                    {tickets.totalsales.seventotalsales} /{" "}
                    {tickets.totalsales.seventotalSold}
                  </p>
                </div>
              </div>
            )}

            <div className="py-3">
              <Pie data={dataSalesPerCategory} />
            </div>

            <div className="py-3">
              <Pie data={dataTicketSales} />
            </div>

            <div className="py-3">
              <Pie data={dataTicketSales7Days} />
            </div>

            <div className="py-3">
              <Pie data={dataPos} />
            </div>

            <div className="py-3">
              <Bar data={dataSalesPerGateway} options={optionsBArChart} />
            </div>

            <div className="py-3">
              <Bar data={dataSalesPerRegion} options={optionsBArChart} />
            </div>

            </div>

            <Divider/>

            <div class="otayo-Service">
              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Otayo Services
              </p>
              <div className="overflow-scroll pb-2">
                <table className="table table table-striped">
                  <thead className="border-t">
                    <tr>
                      <th
                        className="text-xs text-secondary uppercase text-nowrap"
                        scope="col"
                      >
                        List of Services
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap"
                        scope="col"
                      >
                        Service Required
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap"
                        scope="col"
                      >
                        Number of Pax
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap"
                        scope="col"
                      >
                        Working Hours
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap"
                        scope="col"
                      >
                        Total Cost
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap"
                        scope="col"
                      >
                        Fee Payable by Producer
                      </th>
                      <th
                        className="text-xs text-secondary uppercase text-center text-nowrap px-5"
                        scope="col"
                      >
                        Comment
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    <tr className="">
                      <td className="font-normal text-xs text5e4642 text-nowrap">
                        Collaboration Level
                      </td>
                      <td>
                        <select
                          value={collabLevel}
                          className="form-control form-control-select text-xs "
                          onChange={(e) => {
                            setcollabLevel(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "collabType",
                              e.target.value
                            );
                          }}
                        >
                          <option value="exclusive">Exclusive</option>
                          <option value="normal">Non Exclusive</option>
                        </select>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={collabLevelDetails}
                          onChange={(e) => {
                            setcollabLevelDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_collaboration_level_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-normal text-xs text5e4642 ">Ticket Control</td>
                      <td>
                        <select
                          value={ticketControl}
                          className="form-control form-control-select text-xs"
                          onChange={(e) => {
                            setticketControl(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_ticket_control",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={ticketControlPax}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setticketControlPax(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_ticket_control_pax",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        {/* <input type="text" value={ticketControlHours} className="form-control" onChange={e => {setticketControlHours(e.target.value);EventService.updateEventMeta(eventID, '_service_ticket_control_hours', e.target.value);}} /> */}

                        <DateTimeRangePicker
                          onChange={(e) => {
                            setticketControlHours(e);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_ticket_control_hours",
                              e
                            );
                          }}
                          value={ticketControlHours}
                          format={"dd-MM-y h:mm"}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={ticketControlcost}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setticketControlcost(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_ticket_control_cost",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={ticketControlproducerpay}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setticketControlproducerpay(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={ticketControlDetails}
                          onChange={(e) => {
                            setticketControlDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_ticket_control_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-normal text-xs text5e4642">
                        VSP (on-site ticket selling)
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={vsp}
                          onChange={(e) => {
                            setvsp(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={vspPax}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setvspPax(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp_pax",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        {/* <input type="text" value={vspHours} className="form-control" onChange={e => {setvspHours(e.target.value);EventService.updateEventMeta(eventID, '_service_vsp_hours', e.target.value);}} /> */}

                        <DateTimeRangePicker
                          onChange={(e) => {
                            setvspHours(e);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp_hours",
                              e
                            );
                          }}
                          value={vspHours}
                          format={"dd-MM-y h:mm"}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={vspcost}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setvspcost(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp_cost",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={vspproducerpay}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setvspproducerpay(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp_producerpay",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={vspDetails}
                          onChange={(e) => {
                            setvspDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_vsp_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-normal text-xs text5e4642">Hostesses</td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={hostessess}
                          onChange={(e) => {
                            sethostessess(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={hostessessPax}
                          className="form-control text-xs"
                          onChange={(e) => {
                            sethostessessPax(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess_pax",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        {/* <input type="text" value={hostessessHours} className="form-control" onChange={e => {sethostessessHours(e.target.value);EventService.updateEventMeta(eventID, '_service_hostessess_hours', e.target.value);}} /> */}

                        <DateTimeRangePicker
                          onChange={(e) => {
                            sethostessessHours(e);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess_hours",
                              e
                            );
                          }}
                          value={hostessessHours}
                          format={"dd-MM-y h:mm"}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={hostessesscost}
                          className="form-control text-xs"
                          onChange={(e) => {
                            sethostessesscost(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess_cost",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={hostessessproducerpay}
                          className="form-control text-xs"
                          onChange={(e) => {
                            sethostessessproducerpay(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess_producerpay",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={hostessessDetails}
                          onChange={(e) => {
                            sethostessessDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_hostessess_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="font-normal text-xs text5e4642">Seat Labeling</td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={seatlabelingservice}
                          onChange={(e) => {
                            setseatlabelingservice(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_seat_labeling",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <input
                          type="number"
                          value={seatlabelingservicecost}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setseatlabelingservicecost(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_seat_labeling_cost",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={seatlabelingservicepayable}
                          className="form-control text-xs"
                          onChange={(e) => {
                            setseatlabelingservicepayable(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_seat_labeling_producerpay",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={seatlabelingservicecomments}
                          onChange={(e) => {
                            setseatlabelingservicecomments(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_seat_labeling_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="font-normal text-xs text5e4642">Transportation</td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={transportation}
                          onChange={(e) => {
                            settransportation(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td className="text-center">
                        {/* <label className="text-xs textaeaeae">Vehicle Details</label> */}
                        <input
                          type="text"
                          placeholder="Vehicle Details"
                          value={transportationvehicle}
                          className="form-control text-sm"
                          onChange={(e) => {
                            settransportationvehicle(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation_vehicle",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td className="text-center">
                        {/* <label className="text-xs textaeaeae">Number of Vehicles</label> */}
                        <input
                          type="text"
                          placeholder="Number of Vehicles"
                          value={transportationNumber}
                          className="form-control text-sm"
                          onChange={(e) => {
                            settransportationNumber(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation_number",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={transportationcost}
                          className="form-control text-xs"
                          onChange={(e) => {
                            settransportationcost(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation_cost",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={transportationproducerpay}
                          className="form-control text-xs"
                          onChange={(e) => {
                            settransportationproducerpay(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation_producerpay",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={transportationDetails}
                          onChange={(e) => {
                            settransportationDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_service_transportation_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="font-semibold text-sm text5e4642">Total</td>
                      <td></td>
                      <td className="text-center">
                        {(vspPax == "" ? 0 : parseInt(vspPax)) +
                          (hostessessPax == "" ? 0 : parseInt(hostessessPax)) +
                          (ticketControlPax == "" ? 0 : parseInt(ticketControlPax))}
                      </td>
                      <td></td>
                      <td className="text-center">
                        Rs{" "}
                        {(ticketControlcost == "" ? 0 : parseFloat(ticketControlcost)) +
                          (vspcost == "" ? 0 : parseFloat(vspcost)) +
                          (hostessesscost == "" ? 0 : parseFloat(hostessesscost)) +
                          (transportationcost == ""
                            ? 0
                            : parseFloat(transportationcost)) +
                          (seatlabelingservicecost == ""
                            ? 0
                            : parseFloat(seatlabelingservicecost))}
                      </td>
                      <td className="text-center">
                        Rs{" "}
                        {(ticketControlproducerpay == ""
                          ? 0
                          : parseFloat(ticketControlproducerpay)) +
                          (vspproducerpay == "" ? 0 : parseFloat(vspproducerpay)) +
                          (hostessessproducerpay == ""
                            ? 0
                            : parseFloat(hostessessproducerpay)) +
                          (transportationproducerpay == ""
                            ? 0
                            : parseFloat(transportationproducerpay)) +
                          (seatlabelingservicepayable == ""
                            ? 0
                            : parseFloat(seatlabelingservicepayable))}
                      </td>
                      <td>
                        {/* <button
                          className="btn btn-warning btn-sm text-right float-right rounded-none text-nowrap"
                          onClick={(e) => {
                            e.preventDefault();
                            saveEventServices();
                          }}
                        >
                          Click Here to Save Otayo Services
                        </button> */}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button
                 className="btn btn-warning btn-sm text-right float-left rounded-none text-nowrap"
                 onClick={(e) => {
                 e.preventDefault();
                 saveEventServices();
                 }}
                 >
                 Click Here to Save Otayo Services
                </button>
              </div>

              <div className="mt-1 border rounded-sm shadow-sm">
                <p className="font-semibold px-3 py-2 text-orange-500">Ticketing Fees</p>
                <Divider />

                <div className="px-3 py-2 text-sm d-flex flex-col gap-1 justify-content-between">
                  <p className="text-secondary">Total Ticket Fees :</p>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className="form-control text-sm "
                    placeholder=""
                    value={coreProducerFee.replace("Rs", "") + "Rs"}
                    onChange={(e) => {
                      setCoreProducerFee(e.target.value);
                      EventService.updateEventMeta(
                        eventID,
                        "_service_core_fee",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <Divider />

                <div
                  className="px-3 py-2 text-sm d-flex flex-col gap-1 justify-content-between "
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <p className="text-secondary">Ticket Commission :</p>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key) && event.key != ".") {
                        event.preventDefault();
                      }
                    }}
                    className="form-control text-sm"
                    placeholder=""
                    value={ticketCommision.replace("%", "") + "%"}
                    onChange={(e) => {
                      setticketCommision(e.target.value);
                      EventService.updateEventMeta(
                        eventID,
                        "_service_ticket_commision",
                        e.target.value
                      );
                    }}
                  />
                </div>

                <Divider />

                <div className="px-3 py-2 text-sm d-flex flex-col gap-1 justify-content-between">
                  <p className="text-secondary">Invitation :</p>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className="form-control text-sm"
                    placeholder=""
                    value={invitationFee.replace("Rs", "") + "Rs"}
                    onChange={(e) => {
                      setinvitationFee(e.target.value);
                      EventService.updateEventMeta(
                        eventID,
                        "_service_invitation_fee",
                        e.target.value
                      );
                    }}
                  />
                </div>

                <Divider />

                <div
                  className="px-3 py-2 text-sm d-flex flex-col gap-1 justify-content-between"
                  style={{ backgroundColor: "#F7F7F7" }}
                >
                  <p className="text-secondary">Bank Fees payable by:</p>
                  <select
                    className="form-control text-sm"
                    value={bankFeePayableBy}
                    onChange={(e) => {
                      setbankFeePayableBy(e.target.value);
                      EventService.updateEventMeta(
                        eventID,
                        "_service_bank_fee_payable_by",
                        e.target.value
                      );
                    }}
                  >
                    <option value="">Select Payer</option>
                    <option value="Producer">Producer</option>
                    <option value="Customer">Customer</option>
                  </select>
                </div>

                <div className="px-3 py-2">
                  <p className="font-semibold text-sm pb-1">
                    Special Conditions, Comments and Information:
                  </p>
                  <textarea
                    className="form-control text-sm"
                    rows="5"
                    id="comment"
                    placeholder="Special Conditions"
                    value={specialComments}
                    onChange={(e) => {
                      setspecialComments(e.target.value);
                      EventService.updateEventMeta(
                        eventID,
                        "_service_special_comments",
                        e.target.value
                      );
                    }}
                  ></textarea>
                  <button
                    className="btn btn-warning mt-2 btn-sm rounded-none"
                    onClick={(e) => {
                      e.preventDefault();
                      saveEventServices();
                    }}
                  >
                    Click Here to Save Otayo Services
                  </button>
                </div>
              </div>
            </div>

            <div className="Branding-services">
              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Branding Services
              </p>

              <div className="overflow-scroll">
                <table className="table table-striped pb-0">
                  <thead className="border-t">
                    <tr>
                      <th
                        className="text-xs uppercase text-nowrap text-secondary"
                        scope="col"
                      >
                        List of Services
                      </th>
                      <th
                        className="text-xs uppercase text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Service Required
                      </th>
                      <th
                        className="text-xs uppercase text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Service Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {/* <tr>
                                              <td className="font-normal text-sm text5e4642">Marketing Packages</td>
                                              <td>
                                                  <select className="form-control form-control-select text-sm" value={marketingPackages} onChange={e => {setmarketingPackages(e.target.value);EventService.updateEventMeta(eventID, '_branding_marketing_packages', e.target.value);}}>
                                                      <option value="yes">Yes</option>
                                                      <option value="no">No</option>
                                                  </select>
                                              </td>
                                              <td><textarea className="form-control text-sm" value={marketingPackagesDetails} onChange={e => {setmarketingPackagesDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_marketing_packages_details', e.target.value);}} /></td>
                                          </tr> */}
                    <tr>
                      <td className="font-normal text-xs text5e4642">
                        Branded Pochettes (Envelopes)
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={envelopes}
                          onChange={(e) => {
                            setenvelopes(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_branding_envelopes",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={envelopesDetails}
                          onChange={(e) => {
                            setenvelopesDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_branding_envelopes_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-normal text-xs text5e4642">
                        Branded Event Tickets
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-xs"
                          value={brandedTickets}
                          onChange={(e) => {
                            setbrandedTickets(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_branding_branded_tickets",
                              e.target.value
                            );
                          }}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                      <td>
                        <textarea
                          rows="1"
                          className="form-control text-xs"
                          value={brandedTicketsDetails}
                          onChange={(e) => {
                            setbrandedTicketsDetails(e.target.value);
                            EventService.updateEventMeta(
                              eventID,
                              "_branding_branded_tickets_details",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                                              <td className="font-normal text-sm text5e4642">Seat Labeling</td>
                                              <td>
                                                  <select className="form-control form-control-select text-sm" value={seatLabeling} onChange={e => {setseatLabeling(e.target.value);EventService.updateEventMeta(eventID, '_branding_seat_labeling', e.target.value);}}>
                                                      <option value="yes">Yes</option>
                                                      <option value="no">No</option>
                                                  </select>
                                              </td>
                                              <td><textarea className="form-control text-sm" value={seatLabelingDetails} onChange={e => {setseatLabelingDetails(e.target.value);EventService.updateEventMeta(eventID, '_branding_seat_labeling_details', e.target.value);}} /></td>
                                          </tr> */}
                  </tbody>
                </table>
              </div>

              <div className="px-3 py-1 border">
                <p className="font-semibold pb-2">
                  Special Conditions, Comments and Information:
                </p>
                <textarea
                  className="form-control text-xs"
                  rows="4"
                  id="comment"
                  placeholder="Special Conditions"
                  value={marketingSpecialComments}
                  onChange={(e) => {
                    setmarketingSpecialComments(e.target.value);
                    EventService.updateEventMeta(
                      eventID,
                      "_branding_marketing_special_comments",
                      e.target.value
                    );
                  }}
                ></textarea>
              </div>
            </div>

            <div className="Marketing-services">
              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Marketing Services
              </p>

              <div className="overflow-scroll">
                <table className="table table-striped">
                  <thead className="border-t">
                    <tr>
                      <th
                        className="font-bold text-xs text-nowrap text-secondary"
                        scope="col"
                      >
                        Service Category
                      </th>
                      <th
                        className="font-bold text-xs text-nowrap text-secondary"
                        scope="col"
                      >
                        Description
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Amount Payable (VAT Incl.)
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Amount Paid
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Pending Payment
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap text-secondary"
                        scope="col"
                      >
                        Payment Records
                      </th>
                      <th
                        className="font-bold text-xs text-nowrap text-secondary"
                        scope="col"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {marketingServicesMul != null &&
                      marketingServicesMul.map((service, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-xs">{service.category}</td>
                            <td className="text-xs">{service.description}</td>
                            <td className="text-xs text-center">Rs {service.payable}</td>
                            <td className="text-xs text-center text-blue-700">
                              Rs {service.paid}
                            </td>
                            <td className="text-xs text-center text-red-600">
                              Rs {service.pending}
                            </td>
                            <td className="text-xs text-center">
                              <button
                                type="button"
                                className="btn btn-sm text-xs btn-link"
                                onClick={(e) => getPymentDetails(service.id, "marketing")}
                              >
                                Payment Details
                              </button>
                            </td>
                            <td className="text-xs d-flex flex-nowrap gap-2">
                              <button
                                className="btn btn-sm btn-warning bg-orange-600 text-white mr-3"
                                onClick={(e) => editMarketingService(service.id)}
                              >
                                Edit
                              </button>
                              {service.paid == 0 && (
                                <button
                                  className="btn btn-sm btn-warning bg-red-600 text-white"
                                  onClick={(e) =>
                                    deleteMarketingService(index, service.id)
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td className="text-xs">
                        <td className="text-xs">
                          <input
                            className="form-control text-sm bgf7f7f7"
                            value={marketingServicesSingleCategory}
                            onChange={(e) =>
                              setmarketingServicesSingleCategory(e.target.value)
                            }
                          />
                        </td>
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="text"
                          className="form-control text-xs"
                          value={marketingServiceSingleDescription}
                          onChange={(e) =>
                            setmarketingServiceSingleDescription(e.target.value)
                          }
                        />
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-xs"
                          value={marketingServiceSinglePayable}
                          onChange={(e) =>
                            setmarketingServiceSinglePayable(e.target.value)
                          }
                        />
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-xs"
                          value={marketingServiceSinglePaid}
                          onChange={(e) => setmarketingServiceSinglePaid(e.target.value)}
                          disabled={true}
                        />
                      </td>
                      <td className="text-xs">
                        <input
                          className="form-control text-xs bgf7f7f7"
                          value={marketingServiceSinglePending}
                          disabled={true}
                        />
                      </td>
                      <td className="text-xs"></td>
                      <td className="text-xs">
                        <button
                          className="btn btn-sm bg-green-600 btn-success text-white text-sm"
                          onClick={(e) => addmarketingService()}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Producer Tickets">
              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Producer Tickets
              </p>

              <div className="overflow-scroll">
                <table className="table table-striped">
                  <thead className="border-t">
                    <tr>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Date Processed
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Number of Tickets
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Price per Ticket (excl. VAT)
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Total Price (incl. VAT)
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Amount Paid
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Pending Payment
                      </th>
                      <th
                        className="font-bold text-xs text-center text-nowrap"
                        scope="col"
                      >
                        Payment Records
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {producerTickets != null &&
                      producerTickets.map((tickets, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-xs">{tickets.dateprocessed}</td>
                            <td className="text-xs text-center">
                              {tickets.numberoftickets}
                            </td>
                            <td className="text-xs text-center">
                              Rs {tickets.priceperticket}
                            </td>
                            <td className="text-xs text-center">
                              Rs {tickets.totalprice}
                            </td>
                            <td className="text-xs text-blue-700 text-center">
                              Rs {tickets.paid}
                            </td>
                            <td className="text-xs text-red-600 text-center">
                              Rs {tickets.pending}
                            </td>
                            <td className="text-xs text-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-link text-nowrap"
                                onClick={(e) => getPymentDetails(tickets.id, "tickets")}
                              >
                                Payment Details
                              </button>
                            </td>
                            <td className="text-xs d-flex gap-1">
                              <button
                                className="btn btn-sm btn-warning bg-orange-600 text-white mr-3"
                                onClick={(e) => editProducerTickets(tickets.id)}
                              >
                                Edit
                              </button>
                              {tickets.paid == 0 && (
                                <button
                                  className="btn btn-sm btn-warning bg-red-600 text-white"
                                  onClick={(e) =>
                                    deleteProducerTickets(index, tickets.id)
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td className="text-sm">
                        <td className="text-sm">
                          {" "}
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={producerTicketsSingleDateProcessed}
                            onChange={(date) =>
                              setproducerTicketsSingleDateProcessed(date)
                            }
                            placeholderText="Date"
                            className="form-control text-sm bgf7f7f7"
                          />
                        </td>
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-xs"
                          value={producerTicketsSingleNoOfTickets}
                          onChange={(e) =>
                            setproducerTicketsSingleNoOfTickets(e.target.value)
                          }
                        />
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-xs"
                          value={producerTicketsSinglePriceEx}
                          onChange={(e) =>
                            setproducerTicketsSinglePriceEx(e.target.value)
                          }
                        />
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-xs"
                          disabled={true}
                        />
                      </td>
                      <td className="text-xs">
                        <input
                          type="number"
                          className="form-control text-xs"
                          disabled={true}
                        />
                      </td>
                      <td className="text-xs">
                        <input
                          type="number"
                          className="form-control text-xs"
                          disabled={true}
                        />
                      </td>
                      <td className="text-xs"></td>
                      <td className="text-xs">
                        <button
                          className="btn btn-sm bg-green-600 btn-success text-white text-sm"
                          onClick={(e) => addProducerTickets()}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td className=" text-sm text-center">
                        <b>{alltotaltickets}</b>
                      </td>
                      <td></td>
                      <td className=" text-sm text-center">
                        <b>Rs {alltotalPrice}</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-1 border rounded-sm shadow-sm">
              <p className="font-semibold px-3 py-2 text-orange-500">
                Event Producer Notifications
              </p>
              <Divider />
              <div className="px-3 py-1 text-sm d-flex flex-col gap-1 justify-content-between">
                <p className="text-secondary">Total Ticket Fees :</p>

                <select
                  className="form-control form-control-select w-100 text-sm"
                  value={producerNotification}
                  onChange={(e) => {
                    setproducerNotification(e.target.value);
                    EventService.updateEventMeta(
                      eventID,
                      "_producer_notification",
                      e.target.value
                    );
                  }}
                >
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <Divider />
              <div className="px-3 py-1 text-sm d-flex flex-col gap-1 justify-content-between">
                <p className="text-secondary">Daily Sales Report Sent to :</p>

                <input
                  type="text"
                  disabled={true}
                  value={event != null && event.producer_email}
                  className="form-control notif-input text-sm"
                  placeholder="Email Address"
                />
                <div class="add-more flex items-center pt-2 d-flex flex-col">
                  <input
                    type={"email"}
                    value={additionalEmail}
                    onChange={(e) => setAdditionalEmail(e.target.value)}
                    className="form-control text-sm"
                  ></input>
                  <button
                    className="ml-3 my-2 font-semibold bg-orange-500 text-white px-4 py-1 rounded-sm text-sm"
                    disabled={additionalEmail == null}
                    onClick={(e) => addAdditionalEmail}
                  >
                    [+] Add Additional Email
                  </button>
                </div>
              </div>
              <Divider />
            </div>

            <div className="Financial Summary">
              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Financial Summary
              </p>

              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Event Deposit
              </p>

              <div className="overflow-scroll">
                <table className="table">
                  <thead className="border-y">
                    <tr>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Date Processed
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Deposit Amount
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Method of Payment
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Payment Reference
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Additional info
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {allEventDeposits != null &&
                      allEventDeposits.map((deposit, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-xs">{deposit.date}</td>
                            <td className="text-xs">{deposit.amount}</td>
                            <td className="text-xs">{deposit.payment_method}</td>
                            <td className="text-xs">{deposit.reference}</td>
                            <td className="text-xs">{deposit.additional_info}</td>
                            <td className="text-xs">
                              <button
                                className="btn btn-sm btn-warning bg-red-600 text-white"
                                onClick={(e) =>
                                  deleteTableEntry("eventDeposit", deposit.id)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td className="text-sm">
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={eventDeposit.date}
                          onChange={(date) =>
                            setSingleTableInput("eventDeposit", "date", date)
                          }
                          placeholderText="Date"
                          className="form-control text-sm bgf7f7f7"
                        />
                      </td>
                      <td className="text-xs bgf7f7f7">
                        <input
                          type="number"
                          className="form-control text-sm"
                          value={eventDeposit.amount}
                          onChange={(e) =>
                            setSingleTableInput("eventDeposit", "amount", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-xs">
                        <select
                          className="form-control form-control-select text-sm bgf7f7f7"
                          value={eventDeposit.payment_method}
                          onChange={(e) =>
                            setSingleTableInput(
                              "eventDeposit",
                              "payment_method",
                              e.target.value
                            )
                          }
                        >
                          <option value="None">None</option>
                          <option value="Cash">Cash</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                          <option value="Cheque">Cheque</option>
                        </select>
                      </td>
                      <td className="text-xs">
                        <input
                          className="form-control text-xs bgf7f7f7"
                          value={eventDeposit.reference}
                          onChange={(e) =>
                            setSingleTableInput(
                              "eventDeposit",
                              "reference",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="text-xs">
                        <input
                          className="form-control text-xs bgf7f7f7"
                          value={eventDeposit.additional_info}
                          onChange={(e) =>
                            setSingleTableInput(
                              "eventDeposit",
                              "additional_info",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="text-xs">
                        <button
                          className="btn btn-sm bg-green-600 btn-success text-white text-sm"
                          onClick={(e) => addtableEntry("eventDeposit")}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Advance Producer Payment
              </p>

              <div className="overflow-scroll">
                <table className="table">
                  <thead className="border-y">
                    <tr>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Date Processed
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Payment Amount
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Method of Payment
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Payment Reference
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Additional info
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {allAdvanceProdPayments != null &&
                      allAdvanceProdPayments.map((deposit, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-xs">{deposit.date}</td>
                            <td className="text-xs">{deposit.amount}</td>
                            <td className="text-xs">{deposit.payment_method}</td>
                            <td className="text-xs">{deposit.reference}</td>
                            <td className="text-xs">{deposit.additional_info}</td>
                            <td className="text-xs">
                              <button
                                className="btn btn-sm btn-warning bg-orange-600 text-white text-sm"
                                onClick={(e) =>
                                  deleteTableEntry("advanceProdPayment", deposit.id)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td>
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={advanceProdPayment.date}
                          onChange={(date) =>
                            setSingleTableInput("advanceProdPayment", "date", date)
                          }
                          placeholderText="Date"
                          className="form-control text-sm bgf7f7f7"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control text-sm bgf7f7f7"
                          value={advanceProdPayment.amount}
                          onChange={(e) =>
                            setSingleTableInput(
                              "advanceProdPayment",
                              "amount",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-sm bgf7f7f7"
                          value={advanceProdPayment.payment_method}
                          onChange={(e) =>
                            setSingleTableInput(
                              "advanceProdPayment",
                              "payment_method",
                              e.target.value
                            )
                          }
                        >
                          <option value="None">None</option>
                          <option value="Cash">Cash</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                          <option value="Cheque">Cheque</option>
                        </select>
                      </td>
                      <td>
                        <input
                          className="form-control text-sm bgf7f7f7"
                          value={advanceProdPayment.reference}
                          onChange={(e) =>
                            setSingleTableInput(
                              "advanceProdPayment",
                              "reference",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control text-sm bgf7f7f7"
                          value={advanceProdPayment.additional_info}
                          onChange={(e) =>
                            setSingleTableInput(
                              "advanceProdPayment",
                              "additional_info",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-sm bg-green-600 btn-success text-white text-sm"
                          onClick={(e) => addtableEntry("advanceProdPayment")}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Payment To Producer
              </p>

              <div className="overflow-scroll">
                <table className="table">
                  <thead className="border-y">
                    <tr>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Date Processed
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Payment Amount
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Method of Payment
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Payment Reference
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Status
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col">
                        Additional info
                      </th>
                      <th className="font-bold text-xs text-nowrap" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="border-b">
                    {allPaymentToProducer != null &&
                      allPaymentToProducer.map((deposit, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-sm">{deposit.date}</td>
                            <td className="text-sm">{deposit.amount}</td>
                            <td className="text-sm">{deposit.payment_method}</td>
                            <td className="text-sm">{deposit.reference}</td>
                            <td className="text-sm">{deposit.status}</td>
                            <td className="text-sm">{deposit.additional_info}</td>
                            <td className="text-sm">
                              <button
                                className="btn btn-sm btn-warning bg-red-600 text-white text-sm"
                                onClick={(e) =>
                                  deleteTableEntry("paymentToProducer", deposit.id)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td>
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={paymentToProducer.date}
                          onChange={(date) =>
                            setSingleTableInput("paymentToProducer", "date", date)
                          }
                          placeholderText="Date"
                          className="form-control text-sm bgf7f7f7"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control bgf7f7f7"
                          value={paymentToProducer.amount}
                          onChange={(e) =>
                            setSingleTableInput(
                              "paymentToProducer",
                              "amount",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-sm bgf7f7f7"
                          value={paymentToProducer.payment_method}
                          onChange={(e) =>
                            setSingleTableInput(
                              "paymentToProducer",
                              "payment_method",
                              e.target.value
                            )
                          }
                        >
                          <option value="None">None</option>
                          <option value="Cash">Cash</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                          <option value="Cheque">Cheque</option>
                        </select>
                      </td>
                      <td>
                        <input
                          className="form-control text-sm bgf7f7f7"
                          value={paymentToProducer.reference}
                          onChange={(e) =>
                            setSingleTableInput(
                              "paymentToProducer",
                              "reference",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <select
                          className="form-control form-control-select text-sm bgf7f7f7"
                          value={paymentToProducer.status}
                          onChange={(e) =>
                            setSingleTableInput(
                              "paymentToProducer",
                              "status",
                              e.target.value
                            )
                          }
                        >
                          <option value="unpaid">Unpaid</option>
                          <option value="Paid">Paid</option>
                        </select>
                      </td>
                      <td>
                        <input
                          className="form-control text-sm bgf7f7f7"
                          value={paymentToProducer.additional_info}
                          onChange={(e) =>
                            setSingleTableInput(
                              "paymentToProducer",
                              "additional_info",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-sm bg-green-600 btn-success text-white text-sm"
                          onClick={(e) => addtableEntry("paymentToProducer")}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {relatedEventFiles != null &&
                relatedEventFiles.length > 0 &&
                relatedEventFiles.map((file, index) => (
                  <div className="mt-1 border rounded-sm shadow-sm">
                    <div className="d-flex px-3 py-2  justify-content-between align-items-center">
                      <p className="font-semibold text-orange-500">Event Related Files</p>
                      <button
                        type="button"
                        class="btn btn-success btn-sm bg-green-600 btn-success text-white text-xs"
                        onClick={(e) => setisOpenRelatedFileModal(true)}
                      >
                        Upload File
                      </button>
                    </div>

                    <Divider />
                    <div className="px-3 py-1 text-sm d-flex justify-content-between">
                      <p className="text-secondary">ID :</p>
                      <p>{file.id}</p>
                    </div>
                    <Divider />

                    <div className="px-3 py-1 text-sm d-flex justify-content-between">
                      <p className="text-secondary">Date Processed :</p>
                      <p>{file.dateadded}</p>
                    </div>
                    <Divider />

                    <div className="px-3 py-1 text-sm d-flex justify-content-between">
                      <p className="text-secondary text-nowrap">Description :</p>
                      <p className="text-right">{file.description}</p>
                    </div>
                    <Divider />

                    {/* <div className='px-3 py-1 text-sm d-flex justify-content-between'>
                                <p className='text-secondary'>Preview :</p>
                                <p></p>
                                
                              </div>
                              <Divider /> */}

                    <div className="px-3 py-1 text-sm d-flex justify-content-between">
                      <p className="text-secondary">File Download :</p>
                      <a href={file.filepath}>
                        <DownloadIcon style={{ width: 20, height: 20 }} />
                      </a>
                    </div>
                    <Divider />

                    <div className="px-3 py-1 text-sm d-flex justify-content-between">
                      <p className="text-secondary">Action :</p>
                      <button
                        className="btn btn-sm btn-warning bg-red-600 text-white text-xs"
                        onClick={(e) => deleteRelatedFile(file.id)}
                      >
                        Delete
                      </button>
                    </div>
                    <Divider />
                  </div>
                ))}

              <div></div>
            </div>

            <div className="View Bilan">
            <p
                className="pt-3"
                style={{
                  fontWeight: "700",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "#aaaaaa",
                }}
              >
                Otayo Bilan
              </p>

             <div>
             <button onClick={() =>  window.location.href = `${invoice}`} className="text-white bg-orange-500 w-100 py-2">
              View Bilan
             </button>
             <div className="flex justify-around mt-2 w-100 pt-2">
               <button className="upload-invoice font-bold cursor-pointer" onClick={(e) => setShowInvoiceUpload(!showInvoiceUpload)}>Upload</button>
               <span className="text-gray-400">|</span>
               <button className="delete-invoice font-bold cursor-pointer" onClick={(e) => deleteFile('invoice')}>Delete</button>
             </div>

             </div>
            </div>
        </div>

        </>
    );
}

export default EventSingle;