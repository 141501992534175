import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import axios from "axios";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";


import "../styles/Posmain.css"

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;';
const fileExtension = '.xlsx';

const APIURL = "https://otayo.com/erpapi/pos.php";

const PosSalesReport = () => {

    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const [minDate, setMinDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [collectionStatus, setCollectionStatus] = useState("");
    const [orderStatus, setOrderStatus] = useState("Any");
    const [reportData, setReportData] = useState([]);

    const [posName, setPosName] = useState('');
    const [posEmail, setPosEmail] = useState('');
    const [getStatsBtn, setGetStatsBtn] = useState('Get Report');
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [checkedOrdersTemp, setCheckedOrdersTemp] = useState([]);
    const [checkedDates, setCheckedDates] = useState([]);

    const [totalTickets, setTotalTickets] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [totalCash, setTotalCash] = useState(0);
    const [totalCard, setTotalCard] = useState(0);
    const [minperiodDate, setminperiodDate] = useState('');
    const [maxPeriodDate, setmaxPeriodDate] = useState('');

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                PosService.getSinglePOS(id)
                .then(response => {
                    console.log('POS response', {response});
                    if(response.data.pos_name!='undefined') {
                        setPosName(response.data.pos_name);
                        setPosEmail(response.data.email);
                    }
                })
                .catch(err => console.log({err}));
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const Refresh = () => {
        window.location.reload(false);
    }

    const addMoneyCollection = (e) => {
        e.preventDefault();
        var action = 'addMoneyCollection';
        
        var params = {
            action: action,
            order_period: minperiodDate+'--'+maxPeriodDate,
            order_amount: totalSales,
            order_cash: totalCash,
            order_card: totalCard,
            total_tickets: totalTickets,
            pos: id,
            checkedOrders: checkedOrdersTemp
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({response});
            setIsOpen(false);
            getData(null);
            swal("Collection Saved", "Collection has been saved", "success");
        })
        .catch(err => console.log(err));
    }

    const getData = (e) => {
        if(e!=null) {
            e.preventDefault();
        }
        setGetStatsBtn("Getting Report...");
        setTotalTickets(0);
        setTotalSales(0);
        setTotalCash(0);
        setTotalCard(0);
        setReportData([]);
        setCheckedOrders([]);
        setCheckedOrdersTemp([]);
        setCheckedDates([]);
        setminperiodDate('');
        setmaxPeriodDate('');

        var params = {
            action: 'getSalesReport',
            pos: id,
            startDate: startDate,
            endDate: endDate,
            posEmail: posEmail,
            orderStatus: orderStatus,
            collectionStatus: collectionStatus,
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log({response});
            setReportData(response.data.sales);
            setGetStatsBtn("Get Report");

            setTotalTickets(response.data.allTickets);
            setTotalSales(response.data.allTicketSales);
            setTotalCash(response.data.allCash);
            setTotalCard(response.data.allCredit);

            var tempcheckedOrders = [];
            var tempchekDates = [];

            for (const item of response.data.sales) {
                tempcheckedOrders[item.orderID] = true;
                updateChecked(item.orderID, true, true);
                tempchekDates.push(item.post_date_js);
            }

            var min = tempchekDates.reduce(function (a, b) { return Date.parse(a) < Date.parse(b) ? a : b; }); 
            setminperiodDate(min);
            var max = tempchekDates.reduce(function (a, b) { return Date.parse(a) > Date.parse(b) ? a : b; });
            setmaxPeriodDate(max);

            setCheckedOrders(tempcheckedOrders);
            setCheckedDates(tempchekDates);

        })
        .catch(err => console.log({err}));
    }

    const updateChecked = (orderID, checked, init=false) => {
        var checkedOrdersCheckBoxes = checkedOrders;
        checkedOrdersCheckBoxes[orderID] = checked;
        setCheckedOrders(checkedOrdersCheckBoxes);
        
        var temp = checkedOrdersTemp;

        if(checked) {
            temp.push(orderID);
        } else {
            temp = temp.filter(item => item !== orderID);

        }
        // temp[orderID]=checked;
        setCheckedOrdersTemp(temp);

        var temptickets = parseFloat(totalTickets);
        var tempsales = parseFloat(totalSales);
        var tempCards = parseFloat(totalCard);
        var tempCash = parseFloat(totalCash);
        var tempDates = checkedDates;
        for (const item of reportData) {
            if(orderID==item.orderID) {
                if(checked) {
                   temptickets = temptickets + item.totaltickets;
                   tempsales = parseFloat(tempsales) + Math.round(parseFloat(item.total_raw));
                   if(item.method_raw=='cash') {
                        tempCash = parseFloat(tempCash) + Math.round(parseFloat(item.total_raw));
                   } else if(item.method_raw=='terminal') {
                        tempCards = parseFloat(tempCards) + Math.round(parseFloat(item.total_raw));
                   } 

                   tempDates.push(item.post_date_js);
                } else {
                    temptickets = temptickets - item.totaltickets;
                    tempsales = parseFloat(tempsales) - Math.round(parseFloat(item.total_raw));
                    if(item.method_raw=='cash') {
                        tempCash = parseFloat(tempCash) - Math.round(parseFloat(item.total_raw));
                   } else if(item.method_raw=='terminal') {
                        tempCards = parseFloat(tempCards) - Math.round(parseFloat(item.total_raw));
                   } 

                   tempDates = tempDates.filter(item => item !== item.post_date_js);
                }

                if(init==false) {
                    setTotalTickets(temptickets);
                    setTotalSales(tempsales);
                    setTotalCard(tempCards);
                    setTotalCash(tempCash);
                    setCheckedDates(tempDates);
                }

                var min = tempDates.reduce(function (a, b) { return Date.parse(a) < Date.parse(b) ? a : b; }); 
                setminperiodDate(min);
                var max = tempDates.reduce(function (a, b) { return Date.parse(a) > Date.parse(b) ? a : b; });
                setmaxPeriodDate(max);
            }
        }
    }

    const exportExcel = (e) => {
        var excelData = [];
        for(var i=0; i<reportData.length; i++) {
            var obj = reportData[i];
            if(checkedOrders[obj.orderID]==true) {
                var collection = 'Pending';
                if(obj.moneycollection=='Pending' || obj.moneycollection=='') {
                    collection = 'Pending';
                } else if(obj.moneycollection=='In-progress') {
                    collection = 'In-progress';
                } else if(obj.moneycollection.includes('Collected')) {
                    collection = obj.moneycollection;
                }

                excelData.push({
                    orderdate: obj.post_date,
                    Events: obj.events,
                    Customer: obj.customer,
                    OrderId: obj.orderID,
                    Method: obj.method,
                    Status: obj.post_status,
                    Total: obj.total.toLocaleString('en-US'),
                    TotalTickets: obj.totaltickets.toLocaleString('en-US'),
                    Agent: obj.agent,
                    Collection: collection,
                });
            }
        }

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'export pos report'+ fileExtension);
    }

    const openAddToCollection = (e) => {
        e.preventDefault();
        console.log({checkedDates});
        var min = checkedDates.reduce(function (a, b) { return Date.parse(a) < Date.parse(b) ? a : b; }); 
        setminperiodDate(min);
        console.log("min:", min)
        var max = checkedDates.reduce(function (a, b) { return Date.parse(a) > Date.parse(b) ? a : b; });
        console.log("max:", max)
        setmaxPeriodDate(max);

        setIsOpen(true);
    }

    return (
        <>
        <div className="salesreport-wrapper p2 d-none d-lg-block">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">{posName} Sales Report</h1>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="flex justify-start mb-2">
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDate}
                                    onChange={date => setStartDateCal(date)}
                                    placeholderText="Start Date"
                                    className='form-control rounded-none text-sm bgf0f0f0'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}> 
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDate}
                                    onChange={date => setEndDateCal(date)}
                                    placeholderText="End Date"
                                    className='form-control rounded-none text-sm bgf0f0f0'
                                    minDate = {minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select className="form-control text-sm" value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
                                    <option value="Any">Order Status</option>
                                    <option value="Any">Any</option>
                                    <option value="wc-pending">Pending</option>
                                    <option value="wc-processing">Paid</option>
                                    <option value="wc-cancelled">Cancelled</option>
                                    <option value="wc-refunded">Refunded</option>
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <select className="form-control text-sm" value={collectionStatus} onChange={e => setCollectionStatus(e.target.value)}>
                                    <option value="Any">Any Money Collection</option>
                                    {/* <option value="Any">Any</option> */}
                                    <option value="Collected">Collected</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Pending">Pending</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="#" className="btn btn-success bg7cc576 rounded-none border-none text-sm" onClick={(e) => getData(e)} >{getStatsBtn}</a>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="#" className="btn btn-warning bgfef200 text-black border-none rounded-none text-sm" onClick={(e) => Refresh()} >Reset</a>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-primary bg-blue-600 hover:bg-blue-800 text-white border-none rounded-none text-sm" onClick={(e) => exportExcel()} disable={reportData.length==0}>Export Selected</button>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="reportStats flex justify-between items-center">
                            <div className="reportStatsItem flex flex-column items-center justify-center w-1/5 border-black border">
                                <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs">Total Tickets</div>
                                <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm">{totalTickets.toLocaleString('en-US')}</div>
                            </div>
                            <div className="reportStatsItem flex flex-column items-center justify-center w-1/5 border-black border">
                                <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs">Total Tickets Sales</div>
                                <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm">{totalSales.toLocaleString('en-US')}</div>
                            </div>
                            <div className="reportStatsItem flex flex-column items-center justify-center w-1/5 border-black border">
                                <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs">Total Cash</div>
                                <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm">{totalCash.toLocaleString('en-US')}</div>
                            </div>
                            <div className="reportStatsItem flex flex-column items-center justify-center w-1/5 border-black border">
                                <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs">Credit Card</div>
                                <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm">{totalCard.toLocaleString('en-US')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 flex justify-start items-center">
                        <button className="btn btn-sm btn-warning btn-orange" onClick={e => openAddToCollection(e)}>Input Money Collection Status >></button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Select</th>
                                    <th scope="col">Order Date</th>
                                    <th scope="col">Event Names</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">OrderID</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Number of tickets</th>
                                    <th scope="col">Processing Agent</th>
                                    <th scope="col">Money Collection</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.length>0 && reportData.map((row, index) => (
                                <tr ley={index} className=" text-sm">
                                    <td scope="col">
                                        <input type="checkbox" value={row.orderID} checked={checkedOrders[row.orderID]} onChange={e => {updateChecked(row.orderID, e.target.checked)}} />
                                        </td>
                                    <td scope="col">{row.post_date}</td>
                                    <td scope="col" className="textf36523 underline">{row.events}</td>
                                    <td scope="col">{row.customer}</td>
                                    <td scope="col text-center"><a className="text-blue-600 underline font-semibold" href={`/edit-order/${row.orderID}`} target="_blank">{row.orderID}</a></td>
                                    <td scope="col text-center">{row.method}</td>
                                    <td scope="col text-center">{row.post_status}</td>
                                    <td scope="col font-semibold">{row.total.toLocaleString('en-US')}</td>
                                    <td scope="col font-semibold">{row.totaltickets.toLocaleString('en-US')}</td>
                                    <td scope="col text-center">{row.agent}</td>
                                    <td scope="col text-center">
                                        {(row.moneycollection=='Pending' || row.moneycollection=='') && 
                                         <b className="text-blue-600">Pending</b>
                                        }
                                        {row.moneycollection=='In-progress' && 
                                         <b className="text-orange-600">In-progress</b>
                                        }
                                        {row.moneycollection.includes('Collected') && 
                                         <b className="text-green-600">{row.moneycollection}</b>
                                        }
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            {/* Add money collection */}
            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addMoneyCollection(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Money Collection Status
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Order Period</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" disabled={true} required="required" value={UtilityService.parse_pos_collection_date(minperiodDate +'--'+maxPeriodDate)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Total Order Amount</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" disabled={true} required="required" value={totalSales} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Total Cash Amount</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" disabled={true} required="required" value={totalCash} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Total Credit Card</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" disabled={true} required="required" value={totalCard} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Total No. of Tickets</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" disabled={true} required="required" value={totalTickets} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>

        <div className="d-lg-none">
         <div className="d-flex justify-content-between px-3">
          <p style={{fontWeight : "700",marginBottom : "0px"}}>Sales Report</p>

          <div>
          <a href="#" className="btn btn-warning bgfef200 text-black border-none rounded-none text-sm" onClick={(e) => Refresh()} >Reset</a>
          </div>
         </div>



         <div className="px-3 py-2">
            <div className="d-flex gap-2 pb-2">
            <select className="form-control text-sm" value={orderStatus} onChange={e => setOrderStatus(e.target.value)}>
             <option selected value="Any">Order Status</option>
             <option value="Any">Any</option>
             <option value="wc-pending">Pending</option>
             <option value="wc-processing">Paid</option>
             <option value="wc-cancelled">Cancelled</option>
             <option value="wc-refunded">Refunded</option>
            </select>

            <select className="form-control text-sm" value={collectionStatus} onChange={e => setCollectionStatus(e.target.value)}>
             <option selected value="Any">Any Money Collection</option>
             <option value="Collected">Collected</option>
             <option value="In-progress">In-progress</option>
             <option value="Pending">Pending</option>
            </select>
            </div>


            <div className="d-flex gap-2 pb-2">
            <DatePicker
            dateFormat="dd-MM-yyyy"
            selected={startDate}
            onChange={date => setStartDateCal(date)}
            placeholderText="Start Date"
            className='form-control rounded-none text-sm bgf0f0f0'
            />

            <DatePicker
             dateFormat="dd-MM-yyyy"
             selected={endDate}
             onChange={date => setEndDateCal(date)}
             placeholderText="End Date"
             className='form-control rounded-none text-sm bgf0f0f0'
             minDate = {minDate}
            />
                           
            </div>

            <div className="d-flex gap-2 pb-2">
                <button className="w-100 bg-blue-600 py-1 text-white rounded-sm" onClick={(e) => exportExcel()} disable={reportData.length==0}>Export Selected</button>
                <button className="w-100 bg-green-700 py-1 text-white rounded-sm" onClick={(e) => getData(e)} >{getStatsBtn}</button>
            </div>
         </div>

         <div className="d-flex overflow-scroll flex-nowrap gap-2 px-3
         pb-2">
         <div className="reportStatsItem flex flex-column items-center justify-center">
            <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs text-nowrap">Total Tickets</div>
            <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm border ">{totalTickets.toLocaleString('en-US')}</div>
         </div>
         <div className="reportStatsItem flex flex-column items-center justify-center">
             <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs text-nowrap">Total Tickets Sales</div>
             <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm border">{totalSales.toLocaleString('en-US')}</div>
         </div>
         <div className="reportStatsItem flex flex-column items-center justify-center">
             <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs text-nowrap">Total Cash</div>
             <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm border" >{totalCash.toLocaleString('en-US')}</div>
         </div>
         <div className="reportStatsItem flex flex-column items-center justify-center">
             <div className="reportStatsItemHeader p-2 w-full font-semibold uppercase bgf36523 text-white text-center text-xs text-nowrap">Credit Card</div>
             <div className="reportStatsItemValue text-black text-center p-2 w-full font-normal text-sm border">{totalCard.toLocaleString('en-US')}</div>
         </div>
         </div>


         {/* Table */}

         <div className="overflow-scroll px-3 py-2">
         <table className="table table-ticket-sale table-striped">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm border">
                                <tr className="text-nowrap">
                                    <th scope="col">Select</th>
                                    <th scope="col">Order Date</th>
                                    <th scope="col">Event Names</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">OrderID</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Number of tickets</th>
                                    <th scope="col">Processing Agent</th>
                                    <th scope="col">Money Collection</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.length>0 && reportData.map((row, index) => (
                                <tr ley={index} className="text-xs">
                                    <td scope="col">
                                        <input type="checkbox" value={row.orderID} checked={checkedOrders[row.orderID]} onChange={e => {updateChecked(row.orderID, e.target.checked)}} />
                                        </td>
                                    <td scope="col">{row.post_date}</td>
                                    <td scope="col" className="textf36523 underline">{row.events}</td>
                                    <td scope="col">{row.customer}</td>
                                    <td scope="col text-center"><a className="text-blue-600 underline font-semibold" href={`/edit-order/${row.orderID}`} target="_blank">{row.orderID}</a></td>
                                    <td scope="col text-center">{row.method}</td>
                                    <td scope="col text-center">{row.post_status}</td>
                                    <td scope="col font-semibold">{row.total.toLocaleString('en-US')}</td>
                                    <td scope="col font-semibold">{row.totaltickets.toLocaleString('en-US')}</td>
                                    <td scope="col text-center">{row.agent}</td>
                                    <td scope="col text-center">
                                        {(row.moneycollection=='Pending' || row.moneycollection=='') && 
                                         <b className="text-blue-600">Pending</b>
                                        }
                                        {row.moneycollection=='In-progress' && 
                                         <b className="text-orange-600">In-progress</b>
                                        }
                                        {row.moneycollection.includes('Collected') && 
                                         <b className="text-green-600">{row.moneycollection}</b>
                                        }
                                    </td>
                                </tr>
                                ))}
                            </tbody>
         </table>
         </div>

   
        </div>
        </>
    );
}

export default PosSalesReport;