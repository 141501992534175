import { useEffect, useState, useRef } from "react";
import Chip from '@mui/material/Chip';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import readXlsxFile from 'read-excel-file';
import axios from "axios";
import { saveAs } from "file-saver";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";

import "../styles/Newsletter.css";

const APIURL = "https://otayo.com/erpapi/newsletter.php";
const NEWSLETTERURL1 = "https://otayo.com/erpapi/newsletter-raw1.php";
const NEWSLETTERURL2 = "https://otayo.com/erpapi/newsletter-raw2.php";
const NEWSLETTERURL3 = "https://otayo.com/erpapi/newsletter-raw3.php";

const Newsletter = () => {

    const [data, setData] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventGeneral, setEventGeneral] = useState('');
    const [eventSingle, setEventSingle] = useState('');
    const [previewdata, setPreviewdata] = useState('');
    const [loading, setloading] = useState('Loading Events...');
    const [disabledDownload, setDisabledDownload] = useState(true);
    const [filename, setFilename] = useState('newsletter.html');
    const [sections, setSections] = useState([
        {
            section: "General",
            events: [{id: 1, name: "General", sort: 1}]
        }
    ]);
    const [selectedImage, setSelectedImage] = useState('');
    const [uploadedImage, setUploadedImage] = useState('');
    const [headerlink, setheaderlink] = useState('');
    const [Template2Text, setTemplate2Text] = useState('');
    const [disabletemp2button, setdisabletemp2button] = useState(true);

    const [customEvents, setcustomEvents] = useState([
        {
            id: 1,
            name: "General",
            sort: 1
        }
    ]);
    const ref = useRef(null);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) { 
                // processData();
                getEvents();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const handleClick = (e) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    const getEvents = () => {
        let params = {
            action: "getEvents",
        };
        axios.post(APIURL, {params})
        .then(res => {
            console.log("res", res)
            if(res.data.length > 0) {
                setEvents(res.data);
            }
            setloading('');
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getAllCurrent = (e) => {
        e.preventDefault();

        setDisabledDownload(true);
        setloading('Loading Preview...');
        var params = {
            action: "getAllCurrent",
            sections : sections,
            eventGeneral: eventGeneral
        };
        axios.post(NEWSLETTERURL1+"?event="+eventGeneral, {...params})
        .then(res => {
            console.log("res", res);
            setPreviewdata(res.data);
            setloading('');
            setDisabledDownload(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getAllCurrentMonth = (e) => {
        e.preventDefault();

        setDisabledDownload(true);
        setloading('Loading Preview...');
        var params = {
            action: "getAllCurrentMonth",
            events : customEvents,
            uploadedImage: uploadedImage,
            Template2Text: Template2Text,
            headerlink: headerlink
        };
        axios.post(NEWSLETTERURL2+"?image="+uploadedImage+'&text='+Template2Text, {...params})
        .then(res => {
            console.log("res", res);
            setPreviewdata(res.data);
            setloading('');
            setDisabledDownload(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getSingleEvent = (e) => {
        e.preventDefault();

        setDisabledDownload(true);
        setloading('Loading Preview...');
        axios.get(NEWSLETTERURL3+"?event="+eventSingle)
        .then(res => {
            console.log("res", res);
            setPreviewdata(res.data);
            setloading('');
            setDisabledDownload(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const addNewSection = (e) => {
        e.preventDefault();
        let newSection = {
            section: "New Section",
            events: [{id: 1, name: "General", sort: 1}]
        }
        setSections([...sections, newSection]);
    }

    const deleteEvent = (e, index, eventindex) => {
        e.preventDefault();
        let newSections = sections;
        newSections[index].events.splice(eventindex, 1);
        setSections([...newSections]);
    }

    const updateSectionEvent = (value, index, eventindex) => {
        let newSections = sections;
        newSections[index].events[eventindex].name = value;
        newSections[index].events[eventindex].id = value;
        setSections([...newSections]);
    }

    const addNewEvent = (e, index) => {
        e.preventDefault();
        let newSections = sections;
        let newEvent = {id: "", name: "", sort: newSections[index].events.length+1};
        newSections[index].events.push(newEvent);
        setSections([...newSections]);
    }

    const updateEventSort = (e, index, eventindex) => {
        let newSections = sections;
        newSections[index].events[eventindex].sort = e.target.value;
        setSections([...newSections]);
    }

    const updateSectionLabel = (e, index) => {
        let newSections = sections;
        newSections[index].section = e.target.value;
        setSections([...newSections]);
    }

    const deleteSection = (e, index) => {
        e.preventDefault();
        let newSections = sections;
        newSections.splice(index, 1);
        setSections([...newSections]);
    }

    const onFileChange = (e) => {
        setdisabletemp2button(true);
        // let files = e.target.files;
        // let fileReader = new FileReader();
        // fileReader.readAsDataURL(files[0]);
 
        // fileReader.onload = (event) => {
        //     setSelectedImage(event.target.result);
        // }

        setSelectedImage(e.target.files);
        setUploadedImage('');

        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("file[]", e.target.files[i]);
        }

        // const formData = { image: selectedImage }
        let endpoint = "https://otayo.com/erpapi/newsletter-image-upload.php";
         axios.post(endpoint, formData, {
         }).then((res) => {
            console.log(res);
            setUploadedImage(res.data[0].url);
            console.log('File uploaded!');
            setdisabletemp2button(false);
        }). catch((err) => {
            console.log(err);
            setdisabletemp2button(false);
        });
    }

    const saveFile = (e) => {
        e.preventDefault();
        saveAs(
        "https://otayo.com/erpapi/newsletter.html",
        "newsletter.html"
        );
    };

    const updateCustomEvent = (value, index) => {
        let newCustEvents = customEvents;
        newCustEvents[index].name = value;
        newCustEvents[index].id = value;
        setcustomEvents([...newCustEvents]);
    }

    const updateCustomEventSort = (value, index) => {
        let newCustEvents = customEvents;
        newCustEvents[index].sort = value;
        setcustomEvents([...newCustEvents]);
    }

    const deleteCustomEvent = (e, index) => {
        e.preventDefault();
        let newCustEvents = customEvents;
        newCustEvents.splice(index, 1);
        setcustomEvents([...newCustEvents]);
    }

    const addNewCustomEvent = (e) => {
        e.preventDefault();
        let newCustomEvent = {id: "", name: "", sort: customEvents.length + 1};
        setcustomEvents([...customEvents, newCustomEvent]);
    }

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg text5e4642 mb-2">Otayo Newsletter</h1>
                        <div>
                            <button className="mr-4 font-bold text-red-700">{loading}</button>
                            <button className="btn btn-warning ml-4 bg-orange-500 hover:bg-orange-600 rounded-none font-semibold" onClick={(e) => window.location.reload()}>Reset</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col items-start w-1/3">
                                <h2 className="font-bold text-base mb-2 text5e4642">All Current Events</h2>                                
                                
                                <label className="font-bold mt-1 mb-1 text-sm">Select Featured Event</label>
                                <select className="form-control text-sm" value={eventGeneral} onChange={e => setEventGeneral(e.target.value)}>
                                    <option value="">Select Event</option>
                                    {events.length>0 && events.map((event, index) => (
                                        <option key={index} value={event.event_id}>{event.event_title}</option>
                                    ))}
                                 </select>   
                                <hr />
                                <label className="font-bold mt-4 mb-3 text-center">Create Sections</label>
                                 <div className="sections-wrapper  mt-1">
                                 {sections.length>0 && sections.map((section, index) => (
                                    <div className="flex flex-col justify-between" style={{border: "1px solid grey", padding:"15px"}} key={index}>
                                        <div className="font-bold flex mb-2 items-start justify-between text-sm"><span>Section Label:</span> <input value={section.section} onChange={e => updateSectionLabel(e, index)} className="form-control w-60" /> <button className="btn btn-sm btn-danger text-sm" onClick={e => deleteSection(e, index)}>X</button></div>
                                        {section.events.length>0 && section.events.map((event, eventindex) => (
                                            <div key={eventindex} className="flex justify-center items-center mb-2">
                                                <div><select className="form-control text-sm" value={event.id} onChange={e => updateSectionEvent(e.target.value, index, eventindex)}>
                                                    <option value="">Select Event</option>
                                                    {events.length>0 && events.map((event, index) => (
                                                        <option key={index} value={event.event_id}>{event.event_title}</option>
                                                    ))}
                                                </select></div>
                                                <div className="ml-2 mr-2"><input type='number' value={event.sort} onChange={e => updateEventSort(e, index, eventindex)} className="form-control text-sm" /></div>
                                                <div className=""><button className="btn btn-danger btn-sm text-sm" onClick={e=>deleteEvent(e, index, eventindex)}>Delete</button></div>
                                            </div>
                                        ))}
                                         <div className="btn btn-sm btn-success mb-3 mt-2 float-right text-right text-sm" onClick={e => addNewEvent(e, index)}>Add new event</div>
                                    </div>
                                 ))}
                                </div>
                                <div className="btn btn-sm btn-success mb-3 mt-2 text-sm" onClick={e => addNewSection(e)}>Add new section</div>
                                
                                <button className="btn btn-primary text-sm" onClick={e => getAllCurrent(e)}>Use This Template</button>
                            </div>    

                            <div className="flex flex-col items-start w-1/3">
                                <h2 className="font-bold text-lg text5e4642 mb-2">Custom Events</h2> 
                                <label className="mt-2 font-semibold text-sm">Header Text</label>
                                <input type="text" className="form-control text-sm mt-1 mb-4" placeholder="Enter heading text here..." value={Template2Text} onChange={e => setTemplate2Text(e.target.value)} />
                                <label className="mt-2 font-semibold text-sm">Select Header Image</label>
                                <input type="file" className="form-control text-sm" name="image" onChange={e => onFileChange(e)} />       
                                <label className="mt-2 font-semibold text-sm">Header Image Link</label>
                                <input type="text" placeholder="Header Image Link" className="form-control text-sm" value={headerlink} onChange={e => setheaderlink(e.target.value)} />          

                                <div className="flex flex-col mt-3 border p-2 w-full">
                                    <label className="mb-1 font-semibold">Select Events</label>
                                    <div className="flex flex-wrap">
                                        {customEvents.length>0 && customEvents.map((event, index) => (
                                            <div className="flex flex-row justify-center" key={index}>
                                                <select className="form-control text-sm" value={event.id} onChange={e => updateCustomEvent(e.target.value, index)}>
                                                    <option value="">Select Event</option>
                                                    {events.length>0 && events.map((event, index) => (
                                                        <option key={index} value={event.event_id}>{event.event_title}</option>
                                                    ))}
                                                </select>
                                                <div className="ml-2 mr-2"><input type='number' value={event.sort} onChange={e => updateCustomEventSort(e.target.value, index)} className="form-control text-sm" /></div>
                                                <div className=""><button className="btn btn-danger btn-sm text-sm" onClick={e=>deleteCustomEvent(e, index)}>Delete</button></div>
                                            </div>
                                        ))}
                                        <div className="btn btn-sm btn-success mb-3 mt-2 float-right text-right text-sm" style={{width:"100%"}} onClick={e => addNewCustomEvent(e)}>Add new event</div>
                                    </div>

                                </div>               
                                <button className="btn btn-primary mt-4 text-sm" disabled={disabletemp2button} onClick={e => getAllCurrentMonth(e)}>Use This Template</button>
                            </div>    

                            <div className="flex flex-col items-start">
                                <h2 className="font-bold text-lg text5e4642 mb-2">Single Event</h2>                                
                                <label className="font-bold mt-1 mb-1 text-sm">Select Event</label>
                                <select className="form-control text-sm" value={eventSingle} onChange={e => setEventSingle(e.target.value)}>
                                    <option value="">Select Event</option>
                                    {events.length>0 && events.map((event, index) => (
                                        <option key={index} value={event.event_id}>{event.event_title}</option>
                                        ))}
                                 </select>   
                                <button className="btn btn-primary mt-4 text-sm" disabled={eventSingle==''} onClick={e => getSingleEvent(e)}>Use This Template</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <hr />
                        <div className="flex justify-between mt-4 align-middle" style={{width: '100%'}}>
                            <h2 className="mt-4 text-center font-bold text-base text5e4642">Preview <span className="ml-4 font-bold text-red-600">{loading}</span></h2> <a target="_blank" href={'https://otayo.com/erpapi/newsletter.html'} download={'https://otayo.com/erpapi/newsletter.html'} className="btn btn-sm btn-warning ml-4 text-sm" disabled={disabledDownload}>View in new tab</a>
                            <button className="btn btn-sm btn-primary ml-4 text-sm" onClick={e => handleClick(e)}>Copy Code</button>
                        </div>
                        
                        {/* react display raw html */}
                        <div className="mt-4 p-10" style={{backgroundColor: "#ebebeb"}} dangerouslySetInnerHTML={{__html: previewdata}}></div>

                        <div className="mt-4 p-10" ref={ref}>
                            <label className="font-bold mb-2">Copy HTML code</label>
                            <textarea className="form-control h-96" value={previewdata} readOnly></textarea>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Newsletter;