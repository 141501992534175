import { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

import ProducersService from "../../services/producers.service";
import { Divider, Drawer } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const AddProducer = (props) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [rerender, setRerender] = useState(false);
    
    const [isopen, setIsopen] = useState(false);

    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

    const handleRightDrawer = () => {
        setRightDrawerOpen(!rightDrawerOpen);
      };
    
   
    const openPopup = (e) => {
        setIsopen(true);
    }

    const addProducer = (data) => {
        console.log(data)
        ProducersService.addProducer(data).then((response) => {
            console.log(response.data);
            if(response.data.status == "success") {
                swal("Success!", response.data.message, "success");
                setIsopen(false);
                setRerender(!rerender);
                props.onChange();
            } else {
                swal("Error!", response.data.message, "error");
            }
        });
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <>
            <div className="d-none d-lg-block">
            <button type="button" className="btn btn-success w-500 bg-green-700 text-white" style={{width:"200px"}} onClick={e => openPopup(e)}>Add New Producer</button>
            <Modal
                open={isopen}
                onClose={e => {setIsopen(false)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={handleSubmit(addProducer)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add New Producer
                        </div>
                    </div>
                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="chosenitems" className="col-4 col-form-label"><b>Company (Producer) Name:</b></label>
                        <div className="col-8">
                            <input className="form-control" type={"text"} {...register("companyProducerName", { required: true })} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="handlingAgentName" className="col-4 col-form-label"><b>Producer Email (?):</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"email"} {...register("producerEmail", { required: true })} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="receivedby" className="col-4 col-form-label"><b>Account Password</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"text"} {...register("accountPassword", { required: true })} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="linktocomplaint" className="col-4 col-form-label"><b>Contact Person</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"text"} {...register("contactPerson")} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="customermobilenumber" className="col-4 col-form-label"><b>Contact Number</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"number"} {...register("contactNumber")} />
                        </div>
                    </div>   
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="complaintSummary" className="col-4 col-form-label"><b>Company BRN</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"number"} {...register("brn")} />
                        </div>
                    </div>

                     <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="complaintSummary" className="col-4 col-form-label"><b>Company VAT</b></label>
                        <div className="col-8"> 
                            <input className="form-control" type={"number"} {...register("vat")} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <input className="btn btn-succes bg-green-700 text-white" type="submit" value={"Submit"} /><button type="button" className="btn btn-sm btn-danger bg-red-700" onClick={e => setIsopen(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </div>

            
            <div className="d-lg-none">
            <button className="bg-orange-500 text-white font-semibold text-sm px-2 py-1 rounded-sm" onClick={handleRightDrawer}>Add New Producer</button>

            <Drawer anchor="right" open={rightDrawerOpen} onClose={handleRightDrawer} >
               <div className="d-flex flex-col" style={{ width : '100vw'}}>
                 <div className="d-flex justify-content-between align-items-center pt-3 p-3">
                  <button onClick={() => handleRightDrawer()}><ArrowBackIcon style={{fontSize : '25px'}}/></button>
                  <p className='text-sm text-secondary'>Form to Add New Producer</p>
                 </div>

                  <form onSubmit={handleSubmit(addProducer)} style={{textAlign: "left"}} className='px-4 d-flex flex-col justify-content-evenly'>

                  <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Company (Producer) Name:</b></label>
                        <div>
                            <input className="form-control" type="text" {...register("companyProducerName", { required: true })} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Producer Email (?):</b></label>
                        <div>
                            <input className="form-control" type="email" {...register("producerEmail", { required: true })} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Account Password</b></label>
                        <div>
                            <input className="form-control" type="text" {...register("accountPassword", { required: true })} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Contact Person</b></label>
                        <div>
                            <input className="form-control" type="text" {...register("contactPerson")} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Contact Number</b></label>
                        <div>
                            <input className="form-control" type="number" {...register("contactNumber")} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Company BRN</b></label>
                        <div>
                            <input className="form-control" type="number" {...register("brn")} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label text-sm"><b>Company VAT</b></label>
                        <div>
                            <input className="form-control" type="number" {...register("vat")} />
                        </div>
                    </div>

                    <button className='bg-orange-500 text-white font-semibold px-4 py-1 mt-3 rounded-sm'>
                        Submit
                    </button>
                  </form>
                </div>                
            </Drawer>
            </div>
            
        </>
        );
}

export default AddProducer;