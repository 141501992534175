import React from 'react'
import { useState } from 'react';
import { Divider ,Drawer } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const POSCollectionReport = ({row}) => {

    const [DrawerOpen, setDrawerOpen] = useState(false);

    const handleDrawer = () => {
        setDrawerOpen(!DrawerOpen)
    }

    console.log(row)
  return (
    <div className="border rounded-sm shadow-sm mb-3">
        <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-2">
         <p className="font-semibold text-nowrap">ID : {row.id} </p>
         <p className="text-secondary text-xs">{row.date_collected}</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-2">
         <p className="text-secondary text-nowrap">
            {row.status!='Submitted' && 
            <b className="text-orange-600">In-progress</b>
            }
            {row.status=='Submitted' && 
            <b className="text-green-600">Submitted</b>
            } 
        </p>
         <button onClick={() => handleDrawer()} className="font-medium text-orange-500">See more</button>
        </div>

        <Drawer anchor='bottom' open={DrawerOpen} onClose={handleDrawer}> 
        <div style={{ padding: 16 }}>
            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Date Collected :</p>
            <p className="font-semibold ">{row.date_collected}</p>
            </div>
            <Divider />
            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Date Created :</p>
            <p className="font-semibold ">{row.created_on}</p>
            </div>
            <Divider />
            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Order Period :</p>
            <p className="font-semibold text-right">{row.order_period}</p>
            </div>
            <Divider />
            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Total No of Tickets :</p>
            <p className="font-semibold ">{row.total_tickets==null ? row.total_tickets : row.total_tickets.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Total Order Amount :</p>
            <p className="font-semibold ">{row.order_amount==null ? row.order_amount : row.order_amount.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Total Cash :</p>
            <p className="font-semibold">Rs {row.order_cash==null ? row.order_cash : row.order_cash.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Cash Collected :</p>
            <p className="font-semibold">Rs {row.cash_collected==null ? row.cash_collected : row.cash_collected.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Credit Card Collected :</p>
            <p className="font-semibold">{row.credit_card_collected==null ? row.credit_card_collected : row.credit_card_collected.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Total Credit Card :</p>
            <p className="font-semibold">{row.order_card==null ? row.order_card : row.order_card.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Incentive Given :</p>
            <p className="font-semibold">{row.incentive_given==null ? row.incentive_given : row.incentive_given.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Net Amount Collected :</p>
            <p className="font-semibold">Rs {row.net_amount_collected==null ? row.net_amount_collected : row.net_amount_collected.toLocaleString('en-US')}</p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between align-items-center px-3 py-1">
            <p className="text-secondary text-nowrap">Comments :</p>
            <p className="font-semibold text-right">{row.comments}</p>
            </div>
            <Divider />
        </div>
        </Drawer>

    </div>

  )
}

export default POSCollectionReport