import axios from "axios";

const APIURL = "https://otayo.com/erpapi/pos.php";

class PosService {

    createPOS (posName, posEmail, posEmail1, posEmail2, posLocation, posOperatingHoursDays, posPhones, posStatus, posComments, edit=0) {

        var action = 'createPOS';
        if(edit>0) {
            action = 'editPOS';
        }

        var params = {
            action: action,
            posName: posName,
            posEmail: posEmail,
            posEmail1: posEmail1,
            posEmail2: posEmail2,
            posLocation: posLocation,
            posOperatingHoursDays: posOperatingHoursDays,
            posPhones: posPhones,
            posStatus: posStatus,
            posComments: posComments,
            id: edit
        }
        console.log({params});
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getAllPOS () {
        var params = {
            action: "getAllPOS"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getSinglePOS(id) {
        var params = {
            action: "getSinglePOS",
            id: id
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    updateMeta(id, key, value) {
        var params = {
            action: "updatePosMeta",
            id: id,
            key: key,
            value: value
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

}

export default new PosService();