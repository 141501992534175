import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";


import AuthService from "../services/auth.service";

import "../styles/Posmain.css"


const APIURL = "https://otayo.com/erpapi/vsp.php";

const VSPArchive = () => {

    const [data, setData] = useState([]);
    const [loading, setloading] = useState('Loading...');
   

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                getData();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const getData = () => {
         var params = {
            action: 'vspDashboardArchive',
            type: 'current',
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("🚀 ~ file: vspMain.js:61 ~ getData ~ response", response.data)
            setData(response.data);
            setloading(false);
        })
        .catch(err => console.log({err}));
    }

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg text5e4642 mb-2">On-site Services Overview <small className="text-red-600">{loading}</small></h1>
                        <div>
                        <a href="/vsp-calendar" className="btn btn-success btn-green ml-4 font-semibold text-sm">Calendar View</a>
                        <a href="/vsp-staff" className="btn btn-warning btn-orange ml-4 font-semibold text-sm">On-site Staff List</a>
                        <a href="/vsp" className="btn btn-warning btn-yellow ml-4 font-semibold text-sm">On-site Current Events</a>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <table className="table">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Event Name</th>
                                    <th scope="col">Ticket Control</th>
                                    <th scope="col" className="text-center">VSP</th>
                                    <th scope="col" className="text-center">Hostesses</th>
                                    <th scope="col" className="text-center">Transportation</th>
                                    <th scope="col" className="text-center">Total Cost</th>
                                    <th scope="col" className="text-center">Payable by Producer</th>
                                    <th scope="col" className="text-center">Status</th>
                                    <th scope="col" className="text-center">Allocated Staff</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length>0 && data.map((row, index) => (
                                    <tr key={index} className=" text-sm">
                                        <td>{row.eventdate}</td>
                                        <td><a className="text-orange-600 hover:text-orange-400 underline font-semibold" href={"/event/"+row.id}>{row.eventname}</a></td>
                                        <td className="text-center">
                                            <>
                                                {row.service_ticket_control=='Yes' && 
                                                <>
                                                    <span className="text-blue-600 font-semibold">{row.service_ticket_control}</span><br />
                                                    <span>{row.service_ticket_control_pax} pax</span><br />
                                                    <span>{row.service_ticket_control_hours}</span><br />
                                                </>
                                                }
                                                {row.service_ticket_control=='No' && 
                                                <>
                                                    <span className="text-red-600 font-semibold">{row.service_ticket_control}</span>
                                                </>
                                                }
                                            </>
                                        </td>
                                        <td className="text-center">
                                            <>
                                                {row.service_vsp=='Yes' && 
                                                <>
                                                    <span className="text-blue-600 font-semibold">{row.service_vsp}</span><br />
                                                    <span>{row.service_vsp_pax} pax</span><br />
                                                    <span>{row.service_vsp_hours}</span><br />
                                                </>
                                                }
                                                {row.service_vsp=='No' && 
                                                <>
                                                    <span className="text-red-600 font-semibold">{row.service_vsp}</span>
                                                </>
                                                }
                                            </>
                                        </td>
                                        <td className="text-center">
                                            <>
                                                {row.service_hostessess=='Yes' && 
                                                <>
                                                    <span className="text-blue-600 font-semibold">{row.service_hostessess}</span><br />
                                                    <span>{row.service_hostessess_pax} pax</span><br />
                                                    <span>{row.service_hostessess_hours}</span><br />
                                                </>
                                                }
                                                {row.service_hostessess=='No' && 
                                                <>
                                                    <span className="text-red-600 font-semibold">{row.service_hostessess}</span>
                                                </>
                                                }
                                            </>
                                        </td>
                                        <td className="text-center">
                                            <>
                                                {row.service_transportation=='Yes' && 
                                                <>
                                                    <span className="font-semibold">Vehicle Details:</span> {row.service_transportation_vehicle}<br />
                                                    <span className="font-semibold">Number of Vehicles:</span> {row.service_transportation_number}<br />
                                                    <span className="font-semibold">Transport Cost:</span> Rs {row.service_transportation_cost}<br />
                                                </>
                                                }
                                                {row.service_transportation=='No' && 
                                                <>
                                                    <span className="text-red-600 font-semibold">{row.service_transportation}</span>
                                                </>
                                                }
                                            </>
                                        </td>
                                        <td className="text-center">
                                            <>
                                                <span className="font-semibold">Ticket Control:</span> Rs {row.service_ticket_control_cost}<br />
                                                <span className="font-semibold">VSP Cost:</span> Rs {row.service_vsp_cost}<br />
                                                <span className="font-semibold">Hostesses Cost:</span> Rs {row.service_hostessess_cost}<br />
                                                <span className="font-semibold">Transport Cost:</span> Rs {row.service_transportation_cost}<br />
                                                <hr />
                                                <span className="text-orange-500 font-semibold">Grand Total: Rs {row.totalcost}</span>
                                            </>
                                        </td>
                                        <td className="text-center">
                                            <>
                                                <span className="font-semibold">Ticket Control:</span> Rs {row.service_ticket_control_producerpay}<br />
                                                <span className="font-semibold">VSP Cost:</span> Rs {row.service_vsp_producerpay}<br />
                                                <span className="font-semibold">Hostesses Cost:</span> Rs {row.service_hostessess_producerpay}<br />
                                                <span className="font-semibold">Transport Cost:</span> Rs {row.service_transportation_producerpay}<br />
                                                <hr />
                                                <span className="text-orange-500 font-semibold">Grand Total: Rs {row.totalcost_producer}</span>
                                            </>
                                        </td>
                                        <td className="text-center">
                                            {row.service_ticket_control=='No' && row.service_vsp=='No' && row.service_hostessess=='No' &&
                                                <span className="text-yellow-600 font-semibold">Not Required</span>
                                            }

                                            {(row.service_ticket_control!='No' || row.service_vsp!='No' || row.service_hostessess!='No') && row.allocated.length==0 &&
                                                <span className="text-red-600 font-semibold">Pending</span>
                                            }

                                            {(row.service_ticket_control!='No' || row.service_vsp!='No' || row.service_hostessess!='No') && row.allocated.length>0 &&
                                                <span className="text-green-600 font-semibold">Allocated</span>
                                            }
                                        </td>
                                        <td className="text-center">
                                            {row.allocated.length>0 && row.allocated.map((lot, index) => (
                                                <>
                                                    <span key={index}>{lot.type}:<span> {lot.staffname}</span></span><br />
                                                </>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default VSPArchive;