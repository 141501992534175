import React, { useState } from 'react';
import { Divider, Drawer } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LinkIcon from '@mui/icons-material/Link';

const ProducerListCard = ({ data }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const {
    company_name,
    main_contact,
    contact_number,
    email,
    password,
    brn,
    vat,
    events,
    id
  } = data;

  return (
    <div className='border rounded-sm shadow-sm'>
      <div className='d-flex justify-content-between align-items-center px-3 py-2'>
        <p className='font-semibold text-base'>{company_name}</p>
        {/* <p>
          <button onClick={handleDrawerOpen}><MoreHorizIcon style={{ color : '#ea580c'}} /></button>
        </p> */}
      </div>

      <Divider />

        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary text-nowrap'>Producer ID :</p>
            <p className='font-medium'>{id}</p>
        </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Email :</p>
          <p className='font-medium'>{email}</p>
      </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Password :</p>
          <p className='line-clamp-1 text-xs font-medium'>{password}</p>
      </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Contact Person :</p>
          <p className='line-clamp-1 text-right font-medium'>{main_contact}</p>
      </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' >
          <p className='text-secondary text-nowrap'>Contact Number :</p>
          <p className='font-medium'>{contact_number}</p>
      </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>BRN :</p>
          <p className='font-medium'>{brn}</p>
      </div>  

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>VAT :</p>
          <p className='font-medium'>{vat}</p>
      </div>  

      <Divider/>

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Event Name :</p>

          <div className='d-flex flex-wrap justify-content-end text-right'>
          {events.map ( event => (
           <a href={`/event/${event.id}`} className='text-sm underline text-orange-500 font-medium
            '>
           {Array.isArray(event.name) ? event.name.join(', ') : event.name}
           </a>
          ))}
          </div>

      </div>  

      <Divider />

      <div className='d-flex justify-content-end px-3 py-2'>
       {/* <button 
       onClick={() => handleDrawer()}
       className='text-xs rounded-lg px-2 text-orange-600 py-1 flex-nowrap' style={{border : '1px solid #ea580c'}}>Link to an event</button> */}
        <a href={`/edit-producer/${id}`} 
         className='text-sm bg-orange-500 px-2 py-1 text-white rounded-lg d-flex align-items-center gap-1'>
         View / Edit Producer
         <ArrowOutwardIcon style={{fontSize : 20}}/>
        </a>
      </div>

      <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
        <div className='' style={{ padding: '15px 20px' }}>
          <p>Choose Event</p>

          <select>Event Name</select>
        </div>
      </Drawer>
    </div>
  );
}

export default ProducerListCard;