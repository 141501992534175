import React from "react";
import axios from "axios";
import swal from "sweetalert";

import EventsService from "../services/events.service";

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: "",
      responseArray: [],
      event: props.event,
      metakey: props.metakey
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      selectedFile: event.target.files,
      responseArray:[]
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.state.selectedFile) {
      alert("Please select a file!");
      return false;
    }
    const data = new FormData();

    for (let i = 0; i < this.state.selectedFile.length; i++) {
      data.append("file[]", this.state.selectedFile[i]);
    }

    let url = "https://otayo.com/erpapi/fileuploader.php";

    axios
      .post(url, data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.log(res);
        this.setState({ responseArray: res.data });
        this.resetFile();
        if(!res.data[0].error) {
          if(this.state.event!='0') {
            EventsService.updateEventMeta(this.state.event, this.state.metakey, res.data[0].url)
            .then(response=>{
                console.log(response);
                swal("Success", "File uploaded successfully", "success");
                // this.props.onChange(res.data[0].url);
            })
            .catch(error =>{
                alert(error);
            });
          } 
        } else {
          this.props.onChange(res.data[0].url);
        }
        
        if(res.data.length>0 && typeof res.data[0].url!='undefined') {
          this.props.onChange(res.data[0].url);
        }
      });
  }

  resetFile() {
    // Reset file input control
    document.getElementsByName("file")[0].value = null;
  }

  render() {
    return (
      <div className="flex flex-col">
        <input
        type="file"
        className="form-control mb-3"
        multiple
        name="file"
        onChange={this.handleInputChange}
        />
    
        <button
        type="button"
        className="btn btn-success bg-green-700 rounded-none hover:bg-green-800"
        onClick={(e) => this.onSubmit(e)}
        >
        Upload
        </button>  
      </div>
    );
  }
}

export default FileUploader;