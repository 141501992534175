import {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid'

import ProducersService from '../services/producers.service';
import EventsService from '../services/events.service';

import "../styles/Producers.css";

import AddProducer from '../components/producers/AddProducer';
import LinkToEvent from '../components/LinkToEvent.component';
import { Pagination, Typography } from '@mui/material';


import ProducerListCard from '../components/Cards/ProducerListCard';

import SearchIcon from '@mui/icons-material/Search';
import Spinner from '../components/LoadingState/Spinner';



const ProducerList = () => {

    const [allevents, setAllevents] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [prodId, setProdId] = useState(0);
    const [prodEmail, setProdEmail] = useState('');
    const [data, setdata] = useState([]);
    const [count, setcount] = useState(0);
    const [search, setsearch] = useState('');
    const [limit, setlimit] = useState(20);
    const [page, setpage] = useState(1);
    const [dataloader, setdataloader] = useState(true);
    const [columnsheadings, setColumnsheadings] = useState([
        { field: 'id', headerName: 'Producer ID', flex: 1, 
        renderCell: (cellValues) => {
                return (
                    <div className="color-orange break-words font-bold">#{cellValues.row.id}</div>
                )
            },
        },
        { field: 'company_name', headerName: 'Company Name', flex: 2, cellClassName: 'flexcenter' , renderCell: (cellValues) => {
            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left", color: "#01bff1", textDecoration: "underline"}}>
                    {cellValues.row.company_name}
                </div>
                )
            }
        },
        { field: 'email', headerName: 'Producer Email', flex: 2, cellClassName: 'flexcenter', renderCell: (cellValues) => {
            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left", color: "#aeaeae"}}>
                    {cellValues.row.email}
                </div>
                )
            }
        },
        { field: 'password', headerName: 'Account Password', flex: 1 },
        { field: 'comapny_details', headerName: 'Company Details', flex: 1, cellClassName: 'flexcenter',  
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center", padding:"10px", color: "#aeaeae"}}>
                    <div>BRN: {cellValues.row.brn}</div>
                    <div>VAT: {cellValues.row.vat}</div>
                </div>
                )
            }
        },
        { field: 'main_contact', headerName: 'Main Contact Person', flex: 1, cellClassName: 'flexcenter' },
        { field: 'contact_number', headerName: 'Contact Number', flex: 1, cellClassName: 'flexcenter', renderCell: (cellValues) => {
            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'start', width: '100%', alignItems: "center", padding:"10px", textAlign: "left", color: "#aeaeae"}}>
                    {cellValues.row.contact_number}
                </div>
                )
            }
        },
        { field: 'events', headerName: 'Event Names', flex: 2 ,  
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "start", padding:"10px", overflowWrap: 'break-word'}}>
                    {cellValues.row.events.length>0 && cellValues.row.events.map((event, index) => {
                        return (
                            <p style={{overflowWrap: "break-word"}}><a key={index} href={"/event/"+event.id} className="text01bff1 break-words text-left underline">{event.name}</a></p>
                        )
                    }) }
                </div>
                )
            }
        },
        { field: 'actions', headerName: 'Actions', flex: 1, cellClassName: 'flexcenter',  
            renderCell: (cellValues) => {
                return (
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "start", padding:"10px"}}>
                    <button type="button" className="btn btn-sm btn-primary mb-2 textf36523 underline p-1 border-none text-sm text-left" onClick={(e) => OpenLinkingPanel(cellValues.row.id, cellValues.row.email)}>Link To Event</button>
                    <a className=' underline p-1 border-none text-sm text-left bg-none' style={{color: "#4b4746", backgroundColor:"transparent !important"}} href={"/edit-producer/"+cellValues.row.id}>View/Edit Producer</a>
                </div>
                )
            }
        },
    ]);

    useEffect(() => {
        ProducersService.getAllProducers(search, 0, limit, 0).then((response) => {
            console.log(response.data);
            setcount(response.data);
            setTimeout(() => {
                searchClick();
            }, 1000);
        });

        if(allevents.length==0) {
            EventsService.getAllEvents().then((response) => {
                console.log(response.data);
                setAllevents(response.data);
            });
        }

    }, []);

    useEffect(() => {
        searchClick();
    }, [page]);

    useEffect(() => {
        if(count>0) {
            // searchClick();
        }
    }, [count]);

    const OpenLinkingPanel = (prodid, prodemail) => {
        setIsOpen(true);
        setProdId(prodid);
        setProdEmail(prodemail);
        console.log("open linking")
    }

    const doneLinking = () => {
        setIsOpen(false);
        searchClick();
        setProdId(0);
        setProdEmail('');
        console.log("done linking")
    }
    

    const onFilterChange = (e) => {
        searchClick();
    };

    useEffect(() => {
    const keyDownHandler = event => {
      console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        searchClick();
      } else if(search.length==0) {
        searchClick();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [search]);

    const searchClick = () => {
        setdataloader(true);
        setTimeout(() => {
            ProducersService.getAllProducers(search, page, limit, 1).then((response) => {
                console.log(response.data);
                setdata(response.data);
                setdataloader(false);
            });
        }, 1000);
        
    };

    const handleSortModelChange = (e) => {
        searchClick();
    };

    const onChangeProducersRerender = () => {
        searchClick();
    };

    const setsearchAndReload = () => {
        // setsearch(value);
        // if(search.length>2) {
            setdata(0)
            setdataloader(true);
            ProducersService.getAllProducers(search, 0, limit, 0).then((response) => {
                console.log(response.data);
                setcount(response.data);
                searchClick();
            });
        // }
    }

    const currentPage = (value) => {
        setdata(0)
        searchClick(setpage(value));
      }


    return (
        <>
        <div className='d-none d-lg-block p-2'>
         {isOpen && 
            <LinkToEvent prodid={prodId} isOpen={isOpen} prodemail={prodEmail} events={allevents} doneLinking={doneLinking} />
         }
         <div className='prodlist-container p-4 shadow-lg'>
            <div className='row mb-4 flex justify-start items-center'>
                <div className="col-md-12 flex justify-between">
                    <div className="">
                        <div className="title font-bold mb-3 h1 text-lg text5e4944">Producers List</div>
                    </div>
                    <div className="flex items-center">
                        <div className='mr-4 flex '>
                            <input type="text" className="form-control rounded-none" value={search} placeholder="Search Producers" onChange={(e) => setsearch(e.target.value)} />
                            <button className='btn btn-primary btn-sm bg-blue-700 rounded-none text-white'  onClick={(e) => {setsearchAndReload()}}>Search</button>
                        </div>
                        <AddProducer onChange={e => onChangeProducersRerender()} />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <DataGrid
                        rows={data}
                        columns={columnsheadings}
                        pageSize={limit}
                        rowsPerPageOptions={[10, 20, 50]} 
                        autoHeight={true}
                        // rowHeight={100}
                        getEstimatedRowHeight={() => 200}
                        getRowHeight={() => 'auto'}
                        pagination
                        rowCount={count}
                        paginationMode="server"
                        filterMode="server"
                        onFilterModelChange={onFilterChange}
                        loading={dataloader}
                        onPageChange={async (newdata) => {
                            console.log({newdata});
                            await setpage(newdata+1);
                            // searchClick();
                        }}
                        page={page - 1}
                        onPageSizeChange={(newPage) => setpage(newPage)} 
                        sortingMode="server" 
                        onSortModelChange={handleSortModelChange}
                    />
                </div>
            </div>
         </div>
        </div>

        <div className='d-lg-none' style={{padding : '0px 10px 0px 10px'}}>
            <div className='d-flex align-items-baseline justify-content-between pb-1'>
                <p className='' style={{fontWeight : "700" ,color : "#aaaaaa"}}>Producer List</p>

                <AddProducer onChange={e => onChangeProducersRerender()} />
                {/* <LinkToEvent prodid={prodId} isOpen={isOpen} prodemail={prodEmail} events={allevents} doneLinking={doneLinking} /> */}

            </div>

            <div className='flex py-1 gap-2'>
             <input type="text" className="form-control rounded-sm text-sm py-2" value={search} placeholder="Search Producers" onChange={(e) => setsearch(e.target.value)} />
             <button className='bg-orange-500 rounded-sm text-white px-2 text-sm font-semibold'  onClick={(e) => {setsearchAndReload()}}><SearchIcon/></button>
            </div>

            <div className='d-flex flex-col gap-3 my-2'>
                {data.length > 0 ? data.map( ( data , index ) => (
                <ProducerListCard data={data}/>
                    ))
                : <div className="d-flex justify-content-center align-items-center"><Spinner/></div>
                }
            </div>

          

            <div className="d-flex flex-col align-items-center gap-2 justify-content-center pt-3">
            <Pagination
             hidePrevButton hideNextButton
             shape="rounded" 
             siblingCount={1}
             count={count}
             defaultPage={1}
             page={page} 
             onChange={(event , value) => currentPage(value)} />

             <p className="text-xs text-secondary">{page} of {count}</p>
            </div>



        </div>
        </>


    );
}

export default ProducerList;