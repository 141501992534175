import moment from 'moment';
import axios from "axios";

class UtilityService {
    format_date(d) {
        var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +d.getFullYear();
        return datestring;
    }

    format_date_time(d) {
        var datestring = moment(d).format('YYYY-MM-DD HH:mm:ss');
        // var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +d.getFullYear();
        return datestring;
    }

    makeDate(str) {
        if(str=='' || str=="N/A") {
            return null;
        }
        var parts = str.split('-');
        var mydate = new Date(parts[2], parts[1] - 1, parts[0]); 
        return mydate;
    }

    capitalize = (str, lower = false) => {
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    };

    parse_pos_collection_date(d) {
        var split = d.split('--');
        var date1 = new Date(split[0]);
        var date2 = new Date(split[1]); 

        var datestring = ("0" + date1.getDate()).slice(-2) + "/" + ("0"+(date1.getMonth()+1)).slice(-2) + "/" +date1.getFullYear()+" - "+("0" + date2.getDate()).slice(-2) + "/" + ("0"+(date2.getMonth()+1)).slice(-2) + "/" +date2.getFullYear();

        if(date1>date2) {
            datestring = ("0" + date2.getDate()).slice(-2) + "/" + ("0"+(date2.getMonth()+1)).slice(-2) + "/" +date2.getFullYear()+" - "+("0" + date1.getDate()).slice(-2) + "/" + ("0"+(date1.getMonth()+1)).slice(-2) + "/" +date1.getFullYear();
        }
        return datestring;
    }

}

export default new UtilityService();