import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import swal from "sweetalert";

import ProducersService from "../services/producers.service"
import AuthService from "../services/auth.service";
import "../styles/ProducerSingle.css"

import { Divider } from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


const SingleProducer = (props) => {
    const { id } = useParams();
    const [producerID, setProducerID] = useState(id);
    const [user, setuser] = useState(null);
    const [producerData, setProducerData] = useState(null);
    const [newProdContact, setNewProdContact] = useState({
        type: "",
        person: "",
        email: "",
        phone: "",
    });
    const [prodContacts, setProdContacts] = useState(null);
    const [prodStats, setProdStats] = useState(null);
    const [vat, setVat] = useState('');
    const [brn, setBrn] = useState('');
    const [mainContactPerson, setMainContactPerson] = useState('');
    const [mainContactNumber, setMainContactNumber] = useState('');
    const [mainEmail, setMainEmail] = useState('');

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                setuser(user);
                getProducerData();
                getProdContacts();
                getProdEventStats();
            }
        }).catch((err) => {
            console.log(err);
            // setIsLoggedIn(false);
            window.location.href='/login';
        });
        
    }, [id]);

    const getProducerData = () => {
        ProducersService.getProducerData(producerID)
        .then((response) => {
            console.log(response.data);
            setProducerData(response.data);
            setVat(response.data.vat);
            setBrn(response.data.brn);
            setMainContactPerson(response.data.main_contact);
            setMainContactNumber(response.data.contact_number);
            setMainEmail(response.data.email);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getProdContacts = () => {
        ProducersService.getProdContacts(producerID)
        .then((response) => {
            console.log(response.data);
            setProdContacts(response.data);
            if(response.data.length >1) {
                setNewProdContact({
                    type: "additional",
                    person: "",
                    email: "",
                    phone: "",
                });
            } else {
                setNewProdContact({
                    type: "secondary",
                    person: "",
                    email: "",
                    phone: "",
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getProdEventStats = () => {
        ProducersService.getProdEventStats(producerID)
        .then((response) => {
            console.log("setProdStats", response.data);
            setProdStats(response.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const addProducerContact = (e) => {
        ProducersService.addProducerContact(producerID, newProdContact, user.user_nicename)
        .then((response) => {
            console.log(response.data);
            
            swal("Success!", "Contact added successfully!", "success");
            getProdContacts();
            
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const deleteProdContact = (id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The contact will be deleted from the Producer!",
        icon: "warning",
        buttons: ["No, Keep the Contact!", "Yes, Remove Contact!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
           ProducersService.deleteProdContact(id)
            .then((response) => {
                console.log(response.data);
                swal("Success!", "Contact deleted successfully!", "success");
                getProdContacts();
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            swal("Contact Not Removed", "Your contact is safe!", "success");
        }
        });
    }

    const SaveProdAccountDetails = () => {
        ProducersService.saveProdAccountDetails(producerID, producerData.bank_account_name, producerData.bank_name, producerData.account_details, producerData.bank_supporting_details, user.user_nicename)
        .then((response) => {
            console.log(response.data);
            swal("Success!", "Account details updated successfully!", "success");
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updatePassword = (e) => {
        var newPassword = e.target.value;
        setProducerData({...producerData, password: newPassword});
        ProducersService.updatePassword(producerID, newPassword)
        .then((response) => {
            console.log(response.data);
            // swal("Success!", "Password updated successfully!", "success");
        })
        .catch((err) => {
            console.log(err);
        });

    }

    const SendUserLoginDetails = (e) => {
        swal({
        title: "Are you sure?",
        text: "Are you Sure you want to send the details to: " + producerData.email,
        icon: "warning",
        buttons: ["No!", "Yes,Send Details!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            ProducersService.sendUserDetails(producerID, producerData.email)
            .then((response) => {
                console.log(response.data);
                getProducerData();
                swal("Success!", "Login Details Sent To: " + producerData.email, "success");
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            swal("Not Sent", "Login Details not sent", "success");
        }
        });
    }

    const updateUserMeta = (e, key, value) => {
        // e.preventDefault();
        if(key == "vat") {
            setVat(value);
        } else if(key == "brn") {
            setBrn(value);
        }
        ProducersService.updateUserMeta(producerID, key, value)
        .then((response) => {
            console.log(response.data);
            // getProducerData();
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateMainContact = (e) => {

        ProducersService.updateMainContact(producerID, mainContactNumber, mainEmail, mainContactPerson)
        .then((response) => {
            console.log(response.data);
            getProducerData();
            swal("Success!", "Main Contact Updated Successfully!", "success");
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
 

        <>

        <div className="producer-single-wrapper p-2 d-none d-lg-block">
        <div className="producer-single p-6  shadow-lg">
            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="title font-bold text-xl mb-4 h1 text5e4642">{producerData!=null && producerData.company_name}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="section-heading font-bold text-base uppercase p-2 text-gray-500 mb-2">Producer Overview</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="product-overview-item">
                        <div className="title font-semibold text-sm">Contact Person:</div>
                        <div className="value"><input type="text" disabled={true} className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.main_contact}></input></div>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                    <div className="product-overview-item">
                        <div className="title font-semibold text-sm">Company BRN:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={brn} onChange={(e) => updateUserMeta(e, 'brn', e.target.value)} /></div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="product-overview-item">
                        <div className="title font-semibold text-sm">Producer Email:</div>
                        <div className="value"><input type="text" disabled={true} className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.email}></input></div>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                    <div className="product-overview-item">
                        <div className="title font-semibold text-sm">Company VAT Number:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={vat} onChange={e => updateUserMeta(e, 'vat', e.target.value)}></input></div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="product-overview-item">
                        <div className="title font-semibold text-sm">Account Password:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.password} onChange={e => updatePassword()}></input></div>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                    <button className="btn btn-primary bg-color-orange text-white font-semibold border-none w-100 text-sm rounded-none hover:bg-orange-700" type="button" onClick={(e) => SendUserLoginDetails(e)}>Send User Login Details >></button>
                </div>
            </div>

            <div className="row mt-3 mb-3">
                <div className="col-md-12">
                    <div className="">
                        <div className="section-heading font-bold  text-base uppercase p-2 text-gray-500 mb-2">Contact People</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="flex justify-between items-center mb-2 pb-2 border-b-2">
                        <div className="contact-heading font-semibold text-sm">Main Contact</div>
                    </div>
                    <div className="contact-heading font-semibold text-sm">Main Contact</div>
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Contact Person:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={mainContactPerson} onChange={e => setMainContactPerson(e.target.value)}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Contact Number:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={mainContactNumber} onChange={e => setMainContactNumber(e.target.value)}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Email Address:</div>
                        <div className="value"><input type="text" disabled className="form-control text-sm" value={producerData!=null && producerData.email}></input></div>
                    </div>
                    <div className="contact-item">
                        <button className="btn btn-sm btn-warning bg-orange-800 hover:bg-orange-600 text-white text-sm rounded-none" onClick={(e) => updateMainContact(e)}>Update Main Contact</button>
                    </div>
                </div>
                {prodContacts!=null && prodContacts.map((contact, index) => { 
                    return (
                        <div className="col-md-6" key={index}>
                            <div className="flex justify-between items-center mb-2 pb-2 border-b-2">
                                <div className="contact-heading font-semibold text-sm">{contact.type=="secondary" ? "Secondary Contact":"Additional Contact"}</div>
                                <button className="btn btn-sm btn-danger bg-red-700 hover:bg-red-600 text-sm" onClick={e => deleteProdContact(contact.id)}>Delete Contact</button>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Contact Person:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.person}></input></div>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Contact Number:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.phone}></input></div>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Email Address:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.email}></input></div>
                            </div>
                        </div>
                    )
                })
                }
                <div className="col-md-6">
                    <div className="flex justify-between items-center mb-2 pb-2 border-b-2">
                        <div className="contact-heading font-semibold text-sm">{(prodContacts!=null && prodContacts.length<2) ? "Secondary Contact":"Additional Contact"}</div>
                    </div>
                    
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Contact Person:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={newProdContact.person} onChange={e => {setNewProdContact({...newProdContact, person: e.target.value})}}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Contact Number:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={newProdContact.phone} onChange={e => {setNewProdContact({...newProdContact, phone: e.target.value})}}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title  font-semibold text-sm">Email Address:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={newProdContact.email} onChange={e => setNewProdContact({...newProdContact, email: e.target.value})}></input></div>
                    </div>
                    <div className="flex justify-end">
                        <button className="btn btn-sm btn-success bg-green-800 hover:bg-green-600 text-sm" style={{backgroundColor: "#7cc576", border
                    :"none", borderRadius:"0px"}} onClick={(e) => addProducerContact(e)}>Add Contact</button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="section-heading font-bold  text-base uppercase p-2 text-gray-500 mb-2">Bank Details</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="contact-item">
                        <div className="title font-semibold text-sm">Account on the name of:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_account_name} onChange={e => setProducerData({...producerData, bank_account_name: e.target.value})}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title font-semibold text-sm">Bank Name:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_name} onChange={e => setProducerData({...producerData, bank_name: e.target.value})}></input></div>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                    <div className="contact-item">
                        <div className="title font-semibold text-sm">Bank Account number:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.account_details} onChange={e => setProducerData({...producerData, account_details: e.target.value})}></input></div>
                    </div>
                    <div className="contact-item">
                        <div className="title font-semibold text-sm">Other supporting details:</div>
                        <div className="value"><textarea type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_supporting_details} onChange={e => setProducerData({...producerData, bank_supporting_details: e.target.value})}></textarea></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 flex justify-end items-center text-sm"><button className="btn btn-success text-white bg-green-700 hover:bg-green-800 text-sm rounded-none" onClick={(e) => SaveProdAccountDetails()}>Save</button></div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="">
                        <div className="font-bold text-base p-2 text5e4642">Producer Event Records</div>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <table className="table table-responsive">
                        <thead className="table-head">
                            <tr>
                                <th scope="col">Event Date</th>
                                <th scope="col">Event Name</th>
                                <th scope="col" className="text-center uppercase">Total Number Tickets Sold</th>
                                <th scope="col" className="text-center uppercase">Total Sales Amount</th>
                                <th scope="col" className="text-center uppercase">Type of Collaboration</th>
                                <th scope="col" className="text-center uppercase">Comm Level</th>
                                <th scope="col" className="text-center uppercase">Onsite Services</th>
                                <th scope="col" className="text-center uppercase">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prodStats!=null && prodStats.map((stat, index) => {
                                return (
                                    <tr key={index} data-event-id={stat.id}>
                                        <td className=" text-sm">{stat.event_date}</td>
                                        <td><a className="color-blue hover:text-blue-300 underline font-normal text-sm" href={"/event/"+stat.id}>{stat.title}</a></td>
                                        <td className="text-center text-sm">{stat.sold}</td>
                                        <td className="text-center text-sm">Rs {stat.soldamount}</td>
                                        <td className="text-center text-sm textaeaeae">{stat.colab}</td>
                                        <td className="text-center text-sm">{stat.comlevel}</td>
                                        <td className="text-center text-sm textaeaeae">{stat.services}</td>
                                        <td className="flex flex-col">
                                            {/* {stat.bilan!="#" && stat.bilan!="" &&  */}
                                                <a href={stat.bilan} className="color-orange underline mb-1 text-center font-semibold hover:text-orange-300 text-sm">View Bilan</a>
                                            {/* } */}

                                            {/* {stat.bilan=="#" || stat.bilan=="" && 
                                                <a href="{stat.bilan}" className="color-blue mb-1 text-center font-semibold hover:text-blue-300">No Bilan</a>
                                            } */}

                                            {/* {stat.agreement!="#" && stat.agreement!="" &&  */}
                                                <a href={stat.agreement} className="color-blue underline text-center font-semibold hover:text-blue-400 text-sm">View Agreement</a>
                                            {/* } */}

                                            {/* {stat.agreement=="#" || stat.agreement=="" && 
                                                <a href="{stat.agreement}" className="color-orange underline text-center font-semibold hover:text-orange-400">No Agreement</a>
                                            } */}

                                            
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>


        <div className="d-lg-none" style={{padding : '0px 10px'}}>            
            <div className="d-flex justify-content-between align-items-center border-b fixed top-0 left-0 z-20 w-100 bg-white px-3 py-3">
                <div onClick={() =>  window.history.back()}>
                <ArrowBackOutlinedIcon/>
                </div>

                <p className="font-semibold line-clamp-1 text-sm mt-1">{producerData!=null && producerData.company_name}</p>
                
                <div>
                    

                </div>
            </div>

            <div className="px-3 py-2 border rounded-sm shadow-sm">
                <p className='font-semibold'>Overview</p>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />

                <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Company Name :</div>
                        <div className="value"><input type="text" disabled={true} className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.company_name}></input></div>
                </div>

                <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Contact Person:</div>
                        <div className="value"><input type="text" disabled={true} className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.main_contact}></input></div>
                </div>

                <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Producer Email:</div>
                        <div className="value"><input type="text" disabled={true} className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.email}></input></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Account Password:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={producerData!=null && producerData.password} onChange={e => updatePassword()}></input></div>
                    </div>
                <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Company BRN:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={brn} onChange={(e) => updateUserMeta(e, 'brn', e.target.value)} /></div>
                </div>
                    <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Company VAT Number:</div>
                        <div className="value"><input type="text" className="form-control rounded-none bg-greyish border-none text-sm" value={vat} onChange={e => updateUserMeta(e, 'vat', e.target.value)}></input></div>
                    </div>

                    <button className="btn my-3 btn-primary bg-color-orange text-white font-semibold border-none w-100 text-sm rounded-none hover:bg-orange-700" type="button" onClick={(e) => SendUserLoginDetails(e)}>Send User Login Details >></button>                         
            </div>


            <div className="px-3 py-2 border rounded-sm shadow-sm my-3">
                <p className='font-semibold'>Main contact</p>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <div className="d-flex flex-column">
                        <div className=" font-semibold text-sm py-2">Contact Person:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={mainContactPerson} onChange={e => setMainContactPerson(e.target.value)}></input></div>
                </div>

                    <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Contact Number:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={mainContactNumber} onChange={e => setMainContactNumber(e.target.value)}></input></div>
                    </div>

                <div className="d-flex flex-column">
                        <div className="font-semibold text-sm py-2">Email Address:</div>
                        <div className="value"><input type="text" disabled className="form-control text-sm" value={producerData!=null && producerData.email}></input></div>
                    </div>

                    <button className="btn my-3 btn-sm btn-warning bg-orange-800 hover:bg-orange-600 text-white text-sm rounded-none w-100" onClick={(e) => updateMainContact(e)}>Update Main Contact</button>

            </div>


            <div className="px-3 py-2 border rounded-sm shadow-sm my-3">
            {prodContacts!=null && prodContacts.map((contact, index) => { 
                    return (
                        <div className="" key={index}>
                            <div className="flex justify-between items-center mb-2 pb-2 border-b-2">
                                <div className="contact-heading font-semibold text-sm">{contact.type=="secondary" ? "Secondary Contact":"Additional Contact"}</div>
                                <button className="btn btn-sm btn-danger bg-red-700 hover:bg-red-600 text-sm" onClick={e => deleteProdContact(contact.id)}>Delete Contact</button>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Contact Person:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.person}></input></div>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Contact Number:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.phone}></input></div>
                            </div>
                            <div className="contact-item">
                                <div className="title  font-semibold text-sm">Email Address:</div>
                                <div className="value"><input type="text" disabled className="form-control text-sm" value={contact.email}></input></div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="px-3 py-2 border rounded-sm shadow-sm my-3">
                <p className='font-semibold'>Bank Details</p>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    <div className="d-flex flex-column">
                        <div className="title font-semibold text-sm py-2">Bank Name:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_name} onChange={e => setProducerData({...producerData, bank_name: e.target.value})}></input></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="title font-semibold text-sm py-2">Bank Account number:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.account_details} onChange={e => setProducerData({...producerData, account_details: e.target.value})}></input></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="title font-semibold text-sm py-2">Account on the name of:</div>
                        <div className="value"><input type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_account_name} onChange={e => setProducerData({...producerData, bank_account_name: e.target.value})}></input></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="title font-semibold text-sm py-2">Other supporting details:</div>
                        <div className="value"><textarea type="text" className="form-control text-sm" value={producerData!=null && producerData.bank_supporting_details} onChange={e => setProducerData({...producerData, bank_supporting_details: e.target.value})}></textarea></div>
                    </div>

                    <button className="btn btn-success text-white bg-green-700 hover:bg-green-800 text-sm rounded-none w-100 my-4 " onClick={(e) => SaveProdAccountDetails()}>Save</button>
            </div>

            <div className="border rounded-sm shadow-sm px-1">
              <p className='font-semibold px-3 py-2'>Producer Event Record</p>
            {prodStats!=null && prodStats.map((stat, index) => (

                <div className="border rounded-sm mb-3">
                    <a href={"/event/"+stat.id} className="px-3 py-2 font-semibold underline text-orange-600 text-sm">{stat.title}</a>
                    <Divider />

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                        <p className='text-secondary text-nowrap'>Date :</p>
                        <p>{stat.event_date}</p>
                    </div>  

                    <Divider />

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                        <p className='text-secondary text-nowrap'>Ticket Sold :</p>
                        <p>{stat.sold}</p>
                    </div>  
                    <Divider />

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' >
                        <p className='text-secondary text-nowrap'>Sales Amount :</p>
                        <p>Rs {stat.soldamount}</p>
                    </div>  

                    <Divider />

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                        <p className='text-secondary text-nowrap'>Collab Type :</p>
                        <p>{stat.colab}</p>
                    </div> 

                    <Divider/>

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                        <p className='text-secondary text-nowrap'>Comm Level :</p>
                        <p>{stat.comlevel}</p>
                    </div> 

                    <Divider />

                    <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                        <p className='text-secondary text-nowrap'>On Site Service :</p>
                        <p>{stat.services}</p>
                    </div> 


                    <Divider />

                    <div className="px-3 py-1 d-flex justify-content-between px-2">
                     <a href={stat.bilan} className="color-orange underline mb-1 text-center font-semibold hover:text-orange-300 text-sm">View Bilan</a>
                     <a href={stat.agreement} className="color-blue underline text-center font-semibold hover:text-blue-400 text-sm">View Agreement</a>
                    </div>
                </div>
            ) )}
        
            </div>


        </div>
        </>
    );
}

export default SingleProducer;