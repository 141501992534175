import React, { Component } from "react";
import OrdersReportService from "../../services/ordersreport.service";

export default class OrderLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: props.render,
            logs: []
        }
    }

    componentDidMount() {
        this.getLogs();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.render!=this.props.render) {
            this.getLogs();
        }
    }

    getLogs() {
        OrdersReportService.getOrderLogs(this.state.orderid)
        .then(response => {
            console.log("logs :" , response);
            this.setState({
                logs: response.data
            });
        })
        .catch(error => console.log({error}));
    }

    render() {
        return (
            <>
        
            <div className="add-reminder-row shadow-md d-none d-lg-block">
                <div className="row">
                    <div className="col">
                        <h5 style={{marginBottom: "10px", textDecoration: "underline"}} className="orders-h1 font-bold text-base p-2 subsection-heading mb-2">Order Logs</h5>
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Action Type</th>
                                    <th>Action Taken</th>
                                    <th>Data/Code</th>
                                    <th>Date/Time</th>
                                    <th>By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.logs.length>0 && this.state.logs.map((log, index) => (
                                <tr key={index}>
                                    <td scope="row">{log.action_type}</td>
                                    <td>{log.action_taken}</td>
                                    <td>{log.data}</td>
                                    <td>{log.date_added}</td>
                                    <td>{log.by}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        
                    </div>
                </div>

            </div>

            <div className="d-lg-none overflow-scroll">
             <p
                className=""
                style={{
                    fontWeight: "700",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    color: "#aaaaaa",
                }}
                >
                Order Logs
                </p>
           
              <table class="table table-bordered table-striped">
                            <thead className="text-sm text-nowrap">
                                <tr>
                                    <th>Action Type</th>
                                    <th>Action Taken</th>
                                    <th>Data/Code</th>
                                    <th>Date/Time</th>
                                    <th>By</th>
                                </tr>
                            </thead>
                            <tbody className="text-xs">
                                {this.state.logs.length>0 && this.state.logs.map((log, index) => (
                                <tr key={index}>
                                    <td scope="row">{log.action_type}</td>
                                    <td>{log.action_taken}</td>
                                    <td>{log.data}</td>
                                    <td>{log.date_added}</td>
                                    <td>{log.by}</td>
                                </tr>
                                ))}
                            </tbody>
              </table>

            </div>
            </>

            
        );
    }
}