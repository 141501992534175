import axios from "axios";

const APIURL = "https://otayo.com/erpapi/sales.php";

class SalesService {
    getBasicSalesReport() {
        var params = {
            action: "getBasicSalesReport"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getAdvanceSalesReport(from="", to="") {
        var params = {
            action: "getAdvanceSalesReport",
            from: from,
            to: to
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getSalesGraphs() {
        var params = {
            action: "getSalesGraphs"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getAdvanceSalesReportAnalytics(from="", to="", eventID="", invites="no") {
        var params = {
            action: "getAdvanceSalesReportAnalytics",
            from: from,
            to: to,
            eventID: eventID,
            invites: invites
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
}

export default new SalesService();