import React from 'react';
import { CSVLink } from 'react-csv';

const ExportSalesAnalyticsCSV = ({ SalesAnalytics }) => {
  let CSVData = [];

  if (SalesAnalytics !== null) {
    CSVData = SalesAnalytics.map(item => {
      const rowData = Object.entries(item.data).map(([date, value]) => ({
        date,
        value,
      }));

      return [
        item.label,
        ...rowData.flatMap(entry => [entry.date, entry.value]),
      ];
    });
  }

  const headers = [
    // 'Label',          
    // 'Date',          
    // 'Total Sold',    
    // 'Total Sales',
    // 'Total Orders'
  ];

  return (
    <div>
      {SalesAnalytics !== null ? (
        <div className='bg-orange-600 text-white px-2 cursor-pointer py-1 hover:bg-orange-800 ml-3 border'>
          <CSVLink data={[headers, ...CSVData]} filename={'sales_analytics.csv'} style={{ cursor: 'pointer' }}>
            Download CSV
          </CSVLink>
        </div>
      ) : (
        <p className='text-xs'>No CSV data to export</p>
      )}
    </div>
  );
};

export default ExportSalesAnalyticsCSV;
