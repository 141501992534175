import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/ordersreport.service";

export default class AddComplaint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: false,
            receivedby: '',
            items: props.items,
            chosenitems: [],
            linktocomplaint: "",
            customermobilenumber:'',
            complaintSummary: "",
            addcomplainttext: 'Submit',
            disableaddrefundbtn: false,
            isopen: false,
            handlingAgentName: '',
            user: props.user
        }
    }

    openPopup = (e) => {
        this.setState({
            isopen: true
        });
    }

    addComplaint = (e) => {

        this.setState({
            addcomplainttext: 'Submitting Issue...',
            disableaddcomplaint: true
        });
        e.preventDefault();
        OrdersReportService.submitComplaint(this.state.orderid, this.state.chosenitems, this.state.handlingAgentName, this.state.receivedby, this.state.linktocomplaint, this.state.customermobilenumber, this.state.complaintSummary, this.state.user.user_nicename)
        .then(response => {
            console.log('Complaint response', {response});
            this.setState({
                addcomplainttext: 'Submit',
                disableaddcomplaint: false,
                isopen: false
            });
            swal('Complaint Submitted', "The issue has been submitted", "success");
            this.props.onChange();
        })
        .catch(err => console.log({err}));
    }


    setChosenItems = (e) => {

        var chosenItems = Array.from(e.target.selectedOptions, option => option.value);
        console.log({chosenItems});
        this.setState({chosenitems: chosenItems});
    }
    

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <button type="button" className="btn" onClick={e => this.openPopup(e)}>Complaint / Issue</button>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.addComplaint(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Submit Complaint / Issue
                        </div>
                    </div>
                     <div className="form-group row">
                        <label for="chosenitems" className="col-4 col-form-label"><b>Choose Products</b></label>
                        <div className="col-8">
                            <select className="form-control" multiple onChange={e => this.setChosenItems(e)} id="chosenitems" required="required" value={this.state.chosenitems}>
                                {this.state.items.length>0 && this.state.items.map((item, index)=>(
                                    <option key={index} value={item.id+"|"+item.name}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="handlingAgentName" className="col-4 col-form-label"><b>Handling Agent Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="handlingAgentName" placeholder="Enter handling agent name" required="required" onChange={e => {this.setState({handlingAgentName: e.target.value})}} value={this.state.handlingAgentName} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="receivedby" className="col-4 col-form-label"><b>Complaint Received By</b></label>
                        <div className="col-8"> 
                            <select className="form-control" id="receivedby" required="required" onChange={e => {this.setState({receivedby: e.target.value})}} value={this.state.receivedby} >
                                <option value="">Choose Channel</option>
                                <option value="Email">Email</option>
                                <option value="Phone">Phone</option>
                                <option value="Messenger">Messenger</option>
                                <option value="Whatsapp">Whatsapp</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="linktocomplaint" className="col-4 col-form-label"><b>Link to Complaint / Communication</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="linktocomplaint" placeholder="Link to Complaint / Communication" required="required" onChange={e => {this.setState({linktocomplaint: e.target.value})}} value={this.state.linktocomplaint} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="customermobilenumber" className="col-4 col-form-label"><b>Customer Mobile number</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="customermobilenumber" placeholder="Customer Mobile number" required="required" onChange={e => {this.setState({customermobilenumber: e.target.value})}} value={this.state.customermobilenumber} />
                        </div>
                    </div>   
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="complaintSummary" className="col-4 col-form-label"><b>Complaint Summary</b></label>
                        <div className="col-8"> 
                            <textarea rows="4" className="form-control" id="complaintSummary" placeholder="Enter Complaint Summary" required="required" onChange={e => {this.setState({complaintSummary: e.target.value})}} value={this.state.complaintSummary}></textarea>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={this.state.disableaddcomplaint}>{this.state.addcomplainttext}</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}