import React from 'react';
import { Divider } from '@mui/material';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const SalesReportCard = ({ data }) => {

  const [ShowMore, setShowMore] = useState(false)

  const {
    id,
    eventname,
    producer,
    eventdate,
    ticketsallocated_ui,
    totalsold_ui,
    remaining_ui,
    totalsoldpercentage,
    totalreserved_ui,
    totalsales_ui,
    totalOrders,
    amountbypos,
    amountbywebsite,
    amountpercentbypos,
    amountpercentbywebsite,
  } = data;

  let totalsalesNum = parseFloat(totalsales_ui);
  const avgamount = Math.round((totalsalesNum / totalOrders * 1000).toFixed(4));
  const avgticket = totalOrders !== 0 ? Math.round(totalsold_ui / totalOrders) : 0;



  return (
    <div className='border rounded-sm shadow-sm mb-3'>
      <div className='d-flex flex-col justify-content-between px-3 py-2 '>
        <a href={`/event/${id}`} className='font-semibold text-sm text-orange-500 underline'>{eventname}</a>
        {/* <p className='text-sm text-nowrap pt-1'>Percentage Sold : 34.8%</p> */}
      </div>

      <Divider />
      <div className='overflow-hidden'  style={{ maxHeight: ShowMore ? 'unset' : '58px' }}>

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
       <p className='text-secondary text-nowrap'>Total Sold Percentage :</p>
       <p className='font-medium'>{totalsoldpercentage}%</p>
      </div>
      
      <Divider />
      
      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Total Sales :</p>
        <p className='font-medium'>Rs {totalsales_ui}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>ID :</p>
        <p className='font-medium'>{id}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Producer :</p>
        <p className='font-medium'>{producer.name}</p>
      </div>


      <Divider />
 
      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Event Date :</p>
        <p className='font-medium'>{eventdate}</p>
      </div>

      <Divider/>

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Tickets Allocated :</p>
        <p className='font-medium'>{ticketsallocated_ui}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Total Sold :</p>
        <p className='font-medium'>{totalsold_ui}</p>
      </div>

      <Divider/>

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Remaining :</p>
        <p className='font-medium'>{remaining_ui}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Total Reserved :</p>
        <p className='font-medium'>{totalreserved_ui}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Total Orders :</p>
        <p className='font-medium'>{totalOrders}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Amount by POS :</p>
        <p className='font-medium'>{amountbypos}</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Amount by Website :</p>
        <p className='font-medium'>{amountbywebsite}</p>
      </div>

      <Divider/>

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Amount Percent by POS :</p>
        <p className='font-medium'>{amountpercentbypos}%</p>
      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Amount Percent by Website :</p>
        <p className='font-medium'>{amountpercentbywebsite}%</p>
      </div>
      
      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
        <p className='text-secondary text-nowrap'>Average Amount :</p>
        <p className='font-medium'>Rs {avgamount}</p>

      </div>

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
        <p className='text-secondary text-nowrap'>Average Ticket :</p>
        <p className='font-medium'>{avgticket}</p>

      </div>

      <Divider/>
      </div>

      <div className='px-3 py-1 bg-white z-10 text-right' style={{ display: ShowMore ? 'none' : 'block' }}>
        <button className='font-semibold text-orange-500 text-sm rounded-sm' onClick={() => setShowMore(!ShowMore)}>More details <ArrowDropDownIcon style={{height : 15 , width : 15}}/></button>
      </div>


    </div>
  );
};

export default SalesReportCard;
