import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import axios from "axios";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";


import "../styles/Posmain.css"

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const APIURL = "https://otayo.com/erpapi/pos.php";

const PosStaffPerformance = () => {

    const { id } = useParams();

    const [isOpen, setIsOpen] = useState(false);

    const [minDate, setMinDate] = useState("");
    // const [startDate, setStartDate] = useState("");

    const [posName, setPosName] = useState('');
    const [posEmail, setPosEmail] = useState('');
    
    const [editTicketEntryID, setEditTicketEntryID] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [editTicketEntryAllocated, setEditTicketEntryAllocated] = useState('');
    const [editTicketEntryDetails, seteditTicketEntryDetails] = useState('');
    const [editTicketEntryComment, seteditTicketEntryComment] = useState('');
    const [allTicketEntry, setAllTicketEntry] = useState([]);
    const [posCollectionIncentives, setposCollectionIncentives] = useState([]);
    const [posStaffPerformance, setposStaffPerformance] = useState([]);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                getAllTicketEntry();
                getPOSCollectionIncentives();
                PosService.getSinglePOS(id)
                .then(response => {
                    console.log('POS response', {response});
                    if(response.data.pos_name!='undefined') {
                        setPosName(response.data.pos_name);
                        setPosEmail(response.data.email);

                        getPOSStaffPerformance(response.data.id, response.data.email);
                    }
                })
                .catch(err => console.log({err}));
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const Refresh = () => {
        window.location.reload(false);
    }

    const getPOSCollectionIncentives = () => {
        var params = {
            action: 'getPOSCollectionIncentives',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log('pos collection', response)
            setposCollectionIncentives(response.data);
        })
        .catch(err => console.log({err}));
    }

    const getPOSStaffPerformance = (pos_id, email) => {
        var params = {
            action: 'getPOSStaffPerformance',
            pos: pos_id,
            posEmail: email
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setposStaffPerformance(response.data);
        })
        .catch(err => console.log({err}));
    }

    const getAllTicketEntry = () => {
        var params = {
            action: 'getAllTicketsIncentives',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setAllTicketEntry(response.data);
        })
        .catch(err => console.log({err}));
    }

    const getIncentive = (incentive_id) => {
        var params = {
            action: 'getTicketsIncentives',
            id: incentive_id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // setStartDate(response.data.date_given);
            setStartDate('');
            setEditTicketEntryAllocated(response.data.allocated_to);
            seteditTicketEntryComment(response.data.comment);
            seteditTicketEntryDetails(response.data.tickets_details);
           
            setEditTicketEntryID(incentive_id);
            getAllTicketEntry();
            setIsOpen(true);
        })
        .catch(err => console.log({err}));
    }

    const deleteIncentive = (incentive_id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The entry will be deleted from the Incentives!",
        icon: "warning",
        buttons: ["No, Keep the Incentive!", "Yes, Remove Incentive!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
           var params = {
                action: 'deleteIncentive',
                id: incentive_id,
            }

            axios.post(APIURL+"?rand="+Math.random(), params)
            .then(response => {
                getPOSCollectionIncentives();
                swal("Incentive Removed", "Incentive has been removed!", "success");
            })
            .catch(err => console.log({err}));
            swal("Incentive Removed", "Your Incentive has been removed!", "success");
        } else {
            swal("Incentive Not Removed", "Your Incentive is safe!", "success");
        }
        });
    }

    const addTicketEntryIncentive = (e) => {
        e.preventDefault();

        var action = 'addTicketsIncentives';
        if(editTicketEntryID>0) {
            action = 'editTicketsIncentives';
        }
        var params = {
            action: action,
            id: editTicketEntryID,
            date_given: startDate,
            allocated_to: editTicketEntryAllocated,
            comment: editTicketEntryComment,
            tickets_details: editTicketEntryDetails,
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setIsOpen(false);
            swal("Incentive Saved", "Incentive has been saved", "success");
            setStartDate('');
            setEditTicketEntryAllocated('');
            seteditTicketEntryComment('');
            seteditTicketEntryDetails('');
           
            setEditTicketEntryID(0);

            getAllTicketEntry();
        })
        .catch(err => console.log(err));

    }

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">{posName} Staff Performance</h1>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h4 className="eventname font-bold text-base mb-0 text5e4642">Sales by Staff member</h4>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm text-center">
                                <tr>
                                    <th scope="col">Staff Member Name</th>
                                    <th scope="col">This Month</th>
                                    <th scope="col">Last Month</th>
                                    <th scope="col">This Year</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posStaffPerformance.length>0 && posStaffPerformance.map((row, index) => (
                                <tr key={index} className=" text-sm text-center">
                                    <td scope="col">{row.name}</td>
                                    <td scope="col">{row.thismonth}</td>
                                    <td scope="col">{row.lastmonth}</td>
                                    <td scope="col">{row.thisyear}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h4 className="eventname font-bold text-base mb-0 text5e4642">Incentive Amount Given</h4>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm text-center">
                                <tr>
                                    <th scope="col">Collection Entry ID</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Incentive Given</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posCollectionIncentives.length>0 && posCollectionIncentives.map((row, index) => (
                                <tr key={index} className=" text-sm text-center">
                                    <td scope="col">{row.id}</td>
                                    <td scope="col">{row.created_on}</td>
                                    <td scope="col">Rs {(row.incentive_given==null || row.incentive_given==0) ? 0:row.incentive_given.toLocaleString('en-US')}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h4 className="eventname font-bold text-base mb-0 text5e4642">Free ticket incentives</h4>
                        <button className="btn btn-sm btn-success btn-green" onClick={e => setIsOpen(true)}>Add Ticket Incentive Entry</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm text-center">
                                <tr>
                                    <th scope="col">Date Given</th>
                                    <th scope="col">Tickets Allocated to</th>
                                    <th scope="col">Ticket Details</th>
                                    <th scope="col">Comment</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTicketEntry.length>0 && allTicketEntry.map((entry, index) => (
                                <tr key={index} className=" text-sm text-center">
                                    <td scope="col">{entry.date_given}</td>
                                    <td scope="col">{entry.allocated_to}</td>
                                    <td scope="col">{entry.tickets_details}</td>
                                    <td scope="col">{entry.comment}</td>
                                    <td scope="col">
                                        <button className="btn btn-sm btn-link text-red-700 font-semibold" onClick={e => deleteIncentive(entry.id)}>Delete</button> / <button className="btn btn-sm btn-link text-blue-700 font-semibold" onClick={e => getIncentive(entry.id)}>Edit</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            {/* Add Ticket Entry */}
            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addTicketEntryIncentive(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add Ticket Entry
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Date Given:</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={editTicketEntryID} />
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                placeholderText="Date Given"
                                className='form-control rounded-none'
                            />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Tickets Allocated To</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Ticket Allocated to" required="required" value={editTicketEntryAllocated} onChange={e => setEditTicketEntryAllocated(e.target.value) } />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Ticket Details</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Comments" required="required"  value={editTicketEntryDetails} onChange={e => seteditTicketEntryDetails(e.target.value) } />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Comment</b></label>
                        <div className="col-8"> 
                            <textarea className="form-control" placeholder="Enter Comments" required="required" rows="3" value={editTicketEntryComment} onChange={e => seteditTicketEntryComment(e.target.value) } />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>
    );
}

export default PosStaffPerformance;