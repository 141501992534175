import { useEffect, useRef, useState } from "react";
import PageHeading from "../components/PageHeading.component";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChair, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import '../styles/OnSiteSelling.css';
import "../styles/SeatingChart.css";
// import panzoom from "panzoom";
import { PanZoom } from 'react-easy-panzoom'
import DOMPurify from 'dompurify';
import {Hourglass} from 'react-loader-spinner';

import EventsService from "../services/events.service";
import AuthService from "../services/auth.service";
import SeatingChartService from "../services/seatingChart.service";
import {database} from "../services/firebase";
import { data } from "autoprefixer";
import { set } from "react-hook-form";

import Dialog from '@mui/material/Dialog';
import { Drawer } from "@mui/material";



function OnSiteSelling() {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [total, setTotal] = useState(0);
    const [customerName, setCustomerName] = useState("Otayo Onsite Selling");
    const [customerEmail, setCustomerEmail] = useState("onsite@otayo.com");
    const [customerPhone, setCustomerPhone] = useState('4669999');
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [loading, setLoading] = useState(false);
    const [orderID, setOrderID] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [seatingType, setseatingType] = useState(0);
    const [chartHtml, setchartHtml] = useState('');
    const [seatingChartID, setseatingChartID] = useState(0);
    const [position, setPosition] = useState("0 0");
    const [user, setUser] = useState(null);
    const [clearCart, setClearCart] = useState(false);
    const [seats, setSeats] = useState([]);
    const panzoom = useRef(0);
    const seatingRef = useRef(0);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            if(user!=null) {
                // console.log(user);
                setUser(user);
                getAllEvents();
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                window.location.href='/login';
            }
        })
        .catch((err) => {
            setIsLoggedIn(false);
            console.log(err);
            window.location.href='/login';
        });
    }, [])

    useEffect(() => {
        if(isLoggedIn==false) {
            window.location.href='/login';
        }
    } , [isLoggedIn]);

    const getAllEvents = () => {
        EventsService.getAllEvents()
        .then((res) => {
            // console.log({res});
            setEvents(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const loadSeatingChart = (event) => {
        setchartHtml('');
        // if(seatingType=='yes') {
            SeatingChartService.getSeatingChartHtml(event.value)
            .then((res) => {
                setchartHtml(res.data);
                // add class to the seats that have value 534579 on data-tt-id
                var seats = document.querySelectorAll('[data-tt-id="534579"]');
                seats.forEach((seat) => {
                    seat.classList.add('tc_seat_unavailable');
                });
            })
            .catch((err) => {
                console.log(err);
            });
        // }
    };

    const getEventChartID = (event) => {
        SeatingChartService.getEventChartID(event.value)
        .then((res) => {
            // console.log({res});
            if(res.data != null) {
                loadSeatingChart(event);
                setseatingChartID(res.data);
            } else {
                setseatingChartID(0);
            }
        }).catch((err) => {
            console.log({err});
        });
    }

    //     useEffect(() => {
//     const container = seatingRef.current;

//     if (container) {
//       const handleSeatClick = (event) => {
//         const target = event.target;

//         // Check if the clicked element has a specific class or attribute that identifies it as a seat
//         if (target.classList.contains('tc_seat_unit') || target.getAttribute('tc_set_seat')) {
//           // Handle the seat click event here
//           console.log('Seat clicked:', target.textContent);
//         }
//       };

//       // Attach the event listener to the container
//       container.addEventListener('click', handleSeatClick);

//       // Don't forget to remove the event listener when the component unmounts
//       return () => {
//         container.removeEventListener('click', handleSeatClick);
//       };
//     }
//   }, [chartHtml]);

    useEffect(() => {
        if (chartHtml === '') return;
        const container = seatingRef.current;
        // console.log("re initialising");
        // var selectedSeats = {};
        const handleSeatClick = (event) => {
            var element = event.target;
            const id = element.getAttribute('id');
            
            if (element.classList.contains('tc_seat_selected_mine')) {
                setSeats((prevSeats) =>{
                    var seats  = [...prevSeats];
                    var dataToken = element.getAttribute('data-token');
                    if(dataToken==user.token) {
                        // Remove the class from the clicked element
                        element.classList.remove('tc_seat_selected_mine');
                        
                        // Remove the seat from the database
                        database.ref('in-cart/' + seatingChartID + '/' + id).remove();
                        // remove the seat from the selectedSeats object
                        seats = seats.filter((seat) => seat.seat_id!=id);
                        return seats;
                    }
                });
            } else if (element.classList.contains('tc_seat_selected_else') || element.classList.contains('tc_seat_reserved') || element.classList.contains('tc_seat_unavailable') || element.classList.contains('   seat-row') || element.classList.contains('tc-group-content')) {
                // ignore
            } else {
                setSeats((prevSeats) =>{
                    var seats  = [...prevSeats];
                    // Add the class to the clicked element
                    element.classList.add('tc_seat_selected_mine');
                    // add an attribute to the element data-token
                    element.setAttribute('data-token', user.token);
                    // Add the seat to the database
                    database.ref('in-cart/' + seatingChartID + '/' + id).set({
                        session_id: user.token,
                        timestamp: Date.now()
                    });

                    var type = element.getAttribute('data-tt-id');
                    var label = element.querySelector('p').innerText;
                    var currseat = {
                        seat_id: id,
                        type: type,
                        label: label,
                        chart: seatingChartID
                    }
                    // selectedSeats[id] = currseat;
                    // const newSeats = Object.values(selectedSeats);
                    seats.push(currseat);
                    return seats;
                });
            }

        };

        database.ref('in-cart/' + seatingChartID).on('value', (snapshot) => {
            const data = snapshot.val();
            // console.log({data});
            // Remove the class from all seats
            // selectedSeats = {};
            const elements = document.querySelectorAll('.tc_seat_unit');
            elements.forEach((element) => {
                element.classList.remove('tc_seat_selected_mine');
                element.classList.remove('tc_seat_selected_else');
            });
            if (data) {
            Object.keys(data).forEach((key) => {
                if (key !== 'session_id' && key !== 'timestamp') {
                const element = document.getElementById(key);
                // click on the element js
                if (element) {
                    element.setAttribute('data-token', data[key].session_id);
                    if(data[key].session_id!=user.token) {
                        element.classList.add('tc_seat_selected_else');
                    } else {
                        // element.click();
                        element.classList.add('tc_seat_selected_mine');
                    }
                }
                }
            });
            }
        });

        database.ref('reserved/' + seatingChartID).on('value', (snapshot) => {
            const data = snapshot.val();
            // console.log({data});
            // Remove the class from all seats
            const elements = document.querySelectorAll('.tc_seat_unit');
            elements.forEach((element) => {
                element.classList.remove('tc_seat_selected_mine');
                element.classList.remove('tc_seat_selected_else');
                element.classList.remove('ui-selectee');
                element.classList.remove('tc_seat_reserved');
            });
            if (data) {
            Object.keys(data).forEach((key) => {
                if (key !== 'session_id' && key !== 'timestamp') {
                    const element = document.getElementById(key);
                    if (element) {
                        element.classList.add('tc_seat_reserved');
                    }
                }
            });
            }
        });

        // const elements = document.querySelectorAll('.tc_seat_unit');
        // elements.forEach((element) => {
        //     element.addEventListener('click', () => {
        //     handleSeatClick(element);
        //     });
        // });
        container.addEventListener('click', handleSeatClick);

        return () => {
            // Clean up the event listeners when the component unmounts
            console.log("i am cleaning up");
            clearAll();
            container.removeEventListener('click', handleSeatClick);
            const elements = document.querySelectorAll('.tc_seat_unit');
            elements.forEach((element) => {
                // element.removeEventListener('click', () => {
                // });
                // if element has class tc_seat_selected_mine then remove it
                if(element.classList.contains('tc_seat_selected_mine')) {
                    element.classList.remove('tc_seat_selected_mine');
                    const id = element.getAttribute('id');
                    database.ref('in-cart/' + seatingChartID + '/' + id).remove();
                }
            });
        };
    }, [chartHtml]);

    useEffect(() => {
        // console.log({seats});
        var newTickets = [...tickets];
        for(var i=0; i<newTickets.length; i++) {
            newTickets[i].quantity = 0;
            newTickets[i].seats = [];
        }

        for(var i=0; i<seats.length; i++) {
            var seat = seats[i];
            var ticketIndex = newTickets.findIndex((ticket) => ticket.id==seat.type);
            if(ticketIndex!=-1) {
                newTickets[ticketIndex].quantity++;
                newTickets[ticketIndex].seats.push(seat);
            }
        }
        // console.log({newTickets});
        setTickets([...newTickets]);
        calculateTotal();
    }, [seats]);
    
    const eventChanged = (event) => {
        setSelectedEvent(event);
        
        getEventChartID(event);
        EventsService.getEventTickets(event.value)
        .then((res) => {
            // console.log("Tickets :", res);
            setTickets(res.data);
            if(res.data.length>0) {
                setseatingType(res.data[0].seating_type);
                // if(res.data[0].seating_type=='yes') {
                // }
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const calculateTotal = () => {
        let total = 0;
        tickets.forEach((ticket) => {
            total += ticket.price*ticket.quantity;
        } );
        setTotal(total);
    }

    const updateQuantity = (e, index) => {
        if(e.target.value>=0) {
            var newTickets = [...tickets];
            newTickets[index].quantity = e.target.value;
            setTickets(newTickets);
            calculateTotal();
        }
    } 

    const checkinTickets = (orderID) => {
        // console.log("checkinTickets: ", orderID);
        EventsService.checkinTickets(orderID)
        .then(res => {
            console.log({res});
        })
        .catch(err => {
            console.log({err});
        });
    }

    const placeOrder = () => {
        setOrderID(0);
        setLoading(true);
        if(selectedEvent && tickets.length > 0) {
            EventsService.placeOrder(selectedEvent, tickets, customerName, customerEmail, customerPhone, total, paymentMethod , user, 'yes')
            .then((res) => {
                // console.log(res);
                var newSeats = [...seats];
                newSeats.forEach((seat, index) => {
                    var id = seat.seat_id;
                    database.ref('reserved/' + seatingChartID + '/' + id).set({
                        session_id: user.token,
                        timestamp: Date.now()
                    });
                });
                setOrderID(res.data);
                checkinTickets(res.data);
                // eventChanged(selectedEvent);
                clearAll();
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        }
    }

    const clearAll = () => {
        // setTotal(0);
        // setCustomerName("Otayo Onsite Selling");
        // setCustomerEmail("onsite@otayo.com");
        // setCustomerPhone("4669999");
        // setPaymentMethod("cash");
        var newTickets = [...tickets];
        newTickets.forEach((ticket, index) => {
            newTickets[index].quantity = 0;
        } );
        setSeats([]);
        setTickets(newTickets);
        calculateTotal();
    };

    const loadSeats = (index, ticketId, ticketName, qty) => {
        // console.log("loadSeats: ", ticketId, ticketName, qty);

        SeatingChartService.getAvailableSeatsPerTicketType(selectedEvent.value, ticketId, qty)
        .then(res => {
            // console.log({res});
            var newTickets = [...tickets];
            newTickets[index].seats = res.data;
            setTickets(newTickets);
        }).catch(err => {
            console.log({err});
        });
       
    };

    const zoomIn = () => {
        panzoom.current.zoomIn();
    };

    const zoomOut = () => {
        panzoom.current.zoomOut();
    }

    const reset = () => {
        loadSeatingChart(selectedEvent);
        panzoom.current.reset();
    }

    const autoCenter = () => {
        panzoom.current.autoCenter();
        // panzoom.current.moveByRatio(-20, 0.5);
    }

    const emptyCart = () => {
        var newSeats = [...seats];
        newSeats.forEach((seat, index) => {
            var id = seat.seat_id;
            database.ref('in-cart/' + seatingChartID + '/' + id).remove();
        });
        setSeats([]);
        var clear = !clearCart;
        setClearCart(clear);
        // selectedSeats = {};
    }


    const [openEventModal, setOpenEventModal] = useState(true);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleClickEventModal = () => {
        setOpenEventModal(!openEventModal);
    };

    
    useEffect(() => {
        if (seatingChartID > 0 ) {
            setOpenEventModal(false);
        }
    }, [seatingChartID])

    return (
        <>
         <div className="d-none d-lg-block page-wrapper-parent">
        <div className="page-wrapper shadow-lg p-4">
            <div className="row">
                <div className="col heading-h1-wrapper heading-h1-wrapper-onsiteselling">
                    <h1 className="text-lg text5e4642">On-Site Selling</h1>
                    <Select 
                    options={events} 
                    placeholder="Select an Event" 
                    onChange={(option) => eventChanged(option)}
                    />
                </div>
                <hr />
            </div>

            {selectedEvent && (
            <>
                <div className="row">
                    <div className="col">
                        <div className="event-details-wrapper" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}}>
                            <h2>{selectedEvent.label}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col tickets-heading">
                        Select Tickets Below
                    </div>
                </div>
                {seatingChartID>0 && tickets.length>0 && 
                <div className="row">
                    <div className="col flex justify-center items-center">
                        {tickets.map((ticket, index) => (
                            <div className="seat-info p-2 text-white bold mr-2 last:mr-0" key={index} style={{backgroundColor: ticket.seat_color}}>
                                {ticket.label} - {ticket.price_formatted}
                            </div>
                        ))}
                    </div>
                </div>
                }
                {seatingChartID>0 && chartHtml!='' && 
                <div className="row">
                    <div className="col relative vw-100 h-max mt-5 p-3 border-slate-400 border" style={{overflow: 'hidden'}}>
                        <div className="seating-chart-controls absolute flex flex-col justify-start items-start">
                            <div className="mb-2">
                                <button className="btn btn-sm btn-success bg-orange-800 hover:bg-orange-600 border-none rounded-none font-bold text-lg mr-2" onClick={e => zoomOut()}>-</button>
                                <button className="btn btn-sm btn-success bg-orange-800 hover:bg-orange-600 border-none  rounded-none font-bold text-lg" onClick={e => zoomIn()}>+</button>
                            </div>
                            
                            {/* <button className="btn btn-sm btn-success bg-green-800 hover:bg-green-600 border-none rounded-none  font-bold text-base mb-2" onClick={e => autoCenter()}>Center</button> */}
                            <button className="btn btn-sm btn-success bg-green-800 hover:bg-green-600 border-none rounded-none  font-bold text-base" onClick={e => reset()}>Reset</button>
                        </div>
                        <PanZoom
                            // autoCenter={true}
                            // autoCenterZoomLevel={0.3}
                            ref={panzoom}
                            // enableBoundingBox={true}
                            noStateUpdate={true}
                        >
                            <div ref={seatingRef} style={{height: "70vh", display: "block"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chartHtml) }}></div>
                        </PanZoom>
                    </div>
                </div>
                }

                {seatingChartID>0 && seats.length>0 && 
                <>
                <div className="row">
                    <div className="col font-bold mt-3">
                        Select Seats:
                    </div>
                </div>
                <div className="row flex justify-between items-center">
                    <div className="col flex justify-start items-center">
                        {seats.map((seat, index) => (
                            <div className="seat-info p-2 text-white bold mr-2 last:mr-0 bg-orange-600" key={index} style={{backgroundColor: seat.color}}>
                                SEAT # {seat.label}
                            </div>
                        ))}
                    </div>
                    <button className="btn btn-danger bg-red-500 hover:bg-red-800 text-white font-bold w-20" id="empty-cart" onClick={e => emptyCart()}>Empty Cart</button>
                </div>
                </>
                }

                {seatingChartID>0 && chartHtml=='' && 
                <div className="row">
                    <div className="col relative vw-100 h-max mt-5 p-3 border-slate-400 border flex justify-center items-center" style={{height: "50vh", overflow: 'hidden'}}>
                        <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#f36523', '#FF9A6B']}
                        />
                    </div>
                </div>
                }
                
                {tickets && (
                <div className="container">
                    {seatingChartID==0 && 
                    <div className="row">
                        <div className="col event-details-tickets-wrapper">
                            <div className="event-details-tickets">
                                {tickets.map((ticket, index) => (
                                <div className="flex flex-column items-start justify-center w-100 mb-3">
                                    <div className="ticket-wrapper">
                                        <div className="ticket-name w-40">{ticket.label}</div>
                                        <div className="ticket-price w-15">Rs {ticket.price_formatted}</div>
                                        <div className={parseInt(ticket.stock)>0 ? "remaining-tickets w-15":"remaining-tickets soldout w-15"}>{ticket.stock} Left</div>
                                        <div className="quantity w-20">
                                            {parseInt(ticket.stock)>0 && 
                                                <input type="number"  className="form-control" onChange={(e)=> updateQuantity(e, index)} value={ticket.quantity} />
                                            }
                                            {parseInt(ticket.stock)<1 && 
                                                <span class="soldout">Sold-Out</span>
                                            }
                                        </div>
                                        {ticket.seating_type=="Yes" && 
                                            <div class="allocateseats w-15">
                                                {parseInt(ticket.stock)>0 && 
                                                    <button type="button" className="btn btn-sm btn-success bg-green-800 hover:bg-green-600" onClick={(e)=>loadSeats(index, ticket.id, ticket.label, ticket.quantity)} disabled={ticket.quantity<1}><FontAwesomeIcon icon={faChair} /> Allocate Seats</button>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {ticket.seats.length>0 && 
                                    <div className="flex justify-start items-center w-100 mt-0 bg-slate-400 p-2">
                                        {ticket.seating_type=="Yes" && ticket.seats.length>0 && ticket.seats.map((seat, index2) => ( 
                                            <div className="seat-wrapper" key={index2}>
                                                <div className="cursor-pointer absolute -top-1 -right-1">
                                                    <FontAwesomeIcon className="del-seats text-white" icon={faTimesCircle} size="1x" />
                                                </div>
                                                <div className="seat-number">{seat.seat_name}</div>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col tickets-heading">
                            Enter Customer Details 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col customer-details-form">
                                <div className="form-group">
                                    <label htmlFor="customer-name">Customer Name</label>
                                    <input type="text" className="form-control" id="customer-name" placeholder="Enter Customer Name" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customer-phone">Phone Number</label>
                                    <input type="number" className="form-control" id="customer-phone" placeholder="Enter Customer Phone" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)}  />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customer-email">Customer Email</label>
                                    <input type="email" className="form-control" id="customer-email" placeholder="Enter Customer Email" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)}  />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col book-tickets-buttons">
                            <button className="btn btn-danger" onClick={e => clearAll()}>Clear</button>
                            <div className="total-wrapper">Total: Rs {total}</div>
                            <div className="booktickets-payment">
                                <select className="form-control form-control-select select-payment" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)}>
                                    <option value="cash">Cash</option>
                                    <option value="terminal">Terminal Payment</option>
                                </select>
                                <button className="btn btn-success" disabled={total==0 || loading} onClick={e => placeOrder()}>
                                    {loading ? "Creating Order..." : "Book Now"}
                                </button>
                            </div>
                        </div>
                    </div>
                    {orderID>0 && 
                    <div className="row">
                        <div className="col download-tickets">
                            <div class="alert alert-success" role="alert">
                                <span><b>Order# {orderID} Added Successfully!</b></span> <span><a href={"https://otayo.com/phantom/physicalticket-onsite.php?order="+orderID+"&admin=pos"} target="_blank">Download Tickets</a></span> 
                            </div>
                        </div>
                    </div>
                    }
                </div>
                )}
            </>
            )}
        </div>
         </div>


         <div className="d-lg-none overflow-hidden" style={{height : '100vh'}}>
       
          <div className="px-3 pb-3">
          <Select options={events} 
             placeholder="Select an Event" 
             onChange={(option) => eventChanged(option)}
             />
          </div>

          {/* if no seating map */}
          <div>
          {tickets && (
                <div className="container">
                  
                    {seatingChartID==0 && 
                    <>
                        <div className="row">
                            <div className="col tickets-heading my-3">
                                Select Tickets Below
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    {tickets.map((ticket, index) => (
                                    <div className="flex flex-column items-start justify-center w-100 mb-3">
                                        <div className="ticket-wrapper text-sm">
                                            <div className="ticket-name w-40">{ticket.label}</div>
                                            <div className="ticket-price w-15">Rs {ticket.price_formatted}</div>
                                            <div className={parseInt(ticket.stock)>0 ? "remaining-tickets w-15":"remaining-tickets soldout w-15"}>{ticket.stock} Left</div>
                                            <div className="quantity w-20">
                                                {parseInt(ticket.stock)>0 && 
                                                    <input type="number"  className="form-control" onChange={(e)=> updateQuantity(e, index)} value={ticket.quantity} />
                                                }
                                                {parseInt(ticket.stock)<1 && 
                                                    <span class="soldout">Sold-Out</span>
                                                }
                                            </div>
                                            {ticket.seating_type=="Yes" && 
                                                <div class="allocateseats w-15">
                                                    {parseInt(ticket.stock)>0 && 
                                                        <button type="button" className="btn btn-sm btn-success bg-green-800 hover:bg-green-600" onClick={(e)=>loadSeats(index, ticket.id, ticket.label, ticket.quantity)} disabled={ticket.quantity<1}><FontAwesomeIcon icon={faChair} /> Allocate Seats</button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {ticket.seats.length>0 && 
                                        <div className="flex justify-start items-center w-100 mt-0 bg-slate-400 p-2">
                                            {ticket.seating_type=="Yes" && ticket.seats.length>0 && ticket.seats.map((seat, index2) => ( 
                                                <div className="seat-wrapper" key={index2}>
                                                    <div className="cursor-pointer absolute -top-1 -right-1">
                                                        <FontAwesomeIcon className="del-seats text-white" icon={faTimesCircle} size="1x" />
                                                    </div>
                                                    <div className="seat-number">{seat.seat_name}</div>
                                                </div>
                                            ))}
                                        </div>
                                        }
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>

                    }
                </div>
            )}
          </div>

          {/* if yes seating map */}
         {seatingChartID>0 && chartHtml!='' ?
                <div className="row">
                    <div className="col relative vw-100 h-max border-slate-400 border" style={{overflow: 'hidden'}}>
                        <div className="seating-chart-controls fixed right-1 top-30 flex flex-col justify-start items-start">
                            <div className="mb-2">
                                <button className="bg-orange-600 text-white rounded-sm px-2 py-1 text-xl mr-2 shadow-sm" onClick={e => panzoom.current.zoomOut(8)}> -- </button>
                                <button className="bg-orange-600 text-white rounded-sm px-2 py-1 text-xl shadow-sm" onClick={e => panzoom.current.zoomIn(8)}> + </button>
                            </div>
                            
                            {/* <button className="btn btn-sm btn-success bg-green-800 hover:bg-green-600 border-none rounded-none  font-bold text-base mb-2" onClick={e => autoCenter()}>Center</button> */}
                            <button className="bg-orange-600 text-white rounded-sm px-2 py-1 text-lg shadow-sm" onClick={e => reset()}>Reset</button>
                        </div>
                        <PanZoom
                            // autoCenter={true}
                            // autoCenterZoomLevel={0.3}
                            ref={panzoom}
                            // enableBoundingBox={true}
                            noStateUpdate={true}
                        >
                            <div className="z-10" ref={seatingRef} style={{height: "100vh", display: "block"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chartHtml) }}></div>
                        </PanZoom>
                    </div>
                </div>

                : <>  {seatingChartID>0 && chartHtml=='' && 
                <div className="row">
                    <div className="col relative vw-100 h-max mt-5 p-3 border-slate-400 border flex justify-center items-center" style={{height: "50vh", overflow: 'hidden'}}>
                        <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#f36523', '#FF9A6B']}
                        />
                    </div>
                </div>
                } </>
         }

         <button onClick={toggleDrawer} className="fixed bottom-5 text-white font-semibold rounded-sm right-5 z-50 px-4 py-2 bg-orange-600 hover:bg-slate-700 shadow">
           Ticket / Customer Details
         </button> 
         </div>
        <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={toggleDrawer}>
            
            <div className="overflow-scroll" style={{height : '60vh' , top : '-20px'}}>
  
                {seatingChartID > 0 && tickets.length > 0 && 
                <div className="row px-3">
                    <div className="col flex justify-center items-center">
                        {tickets.map((ticket, index) => (
                            <div className="seat-info p-2 text-white bold mr-2 last:mr-0" key={index} style={{backgroundColor: ticket.seat_color}}>
                                {ticket.label} - {ticket.price_formatted}
                            </div>
                        ))}
                    </div>
                </div>
                }
                {seatingChartID>0 && seats.length>0 && 
                <div className="px-3">
                <div className="row">
                    <div className="col font-bold mt-3">
                        Select Seats:
                    </div>
                </div>
                <div className="row flex justify-between items-center" >
                    <div className="col flex flex-wrap gap-2 items-center mb-3">
                        {seats.map((seat, index) => (
                            <div className="seat-info text-xs p-2 text-white bold mr-2 last:mr-0 bg-orange-600" key={index} style={{backgroundColor: seat.color}}>
                                SEAT # {seat.label}
                            </div>
                        ))}
                    </div>
                    <div>
                     <button className="bg-red-500 hover:bg-red-800 text-white font-bold text-nowrap px-4 py-1 w-full" id="empty-cart" onClick={e => emptyCart()}>Empty Cart</button>
                    </div>
                </div>
                </div>
                }   

                 {tickets && (
                <div className="container">
                    <div className="row">
                        <div className="col tickets-heading my-3">
                            Enter Customer Details 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col">
                                <div className="form-group py-2">
                                    <label htmlFor="customer-name pb-1">Customer Name</label>
                                    <input type="text" className="form-control" id="customer-name" placeholder="Enter Customer Name" value={customerName} onChange={e => setCustomerName(e.target.value)} />
                                </div>
                                <div className="form-group py-2">
                                    <label htmlFor="customer-phone pb-1">Phone Number</label>
                                    <input type="number" className="form-control" id="customer-phone" placeholder="Enter Customer Phone" value={customerPhone} onChange={e => setCustomerPhone(e.target.value)}  />
                                </div> 
                                <div className="form-group py-2">
                                    <label htmlFor="customer-email pb-1">Customer Email</label>
                                    <input type="email" className="form-control" id="customer-email" placeholder="Enter Customer Email" value={customerEmail} onChange={e => setCustomerEmail(e.target.value)}  />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col d-flex flex-col">
                            <div className="d-flex justify-content-between py-2">
                            <button className="btn btn-danger px-4" onClick={e => clearAll()}>Clear</button>
                            <div className="">
                                <select className="form-control form-control-select select-payment w-100" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)}>
                                    <option value="cash">Cash</option>
                                    <option value="terminal">Terminal Payment</option>
                                </select>
                            </div>
                            </div>

                            <div className="d-flex justify-content-between py-2">
                            <div className="total-wrapper text-white px-4 py-1">Total: Rs {total}</div>
                            <button className="border px-3 py-1 bg-green-600 text-white rounded-sm" disabled={total==0 || loading} onClick={e => placeOrder()}>
                                    {loading ? "Creating Order..." : "Book Now"}
                            </button>
                            </div>
                            
                        </div>
                    </div>
                    {orderID>0 && 
                    <div className="row">
                        <div className="col download-tickets">
                            <div class="alert alert-success" role="alert">
                                <span><b>Order# {orderID} Added Successfully!</b></span> <span><a href={"https://otayo.com/phantom/physicalticket-onsite.php?order="+orderID+"&admin=pos"} target="_blank">Download Tickets</a></span> 
                            </div>
                        </div>
                    </div>
                    }
                </div>
            )}             

            </div>
        </Drawer>

        </>
    );
}

export default OnSiteSelling;