import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faCaretUp, faSort} from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";

import AuthService from "../services/auth.service";
import SalesService from "../services/sales.service";
import UtilityService from "../services/utility.service";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from "chart.js";
import { Pie, Bar, Line } from "react-chartjs-2";
import "../styles/SalesReport.css"

import "../styles/Dashboard.css";
import DashboardService from '../services/dashboard.service';
import { set } from "react-hook-form";
// import { faker } from '@faker-js/faker';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import "../styles/Dashboard.css";
import SalesReportCard from "../components/Cards/SalesReportCard";
import { Divider } from "@mui/material";
import Spinner from '../components/LoadingState/Spinner'

import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import ExportAdvancedSalesCSV from "../components/CSV/ExportAdvancedSalesCSV";
import ExportSalesAnalyticsCSV from "../components/CSV/ExportSalesAnalyticsCSV";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  LineElement,
  );

  

export const optionsBArChart = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right"
    },
    // title: {
    //   display: true,
    //   text: "Total Sales in Rs"
    // }
  }
};

export const optionsLineChart = {
  responsive: true,

  plugins: {
      legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Sales Analytics',
    },
  },
};

const SalesReport = () => {
    const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateAnalytics, setStartDateAnalytics] = useState(new Date().setDate(new Date().getDate() - 30));
    const [endDateAnalytics, setEndDateAnalytics] = useState(new Date());
    const [minDate, setMinDate] = useState('');
    const [basicReport, setBasicReport] = useState(null);
    const [advanceSalesReport, setAdvanceSalesReport] = useState(null);
    const [advanceSalesReportAnalytics, setAdvanceSalesReportAnalytics] = useState(null);
    const [graphsReport, setGraphsReport] = useState(null);
    const [user, setUser] = useState(null);
    const [dataTicketSalesPerEvent, setDataTicketSalesPerEvent] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

     const [dataSalesAnalytics, setDataSalesAnalytics] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

    const [stats, setStats] = useState({
        orders: 0,
        sales: 0,
        tickets: 0,
    });

    const [dataAmountPerEvent, setDataAmountPerEvent] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });
     const [dataOrderPerEvent, setDataOrderPerEvent] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });
     const [loadingAdvance, setLoadingAdvance] = useState("Preparing Report...");
     const [loadingAdvanceAnalytics, setLoadingAdvanceAnalytics] = useState("Fetching Report...");

     const [dataTicketSalesPerPlatform, setDataTicketSalesPerPlatform] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
        }]
     });

    const [pospercentage, setpospercentage] = useState(0);
    const [websitepercentage, setwebsitepercentage] = useState(0);
    const [sorttype, setsorttype] = useState(true);
    const [advancedRange, setAdvancedRange] = useState('alltime');
    const [advancedRangeAnalytics, setAdvancedRangeAnalytics] = useState('alltime');
    const [includeInvites, setincludeInvites] = useState('no');

    const [ShowByList, setShowByList] = useState(false)

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            // console.log("user", user)
            if(user!=false) {
                DashboardService.getStats()
                .then(res => {
                    // console.log(res.data);
                    setStats({
                        orders: res.data.orders,
                        sales: res.data.amount,
                        tickets: res.data.tickets,
                    });
                })
                // sevenDayStartEnd();
                // sevenDayStartEndAnalytics();
                SalesService.getBasicSalesReport()
                    .then(response => {
                        // console.log("BAsic", response.data);
                        setBasicReport(response.data);

                        var templabel = [];
                        var tempData = [];

                        response.data.map((item, index) => {
                            templabel.push(item.eventname);
                            tempData.push(item.totalsold);
                        });

                        setDataTicketSalesPerEvent({
                            labels: templabel,
                            datasets: [{
                                data: tempData,
                                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                            }]
                        });

                        var templabel3 = [];
                        var tempData3 = [];

                        response.data.map((item, index) => {
                            templabel3.push(item.eventname);
                            tempData3.push(item.totalOrders);
                        });

                        setDataOrderPerEvent({
                            labels: templabel3,
                            datasets: [{
                                data: tempData3,
                                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                            }]
                        });

                        var templabel1 = [];
                        var tempData1 = [];

                        response.data.map((item, index) => {
                            templabel1.push(item.eventname);
                            tempData1.push(item.totalsales);
                        });

                        setDataAmountPerEvent({
                            labels: templabel1,
                            datasets: [{
                                data: tempData1,
                                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                            }]
                        });

                        var templabel2 = ['POS', 'Website'];
                        var tempData2 = [response.data[response.data.length-1].amountbypos, response.data[response.data.length-1].amountbywebsite];
                        setpospercentage(response.data[response.data.length-1].amountpercentbypos);
                        setwebsitepercentage(response.data[response.data.length-1].amountpercentbywebsite);

                        setDataTicketSalesPerPlatform({
                            labels: templabel2,
                            datasets: [{
                                data: tempData2,
                                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
                            }]
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    });

                    getAdvanceReportAnalytics(null);
                
                SalesService.getAdvanceSalesReport(startDate, endDate)
                    .then(response => {
                        // console.log('getAdvanceSalesReport', response.data);
                        setAdvanceSalesReport(response.data);
                        setTimeout(() => {
                            // allTimeSales();
                        }, 1000);

                        setLoadingAdvance("");
                    })
                    .catch(err => {
                        console.log(err);
                    });
                
                SalesService.getSalesGraphs()
                    .then(response => {
                        setGraphsReport(response.data);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            else {
                window.location.href = "/login";
            }
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    useEffect(() => {
        if(advanceSalesReport!=null) {
            allTimeSales();
        }
    }, [advanceSalesReport]);

    const getAdvanceReportAnalytics = (e) => {
        // e.preventDefault();
        // console.log(startDateAnalytics, endDateAnalytics);
        
        setLoadingAdvanceAnalytics('Fetching Report...');
        SalesService.getAdvanceSalesReportAnalytics( UtilityService.format_date_time(startDateAnalytics), UtilityService.format_date_time(endDateAnalytics), "", includeInvites )
        .then((res) => {
            setAdvanceSalesReportAnalytics(res.data);
            // console.log("analytics", res.data);
            setLoadingAdvanceAnalytics(false);

            setDataSalesAnalytics({
                labels: res.data.labels,
                datasets: res.data.graphData
            });

            console.log(dataSalesAnalytics.datasets)
            

        })
        .catch((err) => {
            console.log(err);
        });

    }

    const allTimeSales = () => {
        var templabel = [];
        var tempData = [];

        advanceSalesReport.map((item, index) => {
            templabel.push(item.eventname);
            tempData.push(item.totalsold);
        });

        setDataTicketSalesPerEvent({
            labels: templabel,
            datasets: [{
                data: tempData,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });

        var templabel1 = [];
        var tempData1 = [];

        advanceSalesReport.map((item, index) => {
            templabel1.push(item.eventname);
            tempData1.push(item.totalsales);
        });

        setDataAmountPerEvent({
            labels: templabel1,
            datasets: [{
                data: tempData1,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });
    }

    const OneDaySalesSales = () => {
        var templabel = [];
        var tempData = [];

        advanceSalesReport.map((item, index) => {
            templabel.push(item.eventname);
            tempData.push(item.last24hoursTickets);
        });

        setDataTicketSalesPerEvent({
            labels: templabel,
            datasets: [{
                data: tempData,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });

        var templabel1 = [];
        var tempData1 = [];

        advanceSalesReport.map((item, index) => {
            templabel1.push(item.eventname);
            tempData1.push(item.last24hours);
        });

        setDataAmountPerEvent({
            labels: templabel1,
            datasets: [{
                data: tempData1,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });
    }

    const SevenDaySalesSales = () => {
        var templabel = [];
        var tempData = [];

        advanceSalesReport.map((item, index) => {
            templabel.push(item.eventname);
            tempData.push(item.last7daysTickets);
        });

        setDataTicketSalesPerEvent({
            labels: templabel,
            datasets: [{
                data: tempData,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });

        var templabel1 = [];
        var tempData1 = [];

        advanceSalesReport.map((item, index) => {
            templabel1.push(item.eventname);
            tempData1.push(item.last7days);
        });

        setDataAmountPerEvent({
            labels: templabel1,
            datasets: [{
                data: tempData1,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });
    }

    const ThirtyDaySalesSales = () => {
        var templabel = [];
        var tempData = [];

        advanceSalesReport.map((item, index) => {
            templabel.push(item.eventname);
            tempData.push(item.last30daysTickets);
        });

        setDataTicketSalesPerEvent({
            labels: templabel,
            datasets: [{
                data: tempData,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });

        var templabel1 = [];
        var tempData1 = [];

        advanceSalesReport.map((item, index) => {
            templabel1.push(item.eventname);
            tempData1.push(item.last30days);
        });

        setDataAmountPerEvent({
            labels: templabel1,
            datasets: [{
                data: tempData1,
                backgroundColor: ["#336699","#99CCFF","#999933","#666699","#CC9933","#006666","#3399FF","#993300","#CCCC99","#666666","#FFCC66","#6699CC","#663366","#9999CC","#CCCCCC","#669999","#CCCC66","#CC6600","#9999FF","#0066CC","#99CCCC","#999999","#FFCC00","#009999","#99CC33","#FF9900","#999966","#66CCCC","#339966","#CCCC33","#003f5c","#665191","#a05195","#d45087","#2f4b7c","#f95d6a","#ff7c43","#ffa600","#EF6F6C","#465775","#56E39F","#59C9A5","#5B6C5D","#0A2342","#2CA58D","#84BC9C","#CBA328","#F46197","#DBCFB0","#545775"],
            }]
        });
    }

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const setStartDateCalAnalytics = (date) => {

        setStartDateAnalytics(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDateAnalytics(date);
        }
    }

    const setEndDateCalAnalytics = (date) => {

        setEndDateAnalytics(date);
    }

    const getAdvanceReportDateRange = (e) => {
        // e.preventDefault();
        // console.log(startDate, endDate);
        if(startDate == '' || endDate == '') {
            // console.log("Please select start date and end date");
            swal("Error", "Please select start date and end date", "error");
            // return;
        } else {
            setLoadingAdvance('Preparing Report...');
            SalesService.getAdvanceSalesReport( UtilityService.format_date_time(startDate), UtilityService.format_date_time(endDate) )
            .then((res) => {
                setAdvanceSalesReport(res.data);
                // console.log(res.data);
                setLoadingAdvance(false);
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    const sortData = (key, type) => {
        if(type == true) {
            setsorttype(type);
            var sortedData = basicReport.sort((a, b) => {
                if(a[key] < b[key]) { return -1; }
                if(a[key] > b[key]) { return 1; }
                return 0;
            });
            setBasicReport([...sortedData]);
        } else {
            setsorttype(type);
            var sortedData = basicReport.sort((a, b) => {
                if(a[key] > b[key]) { return -1; }
                if(a[key] < b[key]) { return 1; }
                return 0;
            });
            setBasicReport([...sortedData]);
        }
    }

    const sortAdvancedData = (key, type) => {
        if(type == true) {
            setsorttype(type);
            var sortedData = advanceSalesReport.sort((a, b) => {
                if(a[key] < b[key]) { return -1; }
                if(a[key] > b[key]) { return 1; }
                return 0;
            });
            setAdvanceSalesReport([...sortedData]);
        } else {
            setsorttype(type);
            var sortedData = advanceSalesReport.sort((a, b) => {
                if(a[key] > b[key]) { return -1; }
                if(a[key] < b[key]) { return 1; }
                return 0;
            });
            setAdvanceSalesReport([...sortedData]);
        }
    }
    
    const allTimeStartEnd = () => {
        setStartDate('');
        setEndDate('');
        setAdvancedRange('alltime');
    }

    const oneDayStartEnd = () => {
        var start = new Date();
        start.setDate(start.getDate() - 1);
        setStartDate(start);
        
        var end = new Date();
        setEndDate(end);
        setAdvancedRange('oneday');
    }

    const sevenDayStartEnd = () => {
        var start = new Date();
        start.setDate(start.getDate() - 7);
        setStartDate(start);

        var end = new Date();
        setEndDate(end);
        setAdvancedRange('sevenday');
    }

    const thirtyDayStartEnd = () => {
        var start = new Date();
        start.setDate(start.getDate() - 30);
        setStartDate(start);

        var end = new Date();
        setEndDate(end);
        setAdvancedRange('thirtyday');
    }

    const allTimeStartEndAnalytics = () => {
        setStartDateAnalytics('');
        setEndDateAnalytics('');
        setAdvancedRangeAnalytics('alltime');
    }

    const oneDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 1);
        setStartDateAnalytics(start);
        
        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('oneday');
    }

    const sevenDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 7);
        setStartDateAnalytics(start);

        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('sevenday');
    }

    const thirtyDayStartEndAnalytics = () => {
        var start = new Date();
        start.setDate(start.getDate() - 30);
        setStartDateAnalytics(start);

        var end = new Date();
        setEndDateAnalytics(end);
        setAdvancedRangeAnalytics('thirtyday');
    }


    const handleAnalyticsSelectChange = (selectedValue) => {
        switch (selectedValue) {
          case "24hrs":
            oneDayStartEndAnalytics();
            break;
          case "7days":
            sevenDayStartEndAnalytics();
            break;
          case "30days":
            thirtyDayStartEndAnalytics();
            break;
          case "allTime":
            allTimeStartEndAnalytics();
            break;
          default:
            break;
        }
      };


 

    return (
        <>
        <div className="d-lg-none">

         <div className="d-flex justify-content-between align-items-center"  style={{padding : "0px 10px 15px 10px"}}>
            <p className='' style={{fontWeight : "700",marginBottom : "0px"  ,color : "#aaaaaa"}}>
              Sales Report
            </p>           
            {/* <button className="bg-orange-500 text-white px-2 py-1 text-sm font-semibold rounded-sm">Report Per Event</button> */}
         </div>

         <div className='flex flex-column stats-cards justify-between p-0 px-2'>
                <div className='stats-sep'>
                <div className='card orders'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Orders</p>
                        <CreditCardIcon sx={{ fontSize: 22 }} />                        
                    </div>
                    <div className='title'>{stats.orders}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                <div className='card tickets'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Ticket Sold</p>
                        <ConfirmationNumberOutlinedIcon sx={{ fontSize: 22 }}/>
                    </div>
                    <div className='title'>{stats.tickets}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>

                </div>

                <div className='card amount'>
                    <div className="title-sub d-flex justify-content-between align-items-center m-0 text-white">
                        <p>Sales Amount</p>
                        <AttachMoneyOutlinedIcon sx={{ fontSize: 22 }}/>
                    </div>
                    <div className='title'>Rs {stats.sales}</div>
                    <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                </div>
         </div> 

         <div style={{padding : "0px 10px 0px 10px"}}>
          <div className="d-flex justify-content-between pb-3 pt-2">

          <p className='' style={{fontWeight : "700" ,color : "#000"}}>
           Event Sales Analytics:
          </p>

          {loadingAdvanceAnalytics && <Spinner/>}   
          </div>

            {/* <div>
                <div>
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={startDate}
                    onChange={date => setStartDateCal(date)}
                    placeholderText="Start Date"
                    className='form-control text-sm'
                />
                </div>
                <div>                  
                    <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={endDate}
                    onChange={date => setEndDateCal(date)}
                    placeholderText="End Date"
                    className='form-control text-sm'
                    minDate = {minDate}
                    />
                </div>
                <div>
                    <button href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getAdvanceReportDateRange(e)} >Get Report</button>
                </div>
                <div>
                    <button href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 rounded-none text-white text-sm" onClick={(e) => window.location.reload(false)} >Reset</button>
                </div>
            </div> */}

          <div className="rounded-sm border shadow-sm">
            <div className="px-3 py-2 d-flex justify-content-between align-items-center">
                <div>
                 <p className="font-semibold text-xl text-orange-500">
                    Rs {advanceSalesReportAnalytics!==null &&
                        advanceSalesReportAnalytics.totalSalesUi
                    }</p>
                 <p className="text-secondary text-xs">Total Sales</p>
                </div>
                <div className="">
                <select
                    className="border rounded-sm bg-white text-xs p-2 outline-none"
                    onChange={(event) => handleAnalyticsSelectChange(event.target.value)}
                    >
                    <option value="24hrs">24 Hrs</option>
                    <option value="7days">7 Days</option>
                    <option value="30days" selected>30 Days</option>
                    <option value="allTime">All time</option>
                </select>
                </div>
            </div>

            <Divider />

            <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                <p className='text-secondary text-nowrap'>Average Daily :</p>
                <p className="font-medium">Rs {advanceSalesReportAnalytics!==null && 
                 advanceSalesReportAnalytics.avgDailySalesUi
                }</p>
            </div>

            <Divider />

            <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                <p className='text-secondary text-nowrap'>Order Placed :</p>
                <p className="font-medium">{advanceSalesReportAnalytics!==null && 
                advanceSalesReportAnalytics.ordersPlacedUi
                }</p>
            </div>

            <Divider />

            <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                <p className='text-secondary text-nowrap'>Ticket Sold :</p>
                <p className="font-medium">{advanceSalesReportAnalytics!==null && 
                    advanceSalesReportAnalytics.ticketSoldUi
                   }</p>
            </div>

          </div>
         </div>


         <div className="" style={{padding : "0px 10px 0px 10px"}}>

         <div className="d-flex justify-content-between align-items-center">
          <p className='py-3' style={{fontWeight : "700",marginBottom : "0px"  ,color : "#000"}}>
           Event Sales Details:
          </p> 
          {basicReport == null ?
          <div className=""><Spinner/></div> :
           <div className="d-flex align-items-center">
            <button style={{ color: ShowByList ? '#f37236' : '' }} disabled={ShowByList} onClick={() => setShowByList(!ShowByList)}><TableRowsOutlinedIcon style={{ fontSize: '25px' }}/></button>
            │
            <button  style={{ color: !ShowByList ? '#f37236' : '' }} disabled={!ShowByList} onClick={() => setShowByList(!ShowByList)}><FormatListBulletedOutlinedIcon style={{ fontSize: '25px' }}/></button> 
          </div>
         }
         </div>

         {/* Table */}
          <div className="overflow-scroll"style={{ display: ShowByList ? 'block' : 'none' }}>
            <table className="table table-striped">
            <thead className="text-sm text-nowrap border" >
                <tr>
                    <th scope="col" className="uppercase cursor-pointer" onClick={e => sortData('eventname', !sorttype)}>Event Name <FontAwesomeIcon icon={faSort}  style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="cursor-pointer uppercase" onClick={e => sortData('eventdateraw', !sorttype)}>Event Date <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsold', !sorttype)}>Ticket Sold <FontAwesomeIcon icon={faSort}  style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsales', !sorttype)}>Total Sale <FontAwesomeIcon icon={faSort}  style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalreserved', !sorttype)}>Tickets Reserved <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} /></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('ticketsallocated', !sorttype)}>Tickets Allocated <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('remaining', !sorttype)}>Remaining <FontAwesomeIcon icon={faSort}  style={{ fontSize: '10px' }}/></th>
                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsoldpercentage', !sorttype)}>% of Ticket Sold <FontAwesomeIcon icon={faSort}  style={{ fontSize: '10px' }}/></th>
                    <th scope="col"  className=" uppercase">Producer</th>
                </tr>
            </thead>
            <tbody className="text-xs">
                {basicReport!==null && basicReport.map((event, index) => (
                    <tr key={index} data-event-id={event.id} className="">
                        <td><a href={"/event/"+event.id} className="textf36523 underline font-bold text-nowrap">{event.eventname}</a></td>
                        <td className="textaeaeae font-bold ">{event.eventdate}</td>
                        <td className="text-center textaeaeae font-bold">{event.totalsold_ui}</td>
                        <td className="text-center font-bold">Rs {event.totalsales_ui}</td>
                        <td className="text-center">{event.totalreserved_ui}</td>
                        <td className="text-center">{event.ticketsallocated_ui}</td>
                        <td className="text-center">{event.remaining_ui}</td>
                        <td className="text-center font-bold">{event.totalsoldpercentage}%</td>
                        <td><a href={"/edit-producer/"+event.producer.id} className="text01bff1 cursor-pointer text-center underline">
                            {/* {event.producer.name} */}
                            View
                            </a></td>
                    </tr>
                ))}
            </tbody>
            </table>
          </div>

          {/* List*/}
          <div style={{ display: !ShowByList ? 'block' : 'none' }}>
           {basicReport!==null && basicReport.map((event, index) => (
               <SalesReportCard data={event} />
               ))}
          </div>
         </div>

         <div>
         <Bar data={dataAmountPerEvent} options={optionsBArChart} />
         </div>

         <div>
         <Bar data={dataOrderPerEvent} options={optionsBArChart} />
         </div>

         <div>
         <Pie data={dataTicketSalesPerEvent} />
         </div>

         <div>
         <Pie data={dataTicketSalesPerPlatform} />
         </div>


        </div>

        <div className="d-none d-lg-block salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row">
                    <div className="col-12 flex justify-between items-center w-100">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">Sales Report</h1>
                        <a href="/reports-per-event" className="btn btn-sm btn-warning bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-none">Reports per Event</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="font-bold text-base p-2 subsection-heading mb-2">Event Ticket Sales Overview:</h2>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12 flex stats-cards justify-between items-center'>
                        <div className='card orders'>
                            <div className='title'>{stats.orders}</div>
                            <div className="title-sub">ORDERS</div>
                            <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                        </div>

                        <div className='card tickets'>
                            <div className='title'>{stats.tickets}</div>
                            <div className="title-sub">TICKETS SOLD</div>
                            <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                        </div>

                        <div className='card amount'>
                            <div className='title'>Rs {stats.sales}</div>
                            <div className="title-sub">SALES AMOUNT</div>
                            <div className="percent"><FontAwesomeIcon icon={faCaretUp} /> In last seven days</div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <table className="table table-striped">
                            <thead className=" text-sm">
                                <tr>
                                    <th scope="col" className="uppercase cursor-pointer" onClick={e => sortData('eventname', !sorttype)}>Event Name <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="cursor-pointer uppercase" onClick={e => sortData('eventdateraw', !sorttype)}>Event Date <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsold', !sorttype)}>Ticket Sold <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsales', !sorttype)}>Total Sale <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalreserved', !sorttype)}>Tickets Reserved <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('ticketsallocated', !sorttype)}>Tickets Allocated <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('remaining', !sorttype)}>Remaining <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortData('totalsoldpercentage', !sorttype)}>% of Ticket Sold <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col"  className=" uppercase">Producer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {basicReport!==null && basicReport.map((event, index) => (
                                    <tr key={index} data-event-id={event.id} className=" text-sm">
                                        <td><a href={"/event/"+event.id} className="textf36523 underline font-bold">{event.eventname}</a></td>
                                        <td className="textaeaeae font-bold">{event.eventdate}</td>
                                        <td className="text-center textaeaeae font-bold">{event.totalsold_ui}</td>
                                        <td className="text-center font-bold">Rs {event.totalsales_ui}</td>
                                        <td className="text-center">{event.totalreserved_ui}</td>
                                        <td className="text-center">{event.ticketsallocated_ui}</td>
                                        <td className="text-center">{event.remaining_ui}</td>
                                        <td className="text-center font-bold">{event.totalsoldpercentage}%</td>
                                        <td><a href={"/edit-producer/"+event.producer.id} className="text01bff1 cursor-pointer text-center underline">
                                            {/* {event.producer.name} */}
                                            View
                                            </a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="font-bold text-base p-2 subsection-heading mb-2 text5e4642">Event Sales Analytics: <span className="text-red-600 font-bold ml-4">{loadingAdvanceAnalytics}</span></h2>
                        <div className="flex justify-start">
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => allTimeStartEndAnalytics(e)} >All Time</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => oneDayStartEndAnalytics(e)} >Past 24 Hours</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => sevenDayStartEndAnalytics(e)} >Past 7 Days</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => thirtyDayStartEndAnalytics(e)} >Past 30 Days</button>
                            </div>
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDateAnalytics}
                                    onChange={date => setStartDateCalAnalytics(date)}
                                    placeholderText="Start Date"
                                    className='form-control text-sm'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDateAnalytics}
                                    onChange={date => setEndDateCalAnalytics(date)}
                                    placeholderText="End Date"
                                    className='form-control text-sm'
                                    minDate = {minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>  
                               <select value={includeInvites} className="form-control form-control-select" onChange={e => setincludeInvites(e.target.value)}>
                                <option value="yes">Include Invites</option>
                                <option value="no">Do Not Include Invites</option>
                               </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getAdvanceReportAnalytics(e)} >Get Report</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 rounded-none text-white text-sm" onClick={(e) => window.location.reload(false)} >Reset</button>
                            </div>

                            <ExportSalesAnalyticsCSV SalesAnalytics={dataSalesAnalytics.datasets} />
                        </div> 
                    </div>
                </div>
                <div className="row mb-10">
                    <div className="col-2">
                        <div className="flex flex-col items-center justify-center analytics-item-wrapper">
                            <div className="analytics-item">
                                <div className="header">Total Sales</div>
                                <div className="value">Rs
                                    {advanceSalesReportAnalytics!==null &&
                                        advanceSalesReportAnalytics.totalSalesUi
                                    }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Average Daily</div>
                                <div className="value">Rs
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.avgDailySalesUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Orders Placed</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.ordersPlacedUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Tickets Sold</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.ticketSoldUi
                                }
                                </div>
                            </div>
                            {/* <div className="analytics-item">
                                <div className="header">Add to Cart (Orders)</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.addtoCartOrdersUi
                                }
                                </div>
                            </div>
                            <div className="analytics-item">
                                <div className="header">Add to Cart (Tickets)</div>
                                <div className="value">
                                {advanceSalesReportAnalytics!==null && 
                                    advanceSalesReportAnalytics.addtoCartTicketsUi
                                }
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-10">
                        {advanceSalesReportAnalytics!==null &&
                            <div className="flex justify-center items-center">
                                <Line data={dataSalesAnalytics} options={optionsLineChart} />
                            </div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="font-bold text-base p-2 subsection-heading mb-2 text5e4642">Event Order Sales Analysis: <span className="text-red-600 font-bold ml-4">{loadingAdvance}</span></h2>
                        <div className="flex justify-start">
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => allTimeStartEnd(e)} >All Time</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => oneDayStartEnd(e)} >Past 24 Hours</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => sevenDayStartEnd(e)} >Past 7 Days</button>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <button type="button" className="btn btn-success bg-orange-600 hover:bg-orange-800 rounded-none text-sm" onClick={(e) => thirtyDayStartEnd(e)} >Past 30 Days</button>
                            </div>
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDate}
                                    onChange={date => setStartDateCal(date)}
                                    placeholderText="Start Date"
                                    className='form-control text-sm'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDate}
                                    onChange={date => setEndDateCal(date)}
                                    placeholderText="End Date"
                                    className='form-control text-sm'
                                    minDate = {minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-success bg-green-600 hover:bg-green-800 rounded-none text-sm" onClick={(e) => getAdvanceReportDateRange(e)} >Get Report</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button href="#" className="btn btn-warning bg-orange-600 hover:bg-orange-800 rounded-none text-white text-sm" onClick={(e) => window.location.reload(false)} >Reset</button>
                            </div>

                            <div style={{ marginLeft: '10px' }}>

                             <ExportAdvancedSalesCSV advanceSalesReport={advanceSalesReport} />
    
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-sales-analysis">
                        <table className="table table-striped">
                            <thead className=" text-sm">
                                <tr>
                                    <th scope="col" className="uppercase cursor-pointer" onClick={e => sortAdvancedData('eventname', !sorttype)}>Event Name <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="uppercase cursor-pointer" onClick={e => sortAdvancedData('eventdateraw', !sorttype)}>Event Date <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('totalsold', !sorttype)}>Tickets Sold <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('totalsales', !sorttype)}>Total Sales <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('nooforders', !sorttype)}>No. of Orders <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('avgamount', !sorttype)}>Average Order Amount <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('avgticketsperorder', !sorttype)}>Average No. Tickets per Order <FontAwesomeIcon icon={faSort} /></th>
                                    {/* <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('totalsoldpercentage', !sorttype)}>Total % of Ticket Sold <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('last24hours', !sorttype)}>Past 24 hours (Sales / Tickets) <FontAwesomeIcon icon={faSort} /></th>
                                    <th scope="col" className="text-center uppercase cursor-pointer" onClick={e => sortAdvancedData('last7days', !sorttype)}>Past 7 Days (Sales / Tickets) <FontAwesomeIcon icon={faSort} /></th> */}
                                    {/* <th scope="col" className="text-center">Sales During Date Range</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {advanceSalesReport!==null && advanceSalesReport.map((event, index) => (
                                    <tr key={index} data-event-id={event.id} className=" text-sm">
                                        <td><a href={"/event/"+ event.id} className="textf36523 underline font-bold">{event.eventname}</a></td>
                                        <td className="textaeaeae font-bold">{event.eventdate}</td>
                                        <td className="text-center font-bold">{event.totalsold_ui}</td>
                                        <td data-lookup={event.totalsaleslookup_ui} className="text-center font-bold">Rs {event.totalsales_ui}</td>
                                        <td className="text-center">{event.nooforders_ui}</td>
                                        <td className="text-center textaeaeae">Rs {event.avgamount_ui}</td>
                                        <td className="text-center">{event.avgticketsperorder_ui}</td>
                                        {/* <td className="text-center font-bold">{event.totalsoldpercentage}%</td>
                                        <td className="text-center">{event.last24hours_ui} / {event.last24hoursTickets_ui}</td>
                                        <td className="text-center">{event.last7days_ui} / {event.last7daysTickets_ui}</td> */}
                                        {/* <td className="text-center">{event.fromRange_ui}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <h2 className="font-bold text-base p-2 pl-0 subsection-heading mb-2 text5e4642">Sales Chart <span className="text-xs">(As per selected range above)</span>:</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div class="chart-actions mb-0">
                            {/* <button type="button" class="btn btn-warning graph-btns mr-10 active:bg-orange-500 disabled:bg-gray-500 disabled:border-none text-sm" onClick={(e) => allTimeSales()}>{(startDate=='' || endDate=='')? "All Time": "Selected Range"}</button>
                            <button type="button" class="btn btn-warning graph-btns mr-10 disabled:bg-gray-500 disabled:border-none active:bg-orange-500 text-sm" disabled={loadingAdvance!=''} onClick={e => OneDaySalesSales()}>Past 24 Hours</button>
                            <button type="button" class="btn btn-warning graph-btns mr-10 disabled:bg-gray-500 disabled:border-none active:bg-orange-500 text-sm" disabled={loadingAdvance!=''} onClick={e => SevenDaySalesSales()}> Past 7 Days</button>
                            <button type="button" class="btn btn-warning graph-btns disabled:bg-gray-500 disabled:border-none active:bg-orange-500 text-sm" disabled={loadingAdvance!=''} onClick={e => ThirtyDaySalesSales()}>Past 30 Days</button> */}
                        </div>
                        <div class="chart row pt-10 pb-10 w-100 mt-0 mb-10">
                            {/* <div className="chart-item col-1"></div> */}
                            <div className="chart-item col-12 flex flex-col justify-center items-center mb-6">
                                <div className="sub-heading text-base font-bold mb-4 text5e4642">Total Sales in Rs</div>
                                <Bar data={dataAmountPerEvent} options={optionsBArChart} />
                            </div>

                            {/* <div className="chart-item col-1"></div> */}
                        </div>

                        <div class="chart row pt-10 pb-10 w-100 mt-10 mb-10">
                            <div className="chart-item col-12 flex flex-col justify-center items-center">
                                <div className="sub-heading text-base font-bold mb-4 text5e4642">Orders Per Event</div>
                                <Bar data={dataOrderPerEvent} options={optionsBArChart} />
                            </div>
                        </div>

                        <div class="chart row pt-10 pb-10 w-100 mt-10 mb-10">
                            <div className="chart-item col-6 flex flex-col justify-center items-center">
                                <div className="sub-heading text-base font-bold mb-4 text5e4642">Sales Per Event</div>
                                <Pie data={dataTicketSalesPerEvent} />
                            </div>
                            <div className="chart-item col-1"></div>

                            <div className="chart-item col-4 flex flex-col justify-center items-center">
                                <div className="sub-heading text-base font-bold mb-4 text5e4642">Sales POS vs Wbsite <br /> POS: {pospercentage}% &nbsp;&nbsp;&nbsp;&nbsp;Website: {websitepercentage}%</div>
                                <Pie data={dataTicketSalesPerPlatform} />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

       
        </>
    );
}

export default SalesReport;