import axios from "axios";

const APIURL = "https://otayo.com/erpapi/events.php";
const ScanningControlURL = "https://otayo.com/phantom/scripts/exportattendies.php";

class EventsService {
    getAllEvents() {
        var params = {
            action: "getAllEvents"
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getEventTickets(eventId) {
        var params = {
            action: "getEventTickets",
            eventId: eventId
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    placeOrder(eventId, tickets, customerName, customerEmail, customerPhone, total, paymentMethod, user, autoCheckin='yes') {
        var params = {
            action: "placeOrder",
            eventId: eventId,
            tickets: tickets,
            // customer: {
                name: customerName,
                email: customerEmail,
                phone: customerPhone,
            // },
            total: total,
            paymentMethod: paymentMethod,
            autoCheckin: autoCheckin,
            user: user
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    checkinTickets(orderid) {
        var params = {
            // orderid: orderid
        }
        return axios.get(ScanningControlURL+"?orderid="+orderid, params);
    }

    getAllEventsArchive(search = null, page = 1, limit=20, sort = null) {
         var params = {
            search: search,
            page: page,
            limit: limit,
            sort: sort,
            action: "getAllEventsArchive",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getAllEventsCurrent(search = null, page = 1, limit=20, sort = null) {
         var params = {
            search: search,
            page: page,
            limit: limit,
            sort: sort,
            action: "getAllEventsCurrent",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEvent(eventId) {
        var params = {
            eventId: eventId,
            action: "getEvent",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEventTicketsSales(eventId, start, end) {
        var params = {
            eventId: eventId,
            action: "getEventTicketsSale",
            rand: Math.random(),
            start: start,
            end: end
        }
        return axios.post(APIURL, params);
    }

    getEventInviteTicketsSale(eventId) {
        var params = {
            eventId: eventId,
            action: "getEventInviteTicketsSale",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEventReport(eventId) {
        var params = {
            eventId: eventId,
            action: "getEventReport",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    updateEventMeta(eventId, key, meta) {
        var params = {
            eventId: eventId,
            meta: meta,
            key: key,
            action: "updateEventMeta",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getAllEventMeta(eventId) {
        var params = {
            eventId: eventId,
            action: "getAllEventMeta",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEventDeposits(eventId) {
        var params = {
            eventId: eventId,
            action: "getEventDeposits",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getPaymentToProducer(eventId) {
        var params = {
            eventId: eventId,
            action: "getPaymentToProducer",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getAdvancePaymentToProducer(eventId) {
        var params = {
            eventId: eventId,
            action: "getAdvancePaymentToProducer",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    addSingleTableEntry(eventId, data, type, user, id= 0) {
        var params = {
            eventId: eventId,
            data: data,
            type: type,
            action: "addSingleTableEntry",
            rand: Math.random(),
            user: user,
            id: id
        }
        return axios.post(APIURL, params);
    }

    deleteTableEntry(type, id, user, eventId) {
        var params = {
            type: type,
            id: id,
            action: "deleteTableEntry",
            user: user,
            eventId: eventId,
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEventCoupons(eventId) {
        var params = {
            eventId: eventId,
            action: "getEventCoupons",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    getEventCouponsClassifications(eventId) {
        var params = {
            eventId: eventId,
            action: "getEventCouponsClassifications",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    downloadLabels(eventId, status) {
        var params = {
            eventId: eventId,
            action: "downloadLabels",
            rand: Math.random(),
            status: status
        }
        return axios.post(APIURL, params);
    }

    getFinancialLogs(eventId) {
        var params = {
            eventId: eventId,
            action: "getFinancialLogs",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    eventRelatedFiles(eventId) {
        var params = {
            eventId: eventId,
            action: "eventRelatedFiles",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    deleteRelatedFile(id) {
        var params = {
            id: id,
            action: "deleteRelatedFile",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    submitRelatedFile(eventid, url, desc) {
        var params = {
            eventid: eventid,
            url: url,
            desc: desc,
            action: "submitRelatedFile",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    mobileSlider() {
        var params = {
            action: "mobileSlider",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

    updateSort(id, sort) {
        var params = {
            id: id,
            sort: sort,
            action: "updateMobileSliderSorting",
            rand: Math.random()
        }
        return axios.post(APIURL, params);

    }

    updateMobileSliderSortingAll(data) {
        var params = {
            data: data,
            action: "updateMobileSliderSortingAll",
            rand: Math.random()
        }
        return axios.post(APIURL, params);
    }

}

export default new EventsService();