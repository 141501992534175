import axios from "axios";

const APIURL = "https://otayo.com/erpapi/seatingchart.php";

class SeatingChartService {
    getAvailableSeatsPerTicketType(eventID, ticketID, qty) {
        var params = {
            action: "getAvailableSeatsPerTicketType",
            eventID: eventID,
            ticketID: ticketID,
            qty: qty
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getSeatingChartHtml(eventID) {
        var params = {
            action: "getSeatingChartHtml",
            eventID: eventID,
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

     getEventChartID(eventID) {
        var params = {
            action: "getEventChartID",
            eventID: eventID,
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
}

export default new SeatingChartService();