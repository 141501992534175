import * as React from "react";
import { useEffect, useState } from "react";
import { Router, Routes, Route, Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {faBars, faCircle, faDashboard, faCalendar, faUser, faFileInvoiceDollar, faBell, faRightFromBracket, faTicket, faUserGroup, faShoppingBag, faCaretLeft, faArrowLeft, faAngleLeft, faShop, faStore, faPeopleGroup, faMailBulk} from '@fortawesome/free-solid-svg-icons';


// import { history } from './helpers/history';
import AuthService from "./services/auth.service";

import OnSiteSelling from './pages/OnSiteSelling';
import InvitationsBooking from "./pages/InvitationsBooking";
import TicketTemplates from "./pages/TicketTemplates.js";
import Dashboard from "./pages/dashboard";
import Login from "./pages/Login";
import EventsArchive from "./pages/eventsArchive";
import CurrentEvents from './pages/currentEvents';
import Orders from './pages/orders';
import EditOrder from "./pages/editorder";
import ProducerList from "./pages/producerList";
import SingleProducer from "./pages/producerSingle";
import EventSingle from "./pages/eventSingle";
import SalesReport from "./pages/salesReport";
import CustomerNotifications from "./pages/customerNotifications";
import POSMain from "./pages/posMain";
import PosSingle from "./pages/posSingle";
import PosSalesReport from "./pages/posSalesReport";
import PosCollectionReport from "./pages/posCollectionReport";
import PosStaffPerformance from "./pages/posStaffPerformance";
import PosNotifications from "./pages/posNotications";
import VSPMain from "./pages/vspMain";
import VSPStaffList from "./pages/vspStaffList";
import VSPCalendar from "./pages/vspCalendar";
import VSPArchive from "./pages/vspArchive";
import JuiceAutomation from "./pages/juiceAutomations";
import Newsletter from "./pages/newsletter";
import HomeMobileSlider from "./pages/homeMobileSlider";
import ReportPerEvent from "./pages/reportsPerEvent";

import './App.css';
import ProfileSetting from "./components/Navbar/ProfileSetting.jsx";
import SidebarMobile from "./components/Navbar/SidebarMobile.jsx";



function App(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log({user});
            setUser(user);
            if(user!=false && user!=null) {
                setIsLoggedIn(true);
            } else {
                
            }
        }).catch((err) => {
            console.log(err);
            setIsLoggedIn(false);
            // window.location.href='/login';
        });
    }, []);

    const logout = async () => {

        await AuthService.logout();
        setIsLoggedIn(false);
        window.location.href='/login';
    }
  return (
    <div>
      <div className="">
        <nav className="d-none d-lg-flex navbar navbar-expand navbar-dark" style={{backgroundColor: "#5d4843"}}>
          <div className="navbar-nav mr-auto">
            
            <li className="nav-item">
              <FontAwesomeIcon icon={faBars} color="white" size="2x" />
            </li>
            <li className="nav-item">
              <Link to={"/"} className="navbar-brand flex ml-6">
                <img
                  src="/assets/3b-Logo-Otayo.png"
                  alt="logo-img"
                  className="profile-img-card-header"
                />
              </Link>
            </li>
            {isLoggedIn!=false && 
            <>
            {/* <li className="nav-item">
              <Link to={"/ticket-templates"} className="nav-link">
                Ticket Templates
              </Link>
            </li> */}
            </>
           }
          </div>
          <div className="navbar-nav ml-auto">
              {isLoggedIn==false &&
              <li className="nav-item">
                <a href="/login" className="nav-link">
                  LogIn
                </a>
              </li>
              }
              {isLoggedIn!=false &&
              <>
              <li className="nav-item">
                <a href="/home-mobile-slider" className="nav-link font-normal capitalize hover:text-black cursor-pointer text-sm text-white">
                 Settings
               </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link font-normal capitalize hover:text-black cursor-default text-sm text-white">
                 {user.user_nicename}
               </a>
              </li>
              <li className="nav-item font-normal hover:text-black">
                <a href="#" className="nav-link hover:text-black text-sm text-white" onClick={() => logout()}>
                 LogOut
               </a>
              </li>
              </>
              // <li class="nav-item dropdown">
              //   <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              //     {user.user_nicename}
              //   </a>
              //   <ul class="dropdown-menu">
              //     <li><a href="#" className="nav-link" onClick={() => logout()}>LogOut</a></li>
              //   </ul>
              // </li>
              // <a href="#" className="nav-link" onClick={() => logout()}>
              //   LogOut
              // </a>
              }
           
          </div>
        </nav>

        <nav className="navbar d-lg-none">
            
            <div className="d-flex align-items-center justify-content-between w-100" >
            <SidebarMobile isLoggedIn={isLoggedIn}/>
            <Link to={"/"} className="">
             <img src='https://otayo.com/wp-content/themes/otayo/assets/images/imp_images/LogoOtayo.png' width="55px" />
            </Link>

            {isLoggedIn!=false &&
              <ProfileSetting username={user} logout={logout} />
            }
          </div>
        </nav>

        <div className="container-fluid">
          <diV className="row">
              <div className="col-md-12 flex pl-0 overflow-hidden p-0">
                {isLoggedIn!=false && 
                <div className="d-none sidebar-menu h-screen bg-white drop-shadow-md d-lg-flex flex-col justify-center items-center z-50 sticky top-0 left-0">
                  {/* <div className="nav-link">
                    <Link to={"/dashboard"} className=" z-50"><FontAwesomeIcon icon={faCircle} color="#f36523" size="2x" /></Link>
                    <div className="menu-subtext z-50">
                      <div><Link to={"/dashboard"} className="font-semibold z-50">Dashboard</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div> */}

                  <div className="nav-link">
                    <Link to={"/dashboard"} className=""><FontAwesomeIcon icon={faDashboard} color="#f36523" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/dashboard"} className="font-semibold">Dashboard</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/current-events"} className=""><FontAwesomeIcon icon={faCalendar} color="#f36523" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/current-events"} className="font-semibold">Events</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/orders"} className=""><FontAwesomeIcon icon={faShoppingBag} color="#f36523" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/orders"} className="font-semibold">Orders</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/producer-list"} className=""><FontAwesomeIcon icon={faUser} color="#f4979f" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/producer-list"} className="font-semibold">Producers</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/sales"} className=""><FontAwesomeIcon icon={faFileInvoiceDollar} color="#3bb979" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/sales"} className="font-semibold">Sales Report</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/pos-dashboard"} className=""><FontAwesomeIcon icon={faStore} color="#03a9f4" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/pos-dashboard"} className="font-semibold">POS Dashboard</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/vsp"} className=""><FontAwesomeIcon icon={faPeopleGroup} color="#03a9f4" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/vsp"} className="font-semibold">VSP</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/customer-notifications"} className=""><FontAwesomeIcon icon={faBell} color="#8293c9" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/customer-notifications"} className="font-semibold">Notifications</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/onsite-selling"} className=""><FontAwesomeIcon icon={faTicket} color="#d59ffd" size="2x" /></Link>
                    <div className="menu-subtext">
                      <div><Link to={"/onsite-selling"} className="font-semibold">OnSite Selling</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/newsletter"} className=" z-50"><FontAwesomeIcon icon={faMailBulk} color="#7c83db" size="2x" /></Link>
                    <div className="menu-subtext z-50">
                      <div><Link to={"/newsletter"} className="font-semibold z-50">Newsletter</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"/invitations"} className=" z-50"><FontAwesomeIcon icon={faUserGroup} color="#7c83db" size="2x" /></Link>
                    <div className="menu-subtext z-50">
                      <div><Link to={"/invitations"} className="font-semibold z-50">Invitations</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  <div className="nav-link">
                    <Link to={"#"} onClick={e => logout()} className=" z-50"><FontAwesomeIcon icon={faRightFromBracket} color="#000000" size="2x" /></Link>
                    <div className="menu-subtext z-50">
                      <div><Link to={"#"} onClick={e => logout()} className="font-semibold z-50">Logout</Link></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div>

                  {/* <div className="nav-link">
                    <div onClick={e => logout()} className=" z-50"><FontAwesomeIcon icon={faRightFromBracket} color="#000000" size="2x" /></div>
                    <div className="menu-subtext z-50">
                      <div><div  onClick={e => logout()} className="font-semibold  z-50">Logout</div></div>
                      <div><FontAwesomeIcon icon={faAngleLeft} color="#000000" size="1x" /></div>
                    </div>
                  </div> */}
                  
                  
                  
                  {/* <Link to={"/invitations"} className="nav-link"><FontAwesomeIcon icon={faRightFromBracket} color="#000000" size="2x" /></Link> */}
                </div>
                }
                <div className="content-main pl-5 pr-3 pt-4">
                  <Routes>
                    <Route path="/onsite-selling" element={<OnSiteSelling />} />
                    <Route path="/invitations" element={<InvitationsBooking />} />
                    <Route path="/ticket-templates" element={<TicketTemplates />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/events-archive" element={<EventsArchive />} />
                    <Route path="/current-events" element={<CurrentEvents />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/producer-list" element={<ProducerList />} />
                    <Route path="/edit-order/:id" element={<EditOrder />} />
                    <Route path="/edit-producer/:id" element={<SingleProducer />} />
                    <Route path="/event/:id" element={<EventSingle />} />
                    <Route path="/sales" element={<SalesReport />} />
                    <Route path="/customer-notifications" element={<CustomerNotifications />} />
                    <Route path="/pos-dashboard" element={<POSMain />} />
                    <Route path="/pos/:id" element={<PosSingle />} />
                    <Route path="/pos-sales/:id" element={<PosSalesReport />} />
                    <Route path="/pos-collection/:id" element={<PosCollectionReport />} />
                    <Route path="/pos-performance/:id" element={<PosStaffPerformance />} />
                    <Route path="/pos-notifications" element={<PosNotifications />} />
                    <Route path="/vsp" element={<VSPMain />} />
                    <Route path="/vsp-staff" element={<VSPStaffList />} />
                    <Route path="/vsp-calendar" element={<VSPCalendar />} />
                    <Route path="/vsp-archive" element={<VSPArchive />} />
                    <Route path="/juice-automation" element={<JuiceAutomation />} />
                    <Route path="/newsletter" element={<Newsletter />} />
                    <Route path="/home-mobile-slider" element={<HomeMobileSlider />} />
                    <Route path="/reports-per-event" element={<ReportPerEvent />} />
                  </Routes>
                </div>
              </div>
          </diV>
        </div>

        <div className="p-3" >
         
        </div>
      </div>
    </div>
  );
}

export default App;
