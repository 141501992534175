import React from 'react'
import { useState } from 'react';
import { Divider ,Drawer } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import OrderComments from '../orders/orderComments.component';
import OrderPaymentDetails from '../orders/orderpaymentdetails.component';

const OrderCard = ({order}) => {

    const [drawerOpen, setDrawerOpen] = useState(false);


    const handleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

  return (
    <div className='border rounded-sm shadow-sm'>
     <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
      <p className='font-semibold text-sm'><span className=''>ID:</span> {order.id}</p>
      {order.orderStatus == 'Cancelled' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-red-600'>Cancelled</p> 
        }

        {order.orderStatus == 'Pending' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-yellow-500'>Pending</p> 
        }

        {order.orderStatus == 'Processing' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-green-500'>Processing</p> 
        }
    
     </div>

     <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Date :</p>
          <p className='font-medium'>{order.orderDate}</p>
      </div>  

     <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Event :</p>
          <p className='line-clamp-1 text-right font-medium'>{order.events}</p>
      </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Customer :</p>
          <p className='font-medium text-right'>{order.customerName}</p>
      </div>     

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Payment Method :</p>
          <p className='font-medium'>{order.paymentMethod}</p>
      </div>     


     <Divider />
     
     <div className='px-3 py-1 d-flex justify-content-between align-items-center'>
        {order.cmb_payment_note == '' && <p className='text-secondary text-sm text-nowrap'>Comments : </p>}
        <p className='w-2/3 text-xs text-secondary line-clamp-1'>{order.cmb_payment_note}</p>
        {/* <button onClick={handleDrawer}><MoreHorizIcon style={{ color : '#ea580c'}}/></button> */}
        <OrderComments orderid={order.id} />
     </div>

     <Divider />
     
     <div className='px-3 py-2 d-flex justify-content-between align-items-center py-1'>
        <div className='font-semibold text-sm d-flex flex-col'><p className='text-base'>Rs {order.total}</p>  <OrderPaymentDetails orderid={order.id} /> </div>
        <a href={`/edit-order/${order.id}`} className='font-semibold text-orange-500 underline text-xs py-1 rounded-sm'>View All Details <NavigateNextOutlinedIcon style={{fontSize : 15}}/></a>
     </div>
{/* 

     <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
        <div style={{ padding: '16px' }}>
          <p>Order Comments for #<span className='font-semibold'>{order.id}</span></p>

          <div>
     
          </div>
        </div>
      </Drawer> */}

    </div>
  )
}

export default OrderCard