import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import ViewCarouselSharpIcon from '@mui/icons-material/ViewCarouselSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { Link } from "react-router-dom";

import '../../styles/Navbar/ProfileSetting.css';

const ProfileSetting = ({username , logout}) => {
  const [ToggleDrawer, setToggleDrawer] = useState(false);

  const toggleDrawer = () => {
    setToggleDrawer(!ToggleDrawer);
  };

  return (
    <div className=''>
      <IconButton onClick={toggleDrawer}>
        <AccountCircleSharpIcon style={{ color: 'black' ,fontSize: 30}} />
      </IconButton>

      <Drawer
        anchor='right'
        open={ToggleDrawer}
        onClose={toggleDrawer}
      >
        <div className='profile_items'>
            
            <div className='profile_user d-flex align-items-center justify-content-between'>
            <AccountCircleSharpIcon style={{ color: 'black' ,fontSize: 25}} />
            <p>{username.user_nicename}</p>
            </div>        

            <Divider style={{ marginTop: 10, marginBottom: 10 }}/>

             <div
             onClick={() => logout()}
             className='d-flex align-items-center justify-content-between gap-5'>
             <LogoutSharpIcon style={{ color: 'black' ,fontSize: 25}} />
             <p>Logout</p>
             </div>



        </div>
      </Drawer>
    </div>
  );
}

export default ProfileSetting;