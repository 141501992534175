import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/ordersreport.service";

export default class ChangePaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: false,
            disablechangbutton: true,
            changebtntext: "Change",
            chosenItem : props.currentMethod,
        }
    }

    openPopup = (e) => {
        this.setState({
            isopen: true
        });
    }

    changeMethod = (e) => {

        e.preventDefault();

        this.setState({
            changebtntext: "Changing..."
        });

        OrdersReportService.changePaymentMethod(this.state.orderid, this.state.chosenItem)
        .then(response => {
            console.log("changePaymentMethod", response.data);
            swal({
                title: "Success!",
                text: "Payment Method Changed!",
                icon: "success",
                button: "OK",
            })
            .then(() => {
                this.setState({
                    isopen: false,
                    rerender: true,
                    changebtntext: "Change",
                    disablechangbutton: true
                });
                this.props.onChange();
            });
        })
        .catch(e => {
            console.log(e);
        });

    }

    setChosenItem = (e) => {
        this.setState({
            chosenItem: e.target.value,
            disablechangbutton: false
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <button type="button" className="btn btn-warning btn-sm" style={{backgroundColor: "orange", color: "white"}} onClick={e => this.openPopup(e)}>Change</button>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.changeMethod(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Change Payment Method
                        </div>
                    </div>
                     <div className="form-group row">
                        <label for="chosenitems" className="col-4 col-form-label"><b>Choose Method</b></label>
                        <div className="col-8">
                            <select className="form-control" onChange={e => this.setChosenItem(e)} id="chosenitems" required="required" value={this.state.chosenItem}>
                                <option value="cash">Cash</option>
                                <option value="migs">Credit Card</option>
                                <option value="juice">MCB Juice</option>
                                <option value="terminal">Card Terminal</option>
                                <option value="banktransfer">Bank Transfer</option>
                            </select>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={this.state.disablechangbutton}>{this.state.changebtntext}</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}