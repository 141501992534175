import axios from "axios";

const API_URL = "https://otayo.com/wp-json/jwt-auth/v1/token";

// TODO: add the token auth and identification here

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL, { username, password })
            .then((response) => {
                console.log({ response});

                if (response.data.token && (this.checkExists(response.data.user_role, 'administrator') || this.checkExists(response.data.user_role, 'erp'))) {
                    localStorage.removeItem("usererpotayo");
                    console.log(response.data);
                    localStorage.setItem("usererpotayo", JSON.stringify(response.data));
                    console.log('I am here in service');
                } else {
                    localStorage.removeItem("usererpotayo");
                    return false;
                }
                // console.log('I am here in service');
                return response;
            }).catch( error => {
                localStorage.removeItem("usererpotayo");
                return false;
            });
    }

    checkExists(haystack, needle) {
        for (let k in haystack) {
            if (haystack[k] === needle) {
                return true;
            }
        }

        return false;
    }

    logout() {
        localStorage.removeItem("usererpotayo");
    }

    checklogin() {
        // this.logout();
       return new Promise(function (resolve, reject) {
           var user = localStorage.getItem("usererpotayo");
           console.log({user});
           if(user) {
               resolve(JSON.parse(user));
           } else{
               reject(false);
           }
        });
    }
}

export default new AuthService();