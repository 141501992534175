import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import readXlsxFile from 'read-excel-file';
import axios from "axios";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";

const APIURL = "https://otayo.com/erpapi/orders.php";

const JuiceAutomation = () => {

    const [loading, setloading] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) { 
                processData();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const processData = () => {
        const input = document.getElementById('input')
        input.addEventListener('change', () => {
            readXlsxFile(input.files[0]).then((rows) => {
                setloading('Loading...');
                console.log(rows);

                var action = "juiceautomation";
                var params = {
                    action: action,
                    rows: rows
                }
                axios.post(APIURL+"?rand="+Math.random(), {params})
                .then(response => {
                    console.log(response.data);
                    setloading('');
                    setData(response.data);
                })
                .catch(err => console.log(err));
            });
        });
    }

    const confirmOrder = (e, index, order_id, transaction, message) => {
        swal({
        title: "Are you sure?",
        text: "The order will be converted to paid! Are you sure you want to continue?",
        icon: "warning",
        buttons: ["No, Keep as is!", "Yes, Convert to Paid!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            var params = {
                action: 'converttopaid',
                id: order_id,
                transaction: transaction,
                message: message
            }
            swal("Order is Changing", "Converting the order ...", "info");
            axios.post(APIURL+"?rand="+Math.random(), {params})
            .then(response => {
                var dataTemp = [...data];
                dataTemp[index].order_status = "Processing";
                setData(dataTemp);
                swal.close();
                swal("Order Updated", "The Order has been converted to paid!", "success");
            })
            .catch(err => console.log(err));
        } else {
            swal("Order Not Changed", "Your order id intact!", "success");
        }
        });
    }

    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-xl mb-2">Juice / Bank Transfer Automation</h1>
                        <div>
                            <small style={{marginRight: "15px", fontSize: "18px", color: "red", fontWeight:"bold"}}>{loading}</small>
                            <input className="btn btn-primary bg-blue-500 hover:bg-blue-600 rounded-none font-semibold" type="file" id="input" />
                            <button className="btn btn-warning ml-4 bg-orange-500 hover:bg-orange-600 rounded-none font-semibold" onClick={(e) => window.location.reload()}>Reset</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Sheet Amount</th>
                                    <th scope="col">Order Total</th>
                                    <th scope="col" className="text-center">Method</th>
                                    <th scope="col" className="text-center">Order Status</th>
                                    <th scope="col" className="text-center">Customer</th>
                                    <th scope="col" className="text-center">Email</th>
                                    <th scope="col" className="text-center">Action</th>
                                    <th scope="col" className="text-center">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length>0 && data.map((item, index) => (
                                <tr key={index}>
                                    <td><a className="color-orange break-words font-bold" href={"/edit-order/"+item.order_id} taget="_blank">#{item.order_id}</a></td>
                                    <td>Rs {item.amount}</td>
                                    <td>Rs {item.order_total}</td>
                                    <td>{item.payment_method}</td>
                                    <td>{item.order_status}</td>
                                    <td>{item.customer_name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {item.order_status == "Pending" && item.order_total == item.amount && <button className="btn btn-sm btn-danger" onClick={e => confirmOrder(e, index, item.order_id, item.transaction, item.message)}>Confirm</button>}
                                        {(item.order_status == "Processing" || item.order_status == "Completed") && item.order_total == item.amount && <span className="text-green-600 font-bold">Processing</span>}
                                        {(item.order_status == "Cancelled") && <span className="text-red-600 font-bold">Cancelled</span>}
                                        {(item.order_total != item.amount && item.order_status != "" && item.order_status != "Cancelled") && <span className="text-fuchsia-600 font-bold">Invalid</span>}
                                        {(item.order_status == "") && <span className="text-blue-600 font-bold">Not Found</span>}
                                    </td>
                                    <td>{item.transaction} --- {item.message}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default JuiceAutomation;