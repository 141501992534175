import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom'

import OrdersReportService from "../services/ordersreport.service";
import AuthService from "../services/auth.service";
import {database} from "../services/firebase";

import AddReminder from "../components/orders/addreminder.component";
import OrderNotes from "../components/orders/ordernotes.component";
import OrderLogs from "../components/orders/orderlogs.component";
import SendVoucherToCustomer from "../components/orders/sendvouchertocustomer.component";
import SendVoucherToSupplier from "../components/orders/sendvouchertosupplier.component";
import SupplierConfirmed from "../components/orders/supplierconfirmed.component";
import UpdateVoucherDetails from "../components/orders/updatevoucherdetails.component";
import AddRefund from "../components/orders/addrefund.component";
import AllRefunds from "../components/orders/allrefunds.component";
import AddComplaint from "../components/orders/addcomplaint.component";
import AllComplaints from "../components/orders/allcomplaints.component";
import TransferPoints from "../components/orders/trasnferorder.component";
import Emails from "../components/orders/emails.component";
import ChangePaymentMethod from "../components/orders/changepaymentmethod.component";

import "../styles/EditOrder.css";

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Divider } from "@mui/material";

import Spinner from '../components/LoadingState/Spinner'


function EditOrder(props) {
    const { id } = useParams();
    const [orderid, setorderid] = useState(id);
    const [headingText, setheadingText] = useState("Checking login ...");
    const [data, setdata] = useState();
    const [loading, setloading] = useState();
    const [orderstatus, setorderstatus] = useState('wc-pending');
    const [oldorderstatus, setoldorderstatus] = useState('wc-pending');
    const [items, setitems] = useState(null);
    const [opencheckintimesmodal, setopencheckintimesmodal] = useState(false);
    const [newcheckintime, setnewcheckintime] = useState('');
    const [newcheckouttime, setnewcheckouttime] = useState('');
    const [updatecheckintimesbuttontext, setupdatecheckintimesbuttontext] = useState("Update Check-in/Check-out Time >>");
    const [disablecheckintimesbutton, setdisablecheckintimesbutton] = useState(false);
    const [couponToApply, setcouponToApply] = useState("");
    const [disableapplycouponbtn, setdisableapplycouponbtn] = useState(false);
    const [opencouponmodal, setopencouponmodal] = useState(false);
    const [applycouponbtntext, setapplycouponbtntext] = useState("Apply Coupon");
    const [rerenderIssues, setrerenderIssues] = useState(false);
    const [rerenderFollowup, setrerenderFollowup] = useState(false);
    const [user, setuser] = useState(null);
    const [updateorderstatusbtntext, setupdateorderstatusbtntext] = useState("Update Status");
    const [updateorderstatusbtndisabled, setupdateorderstatusbtndisabled] = useState(false);
    const [updatedetailsbtntext, setupdatedetailsbtntext] = useState("Update Details");
    const [updatedetailsbtndisabled, setupdatedetailsbtndisabled] = useState(false);
    const [cash_reference, setcash_reference] = useState('');
    const [paymentstatusnote, setpaymentstatusnote] = useState('');
    const [transactionref, settransactionref] = useState('');
    const [voucher_note, setvoucher_note] = useState('');
    const [rerenderAllRefunds, setrerenderAllRefunds] = useState(false);
    const [rerenderOrderLogs, setrerenderOrderLogs] = useState(false);
    const [juiceSMS, setJuiceSMS] = useState('');
    // const [user, setUser] = useState({
    //     user_nicename: "",
    // });
    const [itemtoupdate, setitemtoupdate] = useState(0);
    const [resendEmailText, setresendEmailText] = useState("Resend Email");
    const [tickets, setTickets] = useState([]);

    const [resendemailbtntext, setResendemailbtntext] = useState('Resend Email');
    const [resendEmailDisabled, setResendEmailDisabled] = useState(false);

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                setuser(user);
                setheadingText("Loading order details ...");
                getData();
            }
        }).catch((err) => {
            console.log(err);
            // setIsLoggedIn(false);
            window.location.href='/login';
        });
        
    }, [id])

    const onChangeRerender = () => {
        getData();
    }

    const onChangeRerenderIssues = () => {
        setrerenderIssues(!rerenderIssues);
        setrerenderOrderLogs(!rerenderOrderLogs);
    }

    const onChangeRerenderFollowup = () => {
        setrerenderFollowup(!rerenderFollowup);
        setrerenderOrderLogs(!rerenderOrderLogs);
    }

    const getJuiceSMS = () => {
        OrdersReportService.getJuiceSMS(orderid)
        .then(response => {
            console.log("juice sms", response);
            setJuiceSMS(response.data);
        })
        .catch(error => console.log({error}));
    }

    const getData = () => {
        OrdersReportService.getOrderDetails(orderid)
        .then(response => {
            console.log("order details", response);
            setdata(response.data);
            setloading(false);
            setorderstatus(response.data.orderData.status);
            setoldorderstatus(response.data.orderData.status);
            setcash_reference(response.data.payment.cash_reference);
            setpaymentstatusnote(response.data.payment.paymentstatusnote);
            settransactionref(response.data.payment.transactionref);
            setvoucher_note(response.data.payment.voucher_note);
            setTickets(response.data.tickets);

            if(response.data.payment.paymentmethod.includes("Juice")) {
                getJuiceSMS();
            }
        })
        .catch(error => console.log({error}));

        OrdersReportService.getOrderDetailsProducts(orderid)
        .then(response => {
            console.log("order details items", response);
            setitems(response.data);
            setloading(false);
            setheadingText("Order ID: "+orderid);
        })
        .catch(error => console.log({error}));
    }

    const handleStatusChange = (e) => {
        setorderstatus(e.target.value);
        setrerenderOrderLogs(!rerenderOrderLogs);

        swal('Click on "Update Status" button to confirm the change!');
    }

    const onChangeRefundsRerender = () => {
        console.log("rerender refunds");
        setrerenderAllRefunds(!rerenderAllRefunds);
        setrerenderOrderLogs(!rerenderOrderLogs);
    }

    const showcheckintimesmodal = (e, itemID) => {
        setopencheckintimesmodal(true);
        setitemtoupdate(itemID);
    }

    const updateCheckinTimes = (e) => {
       
    } 

    const applyCoupon = (e) => {
        e.preventDefault();
        setapplycouponbtntext("Applying Coupon....");
        setdisableapplycouponbtn(true);

        OrdersReportService.applyCoupon(orderid, couponToApply, user.user_nicename)
        .then(res => {
            console.log({res});
            setcouponToApply("");
            setdisableapplycouponbtn(false);
            setopencouponmodal(false);
            setapplycouponbtntext("Apply Coupon");
            setrerenderOrderLogs(!rerenderOrderLogs);

            swal('Discount Applied', "Coupon has been applied to the order!", "success");
            getData();
        })
        .catch(error => {console.log({error})});
    }

    const removeCoupon = (e, code) => {
        e.preventDefault();
        swal({
        title: "Are you sure?",
        text: "Once removed, The discount will be reomved from the order!",
        icon: "warning",
        buttons: ["No, Keep the coupon!", "Yes, Remove Coupon!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            e.target.disabled=true;
            e.target.innerText = "Deleting..."
            OrdersReportService.removeCoupon(orderid, code, user.user_nicename)
            .then(response => {
                swal('Deleted', 'The coupon "'+code+'" has been removed from the order', 'success');
                getData();
                e.target.disabled=false;
                e.target.innerText = "Remove Coupon";
                setrerenderOrderLogs(!rerenderOrderLogs);
            })
           .catch(err => console.log({err}));
        } else {
            swal("Coupon Not Removed", "Your coupon is safe!", "success");
        }
        });
    }

    const updateOrderStatus = (e) => {
        e.preventDefault();
        setupdateorderstatusbtntext("Updating Status...");
        setupdateorderstatusbtndisabled(true);

        if(orderstatus=='cancelled') {
            var newTickets = [...tickets];
            newTickets.map((ticket, index) => {
                console.log({ticket});
                database.ref('reserved/' + ticket.chart_id + '/' + ticket.seat_id).remove();
            });
        }

        OrdersReportService.updateStatus(orderid, orderstatus, user.user_nicename)
        .then(response => {
            setupdateorderstatusbtntext("Update Status");
            setupdateorderstatusbtndisabled(false);
            setoldorderstatus(orderstatus);
            setrerenderOrderLogs(!rerenderOrderLogs);
            swal("Status Updated!", "The order status has been updated!", "success");
        })
        .catch(err => console.log({err}));
    }

    const updateOrderDetails = (e) => {

        if(e!=null) {
            e.preventDefault();
        }

        setupdatedetailsbtntext("Updating Details...");
        setupdatedetailsbtndisabled(true);

        OrdersReportService.updateDetails(orderid, transactionref, cash_reference, paymentstatusnote, voucher_note, user.user_nicename)
        .then(response => {
            setrerenderOrderLogs(!rerenderOrderLogs);
            setupdatedetailsbtntext("Update Details");
            setupdatedetailsbtndisabled(false);

            swal("Details Updated!", "The order details has been updated!", "success");
        })
        .catch(err => console.log({err}));
    }

   const updateOrderDetailsWaitAuto = () => {

        setTimeout(() => {
            updateOrderDetails(null);
        }, 1000);
    }

    const enableDisableButtons = (e, index) => {
        var itemsTemp = items;
        console.log({itemsTemp});
        if(typeof itemsTemp.day!='undefined' && itemsTemp.day.length>0) {
            console.log("item to update", itemsTemp.day[index]);
            itemsTemp.day[index].buttons_active = true;
        }
        setitems(itemsTemp);
        setrerenderOrderLogs(!rerenderOrderLogs);
    }

    const transferOrder = () => {
        getData();
    }

    const resendEmail = (e) => {
        e.preventDefault();
        setResendemailbtntext("Sending Email...");
        setResendEmailDisabled(true);

        OrdersReportService.resendorderemail(orderid, orderstatus, user.user_nicename)
        .then(response => {
            console.log({response});
            setResendemailbtntext("Resend Email");
            setrerenderOrderLogs(!rerenderOrderLogs);
            setResendEmailDisabled(false);
            swal("Email Sent!", "The email has been sent to the customer!", "success");
        })
        .catch(err => console.log({err}));
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    console.log(data)

    return(

        <>
        <div className="p-2 d-none d-lg-block">
        <div className="shadow-lg p-4">
            <div style={{backgroundClor: "#fbfbfb"}}>
            <div className="row">
                <div className="col mb-3">
                    <h1 className="orders-h1 title font-bold h1 text-lg">{headingText}</h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5 className="font-bold mb-2">Customer Details</h5>
                        {data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                            <div className="orderdetails-paper-content-row">
                                <label>Booked By ID/Name:</label>
                                <span>{data.user.ID} / {data.userData.first_name} {data.userData.last_name}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Customer/Order Name:</label>
                                <span>{data.orderData.billing.first_name} {data.orderData.billing.last_name}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Email:</label>
                                <span>{data.orderData.billing.email}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Phone:</label>
                                <span>{data.orderData.billing.phone}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Account Type:</label>
                                <span>{data.userData.account_type}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Member Rank:</label>
                                <span>{data.userData.rank}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Account Create On:</label>
                                <span>{data.userData.registerdate}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Order Date:</label>
                                <span>{data.order_date}</span>
                            </div>
                            {/* <div className="orderdetails-paper-content-row">
                                <label>Link to CRM:</label>
                                <span><a className="btn btn-sm btn-link" target="_blank" href={"/user-profile/"+data.orderData.customer_id}>View on CRM</a></span>
                            </div> */}
                            <div className="orderdetails-paper-content-row">
                                <label>View Order on Old System:</label>
                                <span><a className="btn btn-sm btn-link" target="_blank" href={"https://otayo.com/wp-admin/post.php?post="+orderid+"&action=edit"}>View Order</a></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Transfer Order to Customer:</label>
                                <span><TransferPoints orderid={orderid} userid={data.user.ID} customerid={data.orderData.customer_id} ownerName={data.userData.first_name+" "+data.userData.last_name} user={user} onChange={() => transferOrder()} /></span>
                            </div>
                        </div>
                        }
                        {data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5 className="font-bold mb-2">Order Summary</h5>
                        {data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                            <div className="orderdetails-paper-content-row">
                                <label>Order Status:</label>
                                <span>
                                    <select className="rounded-sm" style={{border: "1px solid black"}} name='orderstatus' value={orderstatus} onChange={e=>setorderstatus(e.target.value)}>
                                        <option value={'pending'}>Pending Payment</option>
                                        <option value={'processing'}>Paid</option>
                                        <option value={'cancelled'}>Cancelled</option>
                                        <option value={'on-hold'}>On Hold</option>
                                        <option value={'completed'}>Completed</option>
                                        <option value={'refunded'}>Refunded</option>
                                        <option value={'failed'}>Failed</option>
                                    </select>
                                </span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Update Status:</label>
                                <span>
                                    <button type="button" className="btn btn-sm btn-success bg-green-700" onClick={e => updateOrderStatus(e)} disabled={updateorderstatusbtndisabled || orderstatus==oldorderstatus}>{updateorderstatusbtntext}</button>
                                </span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Send Email:</label>
                                <span>
                                    <button type="button" className="btn btn-sm btn-success bg-green-700" onClick={e => resendEmail(e)} disabled={resendEmailDisabled}>{resendemailbtntext}</button>
                                </span>
                            </div>
                            {juiceSMS!='' && 
                            <div className="orderdetails-paper-content-row">
                                <label>Juice SMS:</label>
                                <span style={{width: "75%"}}>{juiceSMS}</span>
                            </div>
                            }
                            {data.coupons.length>0 && data.coupons.map((coupon, index) => ( 
                            <div className="orderdetails-coupons-loop" key={index}>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Code:</label>
                                    <button type="button" className="btn btn-sm" data-tip data-for={"couponTip"+index} style={{backgroundColor: 'bisque'}}>{coupon.code}</button>
                                    {/* <ReactTooltip id={"couponTip"+index} place="top" effect="solid">
                                        {coupon.desc+" - Rs"+coupon.amount}
                                    </ReactTooltip> */}
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Type:</label>
                                    <span>{coupon.type}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Discount:</label>
                                    <span>Rs {coupon.amount}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Action:</label>
                                    <span><button type="button" className="btn btn-sm btn-danger" onClick={e => removeCoupon(e, coupon.code)}>Remove Coupon</button></span>
                                </div>
                            </div>
                            ))}
                            {/* <div className="orderdetails-paper-content-row">
                                <label>Other Orders by This Customer:</label>
                                <span><a href={"/orders/"+data.orderData.customer_id} target="_blank" className="btn btn-sm btn-link">View Orders</a></span>
                            </div> */}
                            {/* <div className="orderdetails-paper-content-row">
                                <label>Resend Email:</label>
                                <span><button className="btn btn-sm btn-success bg-green-700 hover:bg-green-800 rounded-none" onClick={(e) => resendEmail(e)}>{resendEmailText}</button></span>
                            </div> */}
                            {(oldorderstatus=='completed' || oldorderstatus=="processing") &&
                            <div className="orderdetails-paper-content-row">
                                <label>Download e-tickets:</label>
                                <span><a target="_blank" href={"https://otayo.com/phantom/gen.php?order="+orderid+"&token="+data.orderData.billing.email} target="_blank" className="btn btn-sm btn-link">Download e-tickets</a></span>
                            </div>
                            }
                            {(oldorderstatus=='completed' || oldorderstatus=="processing") &&
                            <div className="orderdetails-paper-content-row">
                                <label>Download physical tickets:</label>
                                <span><a target="_blank" href={"https://otayo.com/phantom/physicalticket-new.php?order="+orderid+"&admin=pos&token="+data.orderData.billing.email} target="_blank" className="btn btn-sm btn-link">Download physical tickets</a></span>
                            </div>
                            }
                        </div>
                        }
                        {data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5 className="font-bold mb-2">Payment References</h5>
                        {data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                            <div className="orderdetails-paper-content-row">
                                <label>Payment Method:</label>
                                <ChangePaymentMethod orderid={orderid} currentMethod={data.orderData.payment_method} onChange={e => onChangeRerender()} />
                                <span>{data.payment.paymentmethod}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Payment Date:</label>
                                <span>{data.payment.paid_date}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Transaction Referrence:</label>
                                <span><input className="form-control" name="transactionref" value={transactionref} onChange={e => {settransactionref(e.target.value); }} /></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Cash Reference:</label>
                                <span><input className="form-control" name="cash_reference" value={cash_reference} onChange={e => {setcash_reference(e.target.value); }} /></span>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "column", alignItems: "flex-start"}}>
                                <label>Payment Status Note:</label>
                                <textarea className="form-control" name="paymentstatusnote" rows="3" value={paymentstatusnote} style={{width:"100%"}}  onChange={e => {setpaymentstatusnote(e.target.value);}}></textarea>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "column", alignItems: "flex-start"}}>
                                <label>Order Comments:</label>
                                <textarea className="form-control" name="voucher_note" rows="3" value={voucher_note} style={{width:"100%"}}  onChange={e => {setvoucher_note(e.target.value);}}></textarea>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "cornsilk", padding: "5px", fontSize: "12px"}}>
                                <label>Update Details:</label>
                                <span><button className="btn btn-sm ptn-primary bg-blue-700 hover:bg-blue-800 rounded-none text-white" onClick={(e) => updateOrderDetails()}>{updatedetailsbtntext}</button></span>
                                {/* <button type="button" className="btn btn-sm btn-success" disabled={updatedetailsbtndisabled} onClick={e => this.updateOrderDetails(e)}>{updatedetailsbtntext}</button> */}
                            </div>
                        </div>
                        }
                        {data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
            </div>

            {items!=null && items.other.length>0 && 
                <div className="row orderdetails-items-table-wrapper mt-4 mb-4 shadow-md">
                    <div className="col">
                        <table className="table table-striped table-bordered items-table">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col">Event</th>
                                <th scope="col" className="text-center">Ticket</th>
                                <th scope="col" className="text-center">Cost</th>
                                <th scope="col" className="text-center">Quantity</th>
                                <th scope="col" className="text-center">Discount</th>
                                <th scope="col" className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.other.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row" className="left">
                                        <h6>{item.event_name}</h6>
                                    </th>
                                    <td scope="row" className="left">
                                        {item.product_name}
                                    </td>
                                    <td scope="row">
                                        <h6>Rs {item.item_Line_subtotal}</h6>
                                    </td>
                                    <td scope="row">
                                        {item.qty}
                                    </td>
                                    <td scope="row">
                                        {item.item_discount_raw>0 && 
                                            <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                        }
                                        {item.item_discount_raw<1 && 
                                            <p>-</p>
                                        }
                                    </td>
                                     <td scope="row">
                                        <h6>Rs {item.item_Line_total}</h6>
                                    </td>
                                    {/* <td>
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Selling Price: </b><span>Rs {item.item_Line_subtotal}</span></p>
                                        {item.item_discount_raw>0 && 
                                            <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                        }
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Total: </b><span>Rs {item.item_Line_total}</span></p>
                                    </td> */}

                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            
            }
            {tickets!=null && tickets.length>0 && 
                <div className="row orderdetails-items-table-wrapper mt-4 mb-4 shadow-md">
                    <div className="col">
                        <table className="table table-striped table-bordered items-table">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col" className="text-center">Event</th>
                                <th scope="col" className="text-center">Ticket Type</th>
                                <th scope="col" className="text-center">Seat</th>
                                <th scope="col" className="text-center">Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tickets.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row" className="left">
                                        <h6>{item.event}</h6>
                                    </th>
                                    <td scope="row" className="left">
                                        {item.ticket_type}
                                    </td>
                                    <td scope="row">
                                        <h6>{item.seat_label}</h6>
                                    </td>
                                    <td scope="row">
                                        {item.ticket_code}
                                    </td>

                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            
            }
            {data!=null && 
            <div className="row order-totals-bar pt-3 pb-3 shadow-md">
                <div className="col-10 actions">
                    <button type="button" onClick={e=>setopencouponmodal(true)} className="btn">Apply Coupon</button>
                    {/* <button type="button" className="btn">Refund / Credit</button> */}
                    <AddRefund orderid={orderid} items={data.items} user={user} onChange={e => onChangeRefundsRerender()} />
                    <AddComplaint orderid={orderid} items={data.items} user={user} onChange={e => onChangeRerenderIssues()} />
                </div>
                <div className="col-2 totals">
                    <p style={{borderBottom: "1px solid black"}}><b>Sub-Total: </b><b>Rs {data.payment.subtotal}</b></p>
                    {data.payment.fees.length>0 &&
                        data.payment.fees.map((fee, index) => (
                            <p key={index} className="text-green-700"><b>{fee.name} </b><b>Rs {fee.amount}</b></p>
                        ))
                    }
                    <p style={{color: "red", borderBottom: "1px solid black"}}><b>Total Discount: </b><b>Rs {data.payment.discount_total}</b></p>
                    {data.payment.refund_total!='' &&
                    <>
                        <p><b>Paid by Customer: </b><b>Rs {data.orderData.total}</b></p>
                        <p style={{color: "red", borderBottom: "1px solid black"}}><b>Total Refund: </b><b>-Rs {data.payment.refund_total}</b></p>
                        <p><b>Net Total: </b><b>Rs 
                            {/* {data.payment.total_after_refund} */}
                            {data.orderData.total}
                        </b></p>
                    </>
                    }
                    {data.payment.refund_total=='' && 
                        <p><b>Total: </b><b>Rs {data.orderData.total}</b></p>
                    }
                </div>
            </div>
            }
            {data!=null && 
                <AllRefunds orderid={orderid} user={user} render={rerenderAllRefunds} />
            }
            {data!=null && user!=null && 
            <>
                <AddReminder orderid={orderid} username={user.user_nicename} onChange={(e) => {setrerenderOrderLogs(!rerenderOrderLogs)}} />
            </>
            }

            {data!=null && 
            <div className="row">
                <div className="col">
                    <AllComplaints orderid={orderid} onChange={e => onChangeRerender()} rerender={rerenderIssues} />
                    <OrderLogs orderid={orderid} render={rerenderOrderLogs} />
                </div>
                <div className="col">
                    <OrderNotes orderid={orderid} user={user} />
                </div>
            </div>
            }

            {data!=null && 
            <div className="row">
                <div className="col">
                    <Emails orderid={orderid} email={data.orderData.billing.email} />
                </div>
            </div>
            }

            <Modal
                open={opencheckintimesmodal}
                onClose={e => {setopencheckintimesmodal(false); setitemtoupdate(0)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 600, textAlign: 'center' }}>
                <h2 id="child-modal-title">Check-in/Check-out Time Override</h2>
                <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the Check-in/Check-out time to display on this voucher.</p>
                <p id="child-modal-description">(Leave empty to use default from Product)</p>
                <form onSubmit={(e) => updateCheckinTimes(e)} style={{textAlign: "left"}}>
                     <div className="form-group row">
                        <label for="newcheckintime" className="col-sm-8 col-form-label">Check-in Time</label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" onChange={e => {setnewcheckintime(e.target.value)}} id="newcheckintime" placeholder="13h00" value={newcheckintime} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="newcheckouttime" className="col-sm-8 col-form-label">Check-Out Time</label>
                        <div className="col-sm-4"> 
                            <input type="text" className="form-control" id="newcheckouttime" placeholder="16h00" onChange={e => {setnewcheckouttime(e.target.value)}} value={newcheckouttime} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={disablecheckintimesbutton}>{updatecheckintimesbuttontext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {setopencheckintimesmodal(false); setitemtoupdate(0)}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            <Modal
                open={opencouponmodal}
                onClose={e => {setopencouponmodal(false); setcouponToApply("")}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width:  400, textAlign: 'center' }}>
                <h2 id="child-modal-title" className=" font-bold">Apply Discount Coupon to this Order</h2>
                <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the coupon code below.</p>
                <form onSubmit={(e) => applyCoupon(e)} style={{textAlign: "left"}}>
                     <div className="form-group mb-3">
                        <label for="couponToApply" className="col-form-label"><b>Coupon Code</b></label>
                        <div className="">
                            <input type="text" className="form-control" onChange={e => {setcouponToApply(e.target.value)}} id="couponToApply" placeholder="Enter coupon code" value={couponToApply} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={disableapplycouponbtn}>{applycouponbtntext}</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => {setopencouponmodal(false); setcouponToApply("")}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </div>
            </div>
        </div>

        <div className="d-lg-none" style={{ padding : '0px 10px'}}>
          <div className="d-flex justify-content-between align-items-center border-b fixed top-0 left-0 z-20 w-100 bg-white px-3 py-3">
            <div onClick={() =>  window.history.back()}>
             <ArrowBackOutlinedIcon/>
            </div>

            <p className="font-semibold mt-1">{headingText}</p>
            
            <div>
                
            </div>
          </div>

          <div style={{marginTop : 0}}>
            <div className="border rounded-sm shadow-sm mb-3">
                <div className="px-3 py-2">
                    <p className="font-semibold text-orange-600">Order Details</p>
                </div>

                {data == null &&
                <div className="d-flex justify-content-center py-4">
                 <Spinner/>
                </div>
                }

                {data!=null && 

                <>
                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary '>Customer :</p>
                    <p className="text-right font-medium">{data.orderData.billing.first_name} {data.orderData.billing.last_name}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Booked By :</p>
                    <p className="text-right">{data.user.ID} / {data.userData.first_name} {data.userData.last_name}
                    </p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary'>Phone : </p>
                    <p>{data.orderData.billing.phone}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Email : </p>
                    <p>{data.orderData.billing.email}</p>
                </div>

                <Divider />

                
                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary'>Account Type : </p>
                    <p>{data.userData.account_type}</p>
                </div>

                <Divider />


                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Member Rank : </p>
                    <p>{data.userData.rank}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary'>Order Date: </p>
                    <p>{data.order_date}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Order Status: </p>
                    <button type="button" className="btn btn-sm btn-success bg-green-700 text-nowrap text-xs" onClick={e => updateOrderStatus(e)} disabled={updateorderstatusbtndisabled || orderstatus==oldorderstatus}>{updateorderstatusbtntext}</button>
                    <select className="rounded-sm outline-none text-nowrap" style={{border: "1px solid black"}} name='orderstatus' value={orderstatus} onChange={e=>setorderstatus(e.target.value)}>
                     <option value={'pending'}>Pending Payment</option>
                     <option value={'processing'}>Paid</option>
                     <option value={'cancelled'}>Cancelled</option>
                     <option value={'on-hold'}>On Hold</option>
                     <option value={'completed'}>Completed</option>
                     <option value={'refunded'}>Refunded</option>
                     <option value={'failed'}>Failed</option>
                    </select>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary'>Payment Method : </p>
                    <ChangePaymentMethod orderid={orderid} currentMethod={data.orderData.payment_method} onChange={e => onChangeRerender()} />
                    <span className="text-right">{data.payment.paymentmethod}</span>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Payment Date : </p>
                    <span className="text-right">{data.payment.paid_date}</span>
                </div>
               
                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary'>Transaction Referrence : </p>
                    <span className="text-right">{transactionref}</span>
                </div>
               
                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Cash Reference : </p>
                    <span className="text-right">{cash_reference}</span>
                </div>
               
                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
                    <p className='text-secondary text-nowrap'>Payment Status Note :</p>
                    <span className="text-right">{paymentstatusnote}</span>
                </div>
               
                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary text-nowrap'>Order Comments :</p>
                    <span className="text-right">{voucher_note}</span>
                </div>
               
                <Divider />


                

                </>
                }

                {items!=null && items.other.length>0 && items.other.map((item, index) => (
                <>
                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary'>Event Name :</p>
                    <p className="text-right">{item.event_name}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}} >
                    <p className='text-secondary'>Cost :</p>
                    <p>Rs {item.item_Line_total}</p>
                </div>

                <Divider  />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary'>Quantity :</p>
                    <p>{item.qty}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Download E-ticket : </p>
                    <a target="_blank" href={"https://otayo.com/phantom/gen.php?order="+orderid+"&token="+data.orderData.billing.email} className="text-blue-500 underline"> Download E-ticket</a>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex justify-content-between align-items-center gap-1'>
                    <p className='text-secondary'>Download Physical Ticket : </p>
                    <a target="_blank" href={"https://otayo.com/phantom/physicalticket-new.php?order="+orderid+"&admin=pos&token="+data.orderData.billing.email} className="btn btn-sm btn-link">Download</a>
                </div>

                {data.coupons.length>0 && data.coupons.map((coupon, index) => ( 
                            <div className="orderdetails-coupons-loop text-smx`" key={index}>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Code:</label>
                                    <button type="button" className="btn btn-sm" data-tip data-for={"couponTip"+index} style={{backgroundColor: 'bisque'}}>{coupon.code}</button>
                                    {/* <ReactTooltip id={"couponTip"+index} place="top" effect="solid">
                                        {coupon.desc+" - Rs"+coupon.amount}
                                    </ReactTooltip> */}
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Type:</label>
                                    <span>{coupon.type}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Discount:</label>
                                    <span>Rs {coupon.amount}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Action:</label>
                                    <span><button type="button" className="btn btn-sm btn-danger" onClick={e => removeCoupon(e, coupon.code)}>Remove Coupon</button></span>
                                </div>
                            </div>
                ))}

               
                {/* <Divider style={{ marginTop: 5, marginBottom: 5 }} />

                <div className='text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary'>Discount :</p>
                    <p>- </p>
                </div>

                <Divider style={{ marginTop:10, marginBottom: 10 }} />

                <div className='text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary'>Total :</p>
                    <p className="font-semibold text-green-500">Rs 2700 </p>
                </div> */}

                 </>
                ))}
         
                
            </div>

            <div className="overflow-scroll">
            {items!=null && items.other.length>0 && 
            <table className="table table-striped table-bordered items-table">
            <thead className="thead-dark">
                                <tr className="text-xs">
                                <th scope="col">Event</th>
                                <th scope="col" className="text-center">Ticket</th>
                                <th scope="col" className="text-center">Cost</th>
                                <th scope="col" className="text-center">Quantity</th>
                                <th scope="col" className="text-center">Discount</th>
                                <th scope="col" className="text-center">Total</th>
                                </tr>
            </thead>
            <tbody className="text-xs">
                                {items.other.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row" className="left">
                                        <h6 className="text-nowrap">{item.event_name}</h6>
                                    </th>
                                    <td scope="row" className="left">
                                        {item.product_name}
                                    </td>
                                    <td scope="row">
                                        <h6 className="text-nowrap">Rs {item.item_Line_subtotal}</h6>
                                    </td>
                                    <td scope="row">
                                        {item.qty}
                                    </td>
                                    <td scope="row">
                                        {item.item_discount_raw>0 && 
                                            <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                        }
                                        {item.item_discount_raw<1 && 
                                            <p>-</p>
                                        }
                                    </td>
                                     <td scope="row">
                                        <h6 className="text-nowrap">Rs {item.item_Line_total}</h6>
                                    </td>
                                    {/* <td>
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Selling Price: </b><span>Rs {item.item_Line_subtotal}</span></p>
                                        {item.item_discount_raw>0 && 
                                            <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                        }
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Total: </b><span>Rs {item.item_Line_total}</span></p>
                                    </td> */}

                                </tr>
                                ))}
             </tbody>
            </table>

             }
            </div>
            <div className="overflow-scroll">
              <p
                className=""
                style={{
                    fontWeight: "700",
                    marginLeft: "10px",
                    marginBottom : "10px",
                    color: "#aaaaaa",
                }}
                >Ticket Order Details
              </p>

                {tickets!=null && tickets.length>0 && 
                        <table className="table table-striped table-bordered items-table">
                            <thead className="thead-dark text-sm">
                                <tr>
                                <th scope="col" className="text-center">Event</th>
                                <th scope="col" className="text-center text-nowrap">Ticket Type</th>
                                <th scope="col" className="text-center">Seat</th>
                                <th scope="col" className="text-center">Code</th>
                                </tr>
                            </thead>
                            <tbody className="border text-xs">
                                {tickets.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row" className="left">
                                        <h6 className="text-nowrap">{item.event}</h6>
                                    </th>
                                    <td scope="row" className="left">
                                        {item.ticket_type}
                                    </td>
                                    <td scope="row">
                                        <h6>{item.seat_label}</h6>
                                    </td>
                                    <td scope="row">
                                        {item.ticket_code}
                                    </td>

                                </tr>
                                ))}
                            </tbody>
                        </table>
            }
            </div>

            <div className="border rounded-sm shadow-sm mb-3">
                <div className="px-3 py-2">
                 <p className="font-semibold text-orange-600">Payment Details</p>
                </div>
                {data == null &&
                <div className="d-flex justify-content-center py-4">
                <Spinner/>
                </div>
                }
                {data!=null && 
                <>
                <Divider />
                
                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
                    <p className='text-secondary'>Sub-Total :</p>
                    <p>Rs {data.payment.subtotal}</p>
                </div>

                <Divider />

                
                {data.payment.fees.length > 0 &&
                 data.payment.fees.map((fee, index) => (
                <>
                 <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between text-green-500' style={{ backgroundColor: index === 1 ? '' : '#F7F7F7' }}>        
                    <p className='text-secondary'>{fee.name} </p>
                    <p className="text-right">Rs {fee.amount}</p>
                 </div>

                <Divider />
                </>
                   ))
                }

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between text-red-500'>
                    <p className='text-secondary'>Total Discount :</p>
                    <p>Rs {data.payment.discount_total}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Paid By Customer :</p>
                    <p>Rs {data.orderData.total}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between text-red-500'>
                    <p className='text-secondary'>Total Refund :</p>
                    <p>Rs {data.payment.refund_total}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between py-1' style={{backgroundColor : '#F7F7F7'}}>
                    <p className='text-secondary'>Net Total:</p>
                    <p className="font-semibold text-base text-green-500">Rs {data.orderData.total}</p>
                </div>    
                </>
                }
                
            </div>

            <div className="d-flex flex-col pb-3">
            {data!=null && 
                    <>
                    <button type="button" onClick={e=>setopencouponmodal(true)} className="bg-blue-500 text-white font-semibold py-2">Apply Coupon</button>
                    {/* <AddRefund orderid={orderid} items={data.items} user={user} onChange={e => onChangeRefundsRerender()} />
                    <AddComplaint orderid={orderid} items={data.items} user={user} onChange={e => onChangeRerenderIssues()} /> */}
                    </>
            }
            </div>

            {data!=null && 
               <>
               <OrderLogs orderid={orderid} render={rerenderOrderLogs} />   
               <OrderNotes orderid={orderid} user={user} />
               <Emails orderid={orderid} email={data.orderData.billing.email} />
               </>
            }


          </div>

        </div>
        </>

        );
    
}

export default EditOrder;