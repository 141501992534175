import React from 'react'
import { useState } from 'react';
import { Divider ,Drawer } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const POSStaff = ({staff}) => {

   const [StaffDrawerOpen, setStaffDrawerOpen] = useState(false);

   const handleDrawer = () => {
       setStaffDrawerOpen(!StaffDrawerOpen)
   }
  return (
    <>
    <div onClick={() =>  handleDrawer()} className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
     <p className="text-secondary text-nowrap">{staff.staff_name}</p>
     <button className="px-2 rounded-sm text-sm text-orange-500"><MoreHorizIcon/></button>
    </div>
    <Divider />
    <Drawer anchor='bottom' open={StaffDrawerOpen} onClose={handleDrawer}> 
    <div style={{ padding: 20 }}>
        <>
        <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Role : </p>
        <p className="text-medium">{staff.roles}</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Mobile : </p>
        <p className="text-medium">{staff.Mobile}</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Email : </p>
        <p className="text-medium">{staff.email_address}</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Whatsapp : </p>
        <p className="text-medium">{staff.whatsapp}</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Skill : </p>
        <p className="text-medium">{staff.skill_level=='Medium' &&
                                    <b className="bg-orange-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{staff.skill_level}</b>
                                }
                                {staff.skill_level=='Low' &&
                                    <b className="bg-blue-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{staff.skill_level}</b>
                                }
                                {staff.skill_level=='High' &&
                                    <b className="bg-red-600 text-white  px-2 py-1 text-xs mt-2 rounded-full">{staff.skill_level}</b>
                                }</p>
        </div>
      <Divider />

      <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
        <p className="text-secondary text-nowrap">Training : </p>
        <p className="text-medium"> {staff.training_done=='Yes' &&
                                    <b className="bg-green-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{staff.training_done}</b>
                                }
                                {staff.training_done=='No' &&
                                    <b className="bg-red-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{staff.training_done}</b>
                                }</p>
        </div>
      <Divider />
        
    </>
    </div>
    </Drawer>
    </>
  )
}

export default POSStaff