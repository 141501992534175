import React from 'react';
import { useState } from 'react';
import { Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../../styles/VSPCard/VspmainCard.css'

const VspmainCard = ({data , index}) => {

    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
      setAccordionOpen(!accordionOpen);
    };
  
  return (
    <div key={index} className='border rounded-sm p-3 pb-2 shadow-sm'>
      <div className='d-flex justify-content-between align-items-center'>
        <p className='font-semibold text-sm w-1/2'>{data.eventname}</p>   
        <p className='text-secondary' style={{ fontSize : '11px'}}>{data.eventdate}</p>
      </div>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />

      <Accordion expanded={accordionOpen} style={{ padding: 0, border: 'none' }}>
      <AccordionSummary style={{ display: 'flex',  padding: 0 , width : '100%'}}>
  
          <a href='#' className='font-semibold text-orange-600 text-sm rounded-pill underline '>View VSP</a>
          {/* <a href='#' className='px-3 py-1 text-orange-60 text-white bg-orange-600 text-xs rounded-pill underline' style={{border : '1px solid #ea580c'}}>On-site Staff</a> */}

        <button className='text-xs py-1 rounded-pill' onClick={toggleAccordion} style={{ display: 'flex', alignItems: 'center' }}>
         More Details <ExpandMoreIcon />
        </button>
      </AccordionSummary>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />

      <AccordionDetails>
       <div className=''>
        <p className='font-semibold uppercase text-sm'>Ticket Control</p>
        <> 
        {data.service_ticket_control=='Yes' && 
          <>
           <span className="text-blue-600 font-semibold">{data.service_ticket_control}</span><br />
           <span>{data.service_ticket_control_pax} pax</span><br />
           <span>{data.service_ticket_control_hours}</span><br />
          </>
         }
         {data.service_ticket_control=='No' && 
          <>
           <span className="text-red-600 font-semibold">{data.service_ticket_control}</span>
          </>
         }
        </>
       </div>
      </AccordionDetails>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />

      <AccordionDetails>
        <div>
         <p className='font-semibold uppercase  text-sm'>VSP</p>
         <>
             {data.service_vsp=='Yes' && 
              <>
               <span className="text-blue-600 font-semibold">{data.service_vsp}</span><br />
               <span>{data.service_vsp_pax} pax</span><br />
               <span>{data.service_vsp_hours}</span><br />
              </>
                }
                {data.service_vsp=='No' && 
                <>
                <span className="text-red-600 font-semibold">{data.service_vsp}</span>
                </>
                }
            </> 

        </div>
      </AccordionDetails>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      
      <AccordionDetails>
        <div>
         <p className='font-semibold uppercase  text-sm'>Hotesses</p>
         <>
            {data.service_hostessess=='Yes' && 
            <>
             <span className="text-blue-600 font-semibold">{data.service_hostessess}</span><br />
             <span>{data.service_hostessess_pax} pax</span><br />
             <span>{data.service_hostessess_hours}</span><br />
            </>
            }
            {data.service_hostessess=='No' && 
            <>
            <span className="text-red-600 font-semibold">{data.service_hostessess}</span>
            </>
            }
            </>

        </div>
      </AccordionDetails>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      
      <AccordionDetails>
        <div>
         <p className='font-semibold uppercase  text-sm'>Transportation</p>
         <>
            {data.service_transportation=='Yes' && 
             <>
              <span className="font-semibold">Vehicle Details:</span> {data.service_transportation_vehicle}<br />
              <span className="font-semibold">Number of Vehicles:</span> {data.service_transportation_number}<br />
              <span className="font-semibold">Transport Cost:</span> Rs {data.service_transportation_cost}<br />
             </>
            }
            {data.service_transportation=='No' && 
            <>
            <span className="text-red-600 font-semibold">{data.service_transportation}</span>
            </>
            }
         </>
        </div>
      </AccordionDetails>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      
      <AccordionDetails>
        <div className='text-sm'>
         <p className='font-semibold uppercase text-sm mb-1'>Total Cost</p>
         <>
           <span className="font-semibold text-orange-500 ">Ticket Control:</span> Rs {data.service_ticket_control_cost}<br />
           <span className="font-semibold text-orange-500 ">VSP Cost:</span> Rs {data.service_vsp_cost}<br />
           <span className="font-semibold text-orange-500 ">Hostesses Cost:</span> Rs {data.service_hostessess_cost}<br />
           <span className="font-semibold text-orange-500 ">Transport Cost:</span> Rs {data.service_transportation_cost}<br />
           <hr />
           <span className="text-orange-600 font-semibold">Grand Total: Rs {data.totalcost}</span>
           </>
        </div>
      </AccordionDetails>

      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      
      <AccordionDetails>
        <div className='text-sm'>
         <p className='font-semibold uppercase mb-1'>Payable By Producer</p>
         <>
           <span className="font-semibold text-orange-500">Ticket Control:</span> Rs {data.service_ticket_control_producerpay}<br />
           <span className="font-semibold text-orange-500">VSP Cost:</span> Rs {data.service_vsp_producerpay}<br />
           <span className="font-semibold text-orange-500">Hostesses Cost:</span> Rs {data.service_hostessess_producerpay}<br />
           <span className="font-semibold text-orange-500">Transport Cost:</span> Rs {data.service_transportation_producerpay}<br />
           <hr />
           <span className="text-orange-600 font-semibold">Grand Total: Rs {data.totalcost_producer}</span>
          </>
        </div>
      </AccordionDetails>
    </Accordion>
    </div>
  );
}

export default VspmainCard;