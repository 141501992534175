import { useEffect, useState } from "react";
import AuthService from "../services/auth.service";

import "../styles/Login.css";

function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            if(user!=false) {
                setIsLoggedIn(true);
                window.location.href='/dashboard';
            }
        }).catch((err) => {
            console.log(err);
            setIsLoggedIn(false);
        });
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);

        console.log(username, password);

        if (username != '' && password != '') {
            AuthService.login(username, password)
            .then(() => {
                AuthService.checklogin()
                .then(user => {
                    if(user!=false && user!=null) {
                        setIsLoggedIn(true);
                        window.location.href='/dashboard';
                    }
                }).catch((err) => {
                    console.log(err);
                    setIsLoggedIn(false);
                });
                // history.push("/home");
                
            })
            .catch(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }
    return (
        <div className="col-md-12">
            <div className="card card-container login-container">
                <img
                    src="../assets/logo.png"
                    alt="logo-img"
                    className="profile-img-card"
                />

                <form onSubmit={e => handleLogin(e)}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <button
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                        </button>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default Login;