import React from 'react'
import { useState } from 'react';
import {Divider } from '@mui/material';

const POSMainCard = ({pos}) => {

    const [ShowMore, setShowMore] = useState(false)

    console.log(pos)

  return (

    <div className='border rounded-sm shadow-sm mb-3'>

     <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
        <a href={`/pos/`+pos.id} className='font-semibold text-sm underline text-orange-500'>{pos.pos_name}</a>    
     </div>
     <Divider/>

     <div className='overflow-hidden'  style={{ maxHeight: ShowMore ? 'unset' : '58px' }}>

     <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Last Collection Date :</p>
          <p className='font-medium line-clamp-1 text-right'>{pos.lastcollection}</p>
      </div>    

      <Divider />
      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>POS Cash Balance :</p>
          <p className='font-medium line-clamp-1 text-right'>Rs {pos.poscashbalance.totalCash.toLocaleString('en-US')}</p>
      </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>This month sales :</p>
          <p className='font-medium line-clamp-1 text-right'>Rs {Math.round(pos.getPOSEarnings.thismonth).toLocaleString('en-US')}</p>
      </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Last month sales :</p>
          <p className='font-medium line-clamp-1 text-right'>Rs {Math.round(pos.getPOSEarnings.lastmonth).toLocaleString('en-US')}</p>
      </div>    

      <Divider />

     <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Landline :</p>
          <p className='font-medium line-clamp-1 text-right'>{pos.phones}</p>
      </div>    

      <Divider />
      
     <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Status :</p>
          {(pos.status=='Fully Operational' || pos.status=='') &&
          <b className="text-green-600">{pos.status}</b>
          }
          {pos.status=='Partially Operational' &&
          <b className="text-orange-600">{pos.status}</b>
          }
          {pos.status=='Not Operational' &&
          <b className="text-blue-600">{pos.status}</b>
          }
          {pos.status=='Closed' &&
          <b className="text-red-600">{pos.status}</b>
           }      
      </div>    

      <Divider />
      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' >
          <p className='text-secondary text-nowrap'>Ticket Stock :</p>
          <p className='font-medium line-clamp-1 text-right'>{Math.round(pos.getLastTicketStock.balance).toLocaleString('en-US')}<span className='text-secondary text-xs'> ({pos.getLastTicketStock.date})</span></p>
      </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Pochette Stock :</p>
          <p className='font-medium line-clamp-1 text-right'>{Math.round(pos.getLastPochetteStock.balance).toLocaleString('en-US')}<span className='text-secondary text-xs'> ({pos.getLastPochetteStock.date})</span></p>
      </div>    

      <Divider />

      <div className='d-flex justify-content-between px-3 py-2 '>
        <a className='text-sm underline text-blue-500 font-semibold ' href={`/pos-sales/`+pos.id}>
           Sales Report
        </a>
        <a className='text-sm underline text-orange-500 font-semibold' href={`/pos-collection/`+pos.id}>
            Collection Report
        </a>
      </div>
     </div>

     <div className='px-3 py-1 bg-white z-10 text-right' style={{ display: ShowMore ? 'none' : 'block' }}>
        <button className='font-semibold text-orange-500 text-sm rounded-sm' onClick={() => setShowMore(!ShowMore)}>View More</button>
     </div>
    </div>
    )
}

export default POSMainCard