import React, { Component } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import OrdersReportService from "../../services/ordersreport.service";


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default class OrderComments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderid : props.orderid,
            open: false,
            data: [],
            buttonText : "More Comments"
        }
    }

    handleOpen = (e) => {
        if(this.state.orderid!='') {
            this.setState({
                buttonText: "loading..."
            })
            OrdersReportService.getOrderNotes(this.state.orderid)
            .then(response => {
                console.log("order Notes", response);
                this.setState({
                    open: true,
                    data: response.data,
                    buttonText : "More Comments"
                });
            })
            .catch(error => console.log({error}));
        }
    }

    handleClose = (e) => {
        this.setState({
            open: false
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <Button className="d-none d-lg-block" onClick={(e)=> this.handleOpen(e)} style={{color: "blue"}}>{this.state.buttonText}</Button>
            <button className="d-lg-none text-xs text-nowrap" onClick={(e)=> this.handleOpen(e)}>{this.state.buttonText}<MoreHorizIcon style={{ color : '#ea580c', marginLeft : 5}}/></button>
            <Modal open={this.state.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={()=>this.handleClose}>
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{textDecoration: "underline"}}>
                    Order Comments for #{this.state.orderid}
                </Typography> 
                <Typography id="modal-modal-description" className="payment-details-wrapper" sx={{ mt: 2 }}>
                    {this.state.data.length>0 && this.state.data.map((item, index) => (
                        <div key={index} className="p-2 bg-blue-100 mb-2">
                            <label className="">{item.comment_author} - <span className="text-sm text-gray-500 italic">{item.comment_date}</span></label>
                            <p>{item.comment_content}</p>
                        </div>
                    ))}
                    
                    <div style={{textAlign: "center"}}>
                    <hr /> 
                    <button type="button" onClick={e => this.handleClose(e)} className="btn btn-sm btn-danger bg-red-700 hover:bg-red-800">Close</button>
                    </div>
                </Typography>
                </Box>
            </Modal>
            </>
        )
    }
}