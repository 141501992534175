import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
// import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import axios from "axios";

import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";
import PosService from "../services/pos.service";

import "../styles/Posmain.css"

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Divider ,Drawer  } from "@mui/material";
import POSStaff from "../components/Cards/POS/POSStaff";


const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const APIURL = "https://otayo.com/erpapi/pos.php";

const PosSingle = () => {
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenIssue, setIsOpenIssue] = useState(false);
    const [editIssueID, setEditIssueID] = useState(0);
    const [isOpenContact, setIsOpenContact] = useState(false);
    const [editContactID, setEditContactID] = useState(0);
    const [isOpenTicketLog, setIsOpenTicketLog] = useState(false);
   
    const [isOpenPochetteLog, setIsOpenPochetteLog] = useState(false);
    const [editEquipment, setEditEquipment] = useState(false);

    const [posName, setPosName] = useState('');
    const [posEmail, setPosEmail] = useState('');
    const [posEmail1, setPosEmail1] = useState('');
    const [posEmail2, setPosEmail2] = useState('');
    const [posLocation, setPosLocation] = useState('');
    const [posOperatingHoursDays, setPosOperatingHoursDays] = useState('');
    const [posPhones, setPosPhones] = useState('');
    const [posStatus, setPosStatus] = useState('');
    const [posComments, setPosComments] = useState('');
    const [allPOS, setAllPOS] = useState('');

    const [allIssues, setAllIssues] = useState([]);
    const [addIssueType, setAddIssueType] = useState('');
    const [addIssueDesc, setAddIssueDesc] = useState('');
    const [addIssueUrgency, setAddIssueUrgency] = useState('Low');
    const [addIssueStatus, setAddIssueStatus] = useState('Pending');
    const [addIssueCreatedBy, setAddIssueCreatedBy] = useState('');
    const [selectedIssueID, setSelectedIssueID] = useState(0);

    const [allStaffs, setAllStaffs] = useState([]);
    const [addStaffName, setAddStaffName] = useState('');
    const [addStaffRoles, setAddStaffRoles] = useState('');
    const [addStaffMobile, setAddStaffMobile] = useState('');
    const [addStaffEmail, setAddStaffEmail] = useState('');
    const [addStaffWhatsapp, setAddStaffWhatsapp] = useState('');
    const [addStaffSkillLevel, setAddStaffSkillLevel] = useState('');
    const [addStaffTrainingDone, setAddStaffTrainingDone] = useState('');
    const [addStaffMainPerson, setAddStaffMainPerson] = useState('');
    const [selectedStaffID, setSelectedStaffID] = useState(0);

    const [allTicketBalance, setAllTicketBalance] = useState([]);
    const [ticketLogDate, setTicketLogDate] = useState("");
    const [addTicketBalanceQty, setAddTicketBalanceQty] = useState(0);
    const [addTicketBalanceComments, setAddTicketBalanceComments] = useState('');
    const [addTicketBalanceID, setAddTicketBalanceID] = useState(0);

    const [pochetteLogDate, setPochetteLogDate] = useState("");
    const [allPochetteBalance, setAllPochetteBalance] = useState([]);
    const [addPochetteBalanceQty, setAddPochetteBalanceQty] = useState(0);
    const [addPochetteBalanceComments, setAddPochetteBalanceComments] = useState('');
    const [addPochetteBalanceID, setAddPochetteBalanceID] = useState(0);

   const [posPC, setPosPC] = useState(false);
   const [posTicketPrinter, setPosTicketPrinter] = useState(false);
   const [posCashTill, setPosCashTill] = useState(false);
   const [posKeyboard, setPosKeyboard] = useState(false);
   const [posMouse, setPosMouse] = useState(false);
   const [posCardTerminal, setPosCardTerminal] = useState(false);
   const [posEmtelBox, setPosEmtelBox] = useState(false);
   const [posElectricalExtension, setPosElectricalExtension] = useState(false);
   const [posEquipmentAction, setPosEquipmentAction] = useState('addPOSEquipments');

   const [posIndividualComments, setPosIndividualComments] = useState("");
   const [posTicketsComments, setPosTicketsComments] = useState("");

   const [editPOSName, setEditPOSName] = useState("");
   const [editPOSEmail, setEditPOSEmail] = useState("");
   const [editPOSEmail1, setEditPOSEmail1] = useState("");
   const [editPOSEmail2, setEditPOSEmail2] = useState("");
   const [editPOSLocation, setEditPOSLocation] = useState("");
   const [editPOSDaysHours, setEditPOSDaysHours] = useState("");
   const [editPOSPhones, setEditPOSPhones] = useState("");
   const [editPOSStatus, setEditPOSStatus] = useState("");
   const [editPOSComment, setEditPOSComment] = useState("");
   const [editPOSID, setEditPOSID] = useState(0);

   const [TBLDrawer, setTBLDrawer] = useState(false)

   const handleTBL = () => {
    setTBLDrawer(!TBLDrawer)
   }

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                getAllPOSIssue();
                getAllPOSStaff();
                getAllPOSTickets();
                getPOSEquipments();
                getAllPOSPochettes();
                PosService.getSinglePOS(id)
                .then(response => {
                    console.log('POS response', {response});
                    setAllPOS(response.data);
                    setPosIndividualComments(response.data.pos_individual_comment);
                    setPosTicketsComments(response.data.pos_tickets_comment);
                })
                .catch(err => console.log({err}));
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const updateMeta = (value, key) => {   
        PosService.updateMeta(id, key, value)
        .then(response => {
            console.log('Meta response', {response});
        })
        .catch(err => console.log({err}));   
    };

    // useEffect(() => {   
    //     PosService.updateMeta(id, 'pos_tickets_comment', posTicketsComments)
    //     .then(response => {
    //         console.log('Meta response', {response});
    //     })
    //     .catch(err => console.log({err}));   
    // }, [posTicketsComments]);

    const createPOS = (e) => {
        e.preventDefault();
        var editpos = false;
        if(editPOSID>0) {
            editpos = editPOSID;
        } 
        PosService.createPOS(editPOSName, editPOSEmail, editPOSEmail1, editPOSEmail2, editPOSLocation, editPOSDaysHours, editPOSPhones, editPOSStatus, editPOSComment, editpos)
        .then(response => {
            console.log('POS response', {response});
            setIsOpen(false);
            swal('POS Created', "The POS has been created", "success");
            PosService.getSinglePOS(id)
            .then(response => {
                console.log('POS response', {response});
                setAllPOS(response.data);
            })
            .catch(err => console.log({err}));
        })
        .catch(err => console.log({err}));
    }

    const getPOS = () => {
        var params = {
            action: 'getSinglePOS',
            id: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("single pos", response);
            setEditPOSName(response.data.pos_name);
            setEditPOSEmail(response.data.email);
            setEditPOSEmail1(response.data.email1);
            setEditPOSEmail2(response.data.setPosEmail2);
            setEditPOSLocation(response.data.location);
            setEditPOSDaysHours(response.data.operating_days_hours);
            setEditPOSPhones(response.data.phones);
            setEditPOSStatus(response.data.status);
            setEditPOSComment(response.data.general_comment);
            setEditPOSID(id);
            setIsOpen(true);
        })
        .catch(err => console.log(err));
    }

    const editIssue = (id) => {
        setEditIssueID(id);
        setIsOpenIssue(true);
    };

    const editContact = (id) => {
        setEditContactID(id);
        setIsOpenContact(true);
    };

    const getAllPOSIssue = () => {
        var params = {
            action: 'getAllPOSIssue',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("issues", response);
            setAllIssues(response.data);
        })
        .catch(err => console.log(err));
    }

    const getPOSIssue = (issue_id) => {
        var params = {
            action: 'getPOSIssue',
            id: issue_id,
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("single issue", response);
            setAddIssueType(response.data.issue_type);
            if(response.data.urgency!='') {
                setAddIssueUrgency(response.data.urgency);
            }
            setAddIssueDesc(response.data.full_description);
            setAddIssueStatus(response.data.status);
            setAddIssueCreatedBy(response.data.issue_created_by);
            setSelectedIssueID(issue_id);
            setIsOpenIssue(true);
        })
        .catch(err => console.log(err));
    }

    const addPOSIssue = (e) => {
        e.preventDefault();

        var action = 'addPOSIssue';
        if(selectedIssueID>0) {
            action = 'editPOSIssue';
        }
        var params = {
            action: action,
            id: selectedIssueID,
            issue_type: addIssueType,
            full_description: addIssueDesc,
            urgency: addIssueUrgency,
            status: addIssueStatus,
            issue_created_by: addIssueCreatedBy,
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setIsOpenIssue(false);
            swal("Issue Saved", "Issue has been saved", "success");
            setAddIssueType('');
            setAddIssueUrgency('');
            setAddIssueDesc('');
            setAddIssueStatus('');
            setAddIssueCreatedBy('');
            setSelectedIssueID(0);

            getAllPOSIssue();
        })
        .catch(err => console.log(err));
    }

    const getAllPOSStaff = () => {
        var params = {
            action: 'getAllPOSStaff',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("issues", response);
            setAllStaffs(response.data);
        })
        .catch(err => console.log(err));
    }

    const getPOSStaff = (staff_id) => {
        var params = {
            action: 'getPOSStaff',
            id: staff_id,
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("single staff", response);
            setAddStaffName(response.data.staff_name);
            setAddStaffRoles(response.data.roles);
            setAddStaffMobile(response.data.mobile);
            setAddStaffEmail(response.data.email_address);
            setAddStaffWhatsapp(response.data.whatsapp);
            setSelectedStaffID(staff_id);
            setIsOpenContact(true);
            setAddStaffSkillLevel(response.data.skill_level);
            setAddStaffTrainingDone(response.data.training_done);
            setAddStaffMainPerson(response.data.main_person);
        })
        .catch(err => console.log(err));
    }

    const addPOSStaff = (e) => {
        e.preventDefault();

        var action = 'addPOSStaff';
        if(selectedStaffID>0) {
            action = 'editPOSStaff';
        }
        var params = {
            action: action,
            id: selectedStaffID,
            staff_name: addStaffName,
            roles: addStaffRoles,
            mobile: addStaffMobile,
            email_address: addStaffEmail,
            whatsapp: addStaffWhatsapp,
            pos: id,
            skill_level: addStaffSkillLevel,
            training_done: addStaffTrainingDone,
            main_person: addStaffMainPerson,
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setIsOpenContact(false);
            swal("Staff Saved", "Staff has been saved", "success");
            setAddStaffName('');
            setAddStaffRoles('');
            setAddStaffMobile('');
            setAddStaffEmail('');
            setAddStaffWhatsapp('');
            setAddStaffSkillLevel('');
            setAddStaffTrainingDone('');
            setAddStaffMainPerson('');
            setSelectedStaffID(0);

            getAllPOSStaff();
        })
        .catch(err => console.log(err));

    }

    const getAllPOSTickets = () => {
        var params = {
            action: 'getAllPOSTicketBalance',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            // console.log("issues", response);
            setAllTicketBalance(response.data);
        })
        .catch(err => console.log(err));
    }

    const getPOSTickets = (tickets_id) => {
        var params = {
            action: 'getPOSTicketBalance',
            id: tickets_id,
            pos: id
        }
    
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("single tickets", response);
            setTicketLogDate(response.data.date_time);
            setAddTicketBalanceQty(response.data.balance);
            setAddTicketBalanceComments(response.data.comments);
            setAddTicketBalanceID(tickets_id)
            setIsOpenTicketLog(true);
        })
        .catch(err => console.log(err));
    }

    const addPOSTickets = (e) => {
        e.preventDefault();

        var action = 'addPOSTicketBalance';
        if(selectedStaffID>0) {
            action = 'editPOSTicketBalance';
        }
        var params = {
            action: action,
            id: addTicketBalanceID,
            date_time: ticketLogDate,
            balance: addTicketBalanceQty,
            comments: addTicketBalanceComments,
            pos: id,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setIsOpenContact(false);
            swal("Tickets Log Saved", "Tickets Log has been saved", "success");
            setTicketLogDate('');
            setAddTicketBalanceQty('');
            setAddTicketBalanceComments('');
            
            setAddTicketBalanceID(0);
            setIsOpenTicketLog(false);

            getAllPOSTickets();
        })
        .catch(err => console.log(err));

    }

    // pochettes

    const getAllPOSPochettes = () => {
        var params = {
            action: 'getAllPOSPochetteBalance',
            pos: id
        }
        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("pochettes", response);
            setAllPochetteBalance(response.data);
        })
        .catch(err => console.log(err));
    }

    const getPOSPochettes = (pochette_id) => {
        var params = {
            action: 'getPOSPochetteBalance',
            id: pochette_id,
            pos: id
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("single Pochette", response);
            setPochetteLogDate(response.data.date_time);
            setAddPochetteBalanceQty(response.data.balance);
            setAddPochetteBalanceComments(response.data.comments);
            setAddPochetteBalanceID(pochette_id)
            setIsOpenPochetteLog(true);
        })
        .catch(err => console.log(err));
    }

    const addPOSPochettes = (e) => {
        e.preventDefault();

        var action = 'addPOSPochetteBalance';
        if(selectedStaffID>0) {
            action = 'editPOSPochetteBalance';
        }

        var params = {
            action: action,
            id: addPochetteBalanceID,
            date_time: pochetteLogDate,
            balance: addPochetteBalanceQty,
            comments: addPochetteBalanceComments,
            pos: id,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setIsOpenContact(false);
            swal("Pochettes Log Saved", "Pochettes Log has been saved", "success");
            setPochetteLogDate('');
            setAddPochetteBalanceQty('');
            setAddPochetteBalanceComments('');
            
            setAddPochetteBalanceID(0);
            setIsOpenPochetteLog(false);

            getAllPOSPochettes();
        })
        .catch(err => console.log(err));

    }

    const savePOSEquipment = () => {
        
        var params = {
            action: posEquipmentAction,
            pos: id,
            pc: posPC,
            printer: posTicketPrinter,
            cashtill: posCashTill,
            keyboard: posKeyboard,
            mouse: posMouse,
            cardterminal: posCardTerminal,
            emtelbox: posEmtelBox,
            electricalextension: posElectricalExtension,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            swal("POS Equipments Saved", "POS Equipments has been saved", "success");
            setEditEquipment(false);
        })
        .catch(err => console.log(err));
    }

    const getPOSEquipments = () => {
        var params = {
            action: 'getPOSEquipments',
            pos: id,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            console.log("equipment", response)
            if(response.data.pc!='undefined') {
                setPosEquipmentAction('editPOSEquipments');

                setPosPC(response.data.pc=='0' ? false:true);
                setPosTicketPrinter(response.data.printer=='0' ? false:true);
                setPosCashTill(response.data.cashtill=='0' ? false:true);
                setPosKeyboard(response.data.keyboard=='0' ? false:true);
                setPosMouse(response.data.mouse=='0' ? false:true);
                setPosCardTerminal(response.data.cardterminal=='0' ? false:true);
                setPosEmtelBox(response.data.emtelbox=='0' ? false:true);
                setPosElectricalExtension(response.data.electricalextension=='0' ? false:true);
            }
        })
        .catch(err => console.log(err));
    }

    const deletePOSStaff = (staff_id) => {
        swal({
        title: "Are you sure?",
        text: "Once removed, The contact will be deleted from the list!",
        icon: "warning",
        buttons: ["No, Keep the Contact!", "Yes, Remove Contact!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            var params = {
                action: 'deletePOSStaff',
                id: staff_id,
            }

            axios.post(APIURL+"?rand="+Math.random(), params)
            .then(response => {
                getAllPOSStaff();
                swal("Staff Removed", "Staff has been removed!", "success");
            })
            .catch(err => console.log({err}));
        } else {
            swal("Staff Not Removed", "Staff is safe!", "success");
        }
        });
    }


    return (
        <>
        <div className="salesreport-wrapper p2 d-none d-lg-block">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">{allPOS!='' && allPOS.pos_name!='undefined' ? allPOS.pos_name:'Loading...'} (Overview)</h1>
                        <button className="btn btn-primary btn-sm bg-green-500 hover:bg-green-600 rounded-none border-none font-semibold" onClick={(e) => setIsOpen(e)}>Create New POS >></button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="base text5e4642 font-bold mb-2">POS Details:</h2>
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Location</th>
                                    <th scope="col">Operating Hours</th>
                                    <th scope="col">Landline</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Issue Details</th> */}
                                    <th scope="col" className="w-1/6">General Comment</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-sm" >
                                    <td className="font-semibold"  scope="col">{allPOS!='' && allPOS.location!='undefined' ? allPOS.location:'Loading...'}</td>
                                    <td scope="col">
                                        {allPOS!='' && allPOS.operating_days_hours!='undefined' && 
                                            <div dangerouslySetInnerHTML={{__html:allPOS.operating_days_hours}}></div>
                                        }
                                    </td>
                                    <td className="font-semibold"  scope="col">{allPOS!='' && allPOS.phones!='undefined' ? allPOS.phones:'Loading...'}</td>
                                    <td className="font-semibold"  scope="col">
                                        {allPOS!='' && allPOS.email!='undefined' ? allPOS.email:'Loading...'}
                                        <br />
                                        {allPOS!='' && allPOS.email1!='undefined' ? allPOS.email1:'Loading...'}
                                        <br />
                                        {allPOS!='' && allPOS.email2!='undefined' ? allPOS.email2:'Loading...'}
                                    </td>
                                    <td scope="col">
                                        {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Fully Operational' &&
                                            <b className="text-white bg-green-600 p-2 mt-2 rounded-full">{allPOS.status}</b>
                                        }
                                        {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Partially Operational' &&
                                            <b className="bg-orange-600 text-white p-2 mt-2 rounded-full">{allPOS.status}</b>
                                        }
                                        {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Not Operational' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">{allPOS.status}</b>
                                        }
                                        {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Closed' &&
                                            <b className="bg-red-600 text-white p-2 mt-2 rounded-full">{allPOS.status}</b>
                                        }
                                        </td>
                                    {/* <td scope="col">{allPOS!='' && allPOS.pos_name!='undefined' ? allPOS.pos_name:'Loading...'}</td> */}
                                    <td className="textaeaeae" scope="col">{allPOS!='' && allPOS.general_comment!='undefined' ? allPOS.general_comment:'Loading...'}</td>
                                    <td scope="col"><div className="font-normal textf36523 underline cursor-pointer" onClick={e => getPOS()}>Edit Info</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="eventname font-bold text-base mb-0 text5e4642">Issues Log</h2> <button className="btn btn-success btn-sm bg-black rounded-none font-bold" onClick={e => {setIsOpenIssue(true); setEditIssueID(0);}}>+</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Issue Type</th>
                                    <th scope="col" className="w-1/6">Full Description</th>
                                    <th scope="col">Urgency</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Issue Created by</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allIssues.length>0 && allIssues.map((issue, index) => (
                                <tr key={index}  className=" text-sm">
                                    <td scope="col">{issue.date_time}</td>
                                    <td scope="col">{issue.issue_type}</td>
                                    <td className="textaeaeae" scope="col">{issue.full_description}</td>
                                    <td scope="col">
                                        {issue.urgency=='Medium' &&
                                            <b className="bg-orange-600 text-white p-2 mt-2 rounded-full">{issue.urgency}</b>
                                        }
                                        {issue.urgency=='Low' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">{issue.urgency}</b>
                                        }
                                        {issue.urgency=='High' &&
                                            <b className="bg-red-600 text-white p-2 mt-2 rounded-full">{issue.urgency}</b>
                                        }
                                        {issue.urgency.trim()=='' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">Low</b>
                                        }
                                    </td>
                                    <td scope="col">
                                        {issue.status=='In-progress' &&
                                            <b className="bg-orange-600 text-white p-2 mt-2 rounded-full">{issue.status}</b>
                                        }
                                        {issue.status=='Pending' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">{issue.status}</b>
                                        }
                                        {issue.status=='Resolved' &&
                                            <b className="bg-green-600 text-white p-2 mt-2 rounded-full">{issue.status}</b>
                                        }
                                        {issue.status.trim()=='' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">Pending</b>
                                        }
                                    </td>
                                    {/* <td scope="col">{issue.status}</td> */}
                                    <td scope="col">{issue.issue_created_by}</td>
                                    <td scope="col"><div class="textf36523 underline cursor-pointer font-normal" onClick={e=> getPOSIssue(issue.id)}>Edit Info</div></td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="eventname font-bold text-base mb-0 text5e4642">POS Staff</h2> <button className="btn btn-success btn-sm bg-black rounded-none font-bold" onClick={e => {setIsOpenContact(true); setEditContactID(0);}}>+</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr className=" text-sm">
                                    <th scope="col">Names</th>
                                    <th scope="col">Roles</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Whatsapp</th>
                                    <th scope="col">Skill Level</th>
                                    <th scope="col">Training Done</th>
                                    <th scope="col">Actions:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStaffs.length>0 && allStaffs.map((staff, index) => (
                                <tr key={index} className=" text-sm">
                                    <td scope="col">{staff.staff_name}</td>
                                    <td scope="col">{staff.roles}</td>
                                    <td scope="col">{staff.mobile}</td>
                                    <td scope="col">{staff.email_address}</td>
                                    <td scope="col">{staff.whatsapp}</td>
                                    <td scope="col">
                                        {staff.skill_level=='Medium' &&
                                            <b className="bg-orange-600 text-white p-2 mt-2 rounded-full">{staff.skill_level}</b>
                                        }
                                        {staff.skill_level=='Low' &&
                                            <b className="bg-blue-600 text-white p-2 mt-2 rounded-full">{staff.skill_level}</b>
                                        }
                                        {staff.skill_level=='High' &&
                                            <b className="bg-red-600 text-white p-2 mt-2 rounded-full">{staff.skill_level}</b>
                                        }
                                    </td>
                                    <td scope="col">
                                        {staff.training_done=='Yes' &&
                                            <b className="bg-green-600 text-white p-2 mt-2 rounded-full">{staff.training_done}</b>
                                        }
                                        {staff.training_done=='No' &&
                                            <b className="bg-red-600 text-white p-2 mt-2 rounded-full">{staff.training_done}</b>
                                        }
                                    </td>
                                    <td scope="col">
                                        <button class="text01bff1 p-0 mr-1" onClick={e=> getPOSStaff(staff.id)}>Edit</button>
                                        /
                                        <button class="text-red ml-1 p-0" onClick={e=> deletePOSStaff(staff.id)}>Delete</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 flex flex-column justify-between items-start">
                        <label className="form-label font-semibold text-base mb-2 text5e4642">Comments:</label>
                        <textarea className="form-control text-sm" style={{backgroundColor:"rgb(230, 230, 230)"}} placeholder="Any comments...." rows="4" value={posIndividualComments} onChange={e => {setPosIndividualComments(e.target.value); updateMeta(e.target.value, 'pos_individual_comment');}}></textarea>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="eventname font-bold text-base mb-2 text5e4642">POS Equipment</h2> 
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 flex justify-start items-center">
                        <div className="possystems flex flex-column justify-start w-1/4 pr-10 h-100">
                            <div className="possystemItem mb-2 flex items-center justify-between p-2">
                                <div className="possystemItemLabel font-bold text-sm p-2" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}} >POS System</div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">POS PC</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posPC} onChange={e => setPosPC(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Ticket Printer</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posTicketPrinter}  onChange={e => setPosTicketPrinter(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Cash Till</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posCashTill}  onChange={e => setPosCashTill(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Keyboard</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posKeyboard}  onChange={e => setPosKeyboard(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Mouse</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posMouse}  onChange={e => setPosMouse(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                        </div>

                        <div className="possystems flex flex-column justify-start w-1/4 pl-10 h-100 mt-3">
                            <div className="possystemItem mb-2 flex items-center justify-between">
                                <div className="possystemItemLabel font-bold text-sm p-2" style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase", width:"100%"}} >Supporting Equipment</div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Credit Card Terminal</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posCardTerminal}  onChange={e => setPosCardTerminal(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Emtel Box</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posEmtelBox}  onChange={e => setPosEmtelBox(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>
                            <div className="possystemItem mb-2 flex items-center justify-between pr-10 pl-2 border-b pb-2">
                                <div className="possystemItemLabel text-sm">Electrical Extension</div>
                                <div className="possystemItemValue text-sm"><input type='checkbox' checked={posElectricalExtension}  onChange={e => setPosElectricalExtension(e.target.checked)} disabled={!editEquipment} /></div>
                            </div>

                            {editEquipment==false && 
                                <div className="underline textf36523 text-sm mt-8 cursor-pointer" onClick={e => setEditEquipment(true)}>Click to Edit Equipment Stock List</div>
                            }

                            {editEquipment==true && 
                                <button className="underline text3ab54a text-sm mt-8 cursor-pointer" onClick={e => savePOSEquipment()}>Click to Save Equipment Stock List</button>
                            }
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                        <h2 className="eventname font-bold text-base mb-0 text5e4642">Tickets Balance</h2> <button className="btn btn-success btn-sm btn-orange" onClick={e => setIsOpenTicketLog(true)}>[+] Add Ticket Log Record</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Tickets Balance</th>
                                    <th scope="col">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTicketBalance.length>0 && allTicketBalance.map((ticket, index) => (
                                <tr key={index} className=" text-sm">
                                    <td scope="col">{ticket.date_time}</td>
                                    <td scope="col">{ticket.balance}</td>
                                    <td scope="col">{ticket.comments}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-12 flex justify-between items-center">
                       <h2 className="eventname font-bold  text-base mb-0 text5e4642">Pochettes Balance</h2> <button className="btn btn-success btn-sm btn-orange" onClick={e => setIsOpenPochetteLog(true)}>[+] Add Pochette Log Record</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <table className="table table-ticket-sale">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Pochette Balance</th>
                                    <th scope="col">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPochetteBalance.length>0 && allPochetteBalance.map((pochette, index) => (
                                <tr key={index} className=" text-sm">
                                    <td scope="col">{pochette.date_time}</td>
                                    <td scope="col">{pochette.balance}</td>
                                    <td scope="col">{pochette.comments}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 flex flex-column justify-between items-start">
                        <label className="form-label font-semibold text-base text5e4642">Ticket Comments:</label>
                        <textarea className="form-control" style={{backgroundColor:"rgb(230, 230, 230)"}} placeholder="Any comments on tickets and pochettes...." rows="4" value={posTicketsComments} onChange={e => {setPosTicketsComments(e.target.value); updateMeta(e.target.value, 'pos_tickets_comment');}}></textarea>
                    </div>
                </div>

            </div>

            <Modal
                open={isOpen}
                onClose={e => setIsOpen(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => createPOS(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Create / Edit a POS
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>POS Name</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={editPOSID} />
                            <input type="text" className="form-control" placeholder="Enter POS name" required="required" value={editPOSName} onChange={e => setEditPOSName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Email Address</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" required="required" value={editPOSEmail} onChange={e => setEditPOSEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Alternate Email 1</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={editPOSEmail1} onChange={e => setEditPOSEmail1(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Alternate Email 2</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Email Address" value={editPOSEmail2} onChange={e => setEditPOSEmail2(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Location</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter POS Location" required="required" value={editPOSLocation} onChange={e => setEditPOSLocation(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Operating Days & Hours</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Enter POS Location" required="required" value={editPOSDaysHours} onChange={e => setEditPOSDaysHours(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Phones</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter phone number" required="required" value={editPOSPhones} onChange={e => setEditPOSPhones(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Status</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={editPOSStatus} onChange={e => setEditPOSStatus(e.target.value)}>
                                <option value="Fully Operational">Fully Operational</option>
                                <option value="Partially Operational">Partially Operational</option>
                                <option value="Not Operational">Not Operational</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>General Comment</b></label>
                        <div className="col-8"> 
                            <textarea rows={4} className="form-control" placeholder="Enter a comment if any" value={editPOSComment} onChange={e => setEditPOSComment(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpen(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* Add edit issue record */}
            <Modal
                open={isOpenIssue}
                onClose={e => setIsOpenIssue(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addPOSIssue(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add/Edit Issue Record
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Issue Type</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={selectedIssueID} />
                            <input type="text" className="form-control" placeholder="Enter Issue Type" required="required" value={addIssueType} onChange={e => setAddIssueType(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Description</b></label>
                        <div className="col-8">
                            <textarea rows={4} className="form-control" placeholder="Enter Description" required="required" value={addIssueDesc} onChange={e => setAddIssueDesc(e.target.value)}></textarea> 
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Urgency</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={addIssueUrgency} onChange={e => setAddIssueUrgency(e.target.value)} required="required">
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Status</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={addIssueStatus} onChange={e => setAddIssueStatus(e.target.value)} required="required">
                                <option value="Pending">Pending</option>
                                <option value="In-progress">In-progress</option>
                                <option value="Resolved">Resolved</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Created By</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Agent Name" required="required" value={addIssueCreatedBy} onChange={e => setAddIssueCreatedBy(e.target.value)} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenIssue(false)}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* Add edit more contacts */}
            <Modal
                open={isOpenContact}
                onClose={e => setIsOpenContact(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addPOSStaff(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add/Edit Contacts
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Full Name</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={selectedStaffID} />
                            <input type="text" className="form-control" placeholder="Enter Full Name" required="required" value={addStaffName} onChange={e => setAddStaffName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Role</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Role" required="required" value={addStaffRoles} onChange={e => setAddStaffRoles(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Mobile</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" placeholder="Enter Mobile" required="required" value={addStaffMobile} onChange={e => setAddStaffMobile(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Email</b></label>
                        <div className="col-8"> 
                            <input type="email" className="form-control" placeholder="Enter Email" required="required" value={addStaffEmail} onChange={e => setAddStaffEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Whatsapp</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" placeholder="Enter Whatsapp" required="required" value={addStaffWhatsapp} onChange={e => setAddStaffWhatsapp(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Main Contact Person</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={addStaffMainPerson} onChange={e => setAddStaffMainPerson(e.target.value)}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Skill Level</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={addStaffSkillLevel} onChange={e => setAddStaffSkillLevel(e.target.value)}>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Training Status</b></label>
                        <div className="col-8"> 
                            <select className="form-control" value={addStaffTrainingDone} onChange={e => setAddStaffTrainingDone(e.target.value)}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenContact(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* Add Tickets Balance Log	 */}
            <Modal
                open={isOpenTicketLog}
                onClose={e => setIsOpenTicketLog(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addPOSTickets(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add Tickets Balance Log
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Date</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={addTicketBalanceID} />
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={ticketLogDate}
                                onChange={date => setTicketLogDate(date)}
                                placeholderText="Ticket Log Date"
                                className='form-control rounded-none'
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Ticket Balance</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Ticket Balance" required="required" value={addTicketBalanceQty} onChange={e => setAddTicketBalanceQty(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Comments</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Comments" required="required" value={addTicketBalanceComments} onChange={e => setAddTicketBalanceComments(e.target.value)} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenTicketLog(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* Add Pochette Balance Log */}
            <Modal
                open={isOpenPochetteLog}
                onClose={e => setIsOpenPochetteLog(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...styleNew, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => addPOSPochettes(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add Pochette Balance Log
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Date</b></label>
                        <div className="col-8"> 
                            <input type="hidden" value={addPochetteBalanceID} />
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={pochetteLogDate}
                                onChange={date => setPochetteLogDate(date)}
                                placeholderText="Pochette Log Date"
                                className='form-control rounded-none'
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Pochette Balance</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" placeholder="Enter Pochette Balance" required="required" value={addPochetteBalanceQty} onChange={e => setAddPochetteBalanceQty(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="" className="col-4 col-form-label"><b>Comments</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" placeholder="Enter Comments" required="required" value={addPochetteBalanceComments} onChange={e => setAddPochetteBalanceComments(e.target.value)} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={false}>Confirm</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => setIsOpenPochetteLog(false)}>Cancel</button>
                    </div>
                </form>
                </Box>
            </Modal>

        </div>

        <div className='d-lg-none' style={{ padding: "0px 10px" }}>

        <div className="d-flex justify-content-between align-items-center border-b fixed top-0 left-0 z-20 w-100 bg-white px-3 py-3">
            <div onClick={() => window.history.back()}>
            <ArrowBackOutlinedIcon />
            </div>

            <p className="font-semibold line-clamp-1 text-sm">
            {allPOS!='' && allPOS.pos_name!='undefined' ? allPOS.pos_name:'Loading...'} 
            </p>

            <div></div>
        </div>

        <div className="border rounded-sm shadow-sm">
            <p className="font-semibold px-3 py-2 text-orange-500">Overview</p>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
            <p className="text-secondary text-nowrap">Location :</p>
            <p className="font-medium text-right">
             {allPOS!='' && allPOS.location!='undefined' ? allPOS.location:'Loading...'}
            </p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1" style={{backgroundColor : '#F7F7F7'}}>
            <p className="text-secondary text-nowrap">Operational hours :</p>
            <p className="font-medium text-right">
               {allPOS!='' && allPOS.operating_days_hours!='undefined' && 
                 <div dangerouslySetInnerHTML={{__html:allPOS.operating_days_hours}}></div>
               }
            </p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
            <p className="text-secondary text-nowrap">Landline :</p>
            <p className="font-medium text-right">
            {allPOS!='' && allPOS.phones!='undefined' ? allPOS.phones:'Loading...'}
            </p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1" style={{backgroundColor : '#F7F7F7'}}>
            <p className="text-secondary text-nowrap">Email :</p>
            <p className="font-medium text-right">
            {allPOS!='' && allPOS.email!='undefined' ? allPOS.email:'Loading...'}
             <br />
            {allPOS!='' && allPOS.email1!='undefined' ? allPOS.email1:'Loading...'}
             <br />
            {allPOS!='' && allPOS.email2!='undefined' ? allPOS.email2:'Loading...'}            </p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-between px-3 py-1">
            <p className="text-secondary text-nowrap">Status :</p>
            <p className="font-medium text-right">
            {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Fully Operational' &&
              <b className="text-white bg-green-600 px-2 py-1 text-xs mt-2 rounded-full">{allPOS.status}</b>
            }
            {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Partially Operational' &&
              <b className="bg-orange-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{allPOS.status}</b>
            }
            {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Not Operational' &&
              <b className="bg-blue-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{allPOS.status}</b>
            }
            {allPOS!='' && allPOS.status!='undefined' && allPOS.status=='Closed' &&
              <b className="bg-red-600 text-white px-2 py-1 text-xs mt-2 rounded-full">{allPOS.status}</b>
            }            
            </p>
            </div>
            <Divider />

            <div className="text-sm d-flex gap-1 justify-content-end px-3 py-2">
            <p className="text-orange-500 text-nowrap">Edit Info</p>
            </div>
        </div>

        <div className=" mt-3 border rounded-sm shadow-sm">
        <p className="font-semibold px-3 py-2 text-orange-500">POS Staff</p>
            <Divider />

    
        {allStaffs.length>0 && allStaffs.map((staff, index) => (
            <POSStaff staff={staff}/>
        ))}
        </div>

        <div onClick={() => handleTBL()} className="px-3 py-2 mt-3 border rounded-sm shadow-sm d-flex align-items-center justify-content-between">
         <p className="text-orange-500 font-semibold">Tickets Balance Logs</p>
        </div>

        <div className="overflow-scroll">
            <table className="table table-ticket-sale table-striped">
                                <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col text-nowrap">Tickets Balance</th>
                                        <th scope="col">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTicketBalance.length>0 && allTicketBalance.map((ticket, index) => (
                                    <tr key={index} className="text-xs">
                                        <td scope="col">{ticket.date_time}</td>
                                        <td scope="col">{ticket.balance}</td>
                                        <td scope="col">{ticket.comments}</td>
                                    </tr>
                                    ))}
                                </tbody>
            </table>
        </div>

        {/* <Drawer anchor='bottom' open={TBLDrawer} onClose={handleTBL}>
         <div style={{ padding: 20 , height : '70vh' }} >
         {allTicketBalance.length>0 && allTicketBalance.map((ticket, index) => (
            <>
            <div key={index} className="text-sm py-2">
             <p className="text-secondary">Date : <span className="font-semibold text-black">{ticket.date_time}</span></p>
             <p className="text-secondary">Ticket Balance : <span className="font-semibold text-black">{ticket.balance}</span></p>
             <p className="text-secondary">{ticket.comments}</p>
            </div>
            <Divider/>
            </>
             ))}
         </div>
        </Drawer> */}

        <div onClick={() => handleTBL()} className="px-3 py-2 mt-3 border rounded-sm shadow-sm d-flex align-items-center justify-content-between">
         <p className="text-orange-500 font-semibold">
            Pochette Balance Logs</p>
        </div>

        <div className="overflow-scroll">
            <table className="table table-ticket-sale table-striped">
                                <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Pochette Balance</th>
                                        <th scope="col">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPochetteBalance.length>0 && allPochetteBalance.map((pochette, index) => (
                                    <tr key={index} className="text-xs">
                                        <td scope="col">{pochette.date_time}</td>
                                        <td scope="col">{pochette.balance}</td>
                                        <td scope="col">{pochette.comments}</td>
                                    </tr>
                                    ))}
                                </tbody>
            </table>
        </div>

        {/* <Drawer anchor='bottom' open={TBLDrawer} onClose={handleTBL}>
         <div style={{ padding: 20 , height : '70vh' }} >
         {allPochetteBalance.length>0 && allPochetteBalance.map((pochette, index) => (
            <>
            <div key={index} className="text-sm py-2">
             <p className="text-secondary">Date : <span className="font-semibold text-black">{pochette.date_time}</span></p>
             <p className="text-secondary">Ticket Balance : <span className="font-semibold text-black">{pochette.balance}</span></p>
             <p className="text-secondary">{pochette.comments}</p>
            </div>
            <Divider/>
            </>
             ))}
         </div>
        </Drawer> */}

        </div>
        </>
    );
}

export default PosSingle;