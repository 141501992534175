import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";
import Select from 'react-select';

import EventsService from '../services/events.service';

const FinancialLogs = (props) => {

    const [eventID, setEventID] = useState(props.eventid);
    const [logs, setLogs] = useState('');
    const [isopen, setIsopen] = useState(false);

    const getLogs = () => {
        EventsService.getFinancialLogs(eventID)
        .then(response => {
            console.log('Financial Logs response', {response});
            setLogs(response.data);
        })
        .catch(err => console.log({err}));
    };
    

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "96%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <div className="text-orange-600 p-0 text-sm cursor-pointer underline font-semibold" onClick={e => {setIsopen(true); getLogs();}}>View Logs ></div>
            <Modal
                open={isopen}
                onClose={e => {setIsopen(false); props.doneLinking();}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: "96%", textAlign: 'center' }}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Event Financial Logs
                        </div>
                    </div>
                     <div className="row">
                        <table className="table table-striped table-bordered">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className='text-sm'>
                                <tr>
                                    <th>Event ID</th>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Date Processed</th>
                                    <th>Amount Type</th>
                                    <th>Amount</th>
                                    <th>Method of Payment</th>
                                    <th>Reference</th>
                                    <th>Additional Info</th>
                                    <th>Added By</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th>Added On</th>
                                </tr>
                            </thead>
                            <tbody className='text-sm'>
                                {logs.length>0 && logs.map((log, index) => (
                                    <tr key={index}>
                                        <td>{log.eventid}</td>
                                        <td>{log.lastid}</td>
                                        <td>{log.type}</td>
                                        <td>{log.dateprocessed}</td>
                                        <td>{log.amounttype}</td>
                                        <td>{log.amount}</td>
                                        <td>{log.methodofpayment}</td>
                                        <td>{log.reference}</td>
                                        <td>{log.additionalinfo}</td>
                                        <td>{log.addedby}</td>
                                        <td>{log.status}</td>
                                        <td>{log.action}</td>
                                        <td>{log.added_on}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => {setIsopen(false); props.doneLinking();}}>Close</button>
                    </div>
                </Box>
            </Modal>
            </>
        );
}

export default FinancialLogs;