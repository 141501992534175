import { useEffect, useState } from "react";
import { style } from "@mui/system";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
//import timeGridPlugin from "@fullcalendar/timegrid";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import axios from "axios";


import AuthService from "../services/auth.service";
import UtilityService from "../services/utility.service";

import "../styles/Posmain.css"


const APIURL = "https://otayo.com/erpapi/vsp.php";

function renderEventContent(eventInfo) {
    console.log(eventInfo);
  return (
    <>
      {eventInfo.event._def.title}
    </>
  )
}

const VSPCalendar = () => {

    const [data, setData] = useState([]);
    const [loading, setloading] = useState('Loading...');
   

    useEffect(() => {
        AuthService.checklogin()
        .then(user => {
            console.log("user", user)
            if(user!=false) {
                getData();
            }     
        })
        .catch(err => {
            console.log(err);
        });
        
    }, []);

    const getData = () => {
        var action = 'calendarView';
        var params = {
            action: action,
        }

        axios.post(APIURL+"?rand="+Math.random(), params)
        .then(response => {
            setData(response.data);
            setloading(false);
            console.log("🚀 ~ file: vspCalendar.js:64 ~ getData ~ response.data", response.data)
        })
        .catch(err => console.log(err));
    }


    return (
        <div className="salesreport-wrapper p2">
            <div className="salesreport shadow-lg p-4">
                <div className="row mb-4">
                    <div className="col-12 flex justify-between items-center">
                        <h1 className="eventname font-bold text-lg mb-2 text5e4642">On-site Services Calendar <small className="text-red-600">{loading}</small></h1>
                        <div>
                        <a href="/vsp-staff" className="btn btn-warning btn-orange ml-4 font-semibold text-sm">On-site Staff List</a>
                        <a href="/vsp" className="btn btn-warning btn-yellow ml-4 font-semibold text-sm">On-site Overview</a>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 table-ticket-sale">
                        {/* add calendar here */}
                         <FullCalendar
                            defaultView="dayGridMonth"
                            header={{
                            left: "prev,next",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                            }}
                            themeSystem="Simplex"
                            plugins={[dayGridPlugin]}
                            events={data}
                            eventContent={renderEventContent}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default VSPCalendar;