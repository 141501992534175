import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDashboard,
  faCalendar,
  faShoppingBag,
  faUser,
  faFileInvoiceDollar,
  faStore,
  faPeopleGroup,
  faBell,
  faTicket,
  faMailBulk,
  faUserGroup,
  faRightFromBracket,
  faSort
} from '@fortawesome/free-solid-svg-icons';

import WidgetsIcon from '@mui/icons-material/Widgets';

import MenuIcon from '@mui/icons-material/Menu';
import '../../styles/Navbar/SidebarMobile.css';

const SidebarMobile = ({isLoggedIn}) => {


    const navigationLinks = [
      {
        icon: faDashboard,
        iconColor: '#f36523',
        iconSize: '1x',
        link: '/dashboard',
        text: 'Dashboard',
      },
      
      {
        icon: faCalendar,
        iconColor: '#f36523',
        iconSize: '1x',
        link: '/current-events',
        text: 'Events',
      },
      {
        icon: faShoppingBag,
        iconColor: '#f36523',
        iconSize: '1x',
        link: '/orders',
        text: 'Orders',
      },
      {
        icon: faFileInvoiceDollar,
        iconColor: '#3bb979',
        iconSize: '1x',
        link: '/sales',
        text: 'Sales Report',
      },
      {
        icon: faTicket,
        iconColor: '#d59ffd',
        iconSize: '1x',
        link: '/onsite-selling',
        text: 'OnSite Selling',
      },
        {
          icon: faUser,
          iconColor: '#f4979f',
          iconSize: '1x',
          link: '/producer-list',
          text: 'Producers',
        },
        {
        icon: faStore,
        iconColor: '#03a9f4',
        iconSize: '1x',
        link: '/pos-dashboard',
        text: 'POS Dashboard',
      },
        // {
        //   icon: faDashboard,
        //   iconColor: '#f36523',
        //   iconSize: '1x',
        //   link: '/dashboard',
        //   text: 'Dashboard',
        // },
        // {
        //   icon: faPeopleGroup,
        //   iconColor: '#03a9f4',
        //   iconSize: '1x',
        //   link: '/vsp',
        //   text: 'VSP',
        // },
        // {
        //   icon: faBell,
        //   iconColor: '#8293c9',
        //   iconSize: '1x',
        //   link: '/customer-notifications',
        //   text: 'Notifications',
        // },
        // {
        //   icon: faMailBulk,
        //   iconColor: '#7c83db',
        //   iconSize: '1x',
        //   link: '/newsletter',
        //   text: 'Newsletter',
        // },
        // {
        //   icon: faUserGroup,
        //   iconColor: '#7c83db',
        //   iconSize: '1x',
        //   link: '/invitations',
        //   text: 'Invitations',
        // },
        // {
        //   icon: faSort,
        //   iconColor: '#000000',
        //   iconSize: '1x',
        //   link: 'home-mobile-slider',
        //   text: 'Mobile Slider',
        // }            
      ];


  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    console.log('is login ' , isLoggedIn)
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <div className='sidebar'>
      <IconButton disabled={!isLoggedIn} onClick={toggleSidebar}>
        <MenuIcon style={{ color: 'black' ,fontSize: 24}}/>
      </IconButton>

      <Drawer
        anchor='left'
        open={isOpen}
        onClose={toggleSidebar}
      >
        <div className='sidebar_items'>

        {navigationLinks.map((link, index) => (
        <div onClick={closeSidebar} key={index} className='nav-link'>
          <Link to={link.link} className='d-flex align-items-center gap-3'>
            <FontAwesomeIcon icon={link.icon} color={link.iconColor} size={link.iconSize} width={"20px"} />
                <p>{link.text}</p>
          </Link>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </div>
      ))}     
        </div>
      </Drawer>
    </div>
  );
}

export default SidebarMobile;