function PageHeading(props) {
  return (
    <div className="row">
        <div className="col heading-h1-wrapper">
            <h1>{props.heading}</h1>
            <hr />
        </div>
    </div>
  );
}

export default PageHeading;