import React from 'react';
import { CSVLink } from 'react-csv';

const ExportAdvancedSalesCSV = ({ advanceSalesReport }) => {
  let CSVData = [];

  if (advanceSalesReport !== null) {
    CSVData = advanceSalesReport.map(item => [
      item.eventname,
      item.eventdate,
      item.totalsold,
      item.totalsales,
      item.nooforders_ui,
      item.avgamount_ui,
      Math.floor(item.avgticketsperorder_ui),
    ]);
  }

  const headers = [
    'Event Name',
    'Event Date',
    'Ticket Sold',
    'Ticket Sales',
    'Total Orders',
    'Average Amount',
    'Average Order',
  ];

  return (
    <div>
      {advanceSalesReport !== null ? (
        <div className='bg-orange-600 text-white px-2 cursor-pointer py-1 hover:bg-orange-800 border'>
        <CSVLink data={[headers, ...CSVData]} filename={'sales_report.csv'} style={{cursor : 'pointer'}}>
          Download CSV
        </CSVLink>
        </div>
      ) : (
        <p className='text-xs'>No CSV data to export</p>
      )}
    </div>
  );
};

export default ExportAdvancedSalesCSV;
