import { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import AuthService from "../services/auth.service";
import NotificationsService from "../services/notifications.service";
import FileUploader from "../components/fileuploader.component";

import "../styles/CustomerNotifications.css";


const CustomerNotifications = (props) => {
    // const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);

    const [paymnetMethods, setPaymentMethod] = useState([
        { value: 'All', label: 'All Methods' },
        { value: 'juice', label: 'Juice' },
        { value: 'cash', label: 'Cash' },
        { value: 'migs', label: 'Credit Card' },
        { value: 'banktransfer', label: 'Back Transfer' },
        { value: 'terminal', label: 'Terminal' },
    ]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([{ value: 'All', label: 'All Methods' }]);

    const [orderStatus, setOrderStatus] = useState([
        { value: 'All', label: 'All Status' },
        { value: 'wc-pending', label: 'Pending' },
        { value: 'wc-processing', label: 'Paid' },
        { value: 'wc-cancelled', label: 'Cancelled' },
        { value: 'wc-refunded', label: 'Refunded' },
    ]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState([{ value: 'All', label: 'All Status' }]);

    const [ticketCategory, setTicketCategory] = useState([
        { value: 'All', label: 'All Categories' },
    ]);
    const [selectedTicketCategory, setSelectedTicketCategory] = useState([{ value: 'All', label: 'All Categories' }]);
    
    const [ticketType, setTicketType] = useState([
        { value: 'All', label: 'All Types' },
        { value: 'normal', label: 'Normal' },
        { value: 'invites', label: 'Invites' },
    ]);
    const [selectedTicketType, setSelectedTicketType] = useState({ value: 'All', label: 'All Types' });

    const [sellingChennels, setSellingChennels] = useState([
        { value: 'All', label: 'All Channels' },
        { value: 'online', label: 'Online' },
        { value: 'pos', label: 'POS' },
    ]);
    const [selectedSellingChannels, setSelectedSellingChannels] = useState({ value: 'All', label: 'All Channels' });
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minDate, setMinDate] = useState('');

    const [emails, setEmails] = useState(null);
    const [emailsComma, setEmailsComma] = useState(null);
    const [emailSubject, setEmailSubject] = useState(null);
    const [emailBody, setEmailBody] = useState(null);
    const [fetchText, setFetchText] = useState("Find Emails");
    const [emailsExcludeComma, setEmailsExcludeComma] = useState(null);
    const [previewEmails, setPreviewEmails] = useState(false);
    const [previewEmailHtml, setPreviewEmailHtml] = useState(null);
    const [sendEmailText, setSendEmailText] = useState("Send Email >>");
    const [previewEmailText, setPreviewEmailText] = useState("Preview Email");
    const [resendEmailLang, setResendEmailLang] = useState('en');
    const [resendEmailAttachment, setResendEmailAttachment] = useState('');

    useEffect(() => {
        NotificationsService.getTicketCategories()
            .then((response) => {
                setTicketCategory([{ value: 'All', label: 'All Categories' }, ...response.data]);
            })
            .catch((e) => {
                console.log(e);
            });

    }, []);

    const setStartDateCal = (date) => {

        setStartDate(date);
        setMinDate(date);

        if (endDate != '' && endDate < date) {
            setEndDate(date);
        }
    }

    const setEndDateCal = (date) => {

        setEndDate(date);
    }

    const findEmails = () => {

        setFetchText("Fetching Emails...");
        console.log("test");
        NotificationsService.getEmails(selectedOrderStatus, selectedPaymentMethod, selectedTicketCategory, selectedTicketType.value, selectedSellingChannels.value, startDate, endDate)
            .then((response) => {
                console.log(response.data);
                setEmails(response.data);
                setEmailsComma(response.data.join(","));
                setFetchText("Find Emails");
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const getExport = () => {

    }

    const sendEmail = () => {
        setSendEmailText("Sending Emails...");
        NotificationsService.SendEmails(emailsComma, emailSubject, emailBody, emailsExcludeComma, 'no', resendEmailLang, resendEmailAttachment)
        .then((response) => {
            console.log(response.data);
            setPreviewEmailHtml(response.data);
            setPreviewEmails(true);
            setSendEmailText("Send Email >>");
            swal("Emails Sent!", "Emails have been sent successfully!", "success");
        })
        .catch((e) => {
            console.log(e);
        });
    }

    const previewEmail = (e) => {
        console.log("preview emails");
        setPreviewEmailText("Fetching Preview...");
        NotificationsService.SendEmails(emailsComma, emailSubject, emailBody, emailsExcludeComma, 'yes', resendEmailLang, resendEmailAttachment)
            .then((response) => {
                console.log(response.data);
                setPreviewEmailHtml(response.data);
                setPreviewEmails(true);
                setPreviewEmailText("Preview Email");
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <div className="customernotifications-wrapper p2">
            <div className="customernotifications shadow-lg p-4">
                <div className="row">
                    <div className="col-12">
                        <h1 className="eventname font-bold text-lg text5e4642 mb-2">Customer Notifications</h1>
                    </div>
                </div>

                {/* <div className="row mt-3 mb-3">
                    <div className="col-12 flex">
                        <div className="text=base mr-4">Event Name: </div><div>select 2</div>
                    </div>
                </div> */}

                <div className="row mt-3 mb-3">
                    <div className="col-12 flex flex-col">
                        <div className="font-bold">Choose Filtering Criteria: </div>
                        <table className="table">
                            <thead style={{backgroundColor: "#e6e6e6", color:"#8b8b8b", textTransform: "uppercase"}} className="text-sm">
                                <tr>
                                    <th scope="col">Order Status</th>
                                    <th scope="col">Payment Gateway</th>
                                    <th scope="col">Event + Ticket Category</th>
                                    <th scope="col">Ticket Type</th>
                                    <th scope="col">Selling Channel</th>
                                    <th scope="col">Order From</th>
                                    <th scope="col">Order To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=" text-sm">
                                    <td>
                                       <Select
                                            value={selectedOrderStatus}
                                            onChange={(option) => setSelectedOrderStatus(option)}
                                            options={orderStatus}
                                            isMulti = {true}
                                            placeholder="All Order Status"
                                        />     

                                    </td>
                                    <td>
                                        <Select
                                            value={selectedPaymentMethod}
                                            onChange={(option) => setSelectedPaymentMethod(option)}
                                            options={paymnetMethods}
                                            isMulti = {true}
                                            placeholder="All Payment Methods"
                                        />   
                                    </td>
                                    <td>
                                        <Select
                                            value={selectedTicketCategory}
                                            onChange={(option) => setSelectedTicketCategory(option)}
                                            options={ticketCategory}
                                            isMulti = {true}
                                            placeholder="All Ticket Categries"
                                        />   
                                    </td>
                                    <td>
                                        <Select
                                            value={selectedTicketType}
                                            onChange={(option) => setSelectedTicketType(option)}
                                            options={ticketType}
                                            placeholder="All Ticket Types"
                                        />   
                                    </td>
                                    <td>
                                        <Select
                                            value={selectedSellingChannels}
                                            onChange={(option) => setSelectedSellingChannels(option)}
                                            options={sellingChennels}
                                            placeholder="All Selling Channels"
                                        />   
                                    </td>
                                    <td>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            onChange={date => setStartDateCal(date)}
                                            placeholderText="Start Date"
                                            className='form-control'
                                        />
                                    </td>
                                    <td>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={endDate}
                                            onChange={date => setEndDateCal(date)}
                                            placeholderText="End Date"
                                            className='form-control'
                                            minDate = {minDate}
                                        />
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-12 flex">
                        <div className="text=base mr-4 font-semibold text-black">For Selected Customers </div>
                        <button className="btn btn-success btn-sm bg-green-800 hover:bg-green-700 text-white font-semibold mr-4 rounded-none" onClick={findEmails}>{fetchText}</button>
                        {/* <button className="btn btn-sm bg-color-orange hover:bg-orange-700 text-white font-semibold mr-4 rounded-none disabled:bg-slate-400" disabled={emails==null} onClick={getExport}>Export Selected</button> */}
                    </div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        {/* <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-100"> */}
                        <div className="form-group mb-4">
                            {/* include validation with required or other standard HTML validation rules */}
                            <label className="font-semibold mb-2">Email Addresses</label>
                            <textarea placeholder="Email Addresses" value={emailsComma} onChange={(e) => setEmailsComma(e.target.value)} className="form-control" rows={10} />
                        </div>
                        <div className="form-group mb-4">
                            {/* include validation with required or other standard HTML validation rules */}
                            <label className="font-semibold mb-2">Exclude Email Addresses (Enter coma-saperated emails to exclude)</label>
                            <textarea placeholder="Past Email Addresses to exclude from sending" value={emailsExcludeComma} onChange={(e) => setEmailsExcludeComma(e.target.value)} className="form-control" rows={10} />
                        </div>
                        {/* register your input into the hook by invoking the "register" function */}
                        <div className="form-group mb-4">
                            <label className="font-semibold mb-2">Language</label>
                            <select className="form-control" value={resendEmailLang} onChange={e => setResendEmailLang(e.target.value)}>
                                <option value="en">English</option>
                                <option value="fr">French</option>
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <label className="font-semibold mb-2">Subject</label>
                            <input placeholder="Email Subject" value={emailSubject} onChange={e => setEmailSubject(e.target.value)} className="form-control" />
                        </div>
                        
                        <div className="form-group mb-4">
                            {/* include validation with required or other standard HTML validation rules */}
                            <label className="font-semibold mb-2">Body</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={emailBody}
                                hei={10}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setEmailBody(data);
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>

                        <div className="form-group mb-4">
                            <label className="font-semibold mb-2">Subject</label>
                            <FileUploader event={0} metakey="" onChange={(url) => setResendEmailAttachment(url)} />
                        </div>
                        {/* errors will return when field validation fails  */}
                        
                        <button className="btn btn-warning rounded-none w-40 mt-4 mr-4 bg-orange-600 hover:bg-orange-800 click font-bold text-white active:bg-orange-600 active:border-none" onClick={(e) => previewEmail(e)} disabled={previewEmailText=='Fetching Preview...'}>{previewEmailText}</button>
                        <input type="button" className="btn btn-success rounded-none w-40 mt-4 bg-green-700 border-r-0 font-bold" disabled={emails==null || sendEmailText=='Sending Emails...'} onClick={(e) => sendEmail(e)} value={sendEmailText} />
                        {/* </form> */}
                    </div>
                </div>
                {previewEmailHtml!=null && 
                    <div className="html" dangerouslySetInnerHTML={{__html: previewEmailHtml}} />
                }
                <div></div>
            </div>
        </div>
    );
}

export default CustomerNotifications;