import React from 'react'
import {Divider } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

const LastOrderCard = ({data , index}) => {
//  console.log(data )
return (
<div key={index} className='border rounded-sm shadow-sm'>
     <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
       <p className='font-semibold text-sm'><span className=''>ID:</span> {data.id}</p>
        {data.status == 'Cancelled' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-red-600'>Cancelled</p> 
        }

        {data.status == 'Pending' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-yellow-500'>Pending</p> 
        }

        {data.status == 'Processing' &&
         <p className='text-white px-2 py-1 rounded-sm text-xs bg-green-500'>Processing</p> 
        }
    
     </div>

     <Divider  />

     <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Date :</p>
          <p className='font-medium line-clamp-1 text-right'>{data.date}</p>
      </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
          <p className='text-secondary text-nowrap'>Customer :</p>
          <p className='font-medium text-right'>{data.customer}</p>
      </div>       

     <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
          <p className='text-secondary text-nowrap'>Event :</p>
          <p className='font-medium line-clamp-1 text-right'>{data.events}</p>
      </div>    

      <Divider />

     <div className='px-3 py-2 d-flex justify-content-between align-items-center '>
        <p className='font-semibold text-base'>Rs {data.total} </p>
        <a href={`/edit-order/${data.id}`} className='font-semibold text-orange-500 underline text-xs py-1 rounded-sm '>View All Details <NavigateNextOutlinedIcon style={{fontSize : 15}}/></a>
     </div>
</div>
)
}

export default LastOrderCard