import React, { useState } from 'react';
import { Divider, Drawer } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

const EventCard = ({ eventData }) => {

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className='border rounded-sm shadow-sm'>
      <div className='px-3 py-2 d-flex justify-content-between align-items-center py-1'>
        <p className='font-semibold text-base'>{eventData.eventname}</p>
        <p className='text-xs text-secondary'></p>
      </div>

      <Divider />
    
        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary'>Event Date:</p>
            <p className='font-medium'>{eventData.eventdate}</p>
        </div>

        <Divider />

        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Event ID:</p>
            <p className='font-medium'>{eventData.id}</p>
        </div>

        <Divider />


        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary text-nowrap'>Producer Name :</p>
            <a href={`/edit-producer/${eventData.producer.id}`} className='underline font-medium text-orange-500 text-right line-clamp-1'>{eventData.producer.name}</a>
        </div>

        <Divider />

        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Producer ID :</p>
            <p className='font-medium'>{eventData.producer.id}</p>
        </div>

        <Divider />

        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary'>Last 5 Days Sales:</p>
            <p className='font-medium'>{eventData.last5days}</p>
        </div>

        <Divider />

        <Divider />

        <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'  style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Total Invites :</p>
            <p className='font-medium'>{eventData.totalinvites}</p>
        </div>


        <Divider />

        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary'>Ticket Allocated :</p>
            <p className='font-medium'>{eventData.ticketsallocated}</p>
        </div>

        <Divider />

        
        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Total Reserved :</p>
            <p className='font-medium'>{eventData.totalreserved}</p>
        </div>

        <Divider />


        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' >
            <p className='text-secondary'>Remaining :</p>
            <p className='font-medium'>{eventData.remaining}</p>
        </div>

        <Divider />
        


        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Total Sold :</p>
            <p className='font-medium'>{eventData.totalsold}</p>
        </div>


        <Divider />

        <div className='px-3 py-1 text-sm d-flex justify-content-between gap-1'>
            <p className='text-secondary'>Total Sales Without Discount :</p>
            <p className='font-medium'>Rs {eventData.totalsales}</p>
        </div>

        
        <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between' style={{backgroundColor : '#F7F7F7'}}>
            <p className='text-secondary'>Collab :</p>
            <p className='font-medium'>{eventData.collab}</p>
        </div>    

      <Divider />

      <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between'>
            <p className='text-secondary'>Seated :</p>
            <p className='font-medium'>{eventData.seated}</p>
        </div>    

      <Divider />

      <div className='d-flex justify-content-between align-item-center text-sm px-3 py-2'>
        <p className='text-orange-600 text-xs mt-1' onClick={() => handleDrawer()}>
        {eventData.remaining < 50 && eventData.remaining > 0 && <span className='py-1 bg-red-500 text-white font-semibold rounded-pill'>
         Low stock
        </span> }
        {eventData.last5days > 100 && 
        <span className='py-1 text-green-500 font-semibold rounded-pill'>
          Selling fast
        </span> }
        </p>

        <a href={`/event/${eventData.id}`} className='text-white bg-orange-600 rounded-pill px-2 font-semibold text-xs py-1'>
          View Full Report <NavigateNextOutlinedIcon style={{ fontSize: 15 }} />
        </a>
      </div>

      {/* <Drawer anchor='top' open={drawerOpen} onClose={handleDrawer}>
        <div style={{ padding: 16 }} className='d-flex flex-col gap-3'>
              
        <div className='text-sm'>
            <p className='text-secondary'>Producer Name :</p>
            <p>{eventData.producer.name}</p>
         </div>
         <div className='text-sm'>
            <p className='text-secondary'>Producer Id :</p>
            <p>{eventData.producer.id}</p>
         </div>
         <div className='text-sm'>
            <p className='text-secondary'>Last 5 days sales :</p>
            <p>{eventData.last5days}</p>
         </div>
         <div className='text-sm'>
            <p className='text-secondary'>Ticket Allocated :</p>
            <p>{eventData.ticketsallocated}</p>
         </div>

         <div className='text-sm'>
            <p className='text-secondary'>Total Sold :</p>
            <p>{eventData.totalsold}</p>
         </div>

         <div className='text-sm'>
            <p className='text-secondary'>Reserved Tickets :</p>
            <p>{eventData.totalreserved}</p>
         </div>

         <div className='text-sm'>
            <p className='text-secondary'>Remaining :</p>
            <p>{eventData.remaining}</p>
         </div>

         <div className='text-sm'>
            <p className='text-secondary'>Total Sales Without Discount :</p>
            <p>Rs {eventData.totalsales}</p>
         </div>

        </div>
      </Drawer> */}
    </div>
  );
};

export default EventCard;
