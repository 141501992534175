import axios from "axios";

const APIURL = "https://otayo.com/erpapi/producers.php";

class ProducersService {

    getAllProducers(search = '', page = 0, limit= 20, count=0) {
        var params = {
            action: "getAllProducers",
            search: search,
            page: page,
            limit: limit,
            count: count
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }   

    addProducer(producer) {
        var params = {
            action: "addProducer",
            producer: producer
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getProducerData(producerId) {
        var params = {
            action: "getProducerData",
            producerId: producerId
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getProdContacts(producerId) {
        var params = {
            action: "getProdContacts",
            producerId: producerId
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    addProducerContact(id, contact, user) {
        var params = {
            action: "addProducerContact",
            id: id,
            contact: contact,
            user: user
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    deleteProdContact(id) {
        var params = {
            action: "deleteProdContact",
            id: id
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    getProdEventStats(producerId) {
        var params = {
            action: "getProdEventStats",
            producerId: producerId
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    saveProdAccountDetails(producerID, bank_account_name, bank_name, account_details, bank_supporting_details, user) {
        var params = {
            action: "saveProdAccountDetails",
            producerID: producerID,
            bank_account_name: bank_account_name,
            bank_name: bank_name,
            account_details: account_details,
            bank_supporting_details: bank_supporting_details,
            user: user
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    updatePassword(producerID, newPassword) {
        var params = {
            action: "updatePassword",
            producerID: producerID,
            newPassword: newPassword,
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }
    
    linkEvent(prodID, prodEmail, selectedEvent) {
            var params = {
                action: "linkEvent",
                prodID: prodID,
                prodEmail: prodEmail,
                selectedEvent: selectedEvent,
    
            }
            return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    sendUserDetails(producerID, email) {
        var params = {
            action: "sendUserDetails",
            producerID: producerID,
            email: email
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    updateUserMeta(producerID, key, value) {
        var params = {
            action: "updateUserMeta",
            producerID: producerID,
            key: key,
            value: value
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);
    }

    updateMainContact(producerID, phone, email, name) {
        var params = {
            action: "updateMainContact",
            prodID: producerID,
            phone: phone,
            email: email,
            name: name
        }
        return axios.post(APIURL+"?rand="+Math.random(), params);

    }

}


export default new ProducersService();