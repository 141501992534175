import React from 'react'
import { Divider } from '@mui/material'

const EventSingleSummery = ({event , contract}) => {
  return (
    <div className="border rounded-sm shadow-sm">
                <p className='font-semibold px-3 py-2 text-orange-500'>Summery</p>
                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                  <p className='text-secondary'>Producer :</p>
                  <p className="font-medium">{event!=null && event.producer}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between 'style={{backgroundColor : '#F7F7F7'}}>
                  <p className='text-secondary text-nowrap'>Event Location:</p>
                  <p className="font-medium text-right">{event!=null && event.location}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                  <p className='text-secondary'>Event Start Date:</p>
                  <p className="font-medium">{event!=null && event.eventstartdate}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between ' style={{backgroundColor : '#F7F7F7'}}>
                  <p className='text-secondary'>Event End Date:</p>
                  <p className="font-medium">{event!=null && event.eventenddate}</p>
                </div>

                <Divider />

                <div className='px-3 py-1 text-sm d-flex gap-1 justify-content-between '>
                  <p className='text-secondary'>Seated Event:</p>
                  <p className="font-medium">{event!=null && event.seated}</p>
                </div>
                

                <Divider />

                <div className="px-3 py-2 d-flex justify-content-end">
                    <a href={contract} className="underline text-orange-500 text-sm" target="_blank"> View Contract </a>
                </div>

            </div>
  )
}

export default EventSingleSummery