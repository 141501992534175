import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";
import Select from 'react-select';

import ProducersService from '../services/producers.service';

const LinkToEvent = (props) => {

    const [prodID, setProdID] = useState(props.prodid);
    const [prodEmail, setProdEmail] = useState(props.prodemail);
    const [events, setEvents] = useState(props.events);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [disableLinkbtn, setDisableLinkbtn] = useState(false);
    const [linktext, setLinktext] = useState('Link to Event');
    const [isopen, setIsopen] = useState(true);

    const LinkEvent = (e) => {
        e.preventDefault();
        if(selectedEvent!=null) {
             setLinktext('Linking...');
            setDisableLinkbtn(true);
            ProducersService.linkEvent(prodID, prodEmail, selectedEvent.value)
            .then(response => {
                console.log('Link Event response', {response});
                setLinktext('Link to Event');
                setDisableLinkbtn(false);
                setIsopen(false);
                swal('Event Linked', "The event has been linked", "success");
                props.doneLinking();
            })
            .catch(err => console.log({err}));
        } else {
            swal('Select Event', "Please select an event", "error");
        }
    }
    

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <Modal
                open={isopen}
                onClose={e => {setIsopen(false); props.doneLinking();}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => LinkEvent(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Link To Event
                        </div>
                    </div>
                     <div className="form-group row">
                        <label for="chosenitems" className="col-4 col-form-label"><b>Choose Event</b></label>
                        <div className="col-8">
                            <Select
                                value={selectedEvent}
                                onChange={(option) => setSelectedEvent(option)}
                                options={events}
                            />   
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success bg-green-800" style={{marginRight: "20px"}} disabled={disableLinkbtn}>{linktext}</button><button type="button" className="btn btn-sm btn-danger bg-red-500" onClick={e => {setIsopen(false); props.doneLinking();}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
}

export default LinkToEvent;